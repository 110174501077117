import styled from "@emotion/styled";
import { PopperProps as PopperPropsType } from "@mui/material";
import { colors, shadow } from "../../styles/styles";

export const Container = styled.div<{ needPeriodSwitcher?: boolean }>`
  width: ${({ needPeriodSwitcher }) => (needPeriodSwitcher ? "calc(195px + 300px)" : "195px")};
  // (195px) - ширина переключателя дат
  // (300px) - ширина переключателя периодов
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 16px;

  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
`;

export const DateSwitcher = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 99em;
  text-align: center;

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const DateSwitcherText = styled.p`
  font-size: 13px;
  line-height: 21px;
  font-weight: 700;
  white-space: nowrap;
  color: ${colors.grayscaleBeautifulBlack};
  margin: 0 18px;
`;

export const PaperProps = {
  sx: {
    borderRadius: 4,
    boxShadow: shadow,
    padding: 1,

    "&:has(div)": { margin: "10px" },

    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root, .MuiPickersDay-root": {
      "&:hover, &:focus, &:active": {
        background: colors.grayscaleHoverBackground,
      },
    },

    ".PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersMonth-root.Mui-selected, .MuiPickersDay-root.Mui-selected ":
      {
        background: colors.primaryActiveElement,
        color: colors.grayscaleLable,

        "&:hover": {
          background: colors.grayscaleHoverBackground,
          cursor: "default",
        },

        "&:focus, &:active": {
          background: colors.primaryActiveElement,
          color: colors.grayscaleLable,
        },
      },
  },
};

export const PeriodSwitcherContainer = styled.div`
  width: 400px;
  height: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  background: ${colors.grayscaleInput};
  border-radius: 99em;
  margin: 0 16px 0 0;
  padding: 4px;
`;

export const PeriodSwitcherButton = styled.div<{ enabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ enabled }) => (enabled ? colors.white : colors.transparent)};
  border-radius: 99em;
`;

export const PeriodSwitcherButtonText = styled.p`
  cursor: pointer;
  font-size: 13px;
  line-height: 21px;
  font-weight: 700;
  color: ${colors.grayscaleBeautifulBlack};
`;

export const QuartersPicker = styled.div`
  font-size: 13px;
  line-height: 21px;
  font-weight: 700;
  color: ${colors.grayscaleBeautifulBlack};
`;

export const PopperProps = {
  sx: {
    ".MuiPaper-root": PaperProps.sx,
  },
} as Partial<PopperPropsType>;
