import { EmployeesRequestType, employeesApi } from "../../../api/apiEmployees";

// ------------------------------ ДАННЫЕ

export const fetchEmployees = async (props: EmployeesRequestType) =>
  (await employeesApi().getAll(props)).data.items;

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const fetchTotalCountEmployees = async (props: EmployeesRequestType) =>
  (await employeesApi().getAll(props)).data.totalCount;
