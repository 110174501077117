import { citiesApi } from "../../../../api/apiDictionaries";
import { PaginationType } from "../../../../api/types";

// ------------------------------ ДАННЫЕ

export const fetchCities = async (props: PaginationType & { query?: string }) =>
  (await citiesApi.getAll(props)).data.items;

// ------------------------------ ДАННЫЕ - ВСЕ

export const fetchAllCities = async () => {
  const totalCount = (await citiesApi.getAll({ startIndex: 0, size: 1 })).data.totalCount;

  return (await citiesApi.getAll({ startIndex: 0, size: totalCount })).data.items;
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const fetchTotalCountCities = async (props: PaginationType & { query?: string }) =>
  (await citiesApi.getAll(props)).data.totalCount;
