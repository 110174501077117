export const getRandomBySeed = (key: number, min: number, max: number) => {
  const random = ((key * 9301 + 49297) % 233280) / 233280;

  return min + random * (max - min);
};

export const getHSLForChart = (key: number) => {
  const keyNotNull = key + 1;
  const h = getRandomBySeed(keyNotNull * 4980, 0, 360);
  const s = getRandomBySeed(keyNotNull * 1935, 60, 80);
  const l = getRandomBySeed(keyNotNull * 8920, 40, 70);

  return { h, s, l };
};

export const getRandom = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};
