import { useAtom } from "jotai";
import * as Yup from "yup";
import {
  BigOtherType,
  EntitieType,
  disabilityCodesBlockTwoApi,
} from "../../../api/apiDictionaries";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { searchQueryState, selectedRowsState } from "../../../atoms/atoms";
import { useTable } from "../../../components/Table/useTable";
import { long, notJustSpaces, numbers, required } from "../../../constants/forValidationSchems";
import { useQuery } from "../../../utils/useQuery";
import { disabilityCodesBlockTwoState } from "./utils/atoms";

export const useDisabilityCodeBlockTwoDictionary = () => {
  let [disabilityCodesBlockTwo] = useAtom(disabilityCodesBlockTwoState);

  const [currentUser] = useAtom(currentUserState);
  const [selectedRows] = useAtom(selectedRowsState);
  const [query] = useAtom(searchQueryState);

  // ------------------------------ КЛЮЧИ ЗАПРОСА

  const queryKey = ["disabilityCodesBlockTwoState"];

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Коды нетрудоспособности (блок 2)";

  const addFormName = "disabilityCodeAddForm";
  const addFormTitle = "Код нетрудоспособности (блок 2)";

  const editFormName = "disabilityCodeEditForm";
  const editFormTitle = "Изменение кода нетрудоспособности (блок 2)";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const canViewDictionaries = hasUser && currentUser.data.roleModel.canViewDictionaries;
  const canEditDictionaries = hasUser && currentUser.data.roleModel.canEditDictionaries;

  // ------------------------------ ДАННЫЕ - IDS

  const disabilityCodeBlockTwoIds = disabilityCodesBlockTwo.map((datum) => datum.id);

  // ------------------------------ ДАННЫЕ - ФИЛЬТРАЦИЯ

  if (query) {
    disabilityCodesBlockTwo = disabilityCodesBlockTwo.filter((datum) => {
      const datumForSearch = {
        code: datum.code,
        name: datum.name,
        comment: datum.comment,
      };

      return Object.values(datumForSearch).join().toLowerCase().includes(query.toLowerCase());
    });
  }

  // ------------------------------ ДАННЫЕ - УДАЛЕНИЕ

  const deletion = useQuery().delete({ queryKey, api: disabilityCodesBlockTwoApi });

  const handleDeleteRows = async () => await deletion.mutateAsync(selectedRows);

  // ------------------------------ ТАБЛИЦА

  const { title, grid, formatData, columnsNames } = useTable();

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "Код", name: "code" },
    { id: 2, title: "Расшифровка", name: "name" },
    { id: 3, title: "Комментарий", name: "comment" },
  ];

  const titles = title(titlesInit);

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridInit = [
    ...(canEditDictionaries ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "1fr" },
    { titleId: 2, size: "3fr" },
    { titleId: 3, size: "3fr" },
    ...(canEditDictionaries ? [{ titleId: null, size: "56px" }] : []),
  ];

  const grids = grid(gridInit);

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИs

  const object = (datum: EntitieType<BigOtherType>) => ({
    code: datum.code,
    name: datum.name,
    comment: datum.comment ? (datum.comment === "" ? "—" : datum.comment) : "—",
  });

  const dataForTable = (datum: EntitieType<BigOtherType>) =>
    formatData(object(datum) ?? {}, columnsNames(titlesInit));

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    code: "",
    name: "",
    comment: "",
  };

  const initialValuesEditForm = (datum: EntitieType<BigOtherType>) => ({
    code: datum.code,
    name: datum.name,
    comment: datum.comment ?? "",
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    code: Yup.number().typeError(numbers).max(9999, long).required(required),
    name: Yup.string().matches(/\S/, notJustSpaces).max(255, long).required(required),
    comment: Yup.string().matches(/\S/, notJustSpaces),
  });

  return {
    titlePage,
    addFormName,
    addFormTitle,
    editFormName,
    editFormTitle,

    queryKey,

    canViewDictionaries,
    canEditDictionaries,

    disabilityCodesBlockTwo,
    disabilityCodeBlockTwoIds,
    handleDeleteRows,

    titles,
    titlesInit,
    grids,
    dataForTable,

    initialValuesAddForm,
    initialValuesEditForm,
    validationSchema,
  };
};
