import { diseasesClassesApi } from "../../../../api/apiDictionaries";
import { PaginationType } from "../../../../api/types";

// ------------------------------ ДАННЫЕ

export const fetchDiseasesClasses = async (props: PaginationType & { query?: string }) =>
  (await diseasesClassesApi.getAll(props)).data.items;

// ------------------------------ ДАННЫЕ - ВСЕ

export const fetchAllDiseasesClasses = async () => {
  const totalCount = (await diseasesClassesApi.getAll({ startIndex: 0, size: 1 })).data.totalCount;

  return (await diseasesClassesApi.getAll({ startIndex: 0, size: totalCount })).data.items;
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const fetchTotalCountDiseasesClasses = async (props: PaginationType & { query?: string }) =>
  (await diseasesClassesApi.getAll(props)).data.totalCount;
