export const DICTIONARY = "/dictionaries";
export const DICTIONARY_DISABILITY_CODE = `${DICTIONARY}/disability-codes`;
export const DICTIONARY_DISABILITY_CODE_BLOCK_TWO = `${DICTIONARY}/disability-codes-block-two`;
export const DICTIONARY_DISEASES_CLASSES = `${DICTIONARY}/diseases-classes`;
export const DICTIONARY_INFECTIOUS_DISEASES = `${DICTIONARY}/infectious-diseases`;
export const DICTIONARY_NATIONALITIES = `${DICTIONARY}/nationalities`;
export const DICTIONARY_PERSONNEL_CATEGORIES = `${DICTIONARY}/personnel-categories`;
export const DICTIONARY_COUNTRIES = `${DICTIONARY}/countries`;
export const DICTIONARY_CITIES = `${DICTIONARY}/cities`;
export const DICTIONARY_HOSPITALS = `${DICTIONARY}/hospitals`;
export const DICTIONARY_DISEASE_SEVERITY = `${DICTIONARY}/disease-severity`;
export const DICTIONARY_TREATMENT_PLACES = `${DICTIONARY}/treatment-places`;

export const STATISTICS = "/statistics";
export const STATISTICS_CHARTS_FOR_ORGANIZATIONS = `${STATISTICS}/charts-for-organizations`;
export const STATISTICS_CHARTS_FOR_DEPARTMENTS = (id: string | undefined) =>
  `${STATISTICS}/charts-for-departments/${id ?? ":id"}`;
export const STATISTICS_CHARTS_FOR_EMPLOYEES = (id: string | undefined, page: number | undefined) =>
  `${STATISTICS}/charts-for-employees/${id ?? ":id"}${page ? `?page=${page}` : ""}`;
export const STATISTICS_EMPLOYEE_CARD = (id: string | undefined) =>
  `${STATISTICS}/employee-card/${id ?? ":id"}`;
export const STATISTICS_UPLOAD_EMPLOYEES = `${STATISTICS}/upload-employees`;
