import { useFormikContext } from "formik";
import React, { FC } from "react";
import DateInput from "./DateInput";
import { Container } from "./DateInput.styles";

const StartAndEndDatesInput: FC<PropsType> = (props) => {
  const { name, label, column = false } = props;
  const { minDate, maxDate, disableStart = false, disableStop = false } = props;

  const { values } = useFormikContext<Record<string, Date | null>>();

  const startDate = name ? values[name.start] : values["startDate"];
  const endDate = name ? values[name.stop] : values["endDate"];

  const maxStartDate = maxDate
    ? endDate
      ? maxDate >= endDate
        ? endDate
        : maxDate
      : maxDate
    : endDate
    ? endDate
    : undefined;

  const minEndDate = minDate
    ? startDate
      ? minDate >= startDate
        ? minDate
        : startDate
      : minDate
    : startDate
    ? startDate
    : undefined;

  return (
    <Container column={column}>
      <DateInput
        name={name ? name.start : "startDate"}
        placeholder={!!label ? "С" : "Действует c *"}
        label={label?.start}
        disabled={disableStart}
        minDate={minDate}
        maxDate={maxStartDate}
      />

      <DateInput
        name={name ? name.stop : "endDate"}
        placeholder={!!label ? "По" : "Действует по"}
        label={label?.stop}
        disabled={disableStop}
        minDate={minEndDate}
        maxDate={maxDate}
      />
    </Container>
  );
};

export default StartAndEndDatesInput;

type PropsType = {
  name?: { start: string; stop: string };
  label?: { start: string; stop: string };
  column?: boolean;
  disableStart?: boolean;
  disableStop?: boolean;
  minDate?: Date;
  maxDate?: Date;
};
