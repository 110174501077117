import { useAtom } from "jotai";
import React, { FC, MouseEvent, useState } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";
import { Expand, Reduce } from "../../Icons";
import PopoverHover from "../../PopoverHover";
import { Container } from "./ExpandButton.styles";

const ExpandButton: FC<PropsType> = (props) => {
  const { setSubMenuIsOpen } = props;

  // ------------------------------ < ОТОБРАЖЕНИЕ/СКРЫТИЕ РАСШИРЕННОГО МЕНЮ

  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);

  const expandMenu = () => {
    setMenuIsExpand(!menuIsExpand);
    setSubMenuIsOpen("");

    setTextPopoverHover(menuIsExpand ? "Показать полное меню" : "Уменьшить меню");
  };

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ РАСШИРЕННОГО МЕНЮ >

  // ------------------------------ < ОТОБРАЖЕНИЕ/СКРЫТИЕ ПОДСКАЗОК

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [textPopoverHover, setTextPopoverHover] = useState("Показать полное меню");

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) =>
    setAnchorElement(currentTarget);
  const closePopoverHover = () => setAnchorElement(null);

  const popoverHoverIsOpen = !!anchorElement;
  const id = popoverHoverIsOpen ? "popover-hover" : undefined;

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ ПОДСКАЗОК >

  return (
    <>
      <Container
        isExpand={menuIsExpand}
        onClick={expandMenu}
        aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
        aria-haspopup="true"
        onMouseEnter={openPopoverHover}
        onMouseLeave={closePopoverHover}
      >
        {menuIsExpand ? <Reduce /> : <Expand />}
      </Container>

      <PopoverHover
        id={id}
        isOpen={popoverHoverIsOpen}
        element={anchorElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: 19, horizontal: 44 }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        {textPopoverHover}
      </PopoverHover>
    </>
  );
};

export default ExpandButton;

type PropsType = {
  setSubMenuIsOpen: (arg: string) => void;
};
