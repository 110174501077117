import { Formik } from "formik";
import React, { FC } from "react";
import {
  EntitieType,
  EntitieUpdateType,
  EntitiesType,
  LittleOtherType,
  diseaseSeverityApi,
} from "../../../../api/apiDictionaries";
import { ErrorText } from "../../../../components/ErrorBoundary/ErrorBoundary.styles";
import { Form } from "../../../../components/Forms";
import FormButtons from "../../../../components/Forms/FormButtons";
import RightModal from "../../../../components/RightModal";
import RightModalActions from "../../../../components/RightModal/RightModalActions";
import RightModalBody from "../../../../components/RightModal/RightModalBody";
import TextInput from "../../../../components/TextInput";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { useQuery } from "../../../../utils/useQuery";
import { useDiseaseSeverityDictionary } from "../useDiseaseSeverityDictionary";

const DiseaseSeverityEditForm: FC<PropsType> = (props) => {
  const { data, onClose } = props;

  const diseaseSeverityDictionaryMethods = useDiseaseSeverityDictionary();
  const { editFormName, editFormTitle, queryKey } = diseaseSeverityDictionaryMethods;
  const { initialValuesEditForm, validationSchema } = diseaseSeverityDictionaryMethods;

  const fields = (
    <>
      <TextInput name="name" label="Наименование *" placeholder="Введите наименование" />
      <TextInput name="comment" label="Комментарий" placeholder="—" multiline />
    </>
  );

  const update = useQuery().update<EntitieUpdateType<LittleOtherType>>({
    queryKey,
    api: diseaseSeverityApi,
  });

  const error = getErrorMessage(update.error);
  const isLoading = update.isLoading;

  return (
    <RightModal title={editFormTitle} isOpen onClose={onClose} needBlur>
      <Formik
        initialValues={initialValuesEditForm(data)}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            await update.mutateAsync({ id: data.id, data: values });

            onClose();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ handleSubmit }) => (
          <>
            <RightModalBody>
              <Form formName={editFormName} onSubmit={handleSubmit}>
                {fields}
              </Form>
            </RightModalBody>

            {error ? <ErrorText>{error}</ErrorText> : null}

            <RightModalActions>
              <FormButtons
                formName={editFormName}
                onClose={onClose}
                saveOrAdd="save"
                disabled={isLoading}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

export default DiseaseSeverityEditForm;

type PropsType = {
  data: EntitieType<LittleOtherType>;
  onClose: () => void;
};
