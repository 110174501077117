import { AxiosResponse } from "axios";
import { PaginationType } from "../api/types";

const cachedDataMap = new Map<string, CachedType>();

export const localPagination = async <RES>(
  id: string,
  request: Promise<AxiosResponse>,
  propsPagination: object,
  selector: (data: AxiosResponse) => ResponseSelectorType<RES>
) => {
  const pagination = Object.fromEntries(
    Object.entries(propsPagination).filter(
      ([key]) => key === "startIndex" || key === "size" || key === "page"
    )
  ) as PaginationType;

  const props = JSON.stringify(
    Object.fromEntries(
      Object.entries(propsPagination).filter(([key]) => !Object.keys(pagination).includes(key))
    )
  );

  const cache = cachedDataMap.get(id);

  const start = pagination.startIndex;
  const end = pagination?.size ? pagination.startIndex + pagination.size : undefined;

  if (!cache || cache.props !== JSON.stringify(props)) {
    const response = await request;

    const { items, totalCount } = selector(response);

    cachedDataMap.set("id", { props, data: items, sourceResponse: response });

    return { ...response, data: { items: items.slice(start, end) as RES[], totalCount } };
  }

  const { data, sourceResponse } = cache;
  const cachedValue = data.slice(start, end) as RES[];

  return {
    ...sourceResponse,
    data: { items: cachedValue, totalCount: cache.data.length },
  };
};

type CachedType = {
  props: string;
  data: unknown[];
  sourceResponse: AxiosResponse;
};

type ResponseSelectorType<RES> = { items: RES[]; totalCount: number };
