import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Loupe: FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 18C25 21.3137 22.3137 24 19 24C15.6863 24 13 21.3137 13 18C13 14.6863 15.6863 12 19 12C22.3137 12 25 14.6863 25 18ZM27 18C27 22.4183 23.4183 26 19 26C14.5817 26 11 22.4183 11 18C11 13.5817 14.5817 10 19 10C23.4183 10 27 13.5817 27 18ZM26.7071 24.2929C26.3166 23.9024 25.6834 23.9024 25.2929 24.2929C24.9024 24.6834 24.9024 25.3166 25.2929 25.7071L28.2929 28.7071C28.6834 29.0976 29.3166 29.0976 29.7071 28.7071C30.0976 28.3166 30.0976 27.6834 29.7071 27.2929L26.7071 24.2929Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default Loupe;
