import { getYear } from "date-fns";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { EmployeeStatusType } from "../../../api/types";
import { searchQueryState } from "../../../atoms/atoms";
import { departmentState } from "../../../atoms/atomsOrg";
import { formatDateForBackend, today } from "../../../utils/workingWithDates";
import { fetchEmployees, fetchTotalCountEmployees } from "./fetchData";

// ------------------------------ ТЕКУЩАЯ ДАТА КАЛЕНДАРЯ

export const currentDate = atom<Date>(today);

// ------------------------------ НОМЕР СТРАНИЦЫ

export const pageState = atom(0);

// ------------------------------ КОЛИЧЕСТВО СТРОК НА ОДНОЙ СТРАНИЦЕ

export const quantityInOnePageState = atom(10);

// ------------------------------ ФИЛЬТРЫ

export const absoluteInitialValues = {
  employeeStatus: "" as EmployeeStatusType,
  mainDepartmentCodes: [],
  startDate: null,
  endDate: null,
};

export const filtersState = atom<FiltersType>(absoluteInitialValues);

export type FiltersType = {
  employeeStatus: EmployeeStatusType;
  mainDepartmentCodes: string[];
  startDate: Date | null;
  endDate: Date | null;
};

export const absoluteInitialValuesExport = {
  disabilityCode: "",
  diseasesClass: "",
  mainDepartmentCode: "ALL",
  startDate: null,
  endDate: null,
};

export const exportFiltersState = atom<ExportFiltersType>(absoluteInitialValuesExport);

export type ExportFiltersType = {
  disabilityCode: string;
  diseasesClass: string;
  mainDepartmentCode: string;
  startDate: Date | null;
  endDate: Date | null;
};

// ------------------------------ ДАННЫЕ

export const employeesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);
  const date = get(currentDate);
  const department = get(departmentState);
  const filters = get(filtersState);

  return employeesQuery({ page, quantity, queryState, date, department, filters });
});

export const employeesState = loadable(employeesAtom);

export const employeesQuery = (props: PropsType) => {
  const { page, quantity, queryState, date, department, filters } = props;

  const startIndex = page * quantity;
  const size = quantity;

  const query = queryState || undefined;

  const year = filters.startDate && filters.endDate ? undefined : getYear(date);

  const mainDepartmentCodes = filters.mainDepartmentCodes
    ? filters.mainDepartmentCodes.length === 0
      ? department
      : filters.mainDepartmentCodes.join()
    : undefined;
  const status = filters.employeeStatus || undefined;

  const startDateOfDiseasePeriod = filters.startDate
    ? formatDateForBackend(filters.startDate)
    : undefined;
  const endDateOfDiseasePeriod = filters.endDate
    ? formatDateForBackend(filters.endDate)
    : undefined;

  const args = {
    startIndex,
    size,
    query,
    year,
    mainDepartmentCodes,
    status,
    startDateOfDiseasePeriod,
    endDateOfDiseasePeriod,
  };

  return {
    queryKey: [...Object.entries(args), "employeesState"],
    queryFn: () => fetchEmployees(args),
    keepPreviousData: true,
    enabled: !!mainDepartmentCodes,
  };
};

// ------------------------------ ДАННЫЕ - ОБЩЕЕ КОЛИЧЕСТВО

export const totalCountEmployeesAtom = atomWithQuery((get) => {
  const queryState = get(searchQueryState);
  const date = get(currentDate);
  const department = get(departmentState);
  const filters = get(filtersState);

  return totalCountEmployeesQuery({ queryState, date, department, filters });
});

export const totalCountEmployeesState = loadable(totalCountEmployeesAtom);

export const totalCountEmployeesQuery = (props: Omit<PropsType, "page" | "quantity">) => {
  const { queryState, date, department, filters } = props;

  const startIndex = 0;
  const size = 1;

  const query = queryState || undefined;

  const year = filters.startDate && filters.endDate ? undefined : getYear(date);

  const mainDepartmentCodes = filters.mainDepartmentCodes
    ? filters.mainDepartmentCodes.length === 0
      ? department
      : filters.mainDepartmentCodes.join()
    : undefined;
  const status = filters.employeeStatus || undefined;
  const startDateOfDiseasePeriod = filters.startDate
    ? formatDateForBackend(filters.startDate)
    : undefined;
  const endDateOfDiseasePeriod = filters.endDate
    ? formatDateForBackend(filters.endDate)
    : undefined;

  const args = {
    startIndex,
    size,
    query,
    year,
    mainDepartmentCodes,
    status,
    startDateOfDiseasePeriod,
    endDateOfDiseasePeriod,
  };

  return {
    queryKey: [...Object.entries(args), "totalCountEmployeesState"],
    queryFn: () => fetchTotalCountEmployees(args),
    keepPreviousData: true,
    enabled: !!mainDepartmentCodes,
  };
};

type PropsType = {
  page: number;
  quantity: number;
  date: Date;
  queryState?: string;
  department: string;
  filters: FiltersType;
};
