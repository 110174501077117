import { DateRange } from "@mui/lab";
import { endOfYear, startOfYear } from "date-fns";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { organizationState } from "../../../atoms/atomsOrg";
import { formatDateForBackend, today } from "../../../utils/workingWithDates";
import { fetchStatsForDepartments } from "./fetchData";
import { departments, DepartmentsFiltersType } from "../../../api/apiOrganization";

// ------------------------------ НОМЕР СТРАНИЦЫ

export const pageState = atom(0);

// ------------------------------ КОЛИЧЕСТВО СТРОК НА ОДНОЙ СТРАНИЦЕ

export const quantityInOnePageState = atom(10);

// ------------------------------ ТЕКУЩАЯ ДАТА СТАТИСТИКИ

export const currentDateInterval = atom<DateRange<Date>>([startOfYear(today), endOfYear(today)]);

// ------------------------------ ЭКСПОРТИРУЕМЫЙ ЭЛЕМЕНТ

export const exportElementAtom = atom<HTMLDivElement | null>(null);

// ------------------------------ ФИЛЬТРЫ

export const absoluteInitialValues: FiltersType = {
  disabilityCode: "",
  diseaseClass: "",
};

export const filtersState = atom(absoluteInitialValues);

export type FiltersType = {
  disabilityCode: string;
  diseaseClass: string;
};

// ------------------------------ ДАННЫЕ

export const statsForDepartmentAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const organizationCode = get(organizationState);
  const dateInterval = get(currentDateInterval);
  const filters = get(filtersState);

  return statsForDepartmentQuery({ organizationCode, dateInterval, filters, page, quantity });
});

export const statsForDepartmentState = loadable(statsForDepartmentAtom);

export const statsForDepartmentQuery = (props: PropsType) => {
  const { organizationCode, dateInterval, filters, page, quantity } = props;

  const disabilityId = filters.disabilityCode || undefined;
  const diseaseClassId = filters.diseaseClass || undefined;

  const startIndex = page && quantity ? page * quantity : 0;
  const size = quantity;

  const startDate = formatDateForBackend(dateInterval[0] ?? today);
  const endDate = formatDateForBackend(dateInterval[1] ?? today);

  const args = {
    organizationCode,
    disabilityId,
    diseaseClassId,
    startDate,
    endDate,
    startIndex,
    size,
  };

  return {
    queryKey: [
      organizationCode,
      disabilityId,
      diseaseClassId,
      startDate,
      endDate,
      startIndex,
      size,
      "statsForDepartmentsState",
    ],
    queryFn: () => fetchStatsForDepartments(args),
    keepPreviousData: true,
    enabled: !!organizationCode,
  };
};

// ------------------------------ ДАННЫЕ - ПОДРАЗДЕЛЕНИЯ

export const mainDepartmentsForChartsAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const organizationCode = get(organizationState);
  const dateInterval = get(currentDateInterval);
  const filters = get(filtersState);

  return mainDepartmentsForChartsQuery({ organizationCode, dateInterval, filters, page, quantity });
});

export const mainDepartmentsForChartsState = loadable(mainDepartmentsForChartsAtom);

export const mainDepartmentsForChartsQuery = (props: PropsType) => {
  const { organizationCode, dateInterval, filters, page, quantity } = props;

  const disabilityId = filters.disabilityCode || undefined;
  const diseaseClassId = filters.diseaseClass || undefined;

  const startIndex = page && quantity ? page * quantity : 0;
  const size = quantity;

  const startDate = formatDateForBackend(dateInterval[0] ?? today);
  const endDate = formatDateForBackend(dateInterval[1] ?? today);

  const args = {
    organizationCode,
    disabilityId,
    diseaseClassId,
    startDate,
    endDate,
    startIndex,
    size,
  };

  return {
    queryKey: [
      organizationCode,
      disabilityId,
      diseaseClassId,
      startDate,
      endDate,
      startIndex,
      size,
      "mainDepartmentsForChartsState",
    ],
    queryFn: () => fetchMainDepartmentsForCharts({ ...args, isMainDepartment: true }),
    keepPreviousData: true,
    enabled: !!organizationCode,
  };
};

export const fetchMainDepartmentsForCharts = async (props: DepartmentsFiltersType) => {
  const res = (await departments.getPagination(props)).data;

  return {
    totalCount: res.totalCount,
    items: res.items.map((item) => ({ ...item, id: item.code })),
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const statsForDepartmentAllState = atomWithQuery((get) => {
  const organizationCode = get(organizationState);
  const dateInterval = get(currentDateInterval);
  const filters = get(filtersState);

  return statsForDepartmentQueryAll({ organizationCode, dateInterval, filters });
});

export const statsForDepartmentQueryAll = (props: PropsType) => {
  const { organizationCode, dateInterval, filters } = props;

  const disabilityId = filters.disabilityCode || undefined;
  const diseaseClassId = filters.diseaseClass || undefined;

  const startIndex = 0;
  const size = undefined;

  const startDate = formatDateForBackend(dateInterval[0] ?? today);
  const endDate = formatDateForBackend(dateInterval[1] ?? today);

  const args = {
    organizationCode,
    disabilityId,
    diseaseClassId,
    startDate,
    endDate,
    startIndex,
    size,
  };

  return {
    queryKey: [
      organizationCode,
      disabilityId,
      diseaseClassId,
      startDate,
      endDate,
      "statsForDepartmentAllState",
    ],
    queryFn: () => fetchStatsForDepartments(args),
    keepPreviousData: true,
    enabled: !!organizationCode,
  };
};

type PropsType = {
  organizationCode: string;
  dateInterval: DateRange<Date>;
  filters: FiltersType;
  page?: number;
  quantity?: number;
};
