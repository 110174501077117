import { Formik } from "formik";
import React, { FC } from "react";
import { personnelCategoriesApi } from "../../../../api/apiDictionaries";
import { ErrorText } from "../../../../components/ErrorBoundary/ErrorBoundary.styles";
import { Form } from "../../../../components/Forms";
import FormButtons from "../../../../components/Forms/FormButtons";
import RightModal from "../../../../components/RightModal";
import RightModalActions from "../../../../components/RightModal/RightModalActions";
import RightModalBody from "../../../../components/RightModal/RightModalBody";
import TextInput from "../../../../components/TextInput";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { useQuery } from "../../../../utils/useQuery";
import { usePersonnelCategoryDictionary } from "../usePersonnelCategoryDictionary";

const PersonnelCategoryAddForm: FC<PropsType> = (props) => {
  const { onClose } = props;

  const personnelCategoryDictionaryMethods = usePersonnelCategoryDictionary();
  const { addFormName, addFormTitle, queryKey } = personnelCategoryDictionaryMethods;
  const { initialValuesAddForm, validationSchema } = personnelCategoryDictionaryMethods;

  const fields = (
    <>
      <TextInput name="name" label="Наименование *" placeholder="Введите наименование" />
      <TextInput name="comment" label="Комментарий" placeholder="—" multiline />
    </>
  );

  const create = useQuery().create({ queryKey, api: personnelCategoriesApi });

  const error = getErrorMessage(create.error);
  const isLoading = create.isLoading;

  return (
    <RightModal title={addFormTitle} isOpen onClose={onClose} needBlur>
      <Formik
        initialValues={initialValuesAddForm}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            await create.mutateAsync({ data: values });

            onClose();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ handleSubmit }) => (
          <>
            <RightModalBody>
              <Form formName={addFormName} onSubmit={handleSubmit}>
                {fields}
              </Form>
            </RightModalBody>

            {error ? <ErrorText>{error}</ErrorText> : null}

            <RightModalActions>
              <FormButtons
                formName={addFormName}
                onClose={onClose}
                saveOrAdd="add"
                disabled={isLoading}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

export default PersonnelCategoryAddForm;

type PropsType = {
  onClose: () => void;
};
