import React, { FC } from "react";
import { FilterType } from "../../Header/HeaderNavigation/HeaderNavigationRightButtons";
import { Sieve } from "../../Icons";
import UniversalTextButton from "../UniversalTextButton";
import { FiltersButtonContainer } from "./FiltersButton.styles";

const FiltersButton: FC<FilterType> = (props) => {
  const { handleOpenFilters, hasActiveFilters = false } = props;

  const openFilters = () => handleOpenFilters(true);

  // ------------------------------ ВЫЗОВ ФИЛЬТРОВ >

  return (
    <FiltersButtonContainer hasActiveFilters={hasActiveFilters}>
      <UniversalTextButton text="Фильтры" iconStart={<Sieve />} type="text" onClick={openFilters} />
    </FiltersButtonContainer>
  );
};

export default FiltersButton;
