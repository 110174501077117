import React, { FC } from "react";
import { Square } from "../../Icons";
import { Container } from "./SelectAllButton.styles";

const SelectAllButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <div>
        <Square />
      </div>
    </Container>
  );
};

export default SelectAllButton;

type PropsType = {
  onClick: () => void;
};
