import { useAtom } from "jotai";
import React, { FC, Suspense, useEffect } from "react";
import { dateSwitcherTypeState } from "../../atoms/atoms";
import { departmentState } from "../../atoms/atomsOrg";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ErrorText } from "../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { Main, PageContainer } from "../Page.styles";
import EmployeesFilters from "./Filters/EmployeesFilters";
import EmployeesFiltersExport from "./Filters/EmployeesFiltersExport";
import EmployeesTable from "./Table/EmployeesTable";
import { usePage } from "./hooks/usePage";

const ChartsForEmployeesPage: FC = () => {
  const [_, setDateSwitcherType] = useAtom(dateSwitcherTypeState);
  const [__, setDepartment] = useAtom(departmentState);

  const pageMethods = usePage();
  const { departmentId } = pageMethods;
  const { canViewStatsForEmployees, needPagination, navigation } = pageMethods;
  const { searchInput, filtersIsOpen, exportFiltersIsOpen } = pageMethods;
  const { handleCloseFilters, handleCloseExportFilters } = pageMethods;

  useEffect(() => {
    setDepartment(departmentId ?? "");
    setDateSwitcherType("year");
  }, []);

  return canViewStatsForEmployees ? (
    <PageContainer>
      <Header navigation={navigation} searchInput={searchInput} />

      <Main needPagination>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <EmployeesTable />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {needPagination && <Pagination needPagination={needPagination} />}

      {filtersIsOpen && <EmployeesFilters onClose={handleCloseFilters} />}

      {exportFiltersIsOpen && (
        <Suspense fallback={<Loader />}>
          <EmployeesFiltersExport onClose={handleCloseExportFilters} />
        </Suspense>
      )}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default ChartsForEmployeesPage;
