import { useAtom } from "jotai";
import {
  departmentsState,
  mainDepartmentsState,
  organizationsState,
  positionsState,
} from "../atoms/atomsOrg";
import { citiesAllState } from "../pages/DictionaryPages/CityDictionaryPage/utils/atoms";
import { countriesAllState } from "../pages/DictionaryPages/CountryDictionaryPage/utils/atoms";
import { disabilityCodesBlockTwoState } from "../pages/DictionaryPages/DisabilityCodeBlockTwoDictionaryPage/utils/atoms";
import { disabilityCodesState } from "../pages/DictionaryPages/DisabilityCodeDictionaryPage/utils/atoms";
import { diseaseSeverityState } from "../pages/DictionaryPages/DiseaseSeverityDictionaryPage/utils/atoms";
import { diseasesClassesAllState } from "../pages/DictionaryPages/DiseasesClassDictionaryPage/utils/atoms";
import { hospitalsState } from "../pages/DictionaryPages/HospitalDictionaryPage/utils/atoms";
import { infectiousDiseasesState } from "../pages/DictionaryPages/InfectiousDiseaseDictionaryPage/utils/atoms";
import { nationalitiesState } from "../pages/DictionaryPages/NationalityDictionaryPage/utils/atoms";
import { personnelCategoriesState } from "../pages/DictionaryPages/PersonnelCategoryDictionaryPage/utils/atoms";
import { treatmentPlacesState } from "../pages/DictionaryPages/TreatmentPlaceDictionaryPage/utils/atoms";
import {useAtomValue} from "jotai/utils";

// ------------------------------ ОРГАНИЗАЦИИ

export const getOrganizationOptions = () => {
  const [organizations] = useAtom(organizationsState);

  return organizations.state === "hasData"
    ? organizations.data.map(({ id, name }) => ({ id, name }))
    : [];
};

// ------------------------------ ОСНОВНЫЕ ПОДРАЗДЕЛЕНИЯ

export const getMainDepartmentOptions = () => {
  const mainDepartments= useAtomValue(mainDepartmentsState);

  return mainDepartments.state === "hasData" && mainDepartments.data
    ? mainDepartments.data.map(({ id, name, shortName }) => ({ id, name,shortName }))
    : [];
};

// ------------------------------ ПОДРАЗДЕЛЕНИЯ

export const getDepartmentOptions = () => {
  const [departments] = useAtom(departmentsState);

  return departments.state === "hasData" && departments.data
    ? departments.data.map(({ id, name }) => ({ id, name }))
    : [];
};

// ------------------------------ ДОЛЖНОСТИ

export const getPositionOptions = () => {
  const [positions] = useAtom(positionsState);

  return positions.state === "hasData" ? positions.data.map(({ id, name }) => ({ id, name })) : [];
};

// ------------------------------ КОДЫ НЕТРУДОСПОСОБНОСТИ

export const getDisabilityCodeOptions = () => {
  const [disabilityCodes] = useAtom(disabilityCodesState);

  return disabilityCodes.map(({ id, code, name }) => ({ id, name: `${code} ${name}` }));
};

// ------------------------------ КОДЫ НЕТРУДОСПОСОБНОСТИ - БЛОК 2

export const getAdditionalDisabilityOptions = () => {
  const [disabilityBlockTwo] = useAtom(disabilityCodesBlockTwoState);

  return disabilityBlockTwo.map(({ id, code, name }) => ({ id, name: `${code} ${name}` }));
};

// ------------------------------ КЛАССЫ ЗАБОЛЕВАНИЙ

export const getDiseasesClassOptions = () => {
  const [diseasesClasses] = useAtom(diseasesClassesAllState);

  return diseasesClasses.map(({ id, code, name }) => ({ id, name: `${code} ${name}` }));
};

// ------------------------------ ИНФЕКЦИОННЫЕ ЗАБОЛЕВАНИЯ

export const getInfectiousDiseasesOptions = () => {
  const [infectiousDiseases] = useAtom(infectiousDiseasesState);

  return infectiousDiseases.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ ГРАЖДАНСТВА

export const getNationalityOptions = () => {
  const [nationalities] = useAtom(nationalitiesState);

  return nationalities.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ КАТЕГОРИИ ПЕРСОНАЛА

export const getPersonnelCategoryOptions = () => {
  const [personnelCategories] = useAtom(personnelCategoriesState);

  return personnelCategories.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СТРАНЫ

export const getCountryOptions = () => {
  const [countries] = useAtom(countriesAllState);

  return countries.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ ГОРОДА

export const getCityOptions = () => {
  const [cities] = useAtom(citiesAllState);

  return cities.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СТАЦИОНАРЫ

export const getHospitalsOptions = () => {
  const [hospitals] = useAtom(hospitalsState);

  return hospitals.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ Степени тяжести заболевания

export const getDiseaseSeverityOptions = () => {
  const [diseaseSeverity] = useAtom(diseaseSeverityState);

  return diseaseSeverity.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ МЕСТА ПРОХОЖДЕНИЯ ЛЕЧЕНИЯ
export const getTreatmentPlaceOptions = () => {
  const [treatmentPlaces] = useAtom(treatmentPlacesState);

  return treatmentPlaces.map(({ id, name }) => ({ id, name }));
};

export type OptionType = { id: string; name: string };

export type OptionsType = OptionType[];
