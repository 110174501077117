import React, { FC, MouseEvent, ReactNode, useState } from "react";
import PopoverModal from "../../PopoverModal";
import { Container } from "./UniversalTextButton.styles";

const UniversalTextButton: FC<PropsType> = (props) => {
  const { onClick, text, iconStart, iconEnd, menuElement } = props;
  const { type = "primary", enabled = true } = props;

  // ------------------------------ < ВЫЗОВ МЕНЮ ЭКСПОРТА

  const [exportMenuElement, setExportMenuElement] = useState<HTMLElement | null>(null);

  const openProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportMenuElement(evt.currentTarget);
  };

  const closeExportMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportMenuElement(null);
  };

  const exportMenuIsOpen = !!exportMenuElement;

  // ------------------------------ ВЫЗОВ МЕНЮ ЭКСПОРТА >

  return (
    <>
      <Container
        typebutton={type}
        enabled={enabled}
        onClick={menuElement ? openProfileMenu : enabled ? onClick : undefined}
        hasIconStart={!!iconStart}
        hasIconEnd={!!iconEnd}
        hasText={!!text}
      >
        {iconStart}
        {text && <p>{text}</p>}
        {iconEnd}
      </Container>

      {menuElement ? (
        <PopoverModal
          id="exportMenu"
          isOpen={exportMenuIsOpen}
          element={exportMenuElement}
          onClose={closeExportMenu}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: -10 }}
        >
          {menuElement}
        </PopoverModal>
      ) : null}
    </>
  );
};

export default UniversalTextButton;

type PropsType = {
  text: string;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  menuElement?: ReactNode;
  type?: "primary" | "secondary" | "text" | undefined;
  enabled?: boolean;
  onClick?: () => void;
};
