import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Square: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5903 0.100006C14.7582 0.100006 15.7923 0.299707 16.8665 0.874206C17.8382 1.39385 18.6061 2.1618 19.1258 3.13345C19.7003 4.20767 19.9 5.24181 19.9 7.40972V12.5903C19.9 14.7582 19.7003 15.7923 19.1258 16.8666C18.6061 17.8382 17.8382 18.6062 16.8665 19.1258C15.7923 19.7003 14.7582 19.9 12.5903 19.9H7.40969C5.24178 19.9 4.20764 19.7003 3.13342 19.1258C2.16177 18.6062 1.39382 17.8382 0.874176 16.8666C0.299677 15.7923 0.0999756 14.7582 0.0999756 12.5903V7.40972C0.0999756 5.24181 0.299677 4.20767 0.874176 3.13345C1.39382 2.1618 2.16177 1.39385 3.13342 0.874206C4.20764 0.299707 5.24178 0.100006 7.40969 0.100006H12.5903ZM12.5903 1.90001H7.40969C5.51351 1.90001 4.7601 2.0455 3.9823 2.46147C3.32433 2.81336 2.81333 3.32436 2.46144 3.98233C2.04547 4.76013 1.89998 5.51355 1.89998 7.40972V12.5903C1.89998 14.4865 2.04547 15.2399 2.46144 16.0177C2.81333 16.6757 3.32433 17.1867 3.9823 17.5385C4.7601 17.9545 5.51351 18.1 7.40969 18.1H12.5903C14.4864 18.1 15.2398 17.9545 16.0177 17.5385C16.6756 17.1867 17.1866 16.6757 17.5385 16.0177C17.9545 15.2399 18.1 14.4865 18.1 12.5903V7.40972C18.1 5.51355 17.9545 4.76013 17.5385 3.98233C17.1866 3.32436 16.6756 2.81336 16.0177 2.46147C15.2398 2.0455 14.4864 1.90001 12.5903 1.90001Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default Square;
