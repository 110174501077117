import React, { FC, Fragment } from "react";
import { EmployeeType } from "../../../api/apiEmployees";
import { Table, TableBodyRow, TableHeadRow, TableTitleRow } from "../../../components/Table";
// import { useUserSettings } from "../../../utils/useUserSettings";
import { usePageTable } from "../hooks/useTable";

const EmployeesTable: FC = () => {
  const tableMethods = usePageTable();
  const { employeesForTable, titles, titlesInit, grids } = tableMethods;
  const { dataForTable, dataWrapper } = tableMethods;

  // const { getUserSettings, setUserSettings } = useUserSettings();

  // useEffect(() => getUserSettings("chartsForEmployees", titlesInit), []);

  return (
    <Table titles={titlesInit} needPagination>
      <TableHeadRow
        titles={titles}
        titlesInit={titlesInit}
        grid={grids}
        // setUserSettings={(selectedColumnNumbers) =>
        //   setUserSettings("chartsForEmployees", titlesInit, selectedColumnNumbers)
        // }
      />

      {dataWrapper(
        employeesForTable()?.map(({ employee, subTitle }) => {
          const { id } = employee;

          return (
            <Fragment key={id}>
              {subTitle && <TableTitleRow text={subTitle} />}
              <TableBodyRow id={id} data={dataForTable(employee)} grid={grids} />
            </Fragment>
          );
        })
      )}
    </Table>
  );
};

export default EmployeesTable;

export type EmployeesDataType =
  | { state: "loading" }
  | { state: "hasError"; error: unknown }
  | { state: "hasData"; data?: EmployeeType[] };
