import { useAtom } from "jotai";
import React, { FC } from "react";
import Filters from "../../../components/Filters";
import SelectAutocompleteInput from "../../../components/SelectAutocompleteInput";
import { getDisabilityCodeOptions, getDiseasesClassOptions } from "../../../utils/getOptions";
import { useForms } from "../hooks/useForms";
import { absoluteInitialValues, filtersState } from "../utils/atoms";

const OrganizationsFilters: FC<PropsType> = (props) => {
  const { onClose } = props;

  const [_, setFilters] = useAtom(filtersState);

  const { initialValuesFilters, validationSchema } = useForms();

  const fields = (
    <>
      <SelectAutocompleteInput
        name="disabilityCode"
        options={[{ id: "", name: "" }, ...getDisabilityCodeOptions()]}
        placeholder="Выберите из списка"
        label="Код нетрудоспособности блок 1"
      />
      <SelectAutocompleteInput
        name="diseaseClass"
        options={[{ id: "", name: "" }, ...getDiseasesClassOptions()]}
        label="Класс болезни по МКБ-10"
        placeholder="Выберите из списка"
      />
    </>
  );

  return (
    <Filters
      initialValues={initialValuesFilters}
      absoluteInitialValues={absoluteInitialValues}
      filtersSchema={validationSchema}
      onClose={onClose}
      setFilters={setFilters}
    >
      {fields}
    </Filters>
  );
};

export default OrganizationsFilters;

type PropsType = {
  onClose: () => void;
};
