import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Statistics: FC = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C3.10457 6 4 6.89543 4 8V16C4 17.1046 3.10457 18 2 18C0.89543 18 0 17.1046 0 16V8C0 6.89543 0.89543 6 2 6ZM14 9C15.1046 9 16 9.89543 16 11V16C16 17.1046 15.1046 18 14 18C12.8954 18 12 17.1046 12 16V11C12 9.89543 12.8954 9 14 9ZM8 0C9.10457 0 10 0.89543 10 2V16C10 17.1046 9.10457 18 8 18C6.89543 18 6 17.1046 6 16V2C6 0.89543 6.89543 0 8 0Z"
        fill={colors.grayscalePlaceholder}
      />
    </svg>
  );
};

export default Statistics;
