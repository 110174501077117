import styled from "@emotion/styled";
import { colors } from "../styles/styles";

export const PageContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr;
  background: ${colors.white};
`;

export const Main = styled.main<{ needPagination?: boolean }>`
  width: 100%;
  background: ${colors.gray};
  border-top: 1px solid ${colors.grayscaleInput};
  margin: -1px 0 0 0;
  padding: ${({ needPagination }) => (needPagination ? "16px 16px 0 16px" : "16px")};
  overflow: hidden;
`;
