import { DateRange } from "@mui/lab";
import { endOfYear, getYear, setYear, startOfYear } from "date-fns";
import { useAtom } from "jotai";
import React, { FC, useEffect } from "react";
import { dateSwitcherTypeState, periodSwitcherTypeState } from "../../atoms/atoms";
import { today } from "../../utils/workingWithDates";
import { Container } from "./DateSwitcher.styles";
import DaySwitcher from "./DaySwitcher";
import MonthsSwitcher from "./MonthsSwitcher";
import PeriodSwitcher from "./PeriodSwitcher";
import QuarterSwitcher from "./QuarterSwitcher";
import YearSwitcher from "./YearSwitcher";

const DateSwitcher: FC<PropsType> = (props) => {
  const { date, setDate, dateInterval, setDateInterval, typeInterval, router } = props;
  const { needPeriodSwitcher } = props;

  const [dateSwitcherType] = useAtom(dateSwitcherTypeState);
  const [periodSwitcherType, setPeriodSwitcherType] = useAtom(periodSwitcherTypeState);

  const minDate = startOfYear(setYear(today, getYear(today) - 5));
  const maxDate = endOfYear(today);

  useEffect(() => setPeriodSwitcherType("year"), []);

  return (
    <Container needPeriodSwitcher={needPeriodSwitcher}>
      {dateSwitcherType === "year" ? (
        dateInterval && setDateInterval ? (
          <>
            {needPeriodSwitcher && periodSwitcherType === dateSwitcherType ? (
              <PeriodSwitcher dateInterval={dateInterval} setDateInterval={setDateInterval} />
            ) : null}

            <YearSwitcher
              dateInterval={dateInterval}
              setDateInterval={setDateInterval}
              minDate={minDate}
              maxDate={maxDate}
            />
          </>
        ) : (
          <YearSwitcher date={date} setDate={setDate} minDate={minDate} maxDate={maxDate} />
        )
      ) : null}

      {dateSwitcherType === "quarter" && dateInterval && setDateInterval ? (
        <>
          {needPeriodSwitcher && periodSwitcherType === dateSwitcherType ? (
            <PeriodSwitcher dateInterval={dateInterval} setDateInterval={setDateInterval} />
          ) : null}

          <QuarterSwitcher
            dateInterval={dateInterval}
            setDateInterval={setDateInterval}
            minDate={minDate}
            maxDate={maxDate}
          />
        </>
      ) : null}

      {dateSwitcherType === "month" && dateInterval && setDateInterval ? (
        <>
          {needPeriodSwitcher && periodSwitcherType === dateSwitcherType ? (
            <PeriodSwitcher dateInterval={dateInterval} setDateInterval={setDateInterval} />
          ) : null}

          <MonthsSwitcher
            dateInterval={dateInterval}
            setDateInterval={setDateInterval}
            minDate={minDate}
            maxDate={maxDate}
          />
        </>
      ) : null}

      {dateSwitcherType === "period" ? (
        <>
          {needPeriodSwitcher &&
          periodSwitcherType === dateSwitcherType &&
          dateInterval &&
          setDateInterval ? (
            <PeriodSwitcher dateInterval={dateInterval} setDateInterval={setDateInterval} />
          ) : null}

          <DaySwitcher
            dateInterval={dateInterval}
            setDateInterval={setDateInterval}
            date={dateInterval ? undefined : date}
            setDate={setDateInterval ? undefined : setDate}
            typeInterval={typeInterval}
            maxDate={maxDate}
            minDate={minDate}
            router={router}
          />
        </>
      ) : null}
    </Container>
  );
};

export default DateSwitcher;

type PropsType = {
  typeInterval: TypeIntervalType;
  date?: Date;
  setDate?: (arg: Date) => void;
  dateInterval?: DateRange<Date>;
  setDateInterval?: (arg: DateRange<Date>) => void;
  router?: (date: string | undefined) => string;
  needPeriodSwitcher?: boolean;
};

export type DateSwitcherType = "year" | "quarter" | "month" | "period";

export type TypeIntervalType = "oneDate" | "twoDate";
