import styled from "@emotion/styled";
import { colors } from "../../styles/styles";

export const Container = styled.div<{ mainHeight: number }>`
  min-width: calc(1024px - 73px - (16px * 2));
  // (73px) - ширина боковой панели
  // (16px * 2) - 2 паддинга компоненты Main
  width: 100%;
  height: fit-content;
  max-height: calc(100vh - 132px - 76px - (16px * 2));
  // (132px) - высота Heder
  // (76px) - высота панели организаций
  // (16px * 2) - 2 паддинга компоненты Main
  background: ${colors.white};
  border-radius: 16px;
  padding: 16px 16px 8px 16px;

  canvas {
    max-height: ${({ mainHeight }) => `${mainHeight}px`} !important;
  }
`;

export const ElementForExport = styled.div`
  width: 297mm;
  min-height: 220mm;
  position: absolute;
  left: -10000px;
  background: ${colors.primaryBackground};
`;

export const Header = styled.div`
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  background: ${colors.white};
  margin: 0 0 16px;
  padding: 16px 40px;
  z-index: 2;
`;

export const Title = styled.h1`
  font-size: 20px;
  line-height: 26px;
  font-weight: 800;
  color: ${colors.black};
  margin: 0 0 0 48px;
`;

export const ChartWrapper = styled.div`
  background: ${colors.white};
  border-radius: 16px;
  margin: 0 40px 0 35px;
  padding: 16px;
`;

export const OrganizationsContainer = styled.div`
  display: flex;
  gap: 16px;
  background: ${colors.white};
  border-radius: 16px;
  margin: 0 40px 16px;
  padding: 16px;
`;

export const Organization = styled.div<{ borderColor: string }>`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.grayscaleInput};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 99em;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 16px;

  p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }
`;
