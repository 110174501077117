import styled from "@emotion/styled";
import { colors } from "../../../styles/styles";

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -10px;
`;

export const Icon = styled.div``;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  border-left: 1px solid ${colors.grayscaleAsh};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 0 8px;
  padding: 0 0 0 8px;
`;

export const TextRosatom = styled.p`
  font-size: 10px;
  line-height: 13px;
  font-weight: 400;
  color: ${colors.indigo};
`;
