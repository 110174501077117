import React, { FC, MouseEvent } from "react";
import { ProfileMenuButton, ProfileMenuButtons } from "./Profile.styles";
import { backendURI } from "../../../utils/backendURI";

const ProfileMenu: FC<PropsType> = (props) => {
  const { roleModel, onClose } = props;

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const canViewNotifications = roleModel.canViewNotifications;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  return (
    <>
      <ProfileMenuButtons>
        {canViewNotifications ? (
          <ProfileMenuButton>
            <p>Уведомления</p>
          </ProfileMenuButton>
        ) : null}
        <ProfileMenuButton
          href={
            process.env.REACT_APP_MR_URL
              ? `http://localhost:8080/c/portal/logout`
              : `https://${backendURI()}/c/portal/logout`
          }
          onClick={onClose}
        >
          <p>Выйти</p>
        </ProfileMenuButton>
      </ProfileMenuButtons>
    </>
  );
};

export default ProfileMenu;

type PropsType = {
  roleModel: Record<string, boolean>;
  onClose: (evt: MouseEvent<HTMLElement>) => void;
};
