import { MouseEvent, useState } from "react";
import { PositionType } from "./PopoverHover";

export const usePopoverHover = (props: PropsType) => {
  const { anchorOrigin, transformOrigin, onOpen, onClose } = props;

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );
  const popoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = popoverHoverIsOpen ? "popover-hover" : undefined;

  const openPopoverHover = (evt: MouseEvent<HTMLElement>) => {
    setAnchorPopoverHoverElement(evt.currentTarget);
    if (onOpen) onOpen(evt);
  };
  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
    if (onClose) onClose();
  };

  const propsPopoverHover = {
    id: idPopoverHover,
    isOpen: popoverHoverIsOpen,
    element: anchorPopoverHoverElement,
    onClose: closePopoverHover,
    anchorOrigin: anchorOrigin ?? {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: transformOrigin ?? {
      vertical: 0,
      horizontal: 0,
    },
  };

  return {
    openPopoverHover,
    closePopoverHover,
    propsPopoverHover,
  };
};

type PropsType = {
  anchorOrigin?: PositionType;
  transformOrigin?: PositionType;
  onOpen?: (evt: MouseEvent<HTMLElement>) => void;
  onClose?: () => void;
};
