import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<{ long: boolean; xLong: boolean }>`
  ${button};

  width: ${({ long, xLong }) => (long ? "180px" : xLong ? "328px" : "135px")};
  height: 40px;
  justify-content: flex-start;
  border-radius: 8px;

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }

  svg {
    margin: 0 0 0 16px;
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleLable};
    }
  }
`;
