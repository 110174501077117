import { DateRange } from "@mui/lab";
import {
  endOfISOWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  getYear,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import { useAtom } from "jotai";
import React, { FC } from "react";
import { dateSwitcherTypeState, periodSwitcherTypeState } from "../../atoms/atoms";
import { today } from "../../utils/workingWithDates";
import {
  PeriodSwitcherButton,
  PeriodSwitcherButtonText,
  PeriodSwitcherContainer,
} from "./DateSwitcher.styles";

const PeriodSwitcher: FC<PropsType> = (props) => {
  const { dateInterval, setDateInterval } = props;

  const date = dateInterval[0];

  const [_, setDateSwitcherType] = useAtom(dateSwitcherTypeState);
  const [periodSwitcherType, setPeriodSwitcherType] = useAtom(periodSwitcherTypeState);

  const currentDate = date && getYear(date) !== getYear(today) ? startOfYear(date) : today;

  return (
    <PeriodSwitcherContainer>
      <PeriodSwitcherButton
        onClick={
          periodSwitcherType === "year"
            ? undefined
            : () => {
                setDateSwitcherType("year");
                setPeriodSwitcherType("year");

                if (!setDateInterval) return;

                setDateInterval([startOfYear(currentDate), endOfYear(currentDate)]);
              }
        }
        enabled={periodSwitcherType === "year"}
      >
        <PeriodSwitcherButtonText>Год</PeriodSwitcherButtonText>
      </PeriodSwitcherButton>
      <PeriodSwitcherButton
        onClick={
          periodSwitcherType === "quarter"
            ? undefined
            : () => {
                setDateSwitcherType("quarter");
                setPeriodSwitcherType("quarter");

                if (!setDateInterval) return;

                setDateInterval([startOfQuarter(currentDate), endOfQuarter(currentDate)]);
              }
        }
        enabled={periodSwitcherType === "quarter"}
      >
        <PeriodSwitcherButtonText>Квартал</PeriodSwitcherButtonText>
      </PeriodSwitcherButton>
      <PeriodSwitcherButton
        onClick={
          periodSwitcherType === "month"
            ? undefined
            : () => {
                setDateSwitcherType("month");
                setPeriodSwitcherType("month");

                if (!setDateInterval) return;

                setDateInterval([startOfMonth(currentDate), endOfMonth(currentDate)]);
              }
        }
        enabled={periodSwitcherType === "month"}
      >
        <PeriodSwitcherButtonText>Месяц</PeriodSwitcherButtonText>
      </PeriodSwitcherButton>

      {/* todoLater2.0: возможно выбор произвольного периода придётся перенести в год, но мне это кажется слишком неочевидным
          для пользователей, поэтому пока никто ничего не скажет, планирую оставить так  */}

      <PeriodSwitcherButton
        onClick={
          periodSwitcherType === "period"
            ? undefined
            : () => {
                setDateSwitcherType("period");
                setPeriodSwitcherType("period");

                if (!setDateInterval) return;

                setDateInterval([startOfISOWeek(currentDate), endOfISOWeek(currentDate)]);
              }
        }
        enabled={periodSwitcherType === "period"}
      >
        <PeriodSwitcherButtonText>Период</PeriodSwitcherButtonText>
      </PeriodSwitcherButton>
    </PeriodSwitcherContainer>
  );
};

export default PeriodSwitcher;

type PropsType = {
  dateInterval: DateRange<Date>;
  setDateInterval: (arg: DateRange<Date>) => void;
};
