import { useAtom } from "jotai";
import React, { FC, MouseEvent, useState } from "react";
import { currentUserState } from "../../atoms/atomCurrentUser";
import { menuIsExpandState } from "../../atoms/atoms";
import { Logo as LogoIcon } from "../Icons";
import PopoverHover from "../PopoverHover";
import ExpandButton from "./ExpandButton/ExpandButton";
import * as elements from "./LeftMenu.styles";
import Logo from "./Logo/Logo";
import Profile from "./Profile/Profile";
import SectionsButtons from "./SectionsButtons/SectionsButtons";
import WidgetsButton from "./WidgetsButton/WidgetsButton";

const LeftMenu: FC = () => {
  const { Container } = elements;
  const { Header, MainWrapper, Main, Footer } = elements;

  const [currentUser] = useAtom(currentUserState);
  const [menuIsExpand] = useAtom(menuIsExpandState);
  const [subMenuIsOpen, setSubMenuIsOpen] = useState("");

  // ------------------------------ < ОТОБРАЖЕНИЕ/СКРЫТИЕ ПОДСКАЗОК

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [textPopoverHover, setTextPopoverHover] = useState("");

  const openPopoverHover = (evt: MouseEvent<HTMLElement>, text: string) => {
    setAnchorElement(evt.currentTarget);
    setTextPopoverHover(text);
  };

  const closePopoverHover = () => {
    setAnchorElement(null);
    setTextPopoverHover("");
  };

  const popoverHoverIsOpen = !!anchorElement && !menuIsExpand;
  const id = popoverHoverIsOpen ? "popover-hover" : undefined;

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ ПОДСКАЗОК >

  return (
    <Container isExpand={menuIsExpand}>
      <Header>
        <Logo logo={<LogoIcon />} />
      </Header>

      <MainWrapper>
        <Main isExpand={menuIsExpand}>
          <WidgetsButton
            popoverHoverIsOpen={popoverHoverIsOpen}
            openPopoverHover={openPopoverHover}
            closePopoverHover={closePopoverHover}
          />

          {currentUser.state !== "hasData" ? null : (
            <SectionsButtons
              currentUser={currentUser.data}
              subMenuIsOpen={subMenuIsOpen}
              setSubMenuIsOpen={setSubMenuIsOpen}
              popoverHoverIsOpen={popoverHoverIsOpen}
              openPopoverHover={openPopoverHover}
              closePopoverHover={closePopoverHover}
            />
          )}
        </Main>
      </MainWrapper>

      <Footer>
        <ExpandButton setSubMenuIsOpen={setSubMenuIsOpen} />

        {currentUser.state !== "hasData" ? null : <Profile currentUser={currentUser.data} />}
      </Footer>

      <PopoverHover
        id={id}
        isOpen={popoverHoverIsOpen}
        element={anchorElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: "center", horizontal: 44 }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        {textPopoverHover}
      </PopoverHover>
    </Container>
  );
};

export default LeftMenu;
