import { useAtom } from "jotai";
import React, { FC, ReactNode, SetStateAction, useState } from "react";
import { selectedRowsState } from "../../../atoms/atoms";
import ConfirmModal from "../../ConfirmModal";
import * as elements from "./HeaderNavigation.styles";
import HeaderNavigationBreadcrumbsNav from "./HeaderNavigationBreadcrumbsNav";
import HeaderNavigationLeftButtons from "./HeaderNavigationLeftButtons";
import HeaderNavigationRightButtons, {
  DateSwitcherType,
  FilterType,
  SearchType,
} from "./HeaderNavigationRightButtons";

const HeaderNavigation: FC<PropsType> = (props) => {
  const { children, title, section, needAddButton, needDeleteButton } = props;
  const { buttonsStart, buttonsCenter, buttonsEnd } = props;
  const { needSearchButton, needFilterButton, needDateSwitcher } = props;

  const { Buttons, Container, Title, TitleAndNav } = elements;

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedRows] = useAtom(selectedRowsState);

  const hasLeftButtons =
    buttonsStart || buttonsCenter || buttonsEnd || needAddButton || needDeleteButton;

  const hasAllButtons = hasLeftButtons || needSearchButton || needFilterButton || needDateSwitcher;

  // ------------------------------ < ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ

  const openDeleteConfirmModal = () => {
    if (selectedRows && selectedRows.length === 0) return;

    setOpenConfirmModal(true);
  };

  const closeDeleteConfirmModal = () => setOpenConfirmModal(false);

  // ------------------------------ ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ >

  // ------------------------------ < УДАЛЕНИЕ ВЫДЕЛЕННЫХ СТРОК

  const handleDelete = async () => needDeleteButton?.onClick && needDeleteButton.onClick();

  // ------------------------------ УДАЛЕНИЕ ВЫДЕЛЕННЫХ СТРОК >

  return (
    <Container>
      <TitleAndNav>
        <Title>{typeof title === "string" ? title : title.element}</Title>

        <HeaderNavigationBreadcrumbsNav title={title} section={section} />
      </TitleAndNav>

      {hasAllButtons ? (
        <Buttons>
          {!hasLeftButtons ? (
            children ?? <div />
          ) : (
            <HeaderNavigationLeftButtons
              buttonsStart={buttonsStart}
              buttonsCenter={buttonsCenter}
              buttonsEnd={buttonsEnd}
              needAddButton={needAddButton}
              needDeleteButton={needDeleteButton}
              openDeleteConfirmModal={openDeleteConfirmModal}
            />
          )}

          <HeaderNavigationRightButtons
            needSearchButton={needSearchButton}
            needFilterButton={needFilterButton}
            needDateSwitcher={needDateSwitcher}
          />
        </Buttons>
      ) : null}

      {hasLeftButtons ? children : null}

      {selectedRows ? (
        <ConfirmModal
          text={selectedRows.length}
          isOpen={openConfirmModal}
          onClose={closeDeleteConfirmModal}
          onDelete={handleDelete}
        />
      ) : null}
    </Container>
  );
};

export default HeaderNavigation;

type PropsType = {
  children?: ReactNode;
  title: TitleType;
  buttonsStart?: ReactNode;
  buttonsCenter?: ReactNode;
  buttonsEnd?: ReactNode;
  needAddButton?: ButtonType & { onClick?: (arg: SetStateAction<boolean>) => void };
  needDeleteButton?: ButtonType & { onClick?: () => void };
  needSearchButton?: SearchType;
  needFilterButton?: FilterType;
  needDateSwitcher?: DateSwitcherType;
  section?: SectionType;
};

type TitleType = string | { text: string; element: ReactNode };

type ButtonType = {
  text: string;
  type?: "primary" | "secondary" | undefined;
  disabled?: boolean;
};

type SectionType = { name: string; rout: string };
