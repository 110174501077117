import { css } from "@emotion/react";

export const colors = {
  transparent: "transparent",
  black: "#000000",
  white: "#FFFFFF",
  whiteOpacity: "#FFFFFFCC",
  gray: "#EFEFEF",

  grayscaleLogo: "#5F6062",
  grayscaleChips: "#E3E9ED",
  grayscaleOffWhite: "#FCFCFC",
  grayscaleBackground: "#F7F7FC",
  grayscaleHoverBackground: "#F4F4F4",
  grayscaleDisabled: "#F2F2F2",
  grayscaleInput: "#EFF0F6",
  grayscaleLine: "#D9DBE9",
  grayscaleIcons: "#A4B3C6",
  grayscalePlaceholder: "#A0A3BD",
  grayscaleLable: "#6E7191",
  grayscaleLableOpacityA0: "#6E7191A0",
  grayscaleLableOpacity77: "#6E719177",
  grayscaleBody: "#4E4B66",
  grayscaleAsh: "#262338",
  grayscaleBeautifulBlack: "#14142B",
  grayscaleBeautifulBlackOpacity: "#14142BCC",

  primaryBackground: "#F9FBFF",
  primaryBackgroundOpacity: "#F9FBFF80",
  primaryActiveElement: "#E0F2FF",
  primaryLight: "#CEEAFF",
  primaryDefault: "#118EFF",
  primaryDefaultDark: "#1473F2",
  primaryDark: "#0000FF",

  blue: "#4392CE",
  lightBlue: "#E7F4FD",
  indigo: "#025EA1",
  purple: "#610BEF",

  redBackground: "#FAEBEB",
  redLight: "#F2CCCC",
  redDefault: "#E01010",
  redDark: "#B00000",

  greenBackground: "#EBFFE3",
  greenLight: "#A6F787",
  greenDefault: "#4BB34B",
  greenDark: "#067306",

  warningBackground: "#FFF8E9",
  warningLight: "#FFE6B0",
  warningDefault: "#EAAC30",
  warningDark: "#946300",

  yellowForPinkLogo: "#FCC30B",
  redForPinkLogo: "#FF3A21",
  pinkForPinkLogo: "#FF00FF",
  orangeForPinkLogo: "#FB7C59",
  crimsonForPinkLogo: "#FE3076",
  fuchsiaForPinkLogo: "#FF0CED",
};

export const overflowOneLine = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const overflowTwoLines = css`
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const overflowThreeLines = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const shadow = "0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08)";
