import React, { ChangeEvent, FC } from "react";
import * as elements from "./UploadButton.styles";

const UploadButton: FC<PropsType> = (props) => {
  const { accept, onChange } = props;
  const { enabled = true, type = "primary", text } = props;

  const { Button, VisuallyHiddenInput } = elements;

  const handlerUploadFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const files = evt.target.files;
    if (files?.length) onChange(files[0]);
  };
  return (
    <Button enabled={enabled} typebutton={type}>
      <p>{text}</p>
      <VisuallyHiddenInput type="file" accept={accept} onChange={handlerUploadFile} />
    </Button>
  );
};

export default UploadButton;

type PropsType = {
  text: string;
  accept?: string;
  onChange: (evt: File) => void;
  type?: "primary" | "secondary" | "text" | undefined;
  enabled?: boolean;
};
