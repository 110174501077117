import React, { FC, useEffect, useState } from "react";
import { CityType, EntitieType } from "../../../../api/apiDictionaries";
import { Table, TableBodyRow, TableHeadRow } from "../../../../components/Table";
import { useUserSettings } from "../../../../utils/useUserSettings";
import CityEditForm from "../Forms/CityEditForm";
import { useCityDictionary } from "../useCityDictionary";

const CitiesTable: FC = () => {
  const [modalData, setModalData] = useState<EntitieType<CityType>>();

  const cityDictionaryMethods = useCityDictionary();
  const { canEditDictionaries, cities, cityIds, handleDeleteRows } = cityDictionaryMethods;
  const { titles, titlesInit, grids, dataForTable, dataWrapper } = cityDictionaryMethods;
  const { getUserSettings, setUserSettings } = useUserSettings();

  useEffect(() => getUserSettings("city", titlesInit), []);

  const handleCloseEditForm = () => setModalData(undefined);

  return (
    <>
      <Table titles={titlesInit} needPagination>
        <TableHeadRow
          titles={titles}
          titlesInit={titlesInit}
          grid={grids}
          ids={cityIds}
          needCheck={canEditDictionaries}
          needMenu={canEditDictionaries}
          setUserSettings={(selectedColumnNumbers) =>
            setUserSettings("city", titlesInit, selectedColumnNumbers)
          }
        />

        {dataWrapper(
          cities.map((datum) => {
            const { id } = datum;

            return (
              <TableBodyRow
                key={id}
                id={id}
                data={dataForTable(datum)}
                grid={grids}
                onOpenEditForm={(evt) => {
                  evt.stopPropagation();
                  setModalData(datum);
                }}
                textConfirmModal={datum.name}
                onDeleteRows={handleDeleteRows}
                needMenu={canEditDictionaries}
                needCheck={canEditDictionaries}
              />
            );
          })
        )}
      </Table>

      {canEditDictionaries && modalData ? (
        <CityEditForm data={modalData} onClose={handleCloseEditForm} />
      ) : null}
    </>
  );
};

export default CitiesTable;
