import styled from "@emotion/styled";
import { colors } from "../../styles/styles";

export const FormContainer = styled.div``;

export const FormButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const ThreeColumns = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
`;

export const TwoColumns = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
`;

export const TitleField = styled.h5`
  display: block;
  font-size: 17px;
  line-height: 27px;
  font-weight: 700;
  color: ${colors.grayscaleBeautifulBlack};
  margin: 0 0 8px 0;
`;

export const FormSubTitle = styled.div`
  display: flex;
  justify-content: start;
  gap: 16px;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin: 32px 0 8px 0;

  :first-of-type {
    margin: 0 0 8px 0;
  }
`;
