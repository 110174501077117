import { MenuItem } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC, useMemo, useState } from "react";
import { OptionsType } from "../../utils/getOptions";
import { OpenListButton } from "../Buttons";
import * as elements from "./SelectInput.styles";

const SelectInput: FC<PropsType> = (props) => {
  const { name, defaultValue, options } = props;
  const { label, placeholder, disabled = false, size = "" } = props;

  const { values, handleChange, handleBlur, errors } = useFormikContext<Record<string, string>>();

  const value = values[name];
  const error = errors[name];

  const [isOpen, setIsOpen] = useState(false);
  const [wasInFocus, setWasInFocus] = useState(false);

  const { Container, Label, Item, Placeholder, MenuItemWrapper, MenuProps, Error, MenuItemRender } =
    elements;

  const filteredOptions = useMemo(() => options.filter(({ id }) => id !== ""), [options]);

  const renderValue =
    value !== "" || value === null
      ? () => {
          const name = filteredOptions.find(({ id }) => id === value)?.name ?? "";
          return <MenuItemRender>{name}</MenuItemRender>;
        }
      : () => <Placeholder haslabel={+!!label}>{placeholder}</Placeholder>;

  const icon = () => (
    <OpenListButton
      isOpen={isOpen}
      onOpen={() => (disabled ? undefined : setIsOpen(!isOpen))}
      disabled={disabled}
    />
  );

  return (
    <Container disabled={disabled}>
      {label ? <Label id={`${name}-label`}>{label}</Label> : null}
      <Item
        name={name}
        value={value}
        open={isOpen}
        displayEmpty
        label={label}
        labelId={label ? `${name}-label` : ""}
        haslabel={+!!label}
        renderValue={renderValue}
        IconComponent={icon}
        MenuProps={MenuProps(size)}
        onChange={(evt) => {
          handleChange(evt);
          setWasInFocus(!!error);
        }}
        onBlur={(evt) => {
          handleBlur(evt);
          setWasInFocus(!!error);
        }}
        onClick={() => (disabled ? undefined : setIsOpen(!isOpen))}
        haserror={+!!(error && wasInFocus)}
        defaultValue={defaultValue ? defaultValue : ""}
        autoComplete="off"
        disabled={disabled}
      >
        {filteredOptions.map((option, i) => (
          <MenuItem key={i} value={option.id}>
            <MenuItemWrapper>{option.name}</MenuItemWrapper>
          </MenuItem>
        ))}
      </Item>
      {error && wasInFocus ? <Error>{error}</Error> : null}
    </Container>
  );
};

export default SelectInput;

type PropsType = {
  name: string;
  options: OptionsType;
  placeholder: string;
  label?: string;
  defaultValue?: string | null;
  disabled?: boolean;
  size?: string;
};
