import { AxiosResponse } from "axios";
import { mrApi } from "./api";
import { FileType } from "./types";

export const file = {
  upload: async (props: FormData): Promise<AxiosResponse<FileType>> => {
    return mrApi.post("/files-api/upload", props, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data; boundary=PART",
      },
    });
  },
  download: async (fileRef: string): Promise<AxiosResponse<BlobPart>> => {
    return mrApi.get(`/files-api/download?fileRef=${fileRef}`, {
      responseType: "arraybuffer",
    });
  },
};
