import {
  DICTIONARY_CITIES,
  DICTIONARY_COUNTRIES,
  DICTIONARY_DISABILITY_CODE,
  DICTIONARY_DISABILITY_CODE_BLOCK_TWO,
  DICTIONARY_DISEASES_CLASSES,
  DICTIONARY_DISEASE_SEVERITY,
  DICTIONARY_HOSPITALS,
  DICTIONARY_INFECTIOUS_DISEASES,
  DICTIONARY_NATIONALITIES,
  DICTIONARY_PERSONNEL_CATEGORIES,
  DICTIONARY_TREATMENT_PLACES,
} from "../../../api/routes";

export const dictionaries = (canViewDictionaries: boolean) => [
  ...(canViewDictionaries
    ? [
        {
          name: "disability-code",
          text: "Код нетрудоспособности",
          rout: DICTIONARY_DISABILITY_CODE,
        },
        {
          name: "disability-code-block-two",
          text: "Код нетрудоспособности блок 2",
          rout: DICTIONARY_DISABILITY_CODE_BLOCK_TWO,
        },
        {
          name: "diseases-class",
          text: "Классы болезней по МКБ-10",
          rout: DICTIONARY_DISEASES_CLASSES,
        },
        {
          name: "infectious-disease",
          text: "Инфекционные заболевания",
          rout: DICTIONARY_INFECTIOUS_DISEASES,
        },
        {
          name: "nationality",
          text: "Гражданство",
          rout: DICTIONARY_NATIONALITIES,
        },
        {
          name: "personnel-category",
          text: "Категории персонала",
          rout: DICTIONARY_PERSONNEL_CATEGORIES,
        },
        {
          name: "country",
          text: "Страны",
          rout: DICTIONARY_COUNTRIES,
        },
        {
          name: "city",
          text: "Города",
          rout: DICTIONARY_CITIES,
        },
        {
          name: "hospital",
          text: "Стационары",
          rout: DICTIONARY_HOSPITALS,
        },
        {
          name: "disease-severity",
          text: "Степени тяжести заболевания",
          rout: DICTIONARY_DISEASE_SEVERITY,
        },
        {
          name: "treatment-place",
          text: "Места прохождения лечения",
          rout: DICTIONARY_TREATMENT_PLACES,
        },
      ]
    : []),
];
