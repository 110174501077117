import { useAtom } from "jotai";
import React, { FC } from "react";
import { StartAndEndDatesInput } from "../../../components/DateInput";
import Filters from "../../../components/Filters";
import SelectAutocompleteMultipleInput from "../../../components/SelectAutocompleteMultipleInput";
import SelectInput from "../../../components/SelectInput/SelectInput";
import { employeeStatuses } from "../../../constants/options";
import { getMainDepartmentOptions } from "../../../utils/getOptions";
import { today } from "../../../utils/workingWithDates";
import { useForms } from "../hooks/useForms";
import { absoluteInitialValues, filtersState } from "../utils/atoms";

const EmployeesFilters: FC<PropsType> = (props) => {
  const { onClose } = props;

  const [_, setFilters] = useAtom(filtersState);

  const { initialValuesFilters, validationSchema } = useForms();

  const fields = (
    <>
      <SelectInput
        name="employeeStatus"
        size="small"
        options={employeeStatuses}
        label="Статус"
        placeholder="Выберите из списка"
      />

      <SelectAutocompleteMultipleInput
        name="mainDepartmentCodes"
        options={getMainDepartmentOptions()}
        label="Подразделения"
        placeholder="Выберите из списка"
        type="textInInput"
        size="small"
      />

      <StartAndEndDatesInput
        maxDate={today}
        label={{ start: "Начало периода", stop: "Окончание периода" }}
      />
    </>
  );

  return (
    <Filters
      initialValues={initialValuesFilters}
      absoluteInitialValues={absoluteInitialValues}
      filtersSchema={validationSchema}
      onClose={onClose}
      setFilters={setFilters}
    >
      {fields}
    </Filters>
  );
};

export default EmployeesFilters;

type PropsType = {
  onClose: () => void;
};
