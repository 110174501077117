import React, { FC, Suspense } from "react";
import { usePage } from "./hooks/usePage";
import { ErrorText } from "../../components/ErrorBoundary/ErrorBoundary.styles";
import { Main, PageContainer } from "../Page.styles";
import Header from "../../components/Header";
import ErrorBoundary from "../../components/ErrorBoundary";
import Loader from "../../components/Loader";
import UploadEmployeesTable from "./Table/UploadEmployeesTable";

const UploadEmployeesPage: FC = () => {
  const { canViewUploadEmployeesPage, navigation } = usePage();

  return canViewUploadEmployeesPage ? (
    <PageContainer>
      <Header navigation={navigation} />
      <Main className="main">
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <UploadEmployeesTable />
          </Suspense>
        </ErrorBoundary>
      </Main>
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default UploadEmployeesPage;
