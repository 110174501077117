import React, { FC } from "react";
import { Trash } from "../../Icons";
import { Container } from "./TrashButton.styles";

const TrashButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <Trash />
    </Container>
  );
};

export default TrashButton;

type PropsType = {
  onClick: () => void;
};
