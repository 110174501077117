import { CircularProgress } from "@mui/material";
import saveAs from "file-saver";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import React, { ChangeEvent, DragEventHandler, FC, useEffect, useRef } from "react";
import { statusFileInput } from "../../atoms/atoms";
import { TrashButton } from "../Buttons";
import * as icons from "../Icons";
import * as elements from "./FilesInput.styles";
import { useFilesInput } from "./useFilesInput";

const FilesInput: FC<PropsType> = (props) => {
  const { name, label, accept, maxSize, description, prefix } = props;

  const { values, setFieldValue } = useFormikContext<Record<string, File | undefined | string>>();

  const fileValue = values[name];

  const hasFile = fileValue instanceof File && !fileValue.type.includes("image");

  const [status, setStatus] = useAtom(statusFileInput);

  const ref = useRef<HTMLInputElement>(null);

  const opts = { accept, maxSize };
  const { error, progress, addFile, handleRemove } = useFilesInput(opts);

  const { Container, Title, Description, Text, DocumentIconAndName, Button } = elements;
  const { Input, Icon, Progress, Percent, LoadBarAndText, LoadBar } = elements;
  const { ExclamationMark, ArrowRound, CrossOnCircle, Check, Folder, Book, PaperClip } = icons;

  useEffect(() => {
    !!fileValue && setStatus("wasFile");

    return () => setStatus("empty");
  }, []);

  const handleDragOver: DragEventHandler<HTMLElement> = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleDragLeave: DragEventHandler = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleDrop: DragEventHandler = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    const file = evt.dataTransfer.files?.[0];

    addFile(name, file, setFieldValue);
  };

  const onChange = (evt: ChangeEvent) => {
    const file = (evt.target as HTMLInputElement).files?.[0];

    addFile(name, file, setFieldValue);
  };

  const fileName = fileValue instanceof File ? fileValue?.name : undefined;
  const fileNameWithoutAccept = fileName?.slice(0, fileName.lastIndexOf("."));
  const fileAccept = fileName?.slice(fileName.lastIndexOf("."));
  const fileSize =
    fileValue instanceof File ? fileValue && `${Math.round(fileValue.size / 1024)} КБ` : undefined;

  return (
    <>
      {status === "error" ? (
        <Container status={status} as="div">
          <LoadBarAndText>
            <LoadBar status={status}>
              <ExclamationMark />
            </LoadBar>
            <Text>
              <Title status={status}>{fileName}</Title>
              <Description status={status}>{error}</Description>
            </Text>
          </LoadBarAndText>

          <Button onClick={() => handleRemove(name, ref, setFieldValue)}>
            <ArrowRound />
          </Button>
        </Container>
      ) : status === "progress" ? (
        <Container status={status} as="div">
          <LoadBarAndText>
            <Progress>
              <CircularProgress variant="determinate" value={progress} />
              <Percent>{`${Math.round(progress)}%`}</Percent>
            </Progress>
            <Text>
              <Title status={status}>{fileName}</Title>
              <Description status={status}>{fileSize}</Description>
            </Text>
          </LoadBarAndText>

          <Button onClick={() => handleRemove(name, ref, setFieldValue)}>
            <CrossOnCircle />
          </Button>
        </Container>
      ) : status === "success" ? (
        <Container status={status} as="div">
          <LoadBarAndText>
            <LoadBar status={status}>
              <Check />
            </LoadBar>
            <Text>
              <Title status={status}>Файл загружен</Title>
              <Description status={status}>Обработка завершается...</Description>
            </Text>
          </LoadBarAndText>

          <Icon></Icon>
        </Container>
      ) : status === "loaded" ? (
        <Container status={status} as="div">
          <LoadBarAndText>
            <LoadBar status={status}>
              <Folder />
              {fileAccept}
            </LoadBar>
            <Text>
              <Title
                status={status}
                onClick={() => (hasFile ? saveAs(fileValue, fileName) : undefined)}
                needDownload={hasFile}
              >
                {fileNameWithoutAccept}
              </Title>
              <Description status={status}>{fileSize}</Description>
            </Text>
          </LoadBarAndText>

          <TrashButton onClick={() => handleRemove(name, ref, setFieldValue)} />
        </Container>
      ) : status === "wasFile" ? (
        <Container status={status} as="div">
          {fileName ? (
            <DocumentIconAndName>
              <Book />
              <Title
                status={status}
                onClick={() => (hasFile ? saveAs(fileValue, fileName) : undefined)}
                needDownload={hasFile}
              >
                {fileName}
              </Title>
            </DocumentIconAndName>
          ) : (
            <img alt="picture" src={`${prefix}${fileValue}`} />
          )}

          <TrashButton onClick={() => handleRemove(name, ref, setFieldValue)} />
        </Container>
      ) : (
        <Container
          status={status}
          as="button"
          type="button"
          onClick={() => ref.current?.click()}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <LoadBarAndText>
            <LoadBar status={status}>
              <Folder />
            </LoadBar>
            <Text>
              <Title status={status}>{label}</Title>
              <Description status={status}>
                {description ? description : "Подходят файлы в формате 24x24px "}
                {accept.join(" ")}
              </Description>
            </Text>
          </LoadBarAndText>
          <Icon>
            <PaperClip />
          </Icon>
        </Container>
      )}

      <Input ref={ref} name={name} type="file" accept={accept[0]} onChange={onChange} />
    </>
  );
};

export default FilesInput;

type PropsType = {
  name: string;
  label: string;
  accept: string[];
  maxCount?: number;
  maxSize?: number;
  description?: string;
  prefix?: string;
};

export type StatusType = "empty" | "progress" | "error" | "success" | "loaded" | "wasFile";
