import styled from "@emotion/styled";
import { colors } from "../../styles/styles";

export const Container = styled.header`
  width: 100%;
  position: relative;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.grayscaleInput};
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  padding: 16px 0 0 0;
`;
