import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import React, { FC, ReactNode } from "react";
import { statusFileInput } from "../../atoms/atoms";
import { FormButton } from "../Buttons";
import { FormButtonsContainer } from "./Form.styles";

const FormButtons: FC<PropsType> = (props) => {
  const { formName, onClose, saveOrAdd, onClear, disabled = false } = props;
  const { additionalButton } = props;

  const { isValid } = useFormikContext();

  const [statusFileLoad] = useAtom(statusFileInput);

  const activeButton =
    !disabled &&
    isValid &&
    statusFileLoad !== "progress" &&
    statusFileLoad !== "success" &&
    statusFileLoad !== "error";

  return (
    <FormButtonsContainer>
      {additionalButton?.text && additionalButton?.onClick ? (
        <FormButton
          text={additionalButton.text}
          icon={additionalButton.icon}
          onClick={additionalButton.onClick}
          active
        />
      ) : null}

      {saveOrAdd === "save" ? (
        <FormButton formName={formName} text="Сохранить" active={activeButton} />
      ) : saveOrAdd === "clear" ? (
        <FormButton formName={formName} text="Применить" active={isValid} />
      ) : saveOrAdd === "download" ? (
        <FormButton formName={formName} text="Скачать" active={isValid} />
      ) : (
        <FormButton formName={formName} text="Добавить" active={activeButton} />
      )}

      {additionalButton ? null : saveOrAdd === "clear" || saveOrAdd === "download" ? (
        <FormButton text="Очистить" onClick={onClear} />
      ) : (
        <FormButton text="Отменить" onClick={onClose} />
      )}
    </FormButtonsContainer>
  );
};

export default FormButtons;

type PropsType = {
  formName: string;
  onClose: () => void;
  saveOrAdd: "save" | "add" | "clear" | "download";
  additionalButton?: {
    text?: string;
    icon?: ReactNode;
    onClick?: () => void;
  };
  onClear?: () => void;
  disabled?: boolean;
};
