import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.div`
  margin: 0 24px 12px 24px;
`;

export const TitleAndNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px 0;
`;

export const Title = styled.h1`
  font-size: 20px;
  line-height: 26px;
  font-weight: 800;
  color: ${colors.black};
`;

export const BreadcrumbsNav = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
`;

export const BreadcrumbsLink = styled(Link)`
  color: ${colors.grayscalePlaceholder};
  text-decoration: none;

  :hover {
    color: ${colors.grayscaleBeautifulBlack};
  }
`;

export const Separator = styled.span`
  color: ${colors.grayscalePlaceholder};
`;

export const SubTitle = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.grayscaleBeautifulBlack};
`;

export const Buttons = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
`;

export const LeftButtons = styled.div`
  display: flex;
  gap: 16px;
`;

export const RightButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

export const ButtonProfileMenu = styled.button`
  ${button};

  width: 400px;
  height: 56px;
  justify-content: space-between;
  border-radius: 8px;

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }

  span {
    display: block;
    background: ${colors.grayscalePlaceholder};
    border-radius: 99em;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: ${colors.white};
    margin: 22px;
    padding: 2px 4px;
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleLable};
    }
  }
`;
