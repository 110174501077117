import React, { FC } from "react";
import { Minus, Plus } from "../../Icons";
import { Container } from "./PlusMinusButton.styles";

const PlusMinusButton: FC<PropsType> = (props) => {
  const { onClick, type, disabled } = props;

  return (
    <Container type="button" onClick={disabled ? undefined : onClick} disabled={disabled}>
      {type === "plus" ? <Plus /> : <Minus />}
    </Container>
  );
};

export default PlusMinusButton;

type PropsType = {
  onClick?: () => void;
  type: "plus" | "minus";
  disabled: boolean;
};
