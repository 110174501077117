import React, { FC } from "react";
import { Square, SquareWithCheck } from "../Icons";
import { Container } from "./Check.styles";

const Check: FC<PropsType> = (props) => {
  const { checked = false } = props;

  return (
    <Container
      icon={<Square />}
      checkedIcon={<SquareWithCheck />}
      checked={checked}
    />
  );
};

export default Check;

type PropsType = { checked: boolean };
