import { ActiveElement, Chart, ChartEvent } from "chart.js";
import "chartjs-adapter-date-fns";
import {
  differenceInMonths,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  getMonth,
  getYear,
} from "date-fns";
import { useAtom } from "jotai";
import React, { ReactNode } from "react";
import { ErrorText, InfoText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Skeleton from "../../../components/Skeleton";
import { monthNames } from "../../../constants/dates";
import { colors } from "../../../styles/styles";
import { unknownErrorText } from "../../../utils/getErrorMessage";
import { getNames } from "../../../utils/getNames";
import { formatDate, plusOneDay } from "../../../utils/workingWithDates";
import {
  currentDateInterval,
  selectedOrganizationsAtom,
  statsForOrganizationState,
} from "../utils/atoms";
import { usePanel } from "./usePanel";
import useColor from "./useColor";

export const useCharts = () => {
  // ------------------------------ АТОМЫ

  const [statsForOrganizationsData] = useAtom(statsForOrganizationState);

  const [selectedOrganizations] = useAtom(selectedOrganizationsAtom);
  const [dateInterval] = useAtom(currentDateInterval);

  // ------------------------------ ХУКИ

  const { organizations, organizationsData } = usePanel();

  const { organizationName } = getNames();

  const { getColorsForChart } = useColor();

  // ------------------------------ ДАННЫЕ

  const statsForOrganizations =
    statsForOrganizationsData.state === "hasData" ? statsForOrganizationsData.data : [];

  const dataWrapper = (component: ReactNode) =>
    statsForOrganizationsData.state === "loading" || organizationsData.state === "loading" ? (
      <Skeleton forElement="charts" />
    ) : statsForOrganizationsData.state === "hasError" || organizationsData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : !organizations || organizations.length === 0 ? null : selectedOrganizations.length === 0 ? (
      <InfoText>Ни одна станция не выбрана</InfoText>
    ) : statsForOrganizations.length === 0 ? (
      <InfoText>Нет данных</InfoText>
    ) : (
      component
    );

  // ------------------------------ ДИАГРАММА - ДАННЫЕ

  const organizationIsSelected = (organization: string) =>
    selectedOrganizations.findIndex(
      (selectedOrganization) => selectedOrganization === organization
    ) !== -1;

  const getDatasets = () =>
    statsForOrganizations
      ?.map((chartForOrganization, i) => {
        if (
          selectedOrganizations.includes(chartForOrganization.organization) ||
          statsForOrganizations.every(
            ({ organization }, index) => organization === selectedOrganizations[index]
          )
        ) {
          return {
            label: chartForOrganization.organization,
            data: getLabels()
              .data.find(({ organization }) => organization === chartForOrganization.organization)
              ?.data.map(({ morbidity }) => morbidity),
            fill: "start",
            borderColor: getColorsForChart(i).lines,
            cubicInterpolationMode: "monotone" as const,
            // backgroundColor: colors.transparent,
            // pointRadius: 5,
            pointBackgroundColor: getColorsForChart(i).gradient,
            pointBorderColor: getColorsForChart(i).lines,
            // pointHoverRadius: 7,
            pointHoverBackgroundColor: getColorsForChart(i).gradient,
            pointHoverBorderColor: getColorsForChart(i).lines,
          };
        }
      })
      .filter((datum) => !!datum) as DatasetsType;

  // ------------------------------ ДИАГРАММА - ПОДПИСИ

  const getLabels = () => {
    let data = [] as StatisticsDataType;
    let labels = [] as (string | string[])[];

    const start = dateInterval[0] as Date;
    const end = dateInterval[1] as Date;

    const interval = { start, end };

    const numberOfYears = differenceInYears(end, start);
    const hasRemainingYears = numberOfYears % 9 > 0;

    const numberOfMonths = differenceInMonths(end, start) + 1;
    const hasRemainingMonths = numberOfMonths % 9 > 0;

    const years = eachYearOfInterval(interval);
    const months = eachMonthOfInterval(interval);
    const weeks = eachWeekOfInterval(interval, { weekStartsOn: 1 });

    const days = eachDayOfInterval(interval);

    const filterData = ({ data, start, end }: FilterDataProps) =>
      data.filter(({ date }) => date >= start && date <= end);

    const formatData = (intervals: Date[]) =>
      statsForOrganizations.map(({ organization, data }) => {
        const datum = intervals.map((date, i) => ({
          date: i === 0 ? interval.start : date,
          morbidity:
            filterData({
              data,
              start: i === 0 ? interval.start : date,
              end: i === intervals.length - 1 ? interval.end : intervals[i + 1],
            }).reduce((sum, data) => sum + data.morbidity, 0) /
            filterData({ data, ...interval }).length,
        }));
        return {
          organization,
          data: datum,
        };
      });

    if (numberOfYears > 9 || (numberOfYears === 9 && hasRemainingYears)) {
      data = formatData(years);

      labels = years.map((date) => `${getYear(date)}`);
    } else if (numberOfMonths > 9 || (numberOfMonths === 9 && hasRemainingMonths)) {
      data = formatData(months);

      labels = months.map((date) => [`${monthNames[getMonth(date)][0]}`, `${getYear(date)}`]);
    } else if (numberOfMonths > 2 && numberOfMonths <= 9) {
      data = formatData(weeks);

      labels = weeks.map((date, i) => [
        `${i === 0 ? formatDate(interval.start) : formatDate(date)}`,
        date === weeks[i + 1]
          ? ""
          : ` - ${i === weeks.length - 1 ? formatDate(interval.end) : formatDate(weeks[i + 1])}`,
      ]);
    } else if (numberOfMonths > 1 && numberOfMonths <= 2) {
      data = statsForOrganizations.map(({ organization, data }) => ({
        organization,
        data: data
          .filter((_, i) => i % 2 === 0)
          .map(({ date, morbidity }) => ({ date, morbidity })),
      }));

      labels = days
        .filter((_, i) => i % 2 === 0)
        .map((date, i) => [
          `${formatDate(date)}`,
          i === Math.ceil(days.length / 2) - 1 ? "" : ` - ${formatDate(plusOneDay(date))}`,
        ]);
    } else if (numberOfMonths <= 1) {
      data = statsForOrganizations.map(({ organization, data }) => ({
        organization,
        data: data.map(({ date, morbidity }) => ({ date, morbidity })),
      }));

      labels = days.map((date) => formatDate(date));
    }

    return { data, labels };
  };

  // ------------------------------ ДИАГРАММА - ОПЦИИ

  const getOptions = (canvasElement: HTMLCanvasElement) =>
    ({
      ...getOptionsForExport(),
      interaction: { mode: "nearest" },
      plugins: {
        legend: { display: false },
        tooltip: {
          position: "average",
          backgroundColor: colors.grayscaleBeautifulBlackOpacity,
          bodyColor: colors.white,
          bodyFont: { size: 16, weight: "400" },
          padding: 16,
          cornerRadius: 8,
          displayColors: false,
          callbacks: {
            title: () => "",
            label: ({ formattedValue, dataset }) =>
              `${dataset.label ? `${organizationName(dataset.label)}: ` : ""}${formattedValue}`,
          },
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: "y",
          },
          limits: {
            y: { min: -100, max: 100, minRange: 1 },
          },
        },
      },
      hover: { mode: "nearest", intersect: false },
      onHover: (_, activeElement, chart) => {
        const datasets = chart.data.datasets;

        if (activeElement.length === 0) {
          datasets.forEach((dataset) => (dataset.backgroundColor = colors.transparent));
          chart.update();
          return;
        }

        datasets.forEach((dataset, i) => {
          const colorIndex = statsForOrganizations.findIndex(
            (datum) => datum.organization === dataset.label
          );

          const context = canvasElement.getContext("2d");
          const chartHeight = canvasElement.clientHeight;
          const gradient = context!.createLinearGradient(0, 0, 0, chartHeight);
          gradient?.addColorStop(0, getColorsForChart(colorIndex).gradient);
          gradient?.addColorStop(1, colors.transparent);

          dataset.backgroundColor =
            activeElement[0].datasetIndex === i ? gradient : colors.transparent;
        });

        chart.update();
      },
    } as OptionsType);

  const getOptionsForExport = () =>
    ({
      animation: false,
      scales: {
        x: {
          ticks: {
            maxRotation: 55,
            minRotation: 0,
            font: { family: "Rosatom", size: 12, weight: 600, lineHeight: 1.25 },
            color: colors.grayscaleLable,
            padding: 8,
          },
          grid: {
            color: colors.grayscaleLine,
            drawTicks: false,
            borderDash: [10, 10],
            drawBorder: false,
          },
          grace: 10,
        },
        y: {
          ticks: {
            font: { family: "Rosatom", size: 12, weight: 500, lineHeight: 1.25 },
            color: colors.grayscalePlaceholder,
          },
          grid: { display: false, drawBorder: false },
        },
      },
      elements: {
        line: { backgroundColor: colors.transparent },
        point: { radius: 5, hoverRadius: 7 },
      },
      plugins: {
        legend: { display: false },
        tooltip: { enabled: false },
        zoom: {
          zoom: { mode: "y" },
          limits: {
            y: { min: -100, max: 100, minRange: 1 },
          },
        },
      },
    } as OptionsForExportType);

  return {
    statsForOrganizations,
    dataWrapper,

    organizationIsSelected,

    getDatasets,
    getLabels,
    getOptions,
    getOptionsForExport,
  };
};

type DatasetsType = {
  index: number;
  label: string;
  data: number[];
  fill: string;
  borderColor: string;
  cubicInterpolationMode: "monotone";
  backgroundColor: string;
  pointRadius: number;
  pointBackgroundColor: string;
  pointBorderColor: string;
  pointHoverRadius: number;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
}[];

export type StatisticsDataType = {
  organization: string;
  data: {
    date: Date;
    morbidity: number;
  }[];
}[];

export type OptionsType = {
  plugins: {
    legend: { display: boolean };
    tooltip: {
      callbacks: {
        title: () => string;
        label: (tooltipItem: {
          formattedValue: string;
          dataset: { label?: string };
        }) => string | string[];
      };
    };
  };

  onHover: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
};

type OptionsForExportType = {
  plugins: {
    tooltip: { enabled: boolean };
    legend: { display: boolean };
  };
};

type FilterDataProps = {
  data: { date: Date; morbidity: number }[];
  start: Date;
  end: Date;
};
