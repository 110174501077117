import React, { FC } from "react";
import { OptionsType } from "../../utils/getOptions";
import * as elements from "./SelectAutocompleteMultipleInput.styles";

const SelectAutocompleteInputChips: FC<PropsType> = (props) => {
  const { valuesName, options, deleteValue } = props;

  const { SelectedChipsItems, SelectedChipsItem } = elements;

  return (
    <SelectedChipsItems>
      {valuesName.map((value, i) => (
        <SelectedChipsItem
          key={i}
          label={options.find((option) => option.id === value)!.name}
          onDelete={() => deleteValue(value)}
        />
      ))}
    </SelectedChipsItems>
  );
};

export default SelectAutocompleteInputChips;

type PropsType = {
  valuesName: string[];
  options: OptionsType;
  deleteValue: (arg: string) => void;
};
