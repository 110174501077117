import { useFormikContext } from "formik";
import React, { FC } from "react";
import { OptionsType } from "../../utils/getOptions";
import { Placeholder } from "./SelectAutocompleteMultipleInput.styles";

const SelectAutocompleteInputPlaceholder: FC<PropsType> = (props) => {
  const { name, type, options, label, placeholder, switcherChecked } = props;

  const { values } = useFormikContext<Record<string, string[]>>();

  const valuesName = values[name];

  const nothingIsSelectedAndNoLists = valuesName.length === 0 && type === "textInInput";
  const namesOfSelected = options
    .filter((option) => valuesName.find((value) => option.id === value))
    .map((option) => option.name)
    .join(", ");

  return (
    <Placeholder haslabel={+!!label}>
      {switcherChecked
        ? "Выбраны все варианты"
        : !nothingIsSelectedAndNoLists
        ? namesOfSelected
        : placeholder}
    </Placeholder>
  );
};

export default SelectAutocompleteInputPlaceholder;

type PropsType = {
  name: string;
  type: TypeListType;
  options: OptionsType;
  label?: string;
  placeholder: string;
  switcherChecked: boolean;
};

type TypeListType = "list" | "chips" | "textInInput";
