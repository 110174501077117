import React, { FC, ReactNode } from "react";
import { Container } from "./FormButton.styles";

const FormButton: FC<PropsType> = (props) => {
  const { text, icon, formName, active = true, onClick } = props;

  return (
    <Container
      form={formName}
      type={
        text === "Сохранить" || text === "Добавить" || text === "Применить" || text === "Скачать"
          ? "submit"
          : "button"
      }
      active={active}
      disabled={!active}
      text={text}
      needIcon={!!icon}
      onClick={onClick}
    >
      {icon ?? null}
      <p>{text}</p>
    </Container>
  );
};

export default FormButton;

type PropsType = {
  formName?: string;
  text: string;
  icon?: ReactNode;
  active?: boolean;
  onClick?: () => void;
};
