import { DatePicker, DateRange } from "@mui/lab";
import { TextFieldProps } from "@mui/material";
import { addMonths, endOfMonth, getYear, startOfMonth, subMonths } from "date-fns";
import React, { FC, useState } from "react";
import { minusOneMonths, plusOneMonths, today } from "../../utils/workingWithDates";
import { LeftAndRightArrowsButtons } from "../Buttons";
import { DateSwitcher, DateSwitcherText, PaperProps } from "./DateSwitcher.styles";

const MonthsSwitcher: FC<PropsType> = (props) => {
  const { dateInterval, setDateInterval, minDate, maxDate } = props;

  const [open, setOpen] = useState(false);

  const date = dateInterval[0];

  const disableBack = !!date && subMonths(date, 1) < minDate;
  const disableNext = !!date && addMonths(date, 1) > maxDate;

  const yearIsCurrent = (inputProps: TextFieldProps) =>
    inputProps.inputProps?.value.split(" ")[1] === String(getYear(today));

  return (
    <>
      <LeftAndRightArrowsButtons
        onClick={() => {
          const currentDate = minusOneMonths(date!);

          return disableBack
            ? undefined
            : setDateInterval([startOfMonth(currentDate), endOfMonth(currentDate)]);
        }}
        disableBack={disableBack}
        orientation="left"
      />

      <DatePicker
        views={["month", "year"]}
        value={date}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(newDate) =>
          setDateInterval([startOfMonth(newDate as Date), endOfMonth(newDate as Date)])
        }
        renderInput={(inputProps) => (
          <DateSwitcher onClick={() => setOpen(true)} ref={inputProps.inputRef}>
            <DateSwitcherText>
              {yearIsCurrent(inputProps)
                ? inputProps.inputProps?.value.split(" ")[0]
                : inputProps.inputProps?.value}
            </DateSwitcherText>
          </DateSwitcher>
        )}
        minDate={minDate}
        maxDate={maxDate}
        PaperProps={PaperProps}
      />

      <LeftAndRightArrowsButtons
        onClick={() => {
          const currentDate = plusOneMonths(date!);

          return disableNext
            ? undefined
            : setDateInterval([startOfMonth(currentDate), endOfMonth(currentDate)]);
        }}
        disableNext={disableNext}
        orientation="right"
      />
    </>
  );
};

export default MonthsSwitcher;

type PropsType = {
  dateInterval: DateRange<Date>;
  setDateInterval: (arg: DateRange<Date>) => void;
  minDate: Date;
  maxDate: Date;
};
