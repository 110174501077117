import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { DateSwitcherType } from "../components/DateSwitcher/DateSwitcher";
import { StatusType } from "../components/FilesInput/FilesInput";

// ------------------------------ LOADER'Ы ДОБАВЛЕНИЯ/РЕДАКТИРОВАНИЯ/УДАЛЕНИЯ СТРОКИ ТАБЛИЦЫ

export const statusDeletion = atom(false);
export const statusCreateOrUpdate = atom(false);

// ------------------------------ СОСТОЯНИЕ КНОПКИ ЗАГРУЗКИ ФАЙЛА

export const statusFileInput = atom<StatusType>("empty");

// ------------------------------ ВЫБРАННЫЕ СТРОКИ

export const selectedRowsState = atomWithImmer<string[]>([]);

// ------------------------------ ВЫБРАННЫЕ СТОЛБЦЫ

export const selectedColumnsState = atomWithImmer<number[]>([]);

// ------------------------------ ОТКРЫТЫЕ СПИСКИ

export const openedListsState = atomWithImmer<(string | number)[]>([]);

// ------------------------------ ПОИСК ПО ТАБЛИЦЕ

export const searchQueryState = atom<string>("");

// ------------------------------ ТЕКУЩАЯ СТРАНИЦА ТАБЛИЦЫ

export const pageState = atom(0);

// ------------------------------ СОСТОЯНИЕ ЛЕВОГО МЕНЮ

export const menuIsExpandState = atom(false);

// ------------------------------ ТИП ПЕРЕКЛЮЧАТЕЛЯ ДАТ

export const dateSwitcherTypeState = atom<DateSwitcherType | "">("");

// ------------------------------ СОСТОЯНИЕ ПЕРЕКЛЮЧАТЕЛЯ ПЕРИОДОВ ДАТ

export const periodSwitcherTypeState = atom<DateSwitcherType>("year");
