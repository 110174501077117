import React from "react";
import { Table, TableBodyRow, TableHeadRow } from "../../../components/Table";
import { usePage } from "../hooks/usePage";
import { useAtom } from "jotai";
import { uploadResultState } from "../utils/atoms";

const UploadEmployeesTable = () => {
  const [uploadResult] = useAtom(uploadResultState);

  const { titlesInit, titles, grids, dataForTable, dataWrapper } = usePage();
  return (
    <Table titles={titlesInit}>
      <TableHeadRow titles={titles} titlesInit={titlesInit} grid={grids} />
      {dataWrapper(
        uploadResult.map((datum) => {
          const { id } = datum;

          return <TableBodyRow key={id} id={id} data={dataForTable(datum)} grid={grids} />;
        })
      )}
    </Table>
  );
};

export default UploadEmployeesTable;
