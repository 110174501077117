export const getInclinedWord = (number: number, text: Array<string>): string => {
  number = Math.abs(number) % 100;

  return number > 10 && number < 20
    ? text[2]
    : number % 10 > 1 && number % 10 < 5
    ? text[1]
    : number % 10 === 1
    ? text[0]
    : text[2];
};
