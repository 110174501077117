import styled from "@emotion/styled";
import { FormControlLabel, Switch } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled(FormControlLabel)`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscaleBody};
  margin: 0;
` as typeof FormControlLabel;

export const Switcher = styled(Switch)<{ checked: boolean }>`
  width: 50px;
  height: 24px;
  background: ${colors.white};
  border-radius: 99em;
  margin: 0 8px;
  padding: 0;

  .MuiButtonBase-root {
    padding: 0;
    top: 2px;
    transform: translateX(2px);

    &.Mui-checked {
      transform: translateX(28px);
    }
  }

  .MuiSwitch-track {
    &:before,
    &:after {
      width: 16px;
      height: 16px;
      position: absolute;
      transform: translateY(50%);
      font-size: 10px;
      line-height: 10px;
      font-weight: 500;
      color: ${colors.white};
      white-space: nowrap;
    }

    &:before {
      ${({ checked }) => (checked ? 'content: "ДА"' : 'content: "НЕТ"')};
      ${({ checked }) => (checked ? "left: 10px" : "right: 10px")};
    }
  }

  .Mui-checked + .MuiSwitch-track {
    background: ${colors.grayscaleBody};
    opacity: 1;
  }

  .MuiSwitch-thumb {
    box-shadow: none;
    color: ${colors.white};
  }

  .MuiTouchRipple-root {
    display: none;
  }
`;
