import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors, overflowOneLine } from "../../../styles/styles";

export const Container = styled.div<{ onlyIcon?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  margin: ${({ onlyIcon }) => (onlyIcon ? "10px 0" : "0")};
  padding: ${({ onlyIcon }) => (onlyIcon ? "0" : "10px 0")};

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Icon = styled.div<{ isExpand: boolean; backgroundImg?: string }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: ${({ backgroundImg }) =>
    backgroundImg ? `url(data:image/jpg+xml;base64,${backgroundImg})` : colors.purple};
  background-size: cover;
  border-radius: 99em;
  cursor: pointer;
  margin: ${({ isExpand }) => (isExpand ? "0 16px 0 0" : "0")};

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: ${colors.white};
  }
`;

export const Name = styled.p`
  ${overflowOneLine}

  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.grayscaleAsh};
`;

export const Roles = styled.p`
  ${overflowOneLine}

  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: ${colors.grayscalePlaceholder};
`;

export const ProfileMenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

export const ProfileMenuButton = styled.a`
  ${button};

  width: 328px;
  height: 56px;
  justify-content: space-between;
  border-radius: 8px;

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }

  span {
    display: block;
    background: ${colors.grayscalePlaceholder};
    border-radius: 99em;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: ${colors.white};
    margin: 22px;
    padding: 2px 4px;
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};
    text-decoration: none;

    svg > path {
      fill: ${colors.grayscaleLable};
    }
  }
`;
