import React, { FC } from "react";
import { colors } from "../../styles/styles";

const ArrowRight: FC = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375342 11.7809C-0.0559197 11.4359 -0.125841 10.8066 0.219168 10.3753L3.71941 6.00001L0.219168 1.6247C-0.125842 1.19344 -0.0559202 0.564147 0.375342 0.219138C0.806604 -0.125872 1.4359 -0.0559507 1.78091 0.375311L5.78091 5.37531C6.07308 5.74053 6.07308 6.25948 5.78091 6.6247L1.78091 11.6247C1.4359 12.056 0.806604 12.1259 0.375342 11.7809Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default ArrowRight;
