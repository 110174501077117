import styled from "@emotion/styled";
import { InputLabel, Select, SelectProps } from "@mui/material";
import { colors, overflowOneLine, shadow } from "../../styles/styles";

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};
  margin: 0 0 16px 0;

  * {
    cursor: inherit !important;
  }
`;

export const Item = styled(Select)<ItemProps>`
  width: 100%;
  height: 56px;
  background: ${({ haserror }) => (haserror ? colors.redBackground : colors.grayscaleInput)};
  border-radius: 16px;
  cursor: pointer;
  padding: ${({ haslabel }) => (haslabel ? "8px 16px" : "16px")};

  .MuiSelect-select {
    margin: ${({ haslabel }) => (haslabel ? "16px 0 0 0" : "0")};
    padding: 0 32px 0 0 !important;
    z-index: 1;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    padding: 0;
  }
` as (props: SelectProps & { haslabel: number; haserror: number }) => JSX.Element;

export const Label = styled(InputLabel)`
  max-width: 100%;
  position: absolute;
  left: 16px;
  top: 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: ${colors.grayscaleLable};
  transform: none;
  margin: 0;
  z-index: 1;
`;

export const Placeholder = styled.p<{ haslabel: number }>`
  ${overflowOneLine};

  width: calc(100% - 40px - 8px - 16px * 2);
  // (40px) - ширина кнопки справа
  // (8px) - ширина отступа отступ от кнопки справа
  // (16px * 2) - 2 паддинга инпута
  position: absolute;
  top: ${({ haslabel }) => (haslabel ? "24px" : "16px")};
  left: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscalePlaceholder};
`;

export const MenuItemWrapper = styled.div`
  font-size: 16px;
  white-space: normal;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscaleAsh};
`;
export const MenuItemRender = styled.div`
  ${overflowOneLine};

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscaleAsh};
`;

export const Error = styled.p`
  position: absolute;
  top: 56px;
  left: 16px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${colors.redDefault};
`;

export const MenuProps = (size: string) => ({
  sx: {
    height: 232,

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: 1,

      ul: {
        width: "100%",
        padding: 0,

        li: {
          minHeight: 56,
          height: "fit-content",
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 1,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": {
        width: 8,
      },

      "&::-webkit-scrollbar-thumb": {
        background: colors.grayscaleLine,
        borderRadius: "99em",
      },
    },

    ".MuiPopover-paper": { width: size === "small" ? 372 : 472 },
  },
  anchorOrigin: {
    vertical: 40,
    horizontal: 0,
  },
  transformOrigin: {
    vertical: -8,
    horizontal: 16,
  },
});

type ItemProps = {
  multiline: boolean;
  haslabel: number;
  haserror: number;
};
