import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ru } from "date-fns/locale";
import { Provider, useAtom } from "jotai";
import { queryClientAtom } from "jotai/query";
import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import App from "./App";
import * as routes from "./api/routes";
import { currentUserState } from "./atoms/atomCurrentUser";
import { organizationState } from "./atoms/atomsOrg";
import Loader from "./components/Loader";
import keycloakInstance from "./keycloak";
import {
  ChartsForDepatrmentsPage,
  ChartsForEmployeesPage,
  ChartsForOrganizationsPage,
  CityDictionaryPage,
  CountryDictionaryPage,
  DisabilityCodeBlockTwoDictionaryPage,
  DisabilityCodeDictionaryPage,
  DiseaseSeverityDictionaryPage,
  DiseasesClassDictionaryPage,
  EmployeeCardPage,
  HospitalDictionaryPage,
  InfectiousDiseaseDictionaryPage,
  NationalityDictionaryPage,
  PersonnelCategoryDictionaryPage,
} from "./pages";
import { TreatmentPlaceDictionaryPage } from "./pages/";
import WarningPage from "./pages/WarningPage";
import "./styles/index.css";
import { theme } from "./styles/theme";
import { getErrorMessage } from "./utils/getErrorMessage";
import { Chart } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import UploadEmployeesPage from "./pages/UploadEmployeesPage/UploadEmployeesPage";

Chart.register(zoomPlugin);

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 0 } } });

const AppRoutes = () => {
  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const [currentUser] = useAtom(currentUserState);
  const [_, setOrganization] = useAtom(organizationState);

  const user = currentUser.state === "hasData" && currentUser.data.res;
  const userRoleModel = currentUser.state === "hasData" && currentUser.data.roleModel;
  const error = currentUser.state === "hasError" && currentUser.error;
  const loading = currentUser.state === "loading";

  const navigate = useNavigate();

  useEffect(() => {
    currentUser ? navigate(routes.STATISTICS) : undefined;

    setOrganization(user ? user.organizationCode : "");
  }, [currentUser]);

  error && getErrorMessage(error).includes("The user is missing the main_department attribute") && (
    <WarningPage code="not_main_department" />
  );

  if (!user || !userRoleModel) return loading ? <Loader /> : <WarningPage code="not_auth" />;

  const mainDepartmentCode = user.mainDepartmentCode;

  const twoModule = user.roles.join("").includes("mr-");
  const superUser = userRoleModel.superUser;
  const department = userRoleModel.department;
  const module = userRoleModel.module;

  !user && <WarningPage code="error_profile" />;

  !twoModule && !superUser && <WarningPage code="no_roles" />;

  !module && !user.organizationCode && <WarningPage code="no_org" />;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="*" element={<WarningPage code="page_not_found" />} />

        <Route
          path="/"
          element={<Navigate to={module ? routes.DICTIONARY : routes.STATISTICS} />}
        />

        {/* ------------------------------ СТАТИСТИКА ------------------------------ */}

        <Route
          path={routes.STATISTICS}
          element={
            <Navigate
              to={
                module
                  ? routes.DICTIONARY
                  : department
                  ? routes.STATISTICS_CHARTS_FOR_EMPLOYEES(mainDepartmentCode, undefined)
                  : routes.STATISTICS_CHARTS_FOR_ORGANIZATIONS
              }
            />
          }
        />

        <Route
          path={routes.STATISTICS_CHARTS_FOR_ORGANIZATIONS}
          element={<ChartsForOrganizationsPage />}
        />
        <Route path={routes.STATISTICS_UPLOAD_EMPLOYEES} element={<UploadEmployeesPage />} />
        <Route
          path={routes.STATISTICS_CHARTS_FOR_DEPARTMENTS(undefined)}
          element={<ChartsForDepatrmentsPage />}
        />
        <Route
          path={routes.STATISTICS_CHARTS_FOR_EMPLOYEES(mainDepartmentCode, undefined)}
          element={<ChartsForEmployeesPage />}
        />
        <Route path={routes.STATISTICS_EMPLOYEE_CARD(undefined)} element={<EmployeeCardPage />} />

        {/* ------------------------------ СПРАВОЧНИКИ ------------------------------ */}

        <Route
          path={routes.DICTIONARY}
          element={<Navigate to={routes.DICTIONARY_DISABILITY_CODE} />}
        />

        <Route
          path={routes.DICTIONARY_DISABILITY_CODE}
          element={<DisabilityCodeDictionaryPage />}
        />
        <Route
          path={routes.DICTIONARY_DISABILITY_CODE_BLOCK_TWO}
          element={<DisabilityCodeBlockTwoDictionaryPage />}
        />
        <Route
          path={routes.DICTIONARY_DISEASES_CLASSES}
          element={<DiseasesClassDictionaryPage />}
        />
        <Route
          path={routes.DICTIONARY_INFECTIOUS_DISEASES}
          element={<InfectiousDiseaseDictionaryPage />}
        />
        <Route path={routes.DICTIONARY_NATIONALITIES} element={<NationalityDictionaryPage />} />
        <Route
          path={routes.DICTIONARY_PERSONNEL_CATEGORIES}
          element={<PersonnelCategoryDictionaryPage />}
        />
        <Route path={routes.DICTIONARY_COUNTRIES} element={<CountryDictionaryPage />} />
        <Route path={routes.DICTIONARY_CITIES} element={<CityDictionaryPage />} />
        <Route path={routes.DICTIONARY_HOSPITALS} element={<HospitalDictionaryPage />} />
        <Route
          path={routes.DICTIONARY_DISEASE_SEVERITY}
          element={<DiseaseSeverityDictionaryPage />}
        />
        <Route
          path={routes.DICTIONARY_TREATMENT_PLACES}
          element={<TreatmentPlaceDictionaryPage />}
        />
      </Routes>
    </Suspense>
  );
};

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloakInstance}>
    <QueryClientProvider client={queryClient}>
      <Provider initialValues={[[queryClientAtom, queryClient]] as const}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <HashRouter>
              <App>
                <AppRoutes />
              </App>
            </HashRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);
