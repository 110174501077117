import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { EntitieType, HospitalType } from "../../../../api/apiDictionaries";
import { selectedRowsState } from "../../../../atoms/atoms";
import { InfoText } from "../../../../components/ErrorBoundary/ErrorBoundary.styles";
import { Table, TableBodyRow, TableHeadRow } from "../../../../components/Table";
import { useUserSettings } from "../../../../utils/useUserSettings";
import HospitalEditForm from "../Forms/HospitalEditForm";
import { useHospitalDictionary } from "../useHospitalDictionary";

const HospitalsTable: FC = () => {
  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const [modalData, setModalData] = useState<EntitieType<HospitalType>>();

  const hospitalDictionaryMethods = useHospitalDictionary();
  const { canEditDictionaries } = hospitalDictionaryMethods;
  const { hospitals, hospitalIds, handleDeleteRows } = hospitalDictionaryMethods;
  const { titles, titlesInit, grids, dataForTable } = hospitalDictionaryMethods;
  const { getUserSettings, setUserSettings } = useUserSettings();

  useEffect(() => {
    getUserSettings("hospital", titlesInit);

    return () => setSelectedRows([]);
  }, []);

  const handleCloseEditForm = () => setModalData(undefined);

  return (
    <>
      <Table titles={titlesInit}>
        <TableHeadRow
          titles={titles}
          titlesInit={titlesInit}
          grid={grids}
          ids={hospitalIds}
          needCheck={canEditDictionaries}
          needMenu={canEditDictionaries}
          setUserSettings={(selectedColumnNumbers) =>
            setUserSettings("hospital", titlesInit, selectedColumnNumbers)
          }
        />

        {hospitals.length === 0 ? (
          <InfoText>Нет данных</InfoText>
        ) : (
          hospitals.map((datum) => {
            const { id } = datum;

            return (
              <TableBodyRow
                key={id}
                id={id}
                data={dataForTable(datum)}
                grid={grids}
                onOpenEditForm={(evt) => {
                  evt.stopPropagation();
                  setModalData(datum);
                }}
                textConfirmModal={datum.name}
                onDeleteRows={handleDeleteRows}
                needMenu={canEditDictionaries}
                needCheck={canEditDictionaries}
              />
            );
          })
        )}
      </Table>

      {canEditDictionaries && modalData ? (
        <HospitalEditForm data={modalData} onClose={handleCloseEditForm} />
      ) : null}
    </>
  );
};

export default HospitalsTable;
