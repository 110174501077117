import styled from "@emotion/styled";
import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  ChipTypeMap,
  FormControlLabel,
  Popper,
  Switch,
  SwitchProps,
} from "@mui/material";
import { colors, overflowOneLine, shadow } from "../../styles/styles";

export const Container = styled.div`
  position: relative;
  margin: 0 0 16px 0;
`;

export const Item = styled(Autocomplete)<{ haslabel: number }>`
  width: 100%;
  height: 56px;
  background: ${colors.grayscaleInput};
  border-radius: 16px;
  cursor: pointer;
  padding: ${({ haslabel }) => (haslabel ? "8px 16px" : "16px")};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-root {
    margin: ${({ haslabel }) => (haslabel ? "16px 0 0 0" : "0")};
    padding: 0;
    padding-bottom: 8px;
  }

  .MuiButtonBase-root {
    width: 40px;
    height: 40px;
    position: absolute;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
    right: -8px;
    z-index: 1;

    :hover {
      background: ${colors.grayscaleHoverBackground};

      & > svg > path {
        fill: ${colors.grayscaleLable};
      }
    }
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-root {
    display: none;
  }

  input {
    padding: 0 !important;
    z-index: 1;
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: ${colors.grayscaleLable};
    transform: none;
    margin: 0;

    &.Mui-focused {
      color: ${colors.grayscaleLable};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & {
    haslabel: number;
  }
) => JSX.Element;

export const Placeholder = styled.div<{ haslabel: number }>`
  ${overflowOneLine};

  width: calc(100% - 50px);
  position: absolute;
  top: ${({ haslabel }) => (haslabel ? "24px" : "16px")};
  left: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscalePlaceholder};
  white-space: nowrap;
  z-index: 0;
`;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};

  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
`;

export const SelectedListItems = styled.div`
  margin: 0 16px;
`;

export const SelectedListItem = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 0 0;
`;

export const SelectedListText = styled.p`
  font-size: 15px;
  line-height: 26px;
  font-weight: 700;
`;

export const SelectedChipsItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin: 8px 0;
`;

export const SelectedChipsItem = styled(Chip)`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.grayscaleLable};
  border-radius: 99em;
  padding: 10px 10px 10px 16px;

  .MuiChip-label {
    font-size: 13px;
    line-height: 21px;
    font-weight: 700;
    padding: 0;
  }

  .MuiSvgIcon-root {
    width: 29px;
    height: 29px;
    margin: 0 0 0 8px;
  }

  &:hover {
    background: ${colors.grayscalePlaceholder};
  }
`;

export const SwitchContainer = styled(FormControlLabel)`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscaleBody};
  margin: 8px 0 0 0;
` as typeof FormControlLabel;

export const Switcher = styled(Switch)<{ ischecked: number }>`
  width: 50px;
  height: 24px;
  background: ${colors.white};
  border-radius: 99em;
  margin: 0 8px;
  padding: 0;

  .MuiButtonBase-root {
    padding: 0;
    top: 2px;
    transform: translateX(2px);

    &.Mui-checked {
      transform: translateX(28px);
    }
  }

  .MuiSwitch-track {
    &:before,
    &:after {
      width: 16px;
      height: 16px;
      position: absolute;
      transform: translateY(50%);
      font-size: 10px;
      line-height: 10px;
      font-weight: 500;
      color: ${colors.white};
    }

    &:before {
      ${({ ischecked }) => (ischecked ? 'content: "ДА"' : 'content: "НЕТ"')};
      ${({ ischecked }) => (ischecked ? "left: 10px" : "right: 10px")};
    }
  }

  .Mui-checked + .MuiSwitch-track {
    background: ${colors.grayscaleBody};
    opacity: 1;
  }

  .MuiSwitch-thumb {
    box-shadow: none;
    color: ${colors.white};
  }

  .MuiTouchRipple-root {
    display: none;
  }
` as (props: SwitchProps & { ischecked: number }) => JSX.Element;

export const PaperProps = (size: string) => {
  return {
    sx: {
      width: size === "small" ? 372 : 472,
      maxHeight: 264,
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "auto",
      ml: -2,
      padding: 1,

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "::-webkit-scrollbar": {
        width: 8,
      },

      "::-webkit-scrollbar-thumb": {
        background: colors.grayscaleLine,
        borderRadius: "99em",
      },
    },
  } as const;
};

export const PopperComponent = styled(Popper)`
  padding-top: 16px;
`;
