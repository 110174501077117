import { useFormikContext } from "formik";
import React, { FC } from "react";
import { OptionsType } from "../../utils/getOptions";
import { SwitchContainer, Switcher } from "./SelectAutocompleteMultipleInput.styles";

const SelectAutocompleteInputSwitcher: FC<PropsType> = (props) => {
  const { name, options, labelSwitch, switcherChecked } = props;

  const { setFieldValue } = useFormikContext<Record<string, string[]>>();

  return (
    <SwitchContainer
      control={
        <Switcher
          onChange={() =>
            switcherChecked
              ? setFieldValue(name, [])
              : setFieldValue(
                  name,
                  options.map((option) => option.id)
                )
          }
          checked={switcherChecked}
          ischecked={+switcherChecked}
        />
      }
      label={labelSwitch}
    />
  );
};

export default SelectAutocompleteInputSwitcher;

type PropsType = {
  name: string;
  options: OptionsType;
  labelSwitch: string;
  switcherChecked: boolean;
};
