import { MenuItem } from "@mui/material";
import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { menuIsExpandState } from "../../atoms/atoms";
import { OpenListButton } from "../Buttons";
import * as elements from "./Pagination.styles";

const Pagination: FC<PropsType> = (props) => {
  const { needPagination } = props;
  const { totalCount, setPage, quantity, setQuantity } = needPagination;

  const { Container, MenuItemWrapper, MenuProps, Overlay } = elements;
  const { Blur, Input, PaginationItem, QuantitySelection } = elements;

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page") ?? "1");

  const [menuIsExpand] = useAtom(menuIsExpandState);

  const count = Math.ceil(Number(totalCount) / quantity);

  const hasMoreOnePage = totalCount && count !== 1;

  // ------------------------------ < ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ

  const [isOpen, setIsOpen] = useState(false);

  const icon = () => <OpenListButton isOpen={isOpen} onOpen={() => setIsOpen(!isOpen)} />;

  // ------------------------------ ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ >

  // ------------------------------ < ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА

  const [isWidthLessThanMinimum, setIsWidthLessThanMinimum] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const widthMeter = () => setIsWidthLessThanMinimum(window.innerWidth <= 1024);
    window.addEventListener("resize", widthMeter);

    return () => window.removeEventListener("resize", widthMeter);
  }, []);

  // ------------------------------ ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА >

  // ------------------------------ < УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ

  useEffect(() => {
    setPage(page - 1);
    count < page && setSearchParams({ page: String(count) });
  }, [page, count]);

  // ------------------------------ УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ >

  return (
    <Overlay isWidthLessThanMinimum={isWidthLessThanMinimum} menuIsExpand={menuIsExpand}>
      <Container>
        {hasMoreOnePage ? (
          <PaginationItem
            count={count}
            page={page}
            onChange={(_, value) => setSearchParams({ page: String(value) })}
            size="large"
          />
        ) : null}
        <QuantitySelection>
          <Input
            value={quantity > 100 || quantity === totalCount ? "все" : quantity}
            open={isOpen}
            displayEmpty
            IconComponent={icon}
            MenuProps={MenuProps}
            onChange={(evt) => {
              if (!setQuantity) return;

              const number =
                evt.target.value === "все" ? Number(totalCount) : Number(evt.target.value);

              setQuantity(number);
              setPage(0);
            }}
            onClick={() => setIsOpen(!isOpen)}
            defaultValue={10}
            autoComplete="off"
          >
            {[10, 50, 100, "все"].map((option, i) => (
              <MenuItem key={i} value={option}>
                <MenuItemWrapper>{option}</MenuItemWrapper>
              </MenuItem>
            ))}
          </Input>
        </QuantitySelection>
      </Container>

      <Blur />
    </Overlay>
  );
};

export default Pagination;

type PropsType = {
  needPagination: {
    totalCount: number;
    setPage: (arg: number) => void;
    quantity: number;
    setQuantity?: (arg: number) => void;
  };
};
