// ------------------------------ ПОЛ

export const sexOptions = [
  { id: "MALE", name: "Мужской" },
  { id: "FEMALE", name: "Женский" },
];

// ------------------------------ ДА / НЕТ

export const yesAndNoOptions = [
  { id: "true", name: "Да" },
  { id: "false", name: "Нет" },
];

// ------------------------------ СТАТУСЫ СОТРУДЖНИКОВ

export const employeeStatuses = [
  {
    id: "HEALTHY",
    name: "Здоров",
  },
  {
    id: "SICK",
    name: "Болен",
  },
  {
    id: "DEAD",
    name: "Умер",
  },
];

// ------------------------------ РОЛИ

export const role = [
  {
    id: "system-full-access",
    name: "Суперпользователь",
  },
  {
    id: "mr-director-role",
    name: "Руководитель",
  },
  {
    id: "mr-oot-role",
    name: "Администратор ООТ",
  },
  {
    id: "mr-osr-role",
    name: "Администратор ОСР",
  },
  {
    id: "mr-department-role",
    name: "Администратор департамента",
  },
  {
    id: "mr-module-role",

    name: "Администратор модуля",
  },
];
