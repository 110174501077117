import { Chart } from "chart.js";
import React, { FC, useEffect, useRef } from "react";
import { colors } from "../../../styles/styles";
import { DiagramContainer } from "../ChartsForDepartmentsPage.styles";
import { OptionsType } from "../hooks/useCharts";

const DepartmentDiagram: FC<PropsType> = (props) => {
  const { id, healthIndex, getDatasets, getPlugins, getOptions } = props;

  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasElement = ref.current!;
    const context = canvasElement.getContext("2d")!;

    const gradient = context.createRadialGradient(4, 4, 200, 4, 4, 0);
    gradient.addColorStop(0, colors.greenDark);
    gradient.addColorStop(1, colors.greenDefault);

    const datasets = getDatasets(id, healthIndex, gradient);
    const plugins = getPlugins(healthIndex);
    const options = getOptions(healthIndex);

    const myDiagram = new Chart(canvasElement, {
      type: "doughnut",
      data: { labels: [], datasets },
      plugins: plugins,
      options: options,
    });

    return () => myDiagram.destroy();
  }, [healthIndex]);

  return (
    <DiagramContainer>
      <canvas ref={ref} />
    </DiagramContainer>
  );
};

export default DepartmentDiagram;

type PropsType = {
  id: string;
  healthIndex: number;
  getDatasets: (
    id: string,
    healthIndex: number,
    gradient?: CanvasGradient
  ) => {
    label: string;
    data: number[];
    backgroundColor: (string | CanvasGradient | undefined)[];
    borderRadius: number;
    borderWidth: number;
    borderColor: string[];
    hoverBorderColor: string;
  }[];
  getPlugins: (healthIndex: number) => {
    id: string;
    afterDraw: (chart: Chart) => void;
  }[];
  getOptions: (healthIndex: number) => OptionsType;
};
