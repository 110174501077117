import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button`
  ${button};

  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  div {
    ${button};
  }

  svg {
    width: 20px;
    height: 20px;
  }

  svg > path {
    fill: ${colors.white};
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleBeautifulBlack};
    }
  }
`;
