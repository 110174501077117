import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  endOfDay,
  format,
  getDaysInMonth,
  getMonth,
  parse,
  startOfDay,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import { arrMonths, arrYears } from "../constants/dates";
import { getInclinedWord } from "./getInclinedWord";
import { DateInterval } from "./useCalculateSickLeaves";

// ------------------------------ НАЧАЛО И КОНЕЦ ТЕКУЩЕГО ДНЯ

export const today = startOfDay(new Date());
export const endOfToday = endOfDay(new Date());

// ------------------------------ ПРЕОБРАЗОВАНИЕ ОБЪЕКТА ДАТЫ В СТРОКУ ДАТЫ

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ДАТЫ В ОБЪЕКТ ДАТЫ

export const parseDate = (dateString: string) => {
  const date = dateString.includes("T") ? dateString.split("T")[0] : dateString;
  const formatedDate = parse(date, date.includes("-") ? "yyyy-MM-dd" : "dd.MM.yyyy", today);
  return date ? formatedDate : today;
};

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ДАТЫ В ФОРМАТИРОВАННУЮ СТРОКУ ДАТЫ

export const formatParseDate = (dateString: string) => formatDate(parseDate(dateString));

// ------------------------------ ПРЕОБРАЗОВАНИЕ ОБЪЕКТА ДАТЫ В ФОРМАТИРОВАННУЮ ДЛЯ БЭКЕНДА СТРОКУ ДАТЫ

export const formatDateForBackend = (date: Date) => format(date, "yyyy-MM-dd");

export const minusOneDay = (date: Date) => subDays(date, 1);
export const plusOneDay = (date: Date) => addDays(date, 1);

export const minusOneMonths = (date: Date) => subMonths(date, 1);
export const plusOneMonths = (date: Date) => addMonths(date, 1);

export const minusOneQuarter = (date: Date) => subQuarters(date, 1);
export const plusOneQuarter = (date: Date) => addQuarters(date, 1);

export const minusOneYear = (date: Date) => subYears(date, 1);
export const plusOneYear = (date: Date) => addYears(date, 1);

// ------------------------------ ВЫЧИСЛЕНИЕ РАЗНИЦЫ В МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ

const differenceInYears = (dateString: Date) => differenceInCalendarYears(today, dateString);

const differenceInMonths = (dateString: Date) => differenceInCalendarMonths(today, dateString) % 12;

const textYears = (dateString: Date) => getInclinedWord(differenceInYears(dateString), arrYears);
const textMonths = (dateString: Date) => getInclinedWord(differenceInMonths(dateString), arrMonths);

export const differenceDate = (dateString: string) =>
  differenceInYears(parseDate(dateString)) === 0
    ? `${differenceInMonths(parseDate(dateString))} ${textMonths(parseDate(dateString))}`
    : `${differenceInYears(parseDate(dateString))} ${textYears(parseDate(dateString))}`;

// ------------------------------ ОГРАНИЧЕНИЕ ИНТЕРВАЛА МЕСЯЦОМ
export const clampInterval = (interval: DateInterval, month: number) => {
  const resultInterval = { start: interval.start, end: interval.end };

  if (getMonth(resultInterval.start) != month)
    resultInterval.start = new Date(resultInterval.start.getFullYear(), month, 1);
  if (getMonth(resultInterval.end) != month)
    resultInterval.end = new Date(
      resultInterval.end.getFullYear(),
      month,
      getDaysInMonth(new Date(interval.start).setMonth(month))
    );

  return resultInterval;
};

// ------------------------------ ПОЛУЧЕНИЕ СТРОКИ

export const getPeriodString = (
  since?: Date | string | null,
  until?: Date | string | null,
  withText?: boolean
) => {
  const sinceDate =
    since && (typeof since === "string" ? formatParseDate(since) : formatDate(since));
  const untilDate =
    until && (typeof until === "string" ? formatParseDate(until) : formatDate(until));

  const sinceString = since ? `${withText ? "c " : ""}${sinceDate}` : "";
  const untilString = until ? `${withText ? " по " : " — "}${untilDate}` : "";

  return `${sinceString}${untilString}`;
};
