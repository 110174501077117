import React, { FC } from "react";
import { Cross } from "../../Icons";
import { Container } from "./CloseButton.styles";

const CloseButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <Cross />
    </Container>
  );
};

export default CloseButton;

type PropsType = {
  onClick: () => void;
};
