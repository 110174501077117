import React, { MouseEvent, ReactNode, useState } from "react";
import { ErrorText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Skeleton from "../../../components/Skeleton";
import { unknownErrorText } from "../../../utils/getErrorMessage";
import { differenceDate, formatParseDate } from "../../../utils/workingWithDates";
import { usePage } from "./usePage";

export const usePanel = () => {
  // ------------------------------ ХУКИ

  const { employee, employeeData } = usePage();

  // ------------------------------ ДАННЫЕ - РАБОТНИК

  const diseased = !!employee?.employeeStatus && employee?.employeeStatus === "SICK";
  const dead = !!employee?.employeeStatus && employee?.employeeStatus === "DEAD";

  const employeeDataForPanel = () => {
    if (!employee) return [];

    const { birthDate, personnelNumber, startPositionDate, position, address, since } = employee;

    return [
      {
        title: "Дата рождения",
        description: birthDate
          ? `${formatParseDate(birthDate)} (${differenceDate(birthDate)})`
          : "—",
        needHint: true,
      },
      {
        title: "Табельный номер",
        description: personnelNumber ?? "—",
      },
      {
        title: "Должность",
        description: position?.name ?? "—",
        needHint: true,
      },
      {
        title: "Подразделение",
        description: position?.department.mainDepartmentName ?? "—",
        needHint: true,
      },
      {
        title: "Внутр-ее подразделение",
        description: position?.department.name ?? "—",
        needHint: true,
      },
      {
        title: "Стаж",
        description: startPositionDate
          ? differenceDate(startPositionDate)
          : since
          ? differenceDate(since)
          : "—",
      },
      {
        title: "Адрес регистрации",
        description: address ?? "—",
        needHint: true,
      },
      {
        title: "Номер телефона",
        description:
          employee?.contacts?.find(({ type, isMain }) => type === "PHONE_NUMBER" && isMain)
            ?.value ?? "—",
      },
      {
        title: "E-mail",
        description:
          employee?.contacts?.find(({ type, isMain }) => type === "EMAIL" && isMain)?.value ?? "—",
      },
    ];
  };

  const dataWrapper = (component: ReactNode) =>
    employeeData.state === "loading" ? (
      <Skeleton forElement="panel" />
    ) : employeeData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : (
      component
    );

  // ------------------------------ ДАННЫЕ - ПОДСКАЗКИ К ДЛИННЫМ НАИМЕНОВАНИЯМ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) =>
    setAnchorPopoverHoverElement(currentTarget);

  const closePopoverHover = () => setAnchorPopoverHoverElement(null);

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;

  return {
    employee,
    employeeDataForPanel,
    dataWrapper,

    diseased,
    dead,

    openPopoverHover,
    closePopoverHover,
    anchorPopoverHoverElement,
    popoverHoverIsOpen,
  };
};
