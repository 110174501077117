import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors, overflowTwoLines, shadow } from "../../styles/styles";

export const CardsWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: overlay;
  border-radius: 16px 16px 0 0;
  padding-bottom: 96px;

  &::-webkit-scrollbar {
    background: ${colors.white};
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.grayscaleLableOpacity77};
    border-radius: 99em;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;

export const CardsContainer = styled.div<{ forExport?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 16px;
  margin: ${({ forExport }) => (forExport ? "0 40px" : "0")};
`;

export const CardContainer = styled(Link)<{ needhover: number }>`
  height: 232px;
  display: grid;
  grid-template-columns: calc(100% - 236px) 218px;
  gap: 16px;
  background: ${colors.white};
  border-radius: 16px;
  padding: 16px;

  ${({ needhover }) =>
    needhover
      ? `:hover { box-shadow: ${shadow}; cursor: pointer; }`
      : `:hover { cursor: default; }`}
`;

export const Data = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NameDepartment = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 800;
  color: ${colors.grayscaleBeautifulBlack};
  ${overflowTwoLines}
`;

export const Title = styled.p`
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  color: ${colors.grayscaleLable};
  margin: 8px 0 0 0;
`;

export const AverageAges = styled.div`
  display: flex;
  gap: 8px;
  margin: 4px 0 0 0;
`;

export const TextAndExplanation = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
`;

export const Text = styled.p`
  color: ${colors.black};
`;

export const TextExplanation = styled.p`
  color: ${colors.grayscaleLable};
`;

export const DiagramContainer = styled.div`
  width: 200px;
  height: 200px;
  background: radial-gradient(
    ${colors.transparent},
    ${colors.transparent} 48%,
    ${colors.grayscaleInput} 48%,
    ${colors.grayscaleInput} 100%
  );
  border-radius: 99em;
  margin: 0 18px 0 0;
`;
