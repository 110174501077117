import styled from "@emotion/styled";
import { Drawer } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled(Drawer)<{ size: string; needblur: number }>`
  .MuiModal-root {
    position: relative;
  }

  .MuiBackdrop-root {
    ${({ needblur }) =>
      needblur
        ? "background: rgba(224, 242, 255, 0.6); backdrop-filter: blur(10px); opacity: 0.6"
        : "background: none"}
  }

  .MuiPaper-root {
    width: ${({ size }) => (size === "small" ? "500px" : "600px")};
    box-shadow: ${({ needblur }) =>
      needblur
        ? "none"
        : "0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08)"};
    margin: 0;
    padding: 0;

    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${colors.grayscaleLableOpacity77};
      border-radius: 99em;
    }
  }
`;

export const Head = styled.div`
  margin: 64px 64px 40px 64px;
`;

export const TitleAndCloseButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.grayscaleBeautifulBlack};
  margin: 0;
`;

export const Title = styled.h4`
  font-size: 24px;
  line-height: 34px;
  font-weight: 800;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.grayscaleLable};
  margin: 8px 0 0 0;
`;

export const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
