import styled from "@emotion/styled";
import {
  button,
  buttonDisabled,
  buttonOnlyText,
  buttonPrimaryDefault,
  buttonSecondaryDefault,
  buttonTextDefault,
} from "../../../styles/buttonsStyles";

export const VisuallyHiddenInput = styled.input`
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

export const Button = styled.label<PropsType>`
  ${button};
  ${({ typebutton, enabled }) =>
    enabled
      ? typebutton === "primary"
        ? buttonPrimaryDefault
        : typebutton === "text"
        ? buttonTextDefault
        : buttonSecondaryDefault
      : buttonDisabled};
  ${buttonOnlyText};
`;

type PropsType = {
  typebutton: "primary" | "secondary" | "text";
  enabled: boolean;
};
