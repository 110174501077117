import { getDaysInMonth, getYear, setMonth } from "date-fns";
import { useAtom } from "jotai";
import React, { FC } from "react";
import { today } from "../../../utils/workingWithDates";
import { HealthDays, Month, SickDays, Text } from "../ChartsForEmployeesPage.styles";
import { currentDate } from "../utils/atoms";

const EmployeeMorbidityBar: FC<PropsType> = (props) => {
  const { monthNumber, getSickDaysOfMonth } = props;

  const [date] = useAtom(currentDate);

  const monthIndex = monthNumber - 1;
  const sickDaysOfMonth = getSickDaysOfMonth(monthIndex);
  const daysOfMonth = getDaysInMonth(setMonth(date, monthIndex));
  const sickDaysPercent = sickDaysOfMonth ? (sickDaysOfMonth / daysOfMonth) * 100 : 0;

  const resultBar =
    sickDaysOfMonth || getYear(date) !== getYear(today) ? (
      <>
        {sickDaysPercent < 100 && <HealthDays height={100 - sickDaysPercent} />}

        <SickDays height={sickDaysPercent} />
      </>
    ) : (
      <HealthDays height={sickDaysOfMonth === 0 ? 100 : 0} />
    );

  return (
    <>
      <Month>{resultBar}</Month>
      <Text>{monthNumber}</Text>
    </>
  );
};

export default EmployeeMorbidityBar;

type PropsType = {
  monthNumber: number;
  getSickDaysOfMonth: (monthNumber: number) => number | null;
};
