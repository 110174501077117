import React from "react";
import { colors } from "../../styles/styles";

const Book = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H12C13.1046 2 14 2.89543 14 4V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V4C2 2.89543 2.89543 2 4 2ZM0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V14C16 16.2091 14.2091 18 12 18H4C1.79086 18 0 16.2091 0 14V4ZM4 7C4 6.44772 4.44772 6 5 6H11C11.5523 6 12 6.44772 12 7C12 7.5523 11.5523 8 11 8H5C4.44772 8 4 7.5523 4 7ZM4 11C4 10.4477 4.44772 10 5 10H9C9.5523 10 10 10.4477 10 11C10 11.5523 9.5523 12 9 12H5C4.44772 12 4 11.5523 4 11Z"
        fill={colors.grayscalePlaceholder}
      />
    </svg>
  );
};

export default Book;
