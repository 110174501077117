import { useAtom } from "jotai";
import React, { FC, MouseEvent } from "react";
import { UserType } from "../../../atoms/atomCurrentUser";
import { menuIsExpandState } from "../../../atoms/atoms";
import SectionButtonWithSubMenu from "./SectionButtonWithSubMenu";
import SectionButtonWithoutSubMenu from "./SectionButtonWithoutSubMenu";
import { sections } from "./sections";

const SectionsButtons: FC<PropsType> = (props) => {
  const { currentUser, subMenuIsOpen, setSubMenuIsOpen } = props;
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = props;

  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const canViewSectionsButtonStats = currentUser.roleModel.canViewSectionsButtonStats;
  const canViewDictionaries = currentUser.roleModel.canViewDictionaries;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  return (
    <>
      {sections(canViewSectionsButtonStats, canViewDictionaries).map((section, i) => {
        return section.name === "dictionaries" ? (
          <SectionButtonWithSubMenu
            key={i}
            roleModel={currentUser.roleModel}
            section={section}
            menuIsExpand={menuIsExpand}
            setMenuIsExpand={setMenuIsExpand}
            subMenuIsOpen={subMenuIsOpen}
            setSubMenuIsOpen={setSubMenuIsOpen}
            popoverHoverIsOpen={popoverHoverIsOpen}
            openPopoverHover={openPopoverHover}
            closePopoverHover={closePopoverHover}
          />
        ) : (
          <SectionButtonWithoutSubMenu
            key={i}
            section={section}
            menuIsExpand={menuIsExpand}
            setSubMenuIsOpen={setSubMenuIsOpen}
            popoverHoverIsOpen={popoverHoverIsOpen}
            openPopoverHover={openPopoverHover}
            closePopoverHover={closePopoverHover}
          />
        );
      })}
    </>
  );
};

export default SectionsButtons;

type PropsType = {
  currentUser: UserType;
  subMenuIsOpen: string;
  setSubMenuIsOpen: (evt: string) => void;
  popoverHoverIsOpen: boolean;
  openPopoverHover: (evt: MouseEvent<HTMLElement>, text: string) => void;
  closePopoverHover: () => void;
};
