import html2canvas from "html2canvas";
import { useAtom } from "jotai";
import jsPDF from "jspdf";
import React, { useState } from "react";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { UniversalTextButton } from "../../../components/Buttons";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import { Title } from "../ChartsForOrganizationsPage.styles";
import * as atoms from "../utils/atoms";
import { filtersState } from "../utils/atoms";

export const usePage = () => {
  // ------------------------------ АТОМЫ

  const { currentDateInterval, exportElementAtom, statsForOrganizationState } = atoms;

  const [statsForOrganizationsData] = useAtom(statsForOrganizationState);

  const [currentUser] = useAtom(currentUserState);
  const [filters] = useAtom(filtersState);
  const [dateInterval, setDateInterval] = useAtom(currentDateInterval);
  const [exportElement, setExportElement] = useAtom(exportElementAtom);

  // ------------------------------ СТЕЙТЫ

  const [filtersIsOpen, setFiltersIsOpen] = useState(false);

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Статистика заболеваемости";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const userOrganizationCode = hasUser && currentUser.data.res.organizationCode;
  const oot = hasUser && currentUser.data.roleModel.oot;
  const osr = hasUser && currentUser.data.roleModel.osr;
  const canViewStatsForOrganizations =
    hasUser && currentUser.data.roleModel.canViewStatsForOrganizations;
  const canViewUploadEmployeesPage =
    hasUser &&
    currentUser.data.roleModel.canViewUploadEmployees &&
    currentUser.data.res.organizationCode === "Y000";

  // ------------------------------ ДАННЫЕ - СТАТИСТИКА

  const statsForOrganizations =
    statsForOrganizationsData.state === "hasData" ? statsForOrganizationsData.data : [];

  // ------------------------------ ЭКСПОРТ

  const exportChartsToPDF = () => {
    if (!exportElement) return;

    html2canvas(exportElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 2.0);
      const pdf = new jsPDF({ orientation: "landscape" });
      pdf.addImage(imgData, "PNG", 0, 0, 297, 220);
      pdf.save("График заболеваемости.pdf");
    });
  };

  // ------------------------------ ШАПКА СТРАНИЦЫ - НАВИГАЦИЯ

  const handleOpenFilters = (arg: boolean) => setFiltersIsOpen(arg);
  const handleCloseFilters = () => setFiltersIsOpen(false);

  const hasActiveFilters = JSON.stringify(filters) !== JSON.stringify(atoms.absoluteInitialValues);

  const navigation = (
    <HeaderNavigation
      title={{
        text: titlePage,
        element: <Title>{titlePage}</Title>,
      }}
      buttonsStart={
        <UniversalTextButton
          text="Скачать график"
          onClick={exportChartsToPDF}
          enabled={
            statsForOrganizations &&
            !statsForOrganizations.every((datum) =>
              datum.data.every((item) => item.morbidity === 0)
            )
          }
        />
      }
      needFilterButton={{ handleOpenFilters, hasActiveFilters }}
      needDateSwitcher={{
        typeInterval: "twoDate",
        dateInterval: dateInterval,
        setDateInterval: setDateInterval,
        needPeriodSwitcher: true,
      }}
    />
  );

  return {
    statsForOrganizations,
    statsForOrganizationsData,

    titlePage,

    userOrganizationCode,
    oot,
    osr,
    canViewStatsForOrganizations,
    canViewUploadEmployeesPage,

    setExportElement,
    exportChartsToPDF,

    filtersIsOpen,
    handleCloseFilters,
    navigation,
  };
};
