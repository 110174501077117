import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled(Checkbox)<{ checked: boolean }>`
  &:hover {
    background: ${({ checked }) =>
      checked ? colors.primaryBackground : colors.grayscaleHoverBackground};

    & > svg > path {
      fill: ${({ checked }) =>
        checked ? colors.primaryDefault : colors.grayscaleLable};
    }
  }
`;
