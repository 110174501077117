import styled from "@emotion/styled";
import { colors } from "../../../styles/styles";

export const FiltersButtonContainer = styled.div<{ hasActiveFilters: boolean }>`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    top: 4px;
    right: 4px;
    background: ${({ hasActiveFilters }) =>
      hasActiveFilters ? colors.primaryDefault : colors.transparent};
    border-radius: 99em;
  }
`;
