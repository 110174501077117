import { AxiosResponse } from "axios";
import { useAtom } from "jotai";
import { queryClientAtom } from "jotai/query";
import { useMutation } from "react-query";
import { IdType } from "../api/types";
import { statusCreateOrUpdate, statusDeletion } from "../atoms/atoms";
import { timerForQueries } from "./timerForQueries";

// ------------------------------ СОЗДАНИЕ ДАННЫХ

export const useQuery = () => {
  const [queryClient] = useAtom(queryClientAtom);

  const [_, setStatusCreateOrUpdate] = useAtom(statusCreateOrUpdate);
  const [__, setStatusDeletion] = useAtom(statusDeletion);

  return {
    create: <CPROP>(props: CreatePropsType<CPROP>) => {
      const { queryKey, api } = props;

      return useMutation(
        async (props: CPROP) => api.create(props as CPROP),

        {
          onMutate: async () => setStatusCreateOrUpdate(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);

            setStatusCreateOrUpdate(false);
          },
        }
      );
    },

    update: <UPROP>(props: UpdatePropsType<UPROP>) => {
      const { queryKey, api } = props;

      return useMutation(
        async (props: UPROP) => api.update(props as UPROP),

        {
          onMutate: async () => setStatusCreateOrUpdate(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);

            setStatusCreateOrUpdate(false);
          },
        }
      );
    },

    delete: (props: DeletePropsType) => {
      const { queryKey, api } = props;

      return useMutation(
        async (ids: string[]) => {
          const promises = ids.map(async (id) => api.delete(id));

          return Promise.all(promises);
        },

        {
          onMutate: async () => setStatusDeletion(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);

            setStatusDeletion(false);
          },
        }
      );
    },

    deleteArr: (props: DeleteArrPropsType) => {
      const { queryKey, api } = props;

      return useMutation(async (ids: string[]) => api.deleteArr(ids), {
        onMutate: async () => setStatusDeletion(true),
        onSettled: async () => {
          await timerForQueries();

          queryClient.invalidateQueries(queryKey);

          setStatusDeletion(false);
        },
      });
    },
  };
};

export type CreatePropsType<CPROP> = {
  api: { create: (props: CPROP) => Promise<AxiosResponse<any>> };
  queryKey: QueryKeyType;
};

export type UpdatePropsType<UPROP> = {
  api: { update: (props: UPROP) => Promise<AxiosResponse<any>> };
  queryKey: QueryKeyType;
};

export type DeletePropsType = {
  api: { delete: (id: string) => Promise<void> };
  queryKey: QueryKeyType;
};

type DeleteArrPropsType = {
  api: { deleteArr: (ids: string[]) => Promise<void> };
  queryKey: (string | number | undefined)[];
};

export type ApiType<CPROP, UPROP> = {
  create: (props: CPROP) => Promise<AxiosResponse<IdType>>;
  update: (props: UPROP) => Promise<AxiosResponse<any>>;
  delete: (id: string) => Promise<void>;
  deleteArr: (ids: string[]) => Promise<void>;
};

export type QueryKeyType = (string | number | undefined)[];
