import styled from "@emotion/styled";
import { colors } from "../../../styles/styles";

export const Container = styled.div<ContainerProps>`
  min-width: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns: ${({ grid }) => grid};
  background: ${({ needBackground }) => (needBackground ? colors.lightBlue : colors.transparent)};
  border-bottom: 1px solid ${colors.grayscaleInput};
`;

export const TableBodyItem = styled.div`
  min-width: 0;
  border-right: 1px solid ${colors.grayscaleInput};
  word-wrap: break-word;
  overflow: hidden;
  padding: 14px 20px;

  &.check {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 4px 0 0 0;

    .MuiCheckbox-root {
      width: 40px;
      height: 40px;
    }
  }

  &:last-child {
    border: none;
  }
`;

export const TableBodyItemText = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.grayscaleBody};

  span {
    background: ${colors.warningLight};
  }
`;

export const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

type ContainerProps = {
  grid: string;
  needBackground: boolean;
};
