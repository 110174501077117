import styled from "@emotion/styled";
import {
  button,
  buttonDisabled,
  buttonOnlyText,
  buttonPrimaryDefault,
  buttonSecondaryDefault,
} from "../../../styles/buttonsStyles";

export const Container = styled.button<PropsType>`
  ${button};
  ${({ text, active }) =>
    active
      ? text === "Сохранить" || text === "Добавить" || text === "Применить"|| text === "Скачать"
        ? buttonPrimaryDefault
        : buttonSecondaryDefault
      : buttonDisabled};
  ${({ needIcon }) => (needIcon ? null : buttonOnlyText)};

  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
`;

type PropsType = {
  text: string;
  needIcon: boolean;
  active: boolean;
};
