import { countriesApi } from "../../../../api/apiDictionaries";
import { PaginationType } from "../../../../api/types";

// ------------------------------ ДАННЫЕ

export const fetchCountries = async (props: PaginationType & { query?: string }) =>
  (await countriesApi.getAll(props)).data.items;

// ------------------------------ ДАННЫЕ - ВСЕ

export const fetchAllCountries = async () => {
  const totalCount = (await countriesApi.getAll({ startIndex: 0, size: 1 })).data.totalCount;

  return (await countriesApi.getAll({ startIndex: 0, size: totalCount })).data.items;
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const fetchTotalCountCountries = async (props: PaginationType & { query?: string }) =>
  (await countriesApi.getAll(props)).data.totalCount;
