import { useAtom } from "jotai";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { STATISTICS, STATISTICS_CHARTS_FOR_DEPARTMENTS } from "../../../api/routes";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { organizationState } from "../../../atoms/atomsOrg";
import { NavigateButton, UniversalTextButton } from "../../../components/Buttons";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import HeaderSearchInput from "../../../components/Header/HeaderSearchInput";
import { getNames } from "../../../utils/getNames";
import {
  absoluteInitialValues,
  currentDate,
  employeesState,
  filtersState,
  pageState,
  quantityInOnePageState,
  totalCountEmployeesState,
} from "../utils/atoms";

export const usePage = () => {
  // ------------------------------ АТОМЫ

  const [employeesData] = useAtom(employeesState);
  const [totalCount] = useAtom(totalCountEmployeesState);

  const [currentUser] = useAtom(currentUserState);
  const [filters] = useAtom(filtersState);
  const [organization] = useAtom(organizationState);
  const [date, setDate] = useAtom(currentDate);
  const [__, setPage] = useAtom(pageState);
  const [quantity, setQuantity] = useAtom(quantityInOnePageState);

  // ------------------------------ СТЕЙТЫ

  const [visibleSearch, setVisibleSearch] = useState(false);
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [exportFiltersIsOpen, setExportFiltersIsOpen] = useState(false);

  // ------------------------------ ХУКИ

  const { id } = useParams();

  const { departmentName } = getNames();

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const mainDepartmentCode = hasUser ? currentUser.data.res.mainDepartmentCode : undefined;
  const canViewStatsForEmployees = hasUser && currentUser.data.roleModel.canViewStatsForEmployees;

  // ------------------------------ ДАННЫЕ

  const employees = employeesData.state === "hasData" ? employeesData.data : [];

  // ------------------------------ ДАННЫЕ - ПАГИНАЦИЯ

  const needPagination =
    totalCount.state === "hasData" && totalCount.data && totalCount.data > 1
      ? {
          totalCount: totalCount.data,
          setPage: setPage,
          quantity: quantity,
          setQuantity: setQuantity,
        }
      : undefined;

  // ------------------------------ ШАПКА СТРАНИЦЫ - НАВИГАЦИЯ

  const handleOpenFilters = (arg: boolean) => setFiltersIsOpen(arg);
  const handleCloseFilters = () => setFiltersIsOpen(false);

  const hasActiveFilters = JSON.stringify(filters) !== JSON.stringify(absoluteInitialValues);

  const handleOpenExportFilters = () => setExportFiltersIsOpen(true);
  const handleCloseExportFilters = () => setExportFiltersIsOpen(false);

  const navigation = (
    <HeaderNavigation
      title={{
        text: departmentName(id) ?? "",
        element: (
          <NavigateButton
            title="Статистика заболеваемости"
            link={mainDepartmentCode ? "" : STATISTICS_CHARTS_FOR_DEPARTMENTS(organization)}
          />
        ),
      }}
      buttonsStart={
        <UniversalTextButton text="Выгрузить статистику" onClick={handleOpenExportFilters} />
      }
      needSearchButton={{ visibleSearch, setVisibleSearch }}
      section={{ name: "Статистика заболеваемости", rout: STATISTICS }}
      needFilterButton={{ handleOpenFilters, hasActiveFilters }}
      needDateSwitcher={{
        typeInterval: "oneDate",
        date: date,
        setDate: setDate,
      }}
    />
  );

  // ------------------------------ ШАПКА СТРАНИЦЫ - ПОИСК

  const searchInput = visibleSearch ? <HeaderSearchInput /> : null;

  return {
    employees,
    employeesData,
    needPagination,
    departmentId: id && id !== "" ? id : mainDepartmentCode,

    canViewStatsForEmployees,

    filtersIsOpen,
    handleCloseFilters,
    exportFiltersIsOpen,
    handleCloseExportFilters,
    navigation,
    searchInput,
  };
};
