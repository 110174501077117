import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { EntitieType, LittleOtherType } from "../../../../api/apiDictionaries";
import { selectedRowsState } from "../../../../atoms/atoms";
import { InfoText } from "../../../../components/ErrorBoundary/ErrorBoundary.styles";
import { Table, TableBodyRow, TableHeadRow } from "../../../../components/Table";
import { useUserSettings } from "../../../../utils/useUserSettings";
import DiseaseSeverityEditForm from "../Forms/DiseaseSeverityEditForm";
import { useDiseaseSeverityDictionary } from "../useDiseaseSeverityDictionary";

const DiseaseSeverityTable: FC = () => {
  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const [modalData, setModalData] = useState<EntitieType<LittleOtherType>>();

  const diseaseSeverityDictionaryMethods = useDiseaseSeverityDictionary();
  const { canEditDictionaries, diseaseSeverity } = diseaseSeverityDictionaryMethods;
  const { diseaseSeverityIds, handleDeleteRows } = diseaseSeverityDictionaryMethods;
  const { titles, titlesInit, grids, dataForTable } = diseaseSeverityDictionaryMethods;
  const { getUserSettings, setUserSettings } = useUserSettings();

  useEffect(() => {
    getUserSettings("diseaseSeverity", titlesInit);

    return () => setSelectedRows([]);
  }, []);

  const handleCloseEditForm = () => setModalData(undefined);

  return (
    <>
      <Table titles={titlesInit}>
        <TableHeadRow
          titles={titles}
          titlesInit={titlesInit}
          grid={grids}
          ids={diseaseSeverityIds}
          needCheck={canEditDictionaries}
          needMenu={canEditDictionaries}
          setUserSettings={(selectedColumnNumbers) =>
            setUserSettings("diseaseSeverity", titlesInit, selectedColumnNumbers)
          }
        />

        {diseaseSeverity.length === 0 ? (
          <InfoText>Нет данных</InfoText>
        ) : (
          diseaseSeverity.map((datum) => {
            const { id } = datum;

            return (
              <TableBodyRow
                key={id}
                id={id}
                data={dataForTable(datum)}
                grid={grids}
                onOpenEditForm={(evt) => {
                  evt.stopPropagation();
                  setModalData(datum);
                }}
                textConfirmModal={datum.name}
                onDeleteRows={handleDeleteRows}
                needMenu={canEditDictionaries}
                needCheck={canEditDictionaries}
              />
            );
          })
        )}
      </Table>

      {canEditDictionaries && modalData ? (
        <DiseaseSeverityEditForm data={modalData} onClose={handleCloseEditForm} />
      ) : null}
    </>
  );
};

export default DiseaseSeverityTable;
