import React, { FC, ReactNode } from "react";
import { STATISTICS_CHARTS_FOR_EMPLOYEES } from "../../../api/routes";
import { mainDepartmentState } from "../../../atoms/atomsOrg";
import PopoverHover from "../../../components/PopoverHover";
import * as elements from "../ChartsForDepartmentsPage.styles";
import { useCard } from "../hooks/useCard";
import { usePage } from "../hooks/usePage";
import {useUpdateAtom} from "jotai/utils";

const DepartmentCard: FC<PropsType> = (props) => {
  const { children, datum, forExport = false } = props;

  const { mainDepartmentCode, mainDepartmentName, mainDepartmentFullName } = datum;
  const { employeesCount, sickEmployeesCount } = datum;
  const { averageAge, averageMenAge, averageWomenAge } = datum;

  const { AverageAges, CardContainer, Data, NameDepartment } = elements;
  const { Text, TextAndExplanation, TextExplanation, Title } = elements;

  const setMainDepartment = useUpdateAtom(mainDepartmentState);

  const { closePopoverHover, openPopoverHover, anchorPopoverHoverElement } = useCard();
  const { director } = usePage();

  return (
    <CardContainer
      to={forExport || director ? "" : STATISTICS_CHARTS_FOR_EMPLOYEES(mainDepartmentCode, 0)}
      onClick={() => (forExport ? undefined : setMainDepartment(mainDepartmentCode ?? ""))}
      needhover={+!(forExport || director)}
    >
      <>
        <Data>
          {forExport ? (
            <NameDepartment>{mainDepartmentName ?? "----"}</NameDepartment>
          ) : (
            <NameDepartment
              onMouseEnter={({ currentTarget }) => openPopoverHover(currentTarget)}
              onMouseLeave={closePopoverHover}
            >
              {mainDepartmentName}
            </NameDepartment>
          )}

          <div>
            <Title>Кол-во работников</Title>
            <TextAndExplanation>
              <Text>{sickEmployeesCount}/</Text>
              <TextExplanation>{employeesCount}</TextExplanation>
            </TextAndExplanation>

            <Title>Средний возраст</Title>
            <AverageAges>
              <TextAndExplanation>
                <Text>{averageAge}/</Text>
                <TextExplanation>Все</TextExplanation>
              </TextAndExplanation>
              <TextAndExplanation>
                <Text>{averageMenAge}/</Text>
                <TextExplanation>М</TextExplanation>
              </TextAndExplanation>
              <TextAndExplanation>
                <Text>{averageWomenAge}/</Text>
                <TextExplanation>Ж</TextExplanation>
              </TextAndExplanation>
            </AverageAges>
          </div>
        </Data>

        {children}
      </>

      <PopoverHover
        id={!!anchorPopoverHoverElement ? "popover-hover" : undefined}
        isOpen={!!anchorPopoverHoverElement}
        element={anchorPopoverHoverElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {mainDepartmentFullName}
      </PopoverHover>
    </CardContainer>
  );
};

export default DepartmentCard;

type PropsType = {
  children: ReactNode;
  datum: {
    mainDepartmentCode: string;
    mainDepartmentName?: string;
    mainDepartmentFullName?: string;
    averageAge?: number;
    averageWomenAge?: number;
    averageMenAge?: number;
    employeesCount?: number;
    sickEmployeesCount?: number;
  };
  forExport?: boolean;
};
