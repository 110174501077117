import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { EntitieType, LittleOtherType } from "../../../../api/apiDictionaries";
import { selectedRowsState } from "../../../../atoms/atoms";
import { Table, TableBodyRow, TableHeadRow } from "../../../../components/Table";
import { useUserSettings } from "../../../../utils/useUserSettings";
import CountryEditForm from "../Forms/CountryEditForm";
import { useCountryDictionary } from "../useCountryDictionary";

const CountriesTable: FC = () => {
  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const [modalData, setModalData] = useState<EntitieType<LittleOtherType>>();

  const countryDictionaryMethods = useCountryDictionary();
  const { canEditDictionaries } = countryDictionaryMethods;
  const { countries, countryIds, handleDeleteRows } = countryDictionaryMethods;
  const { titles, titlesInit, grids, dataForTable, dataWrapper } = countryDictionaryMethods;
  const { getUserSettings, setUserSettings } = useUserSettings();

  useEffect(() => {
    getUserSettings("country", titlesInit);

    return () => setSelectedRows([]);
  }, []);

  const handleCloseEditForm = () => setModalData(undefined);

  return (
    <>
      <Table titles={titlesInit} needPagination>
        <TableHeadRow
          titles={titles}
          titlesInit={titlesInit}
          grid={grids}
          ids={countryIds}
          needCheck={canEditDictionaries}
          needMenu={canEditDictionaries}
          setUserSettings={(selectedColumnNumbers) =>
            setUserSettings("country", titlesInit, selectedColumnNumbers)
          }
        />

        {dataWrapper(
          countries.map((datum) => {
            const { id } = datum;

            return (
              <TableBodyRow
                key={id}
                id={id}
                data={dataForTable(datum)}
                grid={grids}
                onOpenEditForm={(evt) => {
                  evt.stopPropagation();
                  setModalData(datum);
                }}
                textConfirmModal={datum.name}
                onDeleteRows={handleDeleteRows}
                needMenu={canEditDictionaries}
                needCheck={canEditDictionaries}
              />
            );
          })
        )}
      </Table>

      {canEditDictionaries && modalData ? (
        <CountryEditForm data={modalData} onClose={handleCloseEditForm} />
      ) : null}
    </>
  );
};

export default CountriesTable;
