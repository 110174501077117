import Chart from "chart.js/auto";
import React, { FC, useEffect, useRef, useState } from "react";
import { Container } from "../../../components/ContainerForExportCharts/HeaderForExportCharts.styles";
import { colors } from "../../../styles/styles";
import { useCharts } from "../hooks/useCharts";
import { chartYZoom, currentDateInterval } from "../utils/atoms";
import { useAtom } from "jotai";

const OrganizationCharts: FC = () => {
  const [parentHeight, setParentHeight] = useState(0);

  const { statsForOrganizations, dataWrapper, getDatasets, getOptions, getLabels } = useCharts();

  const ref = useRef<HTMLCanvasElement>(null);
  const [currentDate] = useAtom(currentDateInterval);
  const [chartZoom, setChartZoom] = useAtom(chartYZoom);

  useEffect(() => {
    const updateParentHeight = () => {
      const height = document.querySelector(".main")?.clientHeight;
      setParentHeight(height ? height - 121 - 16 * 5 : 0);
      // (121px) - высота панели организаций
      // (16px * 5) - 1 марджин панели, 2 паддинга контейнера, 2 паддинга компоненты Main
    };

    window.addEventListener("resize", updateParentHeight);
    updateParentHeight();

    return () => window.removeEventListener("resize", updateParentHeight);
  }, []);

  useEffect(() => {
    setChartZoom(undefined);
  }, [currentDate]);

  useEffect(() => {
    const { labels } = getLabels();

    const canvasElement = ref.current!;

    if (!canvasElement) return;

    const datasets = getDatasets();
    const options = getOptions(canvasElement);

    const myChart = new Chart(canvasElement, {
      type: "line",
      options: options,
      data: { labels, datasets },
    });

    if (chartZoom) myChart.zoomScale("y", chartZoom);
    else myChart.resetZoom();

    const clearHighlights = () => {
      const minMaxY = myChart.scales.y.getMinMax(true);
      if (!(minMaxY.min === 0 && minMaxY.max === 0)) setChartZoom(myChart.scales.y.getMinMax(true));

      myChart.data.datasets.forEach((dataset) => (dataset.backgroundColor = colors.transparent));
      myChart.update();
    };

    const handleLeave = () => {
      clearHighlights();
      setTimeout(clearHighlights, 100);
    };

    if (canvasElement) canvasElement?.addEventListener("mouseleave", handleLeave);
    document?.addEventListener("mouseleave", handleLeave);

    return () => {
      canvasElement.removeEventListener("mouseleave", handleLeave);
      document.removeEventListener("mouseleave", handleLeave);
      myChart.destroy();
    };
  }, [statsForOrganizations]);

  return (
    <>
      {dataWrapper(
        <Container mainHeight={parentHeight}>
          <canvas ref={ref} />
        </Container>
      )}
    </>
  );
};

export default OrganizationCharts;
