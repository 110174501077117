import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { searchQueryState } from "../../../../atoms/atoms";
import {
  fetchAllDiseasesClasses,
  fetchDiseasesClasses,
  fetchTotalCountDiseasesClasses,
} from "./fetchData";

// ------------------------------ НОМЕР СТРАНИЦЫ

export const pageState = atom(0);

// ------------------------------ КОЛИЧЕСТВО СТРОК НА ОДНОЙ СТРАНИЦЕ

export const quantityInOnePageState = atom(10);

// ------------------------------ ДАННЫЕ

export const diseasesClassesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  return diseasesClassesQuery({ page, quantity, queryState });
});

export const diseasesClassesState = loadable(diseasesClassesAtom);

export const diseasesClassesQuery = (props: {
  page: number;
  quantity: number;
  queryState?: string;
}) => {
  const { page, quantity, queryState } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return {
    queryKey: [page * quantity, quantity, query || undefined, "diseasesClassesState"],
    queryFn: () => fetchDiseasesClasses(args),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const totalCountDiseasesClassesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  return totalCountDiseasesClassesQuery({ page, quantity, queryState });
});

export const totalCountDiseasesClassesState = loadable(totalCountDiseasesClassesAtom);

export const totalCountDiseasesClassesQuery = (props: {
  page: number;
  quantity: number;
  queryState?: string;
}) => {
  const { page, quantity, queryState } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return {
    queryKey: [page * quantity, quantity, query || undefined, "totalCountDiseasesClassesState"],
    queryFn: () => fetchTotalCountDiseasesClasses(args),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const diseasesClassesAllState = atomWithQuery(() => ({
  queryKey: ["diseasesClassesAllState"],
  queryFn: () => fetchAllDiseasesClasses(),
}));
