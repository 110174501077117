import React, { FC } from "react";
import PopoverHover from "../../../components/PopoverHover";
import * as elements from "../EmployeeCardPage.styles";
import { usePanel } from "../hooks/usePanel";

const EmployeeCardItem: FC<PropsType> = (props) => {
  const { i, datum } = props;
  const { title, description, needHint = false } = datum;

  const panelMethods = usePanel();
  const { openPopoverHover, closePopoverHover } = panelMethods;
  const { anchorPopoverHoverElement, popoverHoverIsOpen } = panelMethods;

  const { DataItem, Title, Description } = elements;

  return (
    <DataItem key={i} hasMenuButton={false}>
      <Title>{title}</Title>
      <Description
        aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
        aria-haspopup="true"
        onMouseEnter={openPopoverHover}
        onMouseLeave={closePopoverHover}
      >
        {description}
      </Description>

      {needHint ? (
        <PopoverHover
          id={`popover-hover-${i}`}
          isOpen={popoverHoverIsOpen}
          element={anchorPopoverHoverElement}
          onClose={closePopoverHover}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: 10, horizontal: "center" }}
        >
          {description}
        </PopoverHover>
      ) : null}
    </DataItem>
  );
};

export default EmployeeCardItem;

type PropsType = {
  i: number;
  datum: {
    title: string;
    description: string;
    needHint?: boolean;
  };
};
