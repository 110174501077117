import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { searchQueryState } from "../../../../atoms/atoms";
import { fetchAllCountries, fetchCountries, fetchTotalCountCountries } from "./fetchData";

// ------------------------------ НОМЕР СТРАНИЦЫ

export const pageState = atom(0);

// ------------------------------ КОЛИЧЕСТВО СТРОК НА ОДНОЙ СТРАНИЦЕ

export const quantityInOnePageState = atom(10);

// ------------------------------ ДАННЫЕ

export const countriesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  return countriesQuery({ page, quantity, queryState });
});

export const countriesState = loadable(countriesAtom);

export const countriesQuery = (props: PropsType) => {
  const { page, quantity, queryState } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return {
    queryKey: [page * quantity, quantity, query || undefined, "countriesState"],
    queryFn: () => fetchCountries(args),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const totalCountCountriesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  return totalCountCountriesQuery({ page, quantity, queryState });
});

export const totalCountCountriesState = loadable(totalCountCountriesAtom);

export const totalCountCountriesQuery = (props: PropsType) => {
  const { page, quantity, queryState } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return {
    queryKey: [page * quantity, quantity, query || undefined, "totalCountCountriesState"],
    queryFn: () => fetchTotalCountCountries(args),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const countriesAllState = atomWithQuery(() => ({
  queryKey: ["totalCountCountriesAllState"],
  queryFn: () => fetchAllCountries(),
}));

type PropsType = { page: number; quantity: number; queryState?: string };
