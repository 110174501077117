import { AxiosResponse } from "axios";
import { mrApi } from "./api";
import { FormatDateType } from "./types";

// ------------------------------ ГРАФИКИ

export const statistics = {
  getOrganizationStat: async (
    props: OrganizationPropsType
  ): Promise<AxiosResponse<OrganizationStatsType>> => {
    return mrApi.get("/general-stat/organization-stat", { params: props });
  },
  getDepartmentsStat: async (
    props: DepartmentPropsType
  ): Promise<AxiosResponse<DepartmentsStatsType>> => {
    return mrApi.get("/general-stat/departments-stat", { params: props });
  },
};

// ------------------------------ ТИПЫ - СТАТИСТИКА ПО ОРГАНИЗАЦИЯМ

// передаваемые значения

export type OrganizationPropsType = {
  organizationCode: string;
  startDate: FormatDateType;
  endDate: FormatDateType;
  disabilityCode?: string;
  diseasesClass?: string;
};

// получаемые значения

export type OrganizationStatsType = Record<FormatDateType, number>;

// ------------------------------ ТИПЫ - СТАТИСТИКА ПО ПОДРАЗДЕЛЕНИЯМ

// передаваемые значения

export type DepartmentPropsType = {
  organizationCode: string;
  startDate: string;
  endDate: string;
  disabilityId?: string;
  diseaseClassId?: string;
  page?: number;
  quantity?: number;
};

// получаемые значения

export type DepartmentsStatsType = {
  items: DepartmentsStatType[];
  totalCount: number;
};

export type DepartmentsStatType = {
  averageAge: number;
  averageMenAge: number;
  averageWomenAge: number;
  employeesCount: number;
  healthIndex: number;
  mainDepartmentCode: string;
  sickEmployeesCount: number;
};
