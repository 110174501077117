import * as muiElements from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC, Fragment, HTMLAttributes, SyntheticEvent } from "react";
import { OptionType, OptionsType } from "../../utils/getOptions";
import { TriangleDown } from "../Icons";
import * as elements from "./SelectAutocompleteInput.styles";

const SelectAutocompleteInput: FC<PropsType> = (props) => {
  const { name, options, label, placeholder, size = "", disabled, filter = false } = props;

  const { Container, Item, MenuItemWrapper, PopperComponent, PaperProps } = elements;

  const { MenuItem, TextField } = muiElements;

  const { values, handleBlur, setFieldValue } = useFormikContext<Record<string, string>>();

  const selectedOption = options.find(({ id }) => id === values[name]);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      name={name}
      {...params}
      label={label}
      placeholder={placeholder}
      onBlur={handleBlur}
      InputLabelProps={{ shrink: true }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) =>
    option.id === "" ? (
      <Fragment key="" />
    ) : (
      <MenuItem {...props} key={option.id}>
        <MenuItemWrapper>{option.name}</MenuItemWrapper>
      </MenuItem>
    );

  const handleChange = (
    _evt: SyntheticEvent<Element, Event>,
    arg: NonNullable<string | OptionType> | (string | OptionType)[] | null
  ) => {
    if (!arg || typeof arg === "string" || Array.isArray(arg)) return;

    const value = arg.id;
    setFieldValue(name, value);
  };

  return (
    <Container>
      <Item
        value={selectedOption || { id: "", name: "" }}
        options={options}
        getOptionLabel={(option) => option?.name}
        onChange={handleChange}
        renderInput={renderInput}
        renderOption={renderOption}
        popupIcon={<TriangleDown />}
        PopperComponent={PopperComponent}
        componentsProps={{ paper: PaperProps(size) }}
        noOptionsText="Нет совпадений"
        onBlur={handleBlur}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        haslabel={+!!label}
        second={+filter}
        disabled={disabled}
      />
    </Container>
  );
};

export default SelectAutocompleteInput;

type PropsType = {
  name: string;
  options: OptionsType;
  label?: string;
  placeholder: string;
  size?: "small" | "default";
  disabled?: boolean;
  filter?: boolean;
};
