import styled from "@emotion/styled";
import { colors } from "../../../styles/styles";

export const Container = styled.div`
  display: flex;
  margin: 16px 24px 12px 24px;
`;

export const SearchInputLabel = styled.label<{ loupePosition: string }>`
  width: 100%;
  height: 40px;
  display: block;
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: -10px;
  }
`;

export const SearchInputElement = styled.input`
  width: 100%;
  height: 40px;
  background: ${colors.transparent};
  border: none;
  border-bottom: 1px solid ${colors.grayscaleLine};
  outline: none;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: ${colors.grayscaleBeautifulBlack};
  padding: 0 0 0 32px;

  &::placeholder {
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    color: ${colors.grayscalePlaceholder};
  }
`;
