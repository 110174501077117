import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import React, { FC } from "react";
import { useQuery } from "react-query";
import { DiseaseType, EmployeeType, diseasesApi, employeesApi } from "../../../api/apiEmployees";
import { file as fileApi } from "../../../api/apiFile";
import Loader from "../../../components/Loader";
import RightModal from "../../../components/RightModal";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { useQuery as newUseQuery } from "../../../utils/useQuery";
import { FiledsType, useForms } from "../hooks/useForms";
import { usePage } from "../hooks/usePage";
import { usePageTable } from "../hooks/useTable";
import DiseaseFields from "./DiseaseFields";

const DiseaseEditForm: FC<FormPropsType> = (props) => {
  const { document } = props.data;

  const { getFileName, getFileBlob } = usePageTable();
  const fileName = getFileName(document);

  const queryData = document
    ? useQuery([document, fileName], async () => {
        const documentBlob = await getFileBlob(document!);
        const file = new File([await documentBlob], fileName!);
        return file;
      }).data
    : undefined;

  const file = document && queryData ? queryData : undefined;

  return !document ? (
    <DiseaseEditFormLayout {...props} />
  ) : file ? (
    <DiseaseEditFormLayout {...props} file={file} />
  ) : (
    <Loader />
  );
};

const DiseaseEditFormLayout: FC<FormLayoutPropsType> = (props) => {
  const { data, employee, onClose, file } = props;

  const { editFormName, editFormTitle } = usePage();
  const formsMethods = useForms(employee);
  const { diseaseValuesObject, employeeValuesObject } = formsMethods;
  const { initialValuesEditForm, validationSchema } = formsMethods;
  const pageTableMethods = usePageTable();
  const { queryKey } = pageTableMethods;

  const createDisease = newUseQuery().create({
    queryKey,
    api: diseasesApi(),
  });

  const createEmployee = newUseQuery().create({
    queryKey,
    api: employeesApi(),
  });

  const error = getErrorMessage(createDisease.error);
  const isLoading = createDisease.isLoading || createEmployee.isLoading;

  return (
    <RightModal title={editFormTitle} isOpen onClose={onClose} needBlur>
      <Formik
        initialValues={initialValuesEditForm(data, file)}
        validate={(values) => {
          try {
            validateYupSchema(values, validationSchema(values, data.id), true);
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={async (values: FiledsType) => {
          try {
            const { deathStatus, patientStatus, personnelCategory, nationality, deathDate } =
              initialValuesEditForm(data, file);

            if (
              deathStatus !== values.deathStatus ||
              patientStatus !== values.patientStatus ||
              personnelCategory !== values.personnelCategory ||
              nationality !== values.nationality ||
              deathDate !== values.deathDate
            ) {
              const employeeObject = employeeValuesObject(values);

              await createEmployee.mutateAsync({ data: employeeObject });
            }

            const formData = new FormData();
            formData.append("file", values.document!);

            let fileRef = "";

            if (values.document) {
              const formData = new FormData();
              formData.append("file", values.document!);
              fileRef = (await fileApi.upload(formData)).data.fileRef;
            }

            const diseaseValues = diseaseValuesObject({ ...values, document: fileRef }, data);

            if (!diseaseValues) return;

            await createDisease.mutateAsync({ data: diseaseValues });

            onClose();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ handleSubmit }) => (
          <DiseaseFields
            formName={editFormName}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            error={error}
            onClose={onClose}
            isEdit
          />
        )}
      </Formik>
    </RightModal>
  );
};

export default DiseaseEditForm;

type FormPropsType = {
  data: DiseaseType;
  employee: EmployeeType;
  onClose: () => void;
};

type FormLayoutPropsType = FormPropsType & { file?: File };
