import produce from "immer";
import { useAtom } from "jotai";
import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  STATISTICS_CHARTS_FOR_DEPARTMENTS,
  STATISTICS_UPLOAD_EMPLOYEES,
} from "../../../api/routes";
import { organizationsState } from "../../../atoms/atomsOrg";
import { ErrorText, InfoText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Skeleton from "../../../components/Skeleton";
import { colors } from "../../../styles/styles";
import { unknownErrorText } from "../../../utils/getErrorMessage";
import { selectedOrganizationsAtom } from "../utils/atoms";
import useColor from "./useColor";

export const usePanel = () => {
  // ------------------------------ АТОМЫ

  const [organizationsData] = useAtom(organizationsState);

  const [selectedOrganizations, setSelectedOrganizations] = useAtom(selectedOrganizationsAtom);

  // ------------------------------ СТЕЙТЫ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLDivElement | null>(
    null
  );

  // ------------------------------ ХУКИ

  const navigate = useNavigate();
  const { getColorsForChart } = useColor();

  // ------------------------------ ДАННЫЕ - СПИСОК ОРГАНИЗАЦИЙ

  const organizations = organizationsData.state === "hasData" ? organizationsData.data : null;

  const dataWrapper = (component: ReactNode) =>
    organizationsData.state === "loading" ? (
      <Skeleton forElement="panel" />
    ) : organizationsData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : !organizations ? (
      <InfoText>Нет данных</InfoText>
    ) : organizations.length === 0 ? (
      <InfoText>Ни одной организации не найдено</InfoText>
    ) : (
      component
    );

  // ------------------------------ ВЫБОР ОРГАНИЗАЦИЙ

  const indexSelectedOrganizations = (organization: string) =>
    selectedOrganizations.findIndex(
      (selectedOrganization) => selectedOrganization === organization
    );

  const selectedAllOrganizations = organizations?.every(
    ({ id }, index) => id === selectedOrganizations[index]
  );

  const organizationSelection = (id: string) => {
    const newSelectedOrganizations = produce(selectedOrganizations, (draft) => {
      indexSelectedOrganizations(id) === -1
        ? draft.push(id)
        : draft.splice(indexSelectedOrganizations(id), 1);
    });

    selectedOrganizations.find((datum) => datum === id)
      ? setSelectedOrganizations(newSelectedOrganizations)
      : setSelectedOrganizations([...selectedOrganizations, id]);
  };

  const oneOrganizationSelection = (id: string) => setSelectedOrganizations([id]);

  const allOrganizationSelection = () =>
    setSelectedOrganizations(
      !selectedAllOrganizations && organizations ? organizations.map(({ id }) => id) : []
    );

  const selected = (id: string, allOrganizations: boolean) =>
    (allOrganizations ? selectedAllOrganizations : indexSelectedOrganizations(id) !== -1) ?? false;

  const showOrHideText = (id: string, allOrganizations: boolean) =>
    selected(id, allOrganizations) ? "Скрыть на графике" : "Отразить на графике";

  const borderColorOrganizationButton = (index: number | null, allOrganizations: boolean) =>
    typeof index === "number" && !allOrganizations ? getColorsForChart(index).lines : colors.black;

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ - ПОДСКАЗКА

  const openPopoverHover = (element: HTMLDivElement) => setAnchorPopoverHoverElement(element);
  const closePopoverHover = () => setAnchorPopoverHoverElement(null);

  // ------------------------------ ФУНКЦИИ ОБРАБОТКИ СОБЫТИЙ КЛИКОВ ПО МЕНЮ ОРГАНИЗАЦИЙ

  const onClickDepartmentsButton = (id: string) => navigate(STATISTICS_CHARTS_FOR_DEPARTMENTS(id));

  const onClickViewButton = (id: string) => organizationSelection(id);

  const onClickExcludeButton = (id: string) => oneOrganizationSelection(id);

  const onClickUploadButton = () => navigate(STATISTICS_UPLOAD_EMPLOYEES);

  return {
    organizations,
    organizationsData,
    dataWrapper,

    selected,
    showOrHideText,
    borderColorOrganizationButton,
    indexSelectedOrganizations,
    organizationSelection,
    oneOrganizationSelection,
    allOrganizationSelection,

    anchorPopoverHoverElement,
    openPopoverHover,
    closePopoverHover,

    onClickDepartmentsButton,
    onClickViewButton,
    onClickExcludeButton,
    onClickUploadButton,
  };
};
