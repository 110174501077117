import { useFormikContext } from "formik";
import React, { FC } from "react";
import { Container, Switcher } from "./SwitchInput.styles";

const SwitchInput: FC<PropsType> = (props) => {
  const { name, label = "" } = props;

  const { values, handleChange } = useFormikContext<Record<string, boolean>>();

  return (
    <Container
      control={<Switcher name={name} onChange={handleChange} checked={values[name]} />}
      label={label}
    />
  );
};

export default SwitchInput;

type PropsType = {
  name: string;
  label?: string;
};
