import saveAs from "file-saver";
import { useAtom } from "jotai";
import React, { ReactNode } from "react";
import { DiseaseType, diseasesApi } from "../../../api/apiEmployees";
import { file } from "../../../api/apiFile";
import { searchQueryState, selectedRowsState } from "../../../atoms/atoms";
import { ErrorText, InfoText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { useTable } from "../../../components/Table/useTable";
import { unknownErrorText } from "../../../utils/getErrorMessage";
import { useQuery } from "../../../utils/useQuery";
import { getPeriodString } from "../../../utils/workingWithDates";
import { File as FileElement, FileName } from "../EmployeeCardPage.styles";
import { usePage } from "./usePage";

export const usePageTable = () => {
  // ------------------------------ АТОМЫ

  const [selectedRows] = useAtom(selectedRowsState);
  const [query] = useAtom(searchQueryState);

  // ------------------------------ ХУКИ

  const { employee, employeeData, canEditEmployeeCard } = usePage();

  // ------------------------------ КЛЮЧИ ЗАПРОСА

  const queryKey = [employee?.id, "employeeState"];

  // ------------------------------ ДАННЫЕ - ЗАБОЛЕВАНИЯ

  let diseases = employee?.diseases;

  const dataWrapper = (component: ReactNode | undefined) =>
    employeeData.state === "loading" ? (
      <Skeleton forElement="table" grids={grids} canEdit={canEditEmployeeCard} />
    ) : employeeData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : !diseases || (diseases?.length === 0 && query === "") ? (
      <InfoText>Этот работник ещё не болел</InfoText>
    ) : diseases?.length === 0 && query !== "" ? (
      <InfoText>Ничего не найдено</InfoText>
    ) : (
      component
    );

  // ------------------------------ ДАННЫЕ - ЗАБОЛЕВАНИЯ - IDS

  const diseaseIds = diseases?.map((datum) => datum.id);

  // ------------------------------ ДАННЫЕ - ФИЛЬТРАЦИЯ

  if (query) {
    diseases = diseases?.filter((datum) => {
      const datumForSearch = {
        disabilityCode: datum.disability.name,
        treatmentPlace: datum.treatmentPlace.name,
        comment: datum.briefAnamnesis,
      };

      return Object.values(datumForSearch).join().toLowerCase().includes(query.toLowerCase());
    });
  }

  // ------------------------------ ДАННЫЕ - УДАЛЕНИЕ

  const deletion = useQuery().deleteArr({ queryKey, api: diseasesApi() });

  const handleDeleteRows = async () => await deletion.mutateAsync(selectedRows);

  // ------------------------------ ТАБЛИЦА

  const { title, grid, formatData, columnsNames } = useTable();

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "Период заболевания", name: "period" },
    { id: 2, title: "Код нетрудоспособности", name: "disabilityCode" },
    { id: 3, title: "Место прохождения лечения", name: "treatmentPlace" },
    { id: 4, title: "Комментарий", name: "comment" },
    { id: 5, title: "Документы", name: "document" },
  ];

  const titles = title(titlesInit);

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridInit = [
    ...(canEditEmployeeCard ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "1fr" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    { titleId: 5, size: "1fr" },
    ...(canEditEmployeeCard ? [{ titleId: null, size: "56px" }] : []),
  ];

  const grids = grid(gridInit);

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  // ------------------------------ скачивание документа

  const handleDownloadRow = async (file: string) => {
    const blob = getFileBlob(file);
    const fileName = file ? decodeURIComponent(file).split("=")[1] : "";

    saveAs(await blob, fileName);
  };

  // ------------------------------ формирование данных

  const object = (disease: DiseaseType) => {
    const { disability, treatmentPlace, document } = disease;
    const { briefAnamnesis, openSickListDate, closeSickListDate } = disease;

    const getDocument = (file: string | undefined) => {
      return file ? (
        <FileElement>
          <FileName onClick={async () => handleDownloadRow(file)}>
            {decodeURIComponent(file).split("=")[1]}
          </FileName>
        </FileElement>
      ) : undefined;
    };

    return {
      period: getPeriodString(openSickListDate, closeSickListDate),
      disabilityCode: disability.name,
      treatmentPlace: treatmentPlace.name,
      comment: briefAnamnesis ? (briefAnamnesis === "" ? "—" : briefAnamnesis) : "—",
      document: document && getDocument(document),
    };
  };

  const dataForTable = (datum: DiseaseType) =>
    formatData(object(datum) ?? {}, columnsNames(titlesInit));

  // ------------------------------ ВСПОМОГАТЕЛЬНЫЕ ФУНКЦИИ

  const getFileBlob = async (link: string) => {
    const res = await file.download(encodeURIComponent(encodeURIComponent(link!)));

    return new Blob([res.data], { type: res.headers["content-type"] });
  };

  const getFileName = (fileRef: string | undefined) =>
    fileRef ? decodeURIComponent(fileRef).split("=")[1] : "";

  return {
    diseases,
    dataWrapper,
    diseaseIds,
    handleDeleteRows,

    queryKey,

    titles,
    titlesInit,
    grids,
    dataForTable,

    getFileBlob,
    getFileName,
  };
};
