import { atom } from "jotai";
import { ReactNode } from "react";

// ------------------------------ ОТВЕТ ПРИ ЗАГРУЗКЕ СПИСКА

export const absoluteInitialValuesResult: UploadResponseType[] = [];

export type UploadResponseType = {
  id: string;
  name: string | ReactNode;
  type: string;
  operation: string;
};

export const uploadResultState = atom(absoluteInitialValuesResult);
