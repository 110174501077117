import React, { FC } from "react";
import { colors } from "../../styles/styles";

const ThreeDots: FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 24C21.1 24 22 24.9 22 26C22 27.1 21.1 28 20 28C18.9 28 18 27.1 18 26C18 24.9 18.9 24 20 24ZM20 22C18.9 22 18 21.1 18 20C18 18.9 18.9 18 20 18C21.1 18 22 18.9 22 20C22 21.1 21.1 22 20 22ZM20 16C18.9 16 18 15.1 18 14C18 12.9 18.9 12 20 12C21.1 12 22 12.9 22 14C22 15.1 21.1 16 20 16Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default ThreeDots;
