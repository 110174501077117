import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<{ disabledButton?: boolean }>`
  ${button};

  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  opacity: ${({ disabledButton }) => (disabledButton ? "0.2" : "1")};
  cursor: ${({ disabledButton }) => (disabledButton ? "default" : "pointer")};

  :hover {
    background: ${({ disabledButton }) =>
      disabledButton ? colors.transparent : colors.grayscaleHoverBackground};
  }
`;
