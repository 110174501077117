import { useAtom } from "jotai";
import React, { FC, Suspense, useEffect, useState } from "react";
import { DICTIONARY } from "../../../api/routes";
import { selectedRowsState } from "../../../atoms/atoms";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { ErrorText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../../components/Header";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import HeaderSearchInput from "../../../components/Header/HeaderSearchInput";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination";
import { Main, PageContainer } from "../../Page.styles";
import CityAddForm from "./Forms/CityAddForm";
import CitiesTable from "./Table/CitiesTable";
import { useCityDictionary } from "./useCityDictionary";

const CityDictionaryPage: FC = () => {
  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const [visibleSearch, setVisibleSearch] = useState(false);
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);

  const cityDictionaryMethods = useCityDictionary();
  const { titlePage, handleDeleteRows } = cityDictionaryMethods;
  const { canViewDictionaries, canEditDictionaries, needPagination } = cityDictionaryMethods;

  useEffect(() => setSelectedRows([]), []);

  const handleCloseAddForm = () => setAddFormIsOpen(false);

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needAddButton={
        canEditDictionaries ? { text: "Добавить", onClick: setAddFormIsOpen } : undefined
      }
      needDeleteButton={
        canEditDictionaries ? { text: "Удалить", onClick: handleDeleteRows } : undefined
      }
      needSearchButton={{ visibleSearch, setVisibleSearch }}
      section={{ name: "Справочники", rout: DICTIONARY }}
    />
  );

  const searchInput = visibleSearch ? <HeaderSearchInput /> : null;

  return canViewDictionaries ? (
    <PageContainer>
      <Header navigation={navigation} searchInput={searchInput} />

      <Main needPagination>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <CitiesTable />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {needPagination && <Pagination needPagination={needPagination} />}

      {addFormIsOpen && (
        <Suspense fallback={<Loader />}>
          <CityAddForm onClose={handleCloseAddForm} />
        </Suspense>
      )}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default CityDictionaryPage;
