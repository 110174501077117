import React, { FC } from "react";
import { Container, Item, Overlay } from "./Loader.styles";

const Loader: FC<PropsType> = (props) => {
  const { over = false } = props;

  return (
    <Overlay over={over}>
      <Container>
        <Item />
      </Container>
    </Overlay>
  );
};

export default Loader;

type PropsType = {
  over?: boolean;
};
