import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<GearButtonProps>`
  ${button};

  width: ${({ needMenu }) => (needMenu ? "40px" : "28px")};
  height: ${({ needMenu }) => (needMenu ? "40px" : "28px")};
  position: absolute;
  top: ${({ needMenu }) => (needMenu ? "10px" : 0)};
  right: ${({ needMenu }) => (needMenu ? "7px" : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  div {
    ${button};
  }

  svg {
    width: ${({ menuModalIsOpen, needMenu }) =>
      needMenu ? (menuModalIsOpen ? "20px" : "24px") : menuModalIsOpen ? "16px" : "20px"};
    height: ${({ menuModalIsOpen, needMenu }) =>
      needMenu ? (menuModalIsOpen ? "20px" : "24px") : menuModalIsOpen ? "16px" : "20px"};
  }

  svg > path {
    fill: ${colors.white};
  }

  :hover {
    background: ${({ needSelectingColumns }) =>
      needSelectingColumns ? colors.grayscaleHoverBackground : colors.transparent};

    svg > path {
      fill: ${({ needSelectingColumns }) =>
        needSelectingColumns ? colors.grayscaleBeautifulBlack : colors.white};
    }
  }
`;

type GearButtonProps = {
  menuModalIsOpen: boolean;
  needMenu: boolean;
  needSelectingColumns: boolean;
};
