import React, { ReactNode } from "react";
import { DICTIONARY, STATISTICS } from "../../../api/routes";
import { Folder, Statistics } from "../../Icons";

export const sections = (canViewStats: boolean, canViewDictionaries: boolean) => [
  ...(canViewStats
    ? [
        {
          name: "statistics",
          text: "Статистика заболеваемости",
          rout: STATISTICS,
          icon: <Statistics />,
        },
      ]
    : []),
  ...(canViewDictionaries
    ? [
        {
          name: "dictionaries",
          text: "Справочники",
          rout: DICTIONARY,
          icon: <Folder />,
        },
      ]
    : []),
];

export type SectionType = {
  name: string;
  text: string;
  rout: string;
  icon: ReactNode;
};
