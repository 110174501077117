import React, { FC } from "react";
import { colors } from "../../styles/styles";

const TriangleUp: FC = () => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.19772 7.85627C10.0608 7.84989 10.2753 7.76481 9.59868 6.63051C9.58197 6.63051 6.06513 1.23742 6.06479 1.24569C5.18735 -0.413842 4.81727 -0.413853 3.90608 1.23741C3.88937 1.23741 0.357153 6.63051 0.357153 6.63051C-0.322218 7.77685 0.0231854 7.85167 0.924828 7.85647L9.19772 7.85627Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default TriangleUp;
