import React, { FC, ReactNode } from "react";
import { AppContainer } from "./styles/App.styles";
import LeftMenu from "./components/LeftMenu";
import Loader from "./components/Loader";
import { useCachingKeycloak } from "./utils/useCachingKeycloak";

const App: FC<PropsType> = (props) => {
  const { children } = props;
  const { authenticated } = useCachingKeycloak();

  return authenticated ? (
    <AppContainer className="App">
      <LeftMenu />
      {children}
    </AppContainer>
  ) : (
    <Loader />
  );
};

export default App;

type PropsType = {
  children: ReactNode;
};
