import React, { FC } from "react";
import { colors } from "../../styles/styles";

const CrossOnCircle: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10ZM10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5C15.2467 19.5 19.5 15.2467 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5ZM6.46492 13.5355C6.0744 13.145 6.0744 12.5118 6.46492 12.1213L8.5862 10.0001L6.46477 7.87867C6.07424 7.48815 6.07424 6.85499 6.46477 6.46446C6.85529 6.07394 7.48846 6.07394 7.87898 6.46446L10.0004 8.5859L12.1218 6.46447C12.5123 6.07395 13.1455 6.07395 13.536 6.46447C13.9265 6.85499 13.9265 7.48816 13.536 7.87868L11.4146 10.0001L13.5358 12.1213C13.9264 12.5118 13.9264 13.145 13.5358 13.5355C13.1453 13.9261 12.5121 13.9261 12.1216 13.5355L10.0004 11.4143L7.87913 13.5355C7.48861 13.9261 6.85544 13.9261 6.46492 13.5355Z"
        fill={colors.grayscaleLable}
      />
    </svg>
  );
};

export default CrossOnCircle;
