import { AxiosResponse } from "axios";
import { meApi, mrApi } from "./api";
import { RolesType } from "./types";
import { localPagination } from "../utils/localPagination";

// ------------------------------ ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

export const user = {
  get: async (): Promise<AxiosResponse<CurrentUserType>> => {
    return meApi.get(`/users/me`);
  },
  update: async (props: CurrentUserSettingsCreateType) => {
    return meApi.put(`/users/me/settings`, props);
  },
};

// ------------------------------ СПИСОК ОРГАНИЗАЦИЙ

export const organizations = {
  get: async (): Promise<AxiosResponse<OrganizationsType>> => {
    return mrApi.get(`/organizations`);
  },
};

// ------------------------------ СПИСОК ПОДРАЗДЕЛЕНИЙ

export const departments = {
  get: async (props: DepartmentsFiltersType): Promise<AxiosResponse<DepartmentsType>> => {
    return meApi.get(`/departments`, {
      params: props,
    });
  },
  getPagination: async (props: DepartmentsFiltersType): Promise<AxiosResponse<DepartmentsType>> => {
    return localPagination<DepartmentType>(
      "departments",
      meApi.get("/departments", { params: props }),
      props,
      (data) => ({
        items: data.data.items,
        totalCount: data.data.totalCount,
      })
    );
  },
};

// ------------------------------ СПИСОК ДОЛЖНОСТЕЙ

export const positions = {
  get: async (props: PositionsFiltersType): Promise<AxiosResponse<PositionsType>> => {
    return meApi.get(`/positions`, {
      params: props,
    });
  },
};

// ------------------------------ ТИПЫ - ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

// передаваемые значения

export type CurrentUserSettingsCreateType = {
  settings: string;
};

// получаемые значения

export type CurrentUserType = {
  firstName: string;
  middleName: string;
  lastName: string;
  organizationCode: string;
  mainDepartmentCode: string;
  roles: RolesType;
  imageInBase64: string;
  settings: string;
};

// ------------------------------ ТИПЫ - СПИСОК ОРГАНИЗАЦИЙ

// получаемые значения

export type OrganizationsType = {
  items: OrganizationType[];
  totalCount: number;
};

// ------------------------------ ТИПЫ - СПИСОК ПОДРАЗДЕЛЕНИЙ

// передаваемые значения

export type DepartmentsFiltersType = {
  organizationCode?: string;
  isMainDepartment?: boolean;
  mainDepartmentCode?: string;
  page?: number;
  quantity?: number;
};

// получаемые значения

export type DepartmentsType = { items: DepartmentType[]; totalCount: number };

export type DepartmentType = {
  id: string;
  code: string;
  mainDepartment: string;
  mainDepartmentName: string;
  name: string;
  shortName: string;
  type: string;
  organization: OrganizationType;
  parent: string;
  location: LocationType;
};

type LocationType = {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  flat: string;
  name: string;
};

type OrganizationType = {
  code: string;
  name: string;
};

// ------------------------------ ТИПЫ - СПИСОК ДОЛЖНОСТЕЙ

// передаваемые значения

export type PositionsFiltersType = {
  organizationCode?: string;
  departmentCode?: string;
};

// получаемые значения

export type PositionsType = { items: PositionType[]; totalCount: number };

type PositionType = {
  id: string;
  code: string;
  name: string;
  department: DepartmentsType;
};
