import React, { FC } from "react";
import { colors } from "../../styles/styles";

const ListPlus: FC = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C12 4.44772 11.5442 4 10.9975 4H1.00247C0.448823 4 0 4.44386 0 5C0 5.55228 0.455761 6 1.00247 6H10.9975C11.5512 6 12 5.55614 12 5ZM12 1C12 0.447715 11.5442 0 10.9975 0H1.00247C0.448823 0 0 0.443865 0 1C0 1.55228 0.455761 2 1.00247 2H10.9975C11.5512 2 12 1.55614 12 1ZM16 8V5.00937C16 4.45191 15.5561 4 15 4C14.4477 4 14 4.44335 14 5.00937V8H11.0094C10.4519 8 10 8.44386 10 9C10 9.55228 10.4434 10 11.0094 10H14V12.9906C14 13.5481 14.4439 14 15 14C15.5523 14 16 13.5566 16 12.9906V10H18.9906C19.5481 10 20 9.55614 20 9C20 8.44772 19.5566 8 18.9906 8H16ZM0 9C0 9.55228 0.453036 10 0.99703 10H7.00297C7.55361 10 8 9.55614 8 9C8 8.44772 7.54696 8 7.00297 8H0.99703C0.446386 8 0 8.44386 0 9Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default ListPlus;
