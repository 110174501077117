import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { BigOtherType, EntitieType } from "../../../../api/apiDictionaries";
import { selectedRowsState } from "../../../../atoms/atoms";
import { Table, TableBodyRow, TableHeadRow } from "../../../../components/Table";
import { useUserSettings } from "../../../../utils/useUserSettings";
import DiseasesClassEditForm from "../Forms/DiseasesClassEditForm";
import { useDiseasesClassDictionary } from "../useDiseasesClassDictionary";

const DiseasesClassesTable: FC = () => {
  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const [modalData, setModalData] = useState<EntitieType<BigOtherType>>();

  const diseasesClassDictionaryMethods = useDiseasesClassDictionary();
  const { canEditDictionaries } = diseasesClassDictionaryMethods;
  const { diseasesClasses, diseasesClassIds, handleDeleteRows } = diseasesClassDictionaryMethods;
  const { titles, titlesInit, grids, dataForTable, dataWrapper } = diseasesClassDictionaryMethods;
  const { getUserSettings, setUserSettings } = useUserSettings();

  useEffect(() => {
    getUserSettings("diseasesClass", titlesInit);

    return () => setSelectedRows([]);
  }, []);

  const handleCloseEditForm = () => setModalData(undefined);

  return (
    <>
      <Table titles={titlesInit} needPagination>
        <TableHeadRow
          titles={titles}
          titlesInit={titlesInit}
          grid={grids}
          ids={diseasesClassIds}
          needCheck={canEditDictionaries}
          needMenu={canEditDictionaries}
          setUserSettings={(selectedColumnNumbers) =>
            setUserSettings("diseasesClass", titlesInit, selectedColumnNumbers)
          }
        />

        {dataWrapper(
          diseasesClasses.map((datum) => {
            const { id } = datum;

            return (
              <TableBodyRow
                key={id}
                id={id}
                data={dataForTable(datum)}
                grid={grids}
                onOpenEditForm={(evt) => {
                  evt.stopPropagation();
                  setModalData(datum);
                }}
                textConfirmModal={datum.name}
                onDeleteRows={handleDeleteRows}
                needMenu={canEditDictionaries}
                needCheck={canEditDictionaries}
              />
            );
          })
        )}
      </Table>

      {canEditDictionaries && modalData ? (
        <DiseasesClassEditForm data={modalData} onClose={handleCloseEditForm} />
      ) : null}
    </>
  );
};

export default DiseasesClassesTable;
