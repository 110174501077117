import { useAtom } from "jotai";
import { queryClientAtom } from "jotai/query";
import { user } from "../api/apiOrganization";
import { TypeSettingsType, currentUserQuery, currentUserState } from "../atoms/atomCurrentUser";
import { selectedColumnsState } from "../atoms/atoms";
import { timerForQueries } from "./timerForQueries";

export const useUserSettings = () => {
  const [queryClient] = useAtom(queryClientAtom);

  const [_, setSelectedColumns] = useAtom(selectedColumnsState);

  const [currentUser] = useAtom(currentUserState);
  const hasUser = currentUser.state === "hasData";
  const userSettings = hasUser ? currentUser.data.settings : undefined;

  const getUserSettings = (
    typeSetting: TypeSettingsType,
    titlesInit: { id: number; title: string; name: string }[]
  ) => {
    if (!userSettings) return;

    const tableSettings = hasUser ? userSettings[typeSetting]?.tableSettings : undefined;

    if (tableSettings && tableSettings.length !== 0) {
      const getIdBySettingName = (settingName: string) =>
        titlesInit.find(({ name }) => name === settingName)?.id;

      const visibleIdsInSetting = tableSettings
        .filter(({ visible }) => visible)
        .map(({ name }) => getIdBySettingName(name))
        .filter((tableSetting) => tableSetting) as number[];
      const allIds = titlesInit.map(({ id }) => id);
      const idsInSetting = tableSettings.map(({ name }) => getIdBySettingName(name));
      const newIds =
        allIds.length > idsInSetting.length
          ? ([
              ...allIds.filter((id) => !idsInSetting.includes(id)),
              ...idsInSetting.filter((id) => id && !allIds.includes(id)),
            ].filter((id) => !!id) as number[])
          : undefined;

      setSelectedColumns(newIds ? [...visibleIdsInSetting, ...newIds] : visibleIdsInSetting);
    }
  };

  const setSettings = async (
    settingsStr: string,
    newSettings: Record<string, Record<string, Record<string, string | boolean>[]>>
  ) => {
    const settingsObj = settingsStr !== "" ? JSON.parse(settingsStr) : {};
    const settingsObjNew = { ...settingsObj, ...newSettings };
    const settingsStrNew = JSON.stringify(settingsObjNew);

    try {
      await user.update({ settings: settingsStrNew });
      await timerForQueries();
    } catch (err) {
      console.error(err);
    }
  };

  const setUserSettings = async (
    typeSetting: string,
    titlesInit: { id: number; title: string; name: string }[],
    selectedColumnNumbers: number[]
  ) => {
    const hasValidUserSettings =
      typeof userSettings === "string" && selectedColumnNumbers.length !== 0;

    if (!hasValidUserSettings) return;

    const columns = titlesInit
      .filter((title) => title.id !== null)
      .map((title) => ({
        name: title.name,
        visible: selectedColumnNumbers.includes(title.id),
      }));

    setSettings(userSettings, { [typeSetting]: { tableSettings: columns } });

    await timerForQueries(300);
    await queryClient.fetchQuery(currentUserQuery());
  };

  return {
    getUserSettings,
    setUserSettings,
  };
};
