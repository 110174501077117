import * as Yup from "yup";
import { notJustSpaces } from "../../../constants/forValidationSchems";
import { filtersState } from "../utils/atoms";
import {useAtomValue} from "jotai/utils";

export const useForms = () => {
  // ------------------------------ АТОМЫ

  const filters = useAtomValue(filtersState);

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters = {
    disabilityCode: filters.disabilityCode,
    diseaseClass: filters.diseaseClass,
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    disabilityCode: Yup.string().matches(/\S/, notJustSpaces),
    diseaseClass: Yup.string().matches(/\S/, notJustSpaces),
  });

  return {
    initialValuesFilters,
    validationSchema,
  };
};
