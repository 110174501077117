import React, { FC } from "react";
import { colors } from "../../styles/styles";

const ArrowRound: FC = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99999 9.09583C7.99999 9.19534 8.03708 9.29127 8.10402 9.3649C8.25262 9.52837 8.5056 9.54041 8.66906 9.39181L12.9574 5.49335C12.973 5.47912 12.988 5.46415 13.0022 5.4485C13.2499 5.17606 13.2298 4.75443 12.9574 4.50676L8.66906 0.608296C8.59543 0.541361 8.4995 0.504272 8.39999 0.504272C8.17908 0.504272 7.99999 0.683359 7.99999 0.904272L8 4.00005C3.58172 4.00005 0 7.58177 0 12.0001C0 16.4183 3.58172 20.0001 8 20.0001C12.4183 20.0001 16 16.4183 16 12.0001C16 11.4478 15.5523 11.0001 15 11.0001C14.4477 11.0001 14 11.4478 14 12.0001C14 15.3138 11.3137 18.0001 8 18.0001C4.68629 18.0001 2 15.3138 2 12.0001C2 8.68634 4.68629 6.00005 8 6.00005L7.99999 9.09583Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default ArrowRound;
