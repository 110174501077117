import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled(Popover)`
  pointer-events: none;

  .MuiPaper-root {
    max-width: 300px;
    background: ${colors.grayscaleBeautifulBlackOpacity};
    border-radius: 8px;
    box-shadow: none;
  }
` as typeof Popover;

export const Text = styled.div`
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: ${colors.white};
  margin: 4px 8px;
`;
