import styled from "@emotion/styled";
import { button, buttonTextDefault } from "../../../styles/buttonsStyles";

export const Container = styled.button`
  ${button};
  ${buttonTextDefault};

  height: fit-content;
  padding: 0;
`;
