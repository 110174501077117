import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Reduce: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00001 9.9C1.00001 9.40294 1.40295 9 1.90001 9H6.1C6.59706 9 7 9.40294 7 9.9V14.1C7 14.5971 6.59706 15 6.1 15C5.60294 15 5.2 14.5971 5.2 14.1V12.0728L1.5364 15.7364C1.18492 16.0879 0.615076 16.0879 0.263604 15.7364C-0.0878678 15.3849 -0.0878681 14.8151 0.263604 14.4636L3.92721 10.8H1.90001C1.40295 10.8 1.00001 10.3971 1.00001 9.9Z"
        fill={colors.grayscaleIcons}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7364 0.263604C16.0879 0.615076 16.0879 1.18492 15.7364 1.5364L12.0728 5.2H14.0982C14.5952 5.2 14.9982 5.60294 14.9982 6.1C14.9982 6.59706 14.5952 7 14.0982 7H9.9C9.40294 7 9 6.59706 9 6.1V1.90001C9 1.40295 9.40294 1.00001 9.9 1.00001C10.3971 1.00001 10.8 1.40295 10.8 1.90001V3.92721L14.4636 0.263604C14.8151 -0.0878681 15.3849 -0.0878678 15.7364 0.263604Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default Reduce;
