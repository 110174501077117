import { css } from "@emotion/react";
import { opacify } from "polished";
import { colors } from "./styles";

export const button = css`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.transparent};
  border: none;
  border-radius: 99em;
  outline: none;
  cursor: pointer;
  padding: 0;

  p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
`;

export const buttonOnlyText = css`
  p {
    margin: 0;
  }
`;

export const buttonTwoIcons = css`
  p {
    margin: 0 8px;
  }
`;

export const buttonLeftIcons = css`
  p {
    margin: 0 0 0 8px;
  }
`;

export const buttonRightIcons = css`
  p {
    margin: 0 8px 0 0;
  }
`;

export const buttonOnlyIcon = css`
  width: 40px;
  height: 40px;
  padding: 0;
`;

// ------------------------------ ЧЁРНЫЕ КНОПКИ

export const buttonPrimaryDefault = css`
  background: ${colors.grayscaleBeautifulBlack};
  padding: 16px;
  p {
    color: ${colors.white};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    background: ${opacify(-0.3, colors.grayscaleBeautifulBlack)};
  }
`;

export const buttonSecondaryDefault = css`
  background: ${colors.grayscaleInput};
  padding: 16px;
  p {
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
  :hover {
    background: ${opacify(-0.5, colors.grayscaleInput)};
  }
`;

export const buttonOutlinedDefault = css`
  background: ${colors.white};
  outline: 2px solid ${colors.grayscaleBeautifulBlack};
  outline-offset: -2px;
  padding: 16px;

  p {
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const buttonTextDefault = css`
  background: ${colors.white};
  padding: 16px;
  p {
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

// ------------------------------ КРАСНЫЕ КНОПКИ

export const buttonPrimaryAttantion = css`
  background: ${colors.redDefault};
  padding: 16px;
  p {
    color: ${colors.white};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonSecondaryAttantion = css`
  background: ${colors.redLight};
  padding: 16px;
  p {
    color: ${colors.redDark};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.redDark};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonOutlinedAttantion = css`
  background: ${colors.white};
  outline: 2px solid ${colors.redDefault};
  outline-offset: -2px;
  padding: 16px;

  p {
    color: ${colors.redDefault};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.redDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonTextAttantion = css`
  background: ${colors.white};
  padding: 16px;
  p {
    color: ${colors.redDefault};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.redDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

// ------------------------------ ЗЕЛЁНЫЕ КНОПКИ

export const buttonPrimaryPositive = css`
  background: ${colors.greenDefault};
  padding: 16px;
  p {
    color: ${colors.white};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonSecondaryPositive = css`
  background: ${colors.greenBackground};
  padding: 16px;
  p {
    color: ${colors.greenDark};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.greenDark};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonOutlinedPositive = css`
  background: ${colors.white};
  outline: 2px solid ${colors.greenDefault};
  outline-offset: -2px;
  padding: 16px;

  p {
    color: ${colors.greenDefault};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.greenDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonTextPositive = css`
  background: ${colors.white};
  padding: 16px;
  p {
    color: ${colors.greenDefault};
    margin: 0 0 0 8px;
  }
  path {
    fill: ${colors.greenDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

// ------------------------------ НЕАКТИВНЫЕ КНОПКИ

export const buttonDisabled = css`
  background: ${colors.grayscaleDisabled};
  padding: 16px;
  cursor: no-drop;
  p {
    color: ${colors.grayscaleLine};
    margin: 0 0 0 8px;
  }
`;

export const buttonOutlinedDisabled = css`
  background: ${colors.white};
  outline: 2px solid ${colors.grayscaleDisabled};
  outline-offset: -2px;
  padding: 16px;
  cursor: no-drop;
  p {
    color: ${colors.grayscaleLine};
    margin: 0 0 0 8px;
  }
`;

export const buttonTextDisabled = css`
  background: ${colors.white};
  padding: 16px;
  cursor: no-drop;
  p {
    color: ${colors.grayscaleLine};
    margin: 0 0 0 8px;
  }
`;
