import { InputBaseComponentProps } from "@mui/material";
import { useFormikContext } from "formik";
import React, { ChangeEvent, FC, FocusEvent, WheelEvent, useState } from "react";
import { Container, Error, Input } from "./TextInput.styles";

const TextInput: FC<PropsType> = (props) => {
  const { type, multiline = false, disabled = false, inputProps, disabledWheel = false } = props;

  const [wasInFocus, setWasInFocus] = useState(false);

  const { values, handleChange, handleBlur, errors } = useFormikContext<Record<string, string>>();

  const value = values[props.name];
  const error = errors[props.name];

  const onChange = (evt: ChangeEvent) => {
    handleChange(evt);
    setWasInFocus(!!error);
  };

  const onBlur = (evt: FocusEvent) => {
    handleBlur(evt);
    setWasInFocus(!!error);
  };

  const onWheel = (evt: WheelEvent) => {
    if (disabledWheel) (evt.target as HTMLElement).blur();
  };

  return (
    <Container disabled={disabled}>
      <Input
        inputProps={inputProps}
        name={props.name}
        label={props.label}
        haslabel={+!!props.label}
        placeholder={props.placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onWheel={onWheel}
        multiline={multiline}
        rows={multiline ? 4.9 : undefined}
        disabled={disabled}
        haserror={+!!(error && wasInFocus)}
        variant="filled"
        autoComplete="off"
        type={type}
      />
      {error && wasInFocus ? <Error multiline={multiline}>{error}</Error> : null}
    </Container>
  );
};

export default TextInput;

type PropsType = {
  name: string;
  label?: string;
  type?: string;
  placeholder: string;
  inputProps?: InputBaseComponentProps | undefined;
  multiline?: boolean;
  disabled?: boolean;
  disabledWheel?: boolean;
};
