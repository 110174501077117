import { DiseaseType } from "../api/apiEmployees";
import { areIntervalsOverlapping, getMonth } from "date-fns";
import { clampInterval, endOfToday, parseDate, today } from "./workingWithDates";
import { monthNumbers } from "../constants/dates";

export const useCalculateSickLeaves = () => {
  // ------------------------------ ПОЛУЧЕНИЕ КОЛИЧЕСТВА ДНЕЙ НА БОЛЬНИЧНОМ в МЕСЯЦ
  const getSickDaysInMonth = (monthNumber: number, diseases: DiseaseType[]) => {
    if (monthNumber > getMonth(endOfToday)) return null;

    let sickDays = 0;
    const monthIntervals: DateInterval[] = [];

    diseases.forEach(({ openSickListDate, closeSickListDate }) => {
      const fullInterval = {
        start: parseDate(openSickListDate),
        end: !!closeSickListDate ? parseDate(closeSickListDate) : today,
      };

      if (monthNumber < getMonth(fullInterval.start) || monthNumber > getMonth(fullInterval.end))
        return;
      monthIntervals.push(clampInterval(fullInterval, monthNumber));
    });

    let monthIndex = 0;
    if (monthIntervals.length)
      do {
        const endIntervalIndex = intervalCollapse(monthIntervals, monthIndex);
        const startInterval = monthIntervals[monthIndex];
        const endInterval = monthIntervals[endIntervalIndex];
        const start =
          startInterval.start.getDate() <= endInterval.start.getDate()
            ? startInterval.start
            : endInterval.start;
        const end =
          endInterval.end.getDate() >= startInterval.end.getDate()
            ? endInterval.end
            : startInterval.end;

        sickDays += start.getDate() === 1 ? end.getDate() : end.getDate() + 1 - start.getDate();
        monthIndex = monthIndex === endIntervalIndex ? endIntervalIndex + 1 : endIntervalIndex + 1;
      } while (monthIntervals.length - 1 >= monthIndex);

    return sickDays;
  };

  const intervalCollapse = (intervals: DateInterval[], index: number): number => {
    if (!intervals[index + 1]) return index;

    if (areIntervalsOverlapping(intervals[index], intervals[index + 1], { inclusive: true }))
      return intervalCollapse(intervals, index + 1);
    else return index;
  };

  // ------------------------------ СУММАРНОЕ КОЛИЧЕСТВО ДНЕЙ НА БОЛЬНИЧНОМ ЗА ГОД

  const getSickDaysInYear = (diseases: DiseaseType[]) =>
    monthNumbers.reduce((sum, monthNumber) => {
      const currentMonth = getSickDaysInMonth(monthNumber - 1, diseases);
      return currentMonth === null ? sum : sum + currentMonth;
    }, 0);

  return {
    getSickDaysInMonth,
    getSickDaysInYear,
  };
};

export type DateInterval = {
  start: Date;
  end: Date;
};
