import styled from "@emotion/styled";
import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { colors, shadow } from "../../styles/styles";

export const Container = styled.div<{ column: boolean }>`
  width: 100%;
  display: grid;
  ${({ column }) =>
    column ? "grid-template-rows: repeat(2, 1fr);" : "grid-template-columns: repeat(2, 1fr);"}
  gap: 16px;
  margin: 0 0 16px 0;
`;

export const ContainerCalendar = styled.div`
  position: relative;
  margin: 0 0 18px 0;
`;

export const Label = styled.p`
  position: absolute;
  left: 16px;
  top: 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: ${colors.grayscaleLable};
  z-index: 1;
`;

export const Calendar = styled(DatePicker)``;

export const InputAndIcon = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  * {
    cursor: inherit !important;
  }
`;

export const Input = styled(TextField)<{ needlabel: number; haserror: number }>`
  width: 100%;
  height: 56px;
  background: ${({ haserror }) => (haserror ? colors.redBackground : colors.grayscaleInput)};
  border: none;
  border-radius: 16px;
  padding: 0;

  input {
    padding: 0 0 0 14px;
  }

  .MuiFormLabel-root {
    opacity: 1;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${colors.grayscalePlaceholder};
    margin: ${({ needlabel }) => (needlabel ? "8px 0 0 0" : "0")};
  }

  .MuiInputLabel-shrink {
    display: none;
  }

  .MuiInputBase-root {
    height: ${({ needlabel }) => (needlabel ? "48px" : "56px")};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${colors.grayscaleAsh};
    margin: ${({ needlabel }) => (needlabel ? "8px 0 0 0" : "0")};

    .MuiInputBase-input::placeholder {
      opacity: 1;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: ${colors.grayscalePlaceholder};
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        width: 40px;
        height: 40px;
        margin: ${({ needlabel }) => (needlabel ? "-8px -5px 0 0" : "0 -5px 0 0")};
        z-index: 1;

        svg {
          display: none;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 9px;
  right: 9px;
  border-radius: 99em;

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const Error = styled.p`
  position: absolute;
  top: 56px;
  left: 16px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${colors.redDefault};
`;

export const PaperProps = {
  sx: {
    borderRadius: 4,
    boxShadow: shadow,
    padding: 1,

    "&:has(div)": { margin: "30px" },

    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root, .MuiPickersDay-root": {
      "&:hover, &:focus, &:active": {
        background: colors.grayscaleHoverBackground,
      },
    },

    ".PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersMonth-root.Mui-selected, .MuiPickersDay-root.Mui-selected ":
      {
        background: colors.primaryActiveElement,
        color: colors.grayscaleLable,

        "&:hover": {
          background: colors.grayscaleHoverBackground,
          cursor: "default",
        },

        "&:focus, &:active": {
          background: colors.primaryActiveElement,
          color: colors.grayscaleLable,
        },
      },
  },
};
