import { getMonth, getYear } from "date-fns";
import { useAtom } from "jotai";
import { useState } from "react";
import { DiseaseType, EmployeeType } from "../../../api/apiEmployees";
import { endOfToday, formatParseDate, parseDate, today } from "../../../utils/workingWithDates";
import { currentDate } from "../utils/atoms";
import { useCalculateSickLeaves } from "../../../utils/useCalculateSickLeaves";

export const useCharts = (employee: EmployeeType | undefined) => {
  // ------------------------------ АТОМЫ

  const [date] = useAtom(currentDate);

  // ------------------------------ УТИЛИТЫ

  const { getSickDaysInMonth, getSickDaysInYear } = useCalculateSickLeaves();

  // ------------------------------ ДАННЫЕ - БОЛЬНИЧНЫЕ ЛИСТЫ РАБОТНИКА

  const sickLeaves: DiseaseType[] = employee
    ? employee.diseases!.filter(
        ({ openSickListDate, closeSickListDate }) =>
          getYear(parseDate(openSickListDate)) === getYear(date) ||
          (!!closeSickListDate && getYear(parseDate(closeSickListDate)) === getYear(date))
      )
    : [];

  // ------------------------------ ДОПОЛНИТЕЛЬНЕ ДАННЫЕ ПО МЕСЯЦУ

  const getSickLeaveByMonthNumber = (monthNumber: number) => {
    if (monthNumber > getMonth(endOfToday) + 1) return;

    return sickLeaves.filter(({ openSickListDate, closeSickListDate }) => {
      const monthIndex = monthNumber - 1;

      const sinceMonth = getMonth(parseDate(openSickListDate));
      const untilMonth = closeSickListDate
        ? getMonth(parseDate(closeSickListDate))
        : getMonth(today);

      return sinceMonth <= monthIndex && monthIndex <= untilMonth;
    });
  };

  const getSickDaysOfMonth = (monthNumber: number) => getSickDaysInMonth(monthNumber, sickLeaves);

  // ------------------------------ СУММАРНОЕ КОЛИЧЕСТВО ДНЕЙ НА БОЛЬНИЧНОМ ЗА ГОД

  const getSickDaysOfYear = () => getSickDaysInYear(sickLeaves);

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ - ПОДСКАЗКА ДЛЯ ОДНОГО МЕСЯЦА

  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const [popoverId, setPopoverId] = useState<string>();

  const openPopoverHover = (element: HTMLDivElement, month: number) => {
    setPopoverId(month.toString());
    setAnchorElement(element);
  };
  const closePopoverHover = () => {
    setPopoverId(undefined);
    setAnchorElement(null);
  };

  const morbiditiesInfo = (monthNumber: number) =>
    getSickLeaveByMonthNumber(monthNumber)?.map((monthInfo) => {
      const { openSickListDate, closeSickListDate, diseaseClass, disability } = monthInfo;

      const startDate = `С ${formatParseDate(openSickListDate)}`;
      const endDate = closeSickListDate ? ` по ${formatParseDate(closeSickListDate)}` : "по н.в.";
      const period = `${startDate}${endDate}`;

      return {
        diseasesClass: diseaseClass?.code ?? "—",
        disabilityCode: disability.code ?? "—",
        disabilityName: disability.name ?? "—",
        period,
      };
    });

  return {
    getSickDaysOfMonth,
    getSickDaysOfYear,

    popoverId,
    anchorElement,
    openPopoverHover,
    closePopoverHover,
    morbiditiesInfo,
  };
};
