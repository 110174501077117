import React, { FC } from "react";
import { TriangleDown, TriangleRight, TriangleUp } from "../../Icons";
import { Container } from "./OpenListButton.styles";

const OpenListButton: FC<PropsType> = (props) => {
  const { isOpen, openRight = false, onOpen, disabled = false } = props;

  return (
    <Container onClick={onOpen} isOpen={isOpen} disabled={disabled}>
      {isOpen ? openRight ? <TriangleRight /> : <TriangleUp /> : <TriangleDown />}
    </Container>
  );
};

export default OpenListButton;

type PropsType = {
  isOpen: boolean;
  openRight?: boolean;
  onOpen?: () => void;
  disabled?: boolean;
};
