import React, { FC } from "react";
import { OptionsType } from "../../utils/getOptions";
import { TrashButton } from "../Buttons";
import * as elements from "./SelectAutocompleteMultipleInput.styles";

const SelectAutocompleteInputList: FC<PropsType> = (props) => {
  const { valuesName, options, deleteValue } = props;

  const { SelectedListItems, SelectedListItem, SelectedListText } = elements;

  return (
    <SelectedListItems>
      {valuesName.map((value, i) => (
        <SelectedListItem key={i}>
          <SelectedListText>{options.find((option) => option.id === value)!.name}</SelectedListText>
          {<TrashButton onClick={() => deleteValue(value)} />}
        </SelectedListItem>
      ))}
    </SelectedListItems>
  );
};

export default SelectAutocompleteInputList;

type PropsType = {
  valuesName: string[];
  options: OptionsType;
  deleteValue: (arg: string) => void;
};
