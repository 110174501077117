import { useFormikContext } from "formik";
import React, { FC, FormEvent } from "react";
import { DateInput, StartAndEndDatesInput } from "../../../components/DateInput";
import { ErrorText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import FilesInput from "../../../components/FilesInput";
import { Form, FormButtons } from "../../../components/Forms";
import { FormSubTitle } from "../../../components/Forms/Form.styles";
import RightModalActions from "../../../components/RightModal/RightModalActions";
import RightModalBody from "../../../components/RightModal/RightModalBody";
import SelectAutocompleteInput from "../../../components/SelectAutocompleteInput";
import SelectInput from "../../../components/SelectInput";
import SwitchInput from "../../../components/SwitchInput";
import TextInput from "../../../components/TextInput";
import * as ids from "../../../constants/ids";
import * as options from "../../../utils/getOptions";
import { today } from "../../../utils/workingWithDates";
import { FormBlock } from "../EmployeeCardPage.styles";
import { FiledsType } from "../hooks/useForms";

const DiseaseFields: FC<PropsType> = (props) => {
  const { formName, handleSubmit, isLoading, error, onClose, isEdit = false } = props;

  const { values } = useFormikContext<FiledsType>();

  const infectiousDiseasesOptions = options.getInfectiousDiseasesOptions();
  const hospitalsOptions = options.getHospitalsOptions();

  const employeeFields = (
    <>
      <TextInput name="name" label="ФИО" placeholder="—" disabled />
      <TextInput name="organization" label="Организация" placeholder="—" disabled />
      <TextInput name="department" label="Подразделение" placeholder="—" disabled />
      <TextInput name="post" label="Должность" placeholder="—" disabled />

      <SelectInput
        name="nationality"
        options={options.getNationalityOptions()}
        placeholder="Выберите из списка"
        label="Гражданство *"
      />
      <SelectInput
        name="personnelCategory"
        options={options.getPersonnelCategoryOptions()}
        placeholder="Выберите из списка"
        label="Категория персонала *"
      />
      <FormBlock>
        <SwitchInput name="deathStatus" label="Умер" />
      </FormBlock>

      {values["deathStatus"] && (
        <DateInput
          name="deathDate"
          maxDate={today}
          placeholder="Дата смерти"
          label="Дата смерти *"
        />
      )}
    </>
  );

  const diseaseFields = (
    <>
      <FormBlock>
        <SwitchInput name="withoutSickLeave" label="Больничный лист отсутствует" />
      </FormBlock>

      <SelectAutocompleteInput
        name="city"
        options={[{ id: "", name: "" }, ...options.getCityOptions()]}
        placeholder="Выберите из списка"
        label="Город расположения на начало заболевания *"
      />

      <SelectAutocompleteInput
        name="disability"
        options={[{ id: "", name: "" }, ...options.getDisabilityCodeOptions()]}
        placeholder="Выберите из списка"
        label="Код нетрудоспособности блок 1 *"
      />
      <SelectAutocompleteInput
        name="additionalDisability"
        options={[{ id: "", name: "" }, ...options.getAdditionalDisabilityOptions()]}
        placeholder="Выберите из списка"
        label="Код нетрудоспособности блок 2"
      />
      <SelectAutocompleteInput
        name="diseasesClass"
        options={[{ id: "", name: "" }, ...options.getDiseasesClassOptions()]}
        placeholder="Выберите из списка"
        label="Класс по МКБ-10"
      />

      {values["diseasesClass"] === ids.diseaseClassIsInfectious && (
        <>
          <SelectAutocompleteInput
            name="infectionDisease"
            options={[{ id: "", name: "" }, ...infectiousDiseasesOptions]}
            placeholder="Выберите из списка"
            label="Инфекционное заболевание *"
          />
          <TextInput
            name="contactedNumber"
            label="Количество контактировавших лиц *"
            type="number"
            placeholder="Введите значение"
            disabledWheel
          />
          <TextInput
            name="otherEmployeeInfection"
            label="Вероятность заражения других работников"
            type="number"
            placeholder="Введите значение"
            inputProps={{ pattern: "(?=.{0,2}$)" }}
            disabledWheel
          />
          <FormBlock>
            <SwitchInput name="abroad" label="Находился за границей последние 2 недели" />
          </FormBlock>
        </>
      )}

      <SelectInput
        name="treatmentPlace"
        options={options.getTreatmentPlaceOptions()}
        placeholder="Выберите из списка"
        label="Место прохождения лечения *"
      />
      {values["treatmentPlace"] === ids.treatmentPlaceIsHospital && (
        <SelectAutocompleteInput
          name="hospital"
          options={[{ id: "", name: "" }, ...hospitalsOptions]}
          placeholder="Выберите из списка"
          label="Стационар"
        />
      )}

      <SelectInput
        name="patientStatus"
        options={options.getDiseaseSeverityOptions()}
        placeholder="Выберите из списка"
        label="Степень тяжести заболевания *"
      />

      <TextInput name="comment" placeholder="—" multiline />

      {!values["withoutSickLeave"] && (
        <FilesInput
          name="document"
          label="Загрузите файлы из папки или перетащите"
          accept={[".pdf", ".doc", ".docx"]}
          maxSize={4194304}
          description="Файлы не более 4Мб, в форматах "
        />
      )}

      <StartAndEndDatesInput
        label={{
          start: values["withoutSickLeave"] ? "Дата начала *" : "Дата открытия БЛ *",
          stop: values["withoutSickLeave"] ? "Дата окончания" : "Дата закрытия БЛ",
        }}
        maxDate={today}
        disableStart={isEdit}
      />
    </>
  );

  return (
    <>
      <RightModalBody>
        <Form formName={formName} onSubmit={handleSubmit}>
          <FormSubTitle>Работник</FormSubTitle>
          {employeeFields}

          <FormSubTitle>Заболевание</FormSubTitle>
          {diseaseFields}
        </Form>
      </RightModalBody>

      {error ? <ErrorText>{error}</ErrorText> : null}

      <RightModalActions>
        <FormButtons formName={formName} onClose={onClose} saveOrAdd="save" disabled={isLoading} />
      </RightModalActions>
    </>
  );
};

export default DiseaseFields;

type PropsType = {
  formName: string;
  handleSubmit: (evt?: FormEvent<HTMLFormElement> | undefined) => void;
  isLoading: boolean;
  error: string;
  onClose: () => void;
  isEdit?: boolean;
};
