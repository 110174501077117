import styled from "@emotion/styled";
import { colors } from "../../../styles/styles";

export const Container = styled.div`
  min-width: 0;
  position: sticky;
  top: 64px;
  display: flex;
  align-items: center;
  background: ${colors.grayscaleLine};
  overflow: hidden;
  padding: 12px 20px;
  z-index: 2;

  h3 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.grayscaleBody};
  }
`;
