import React, { FC } from "react";
import PopoverHover from "../../../components/PopoverHover";
import { MonthAndText, PopoverDataContainer } from "../ChartsForEmployeesPage.styles";
import EmployeeMorbidityBar from "./EmployeeMorbidityBar";

const EmployeeMorbidityDiagram: FC<PropsType> = (props) => {
  const { monthNumber, morbiditiesInfo, popoverId } = props;
  const { anchorElement, openPopoverHover, closePopoverHover, getSickDaysOfMonth } = props;

  const needPopover = popoverId == monthNumber.toString();

  const morbiditiesForMonth = morbiditiesInfo(monthNumber);

  return (
    <MonthAndText
      onMouseEnter={({ currentTarget }) => {
        openPopoverHover(currentTarget, monthNumber);
      }}
      onMouseLeave={closePopoverHover}
    >
      <EmployeeMorbidityBar monthNumber={monthNumber} getSickDaysOfMonth={getSickDaysOfMonth} />

      {needPopover && !!morbiditiesForMonth?.length && (
        <PopoverHover
          id={popoverId}
          isOpen={!!anchorElement}
          element={anchorElement}
          onClose={closePopoverHover}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: 0, horizontal: -20 }}
        >
          <PopoverDataContainer>
            {morbiditiesForMonth.map(
              ({ diseasesClass, disabilityCode, disabilityName, period }, i) => (
                <div key={i}>
                  <p>Класс заболевания: {diseasesClass}</p>
                  <p>Код заболевания МКБ-10: {disabilityCode}</p>
                  <p>Расшифровка: {disabilityName}</p>
                  <p>Продолжительность: {period}</p>
                </div>
              )
            )}
          </PopoverDataContainer>
        </PopoverHover>
      )}
    </MonthAndText>
  );
};

export default EmployeeMorbidityDiagram;

type MorbiditiesType = {
  diseasesClass: string;
  disabilityCode: string;
  disabilityName: string;
  period: string;
};

type PropsType = {
  monthNumber: number;
  morbiditiesInfo: (arg: number) => MorbiditiesType[] | undefined;
  popoverId: string | undefined;
  anchorElement: HTMLDivElement | null;
  openPopoverHover: (arg: HTMLDivElement, month: number) => void;
  closePopoverHover: () => void;
  getSickDaysOfMonth: (monthNumber: number) => number | null;
};
