import styled from "@emotion/styled";
import { button, buttonOnlyText } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button`
  ${button};
  ${buttonOnlyText};

  width: 40px;
  height: 40px;

  :hover {
    background: ${colors.redBackground};
  }

  & > svg > path {
    fill: ${colors.redDefault};
  }
`;
