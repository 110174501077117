import * as muiElements from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material";
import { useFormikContext } from "formik";
import produce from "immer";
import React, { FC, FocusEvent, HTMLAttributes, useRef, useState } from "react";
import { OptionType, OptionsType } from "../../utils/getOptions";
import { TriangleDown, TriangleUp } from "../Icons";
import * as elements from "./SelectAutocompleteMultipleInput.styles";
import SelectAutocompleteInputChips from "./SelectAutocompleteMultipleInputChips";
import SelectAutocompleteInputList from "./SelectAutocompleteMultipleInputList";
import SelectAutocompleteInputPlaceholder from "./SelectAutocompleteMultipleInputPlaceholder";
import SelectAutocompleteInputSwitcher from "./SelectAutocompleteMultipleInputSwitcher";

const SelectAutocompleteMultipleInput: FC<PropsType> = (props) => {
  const { name, type, options, label } = props;
  const { placeholder, labelSwitch, size = "" } = props;

  const { Container, Item, MenuItemWrapper, PopperComponent, PaperProps } = elements;
  const { MenuItem, TextField } = muiElements;

  const { values, handleBlur, setFieldValue } = useFormikContext<Record<string, string[]>>();

  const valuesName = values[name];

  const textInput = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const deleteValue = (value: string) => {
    const index = valuesName.findIndex((valueName) => value === valueName);

    setFieldValue(
      name,
      produce(valuesName, (draft) => draft.splice(index, 1))
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={label} inputRef={textInput} />
  );

  const renderOption = (item: HTMLAttributes<HTMLLIElement>, option: OptionType) => (
    <MenuItem {...item}>
      <MenuItemWrapper>{option.name}</MenuItemWrapper>
    </MenuItem>
  );

  const switcherChecked = valuesName.length === options.length;
  const needSwitch = !!labelSwitch;

  const autocompleteValue = valuesName.map((value) => options.find(({ id }) => id === value)!);

  const [selectedOptions, setSelectedOptions] = useState<(string | OptionType)[]>([]);

  const sortedOptions = [
    ...selectedOptions.map((selectedOption) =>
      options.find(
        ({ id }) => id === (typeof selectedOption === "string" ? selectedOption : selectedOption.id)
      )
    ),
    ...options.filter(
      (option) =>
        !selectedOptions.some(
          (selectedOption) =>
            option.id === (typeof selectedOption === "string" ? selectedOption : selectedOption.id)
        )
    ),
  ].filter((item) => !!item) as OptionsType;

  return (
    <Container>
      <Item
        options={sortedOptions}
        getOptionLabel={(option) => option.name}
        renderInput={renderInput}
        renderOption={renderOption}
        haslabel={+!!label}
        popupIcon={isOpen ? <TriangleUp /> : <TriangleDown />}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        PopperComponent={PopperComponent}
        componentsProps={{ paper: PaperProps(size) }}
        noOptionsText="Нет совпадений"
        multiple
        value={autocompleteValue}
        onChange={(_evt, arg) => {
          setSelectedOptions(arg);
          const value = arg.map((item) => (item as { id: string }).id);
          setFieldValue(name, value);
        }}
        onBlur={(evt: FocusEvent<Element, Element>) => handleBlur(evt)}
      />

      {textInput.current === document.activeElement && isOpen ? null : (
        <SelectAutocompleteInputPlaceholder
          name={name}
          type={type}
          options={options}
          label={label}
          placeholder={placeholder}
          switcherChecked={switcherChecked}
        />
      )}

      {type === "list" ? (
        <SelectAutocompleteInputList
          valuesName={valuesName}
          options={options}
          deleteValue={deleteValue}
        />
      ) : null}

      {type === "chips" && !switcherChecked && valuesName.length !== 0 ? (
        <SelectAutocompleteInputChips
          valuesName={valuesName}
          options={options}
          deleteValue={deleteValue}
        />
      ) : null}

      {needSwitch ? (
        <SelectAutocompleteInputSwitcher
          name={name}
          options={options}
          labelSwitch={labelSwitch}
          switcherChecked={switcherChecked}
        />
      ) : null}
    </Container>
  );
};

export default SelectAutocompleteMultipleInput;

type PropsType = {
  name: string;
  type: TypeListType;
  options: OptionsType;
  label?: string;
  labelSwitch?: string;
  placeholder: string;
  size?: "small";
};

type TypeListType = "list" | "chips" | "textInInput";
