import { createTheme } from "@mui/material";
import { ruRU } from "@mui/material/locale";

export const theme = createTheme(
  {
    typography: {
      fontFamily: ["Rosatom", "Ubuntu", "Arial", "sans-serif"].join(","),
    },
  },
  ruRU
);
