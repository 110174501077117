import { Formik } from "formik";
import React, { FC } from "react";
import {
  BigOtherType,
  EntitieType,
  EntitieUpdateType,
  diseasesClassesApi,
} from "../../../../api/apiDictionaries";
import { ErrorText } from "../../../../components/ErrorBoundary/ErrorBoundary.styles";
import { Form } from "../../../../components/Forms";
import FormButtons from "../../../../components/Forms/FormButtons";
import RightModal from "../../../../components/RightModal";
import RightModalActions from "../../../../components/RightModal/RightModalActions";
import RightModalBody from "../../../../components/RightModal/RightModalBody";
import TextInput from "../../../../components/TextInput";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { useQuery } from "../../../../utils/useQuery";
import { useDiseasesClassDictionary } from "../useDiseasesClassDictionary";

const DiseasesClassEditForm: FC<PropsType> = (props) => {
  const { data, onClose } = props;

  const diseasesClassDictionaryMethods = useDiseasesClassDictionary();
  const { editFormName, editFormTitle, queryKey } = diseasesClassDictionaryMethods;
  const { initialValuesEditForm, validationSchema } = diseasesClassDictionaryMethods;

  const fields = (
    <>
      <TextInput name="code" label="Класс болезни *" placeholder="Введите класс болезни" />
      <TextInput name="name" label="Расшифровка *" placeholder="Например: Карантин" />
      <TextInput name="comment" label="Комментарий" placeholder="—" multiline />
    </>
  );

  const update = useQuery().update<EntitieUpdateType<BigOtherType>>({
    queryKey,
    api: diseasesClassesApi,
  });

  const error = getErrorMessage(update.error);
  const isLoading = update.isLoading;

  return (
    <RightModal title={editFormTitle} isOpen onClose={onClose} needBlur>
      <Formik
        initialValues={initialValuesEditForm(data)}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            await update.mutateAsync({ id: data.id, data: values });

            onClose();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ handleSubmit }) => (
          <>
            <RightModalBody>
              <Form formName={editFormName} onSubmit={handleSubmit}>
                {fields}
              </Form>
            </RightModalBody>

            {error ? <ErrorText>{error}</ErrorText> : null}

            <RightModalActions>
              <FormButtons
                formName={editFormName}
                onClose={onClose}
                saveOrAdd="save"
                disabled={isLoading}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

export default DiseasesClassEditForm;

type PropsType = {
  data: EntitieType<BigOtherType>;
  onClose: () => void;
};
