import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { colors } from "../../styles/styles";

export const SkeletonElement = styled(Skeleton)`
  width: 100%;
  background: ${colors.white};
  border-radius: 16px;
  transform: none;
  transform-origin: none;
  -webkit-transform: none;
`;

export const SkeletonForEmployee = styled(SkeletonElement)`
  width: 230px;
  height: 15px;
  border-radius: 4px;
  margin: 0 auto 3px 16px;

  &:nth-of-type(2) {
    width: 100px;
    height: 14px;
    margin: 0 auto 1px 16px;
  }

  &:last-child {
    width: 40px;
    margin: 0 auto 0 16px;
  }
`;

export const SkeletonForEmployeesStatus = styled(SkeletonElement)`
  width: 70px;
  height: 34px;
  border-radius: 99em;
  margin: 16px auto;
`;

export const SkeletonForEmployeesDate = styled(SkeletonElement)`
  width: 90px;
  height: 14px;
  border-radius: 4px;
  margin: 16px auto;
`;

export const SkeletonForEmployeesCard = styled(SkeletonElement)`
  width: 588px;
  height: 111px;
  border-radius: 12px;
  margin: 16px;
`;

export const SkeletonForTable = styled(SkeletonElement)`
  height: 24px;
  border-radius: 4px;
  margin: 0 30% 0 16px;
`;

export const SkeletonForPanel = styled(SkeletonElement)`
  height: 120px;
  margin: 0 0 16px 0;
`;

export const SkeletonForCharts = styled(SkeletonElement)`
  min-width: 983px;
  height: 60%;
  max-height: calc(100vh - 84px);
  margin: 16px 0 0 0;
`;

export const SkeletonForCard = styled(SkeletonElement)`
  width: 100%;
  height: 232px;
  max-height: calc(100vh - 84px);
  margin: 16px 0 0 0;
`;

export const TableSimulation = styled.div<{ forEmployeesTable?: boolean }>`
  display: grid;
  grid-template-rows: repeat(
    5,
    ${({ forEmployeesTable }) => (forEmployeesTable ? "140px" : "48px")}
  );
  gap: 1px;
`;

export const Row = styled.div<{ grids: string }>`
  display: grid;
  grid-template-columns: ${({ grids }) => grids};
  border-bottom: 1px solid ${colors.grayscaleInput};

  &:last-child {
    border: none;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${colors.grayscaleInput};

  &:last-child {
    border: none;
  }
`;
