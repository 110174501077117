import { useState } from "react";

export const useCard = () => {
  // ------------------------------ СТЕЙТЫ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] =
    useState<HTMLParagraphElement | null>(null);

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ - ПОДСКАЗКА

  const openPopoverHover = (element: HTMLParagraphElement) => setAnchorPopoverHoverElement(element);
  const closePopoverHover = () => setAnchorPopoverHoverElement(null);

  return {
    anchorPopoverHoverElement,
    openPopoverHover,
    closePopoverHover,
  };
};
