import { useAtom } from "jotai";
import React, { FC, ReactNode } from "react";
import { statusCreateOrUpdate } from "../../atoms/atoms";
import { CloseButton } from "../Buttons";
import Loader from "../Loader";
import {
  Container,
  Description,
  Head,
  Main,
  Title,
  TitleAndCloseButton,
} from "./RightModal.styles";

const RightModal: FC<PropsType> = (props) => {
  const { title, description, children } = props;
  const { isOpen = false, onClose, size = "", needBlur = false } = props;

  const [status] = useAtom(statusCreateOrUpdate);

  return (
    <Container anchor="right" open={isOpen} onClose={onClose} size={size} needblur={+!!needBlur}>
      {status ? <Loader over /> : null}

      <Head>
        <TitleAndCloseButton>
          <Title>{title}</Title>
          <CloseButton onClick={onClose} />
        </TitleAndCloseButton>
        {description && <Description>{description}</Description>}
      </Head>
      <Main>{children}</Main>
    </Container>
  );
};

export default RightModal;

type PropsType = {
  title: string;
  description?: string;
  isOpen?: boolean;
  onClose: () => void;
  size?: string;
  needBlur?: boolean;
  children: ReactNode;
};
