import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { colors } from "../../styles/styles";

export const Overlay = styled.div<{ over: boolean }>`
  ${({ over }) =>
    over
      ? "border-radius: 18px 18px 0 0;   background: rgba(224, 242, 255, 0.2);  backdrop-filter: blur(2px);  opacity: 0.5;"
      : ""};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 99em;
  margin: 100px auto;
  z-index: 999;
`;

export const Item = styled(CircularProgress)`
  height: 100%;
  animation-duration: 5s;
  color: ${colors.primaryDefaultDark};
  z-index: 999;
`;
