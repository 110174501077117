import { useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { Calendar as CalendarIcon } from "../Icons";
import * as elements from "./DateInput.styles";

const DateInput: FC<PropsType> = (props) => {
  const { name, placeholder, label } = props;
  const { minDate = null, maxDate = null, disabled = false } = props;

  const [wasInFocus, setWasInFocus] = useState(false);

  const { ContainerCalendar, Label, Calendar } = elements;
  const { PaperProps, InputAndIcon, Input, Icon, Error } = elements;

  const { values, setFieldValue, errors } = useFormikContext<Record<string, string>>();

  const value = values[name];
  const error = errors[name];

  return (
    <ContainerCalendar>
      {!!label ? <Label>{label}</Label> : null}
      <Calendar
        label={placeholder}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        mask={"__.__.____"}
        inputFormat="dd.MM.yyyy"
        onChange={(newValue) => {
          setFieldValue(name, newValue);
          // handleChange(evt);
          setWasInFocus(!!error);
        }}
        onClose={() => setWasInFocus(!!error)}
        PaperProps={PaperProps}
        renderInput={(params) => (
          <InputAndIcon disabled={disabled}>
            <Input
              value={value}
              needlabel={+!!label}
              onBlur={() => setWasInFocus(!!error)}
              haserror={+!!(error && wasInFocus)}
              {...params}
              autoComplete="off"
            />
            <Icon>
              <CalendarIcon />
            </Icon>
          </InputAndIcon>
        )}
        disabled={disabled}
      />
      {error && wasInFocus ? <Error>{error}</Error> : null}
    </ContainerCalendar>
  );
};

export default DateInput;

type PropsType = {
  name: string;
  placeholder: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
};
