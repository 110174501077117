import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { searchQueryState } from "../../../../atoms/atoms";
import { fetchAllCities, fetchCities, fetchTotalCountCities } from "./fetchData";

// ------------------------------ НОМЕР СТРАНИЦЫ

export const pageState = atom(0);

// ------------------------------ КОЛИЧЕСТВО СТРОК НА ОДНОЙ СТРАНИЦЕ

export const quantityInOnePageState = atom(10);

// ------------------------------ ДАННЫЕ

export const citiesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  return citiesQuery({ page, quantity, queryState });
});

export const citiesState = loadable(citiesAtom);

export const citiesQuery = (props: { page: number; quantity: number; queryState?: string }) => {
  const { page, quantity, queryState } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return {
    queryKey: [page * quantity, quantity, query || undefined, "citiesState"],
    queryFn: () => fetchCities(args),
    keepPreviousData: true,
  };
};

// ------------------------------ ОБЩЕЕ КОЛИЧЕСТВО

export const totalCountCitiesAtom = atomWithQuery((get) => {
  const page = get(pageState);
  const quantity = get(quantityInOnePageState);
  const queryState = get(searchQueryState);

  return totalCountCitiesQuery({ page, quantity, queryState });
});

export const totalCountCitiesState = loadable(totalCountCitiesAtom);

export const totalCountCitiesQuery = (props: {
  page: number;
  quantity: number;
  queryState?: string;
}) => {
  const { page, quantity, queryState } = props;

  const startIndex = page * quantity;
  const size = quantity;
  const query = queryState || undefined;

  const args = { startIndex, size, query };

  return {
    queryKey: [page * quantity, quantity, query || undefined, "totalCountCitiesState"],
    queryFn: () => fetchTotalCountCities(args),
    keepPreviousData: true,
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const citiesAllState = atomWithQuery(() => ({
  queryKey: ["totalCountCitiesAllState"],
  queryFn: () => fetchAllCities(),
}));
