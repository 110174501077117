import { getHSLForChart } from "../../../utils/getRandom";

const useColor = () => {
  const getColorsForChart = (index: number) => {
    const color = getHSLForChart(index);

    return {
      lines: `hsl(${color.h},${color.s}%,${color.l}%)`,
      gradient: `hsl(${color.h},${color.s}%,${color.l}%, 0.26)`,
    };
  };

  return { getColorsForChart };
};

export default useColor;
