import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Trash: FC = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9842 2.72033C13.1133 2.76644 13.2305 2.81229 13.3366 2.85715C13.2305 2.90201 13.1133 2.94785 12.9842 2.99397C11.8039 3.41548 10.042 3.71429 8 3.71429C5.95805 3.71429 4.19606 3.41548 3.01582 2.99397C2.88669 2.94785 2.76946 2.90201 2.66344 2.85715C2.76946 2.81229 2.88669 2.76644 3.01582 2.72033C4.19606 2.29881 5.95805 2 8 2C10.042 2 11.8039 2.29881 12.9842 2.72033ZM0 2.85772C9.61055e-06 2.88215 0.000629775 3.67949 0.0406499 4.88126C0.123605 7.37237 0.375849 11.6012 1.14286 14.2857C1.40296 15.1961 2.07966 16.0312 2.85714 16.5715C3.71457 17.1673 4.33171 17.1591 5.26705 17.1467C5.40824 17.1448 5.55668 17.1429 5.71429 17.1429H10.2857H10.3611C11.3084 17.1433 11.7849 17.1435 12.5714 16.5715C13.3371 16.0146 14.0256 15.1961 14.2857 14.2857C15.0505 11.6089 15.5594 7.39672 15.8124 4.90283C15.904 3.99967 15.9621 3.32187 15.9866 3.02398C15.9955 2.96877 16 2.91315 16 2.85715C16 1.27919 12.4183 0 8 0C3.58172 0 0 1.27976 0 2.85772ZM2.13919 6.95044C2.10233 6.34214 2.0752 5.76752 2.05526 5.25147C2.35335 5.32083 2.63939 5.39639 2.89045 5.47673C3.36787 5.6295 3.71393 5.8029 4.1697 6.03126C4.37218 6.13272 4.59631 6.24502 4.86324 6.37119C5.67092 6.75294 6.6778 7.14286 8 7.14286C9.3222 7.14286 10.3291 6.75295 11.1368 6.37119C11.4037 6.24503 11.6278 6.13273 11.8303 6.03128C12.2861 5.80292 12.6321 5.6295 13.1096 5.47673C13.3085 5.41308 13.5293 5.35243 13.7607 5.29549C13.7102 5.76866 13.652 6.28626 13.586 6.83077C13.301 9.18148 12.887 11.9013 12.3627 13.7363C12.2602 14.0949 11.9181 14.5736 11.3951 14.954C11.1858 15.1062 11.1205 15.1146 11.1079 15.1162L11.1061 15.1165C11.0746 15.1228 11.0104 15.1323 10.8725 15.1377C10.7363 15.143 10.584 15.1429 10.3531 15.1429H10.2857H5.71429C5.54936 15.1429 5.39548 15.1448 5.26053 15.1466L5.2463 15.1468C5.11333 15.1486 5.00348 15.15 4.89961 15.15C4.68369 15.15 4.55629 15.1431 4.46226 15.1309C4.34763 15.1161 4.23568 15.0939 3.99846 14.9291C3.5199 14.5965 3.17489 14.1178 3.06591 13.7363C2.5596 11.9642 2.28148 9.29818 2.13919 6.95044Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default Trash;
