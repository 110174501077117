import React, { FC } from "react";
import DepartmentCard from "../Card/DepartmentCard";
import { CardsContainer, CardsWrapper } from "../ChartsForDepartmentsPage.styles";
import { useCharts } from "../hooks/useCharts";
import DepartmentDiagram from "./DepartmentDiagram";

const DepartmentCharts: FC = () => {
  const { statsFilteredForDepartments, dataWrapper } = useCharts();
  const { getDatasets, getPlugins, getOptions } = useCharts();

  return (
    <CardsWrapper>
      <CardsContainer>
        {statsFilteredForDepartments
          ? dataWrapper(
                statsFilteredForDepartments.map((datum, i) => {
                const { mainDepartmentCode } = datum;

                return (
                  <DepartmentCard key={i} datum={datum}>
                    <DepartmentDiagram
                      id={mainDepartmentCode}
                      healthIndex={datum.healthIndex || 0}
                      getDatasets={getDatasets}
                      getPlugins={getPlugins}
                      getOptions={getOptions}
                    />
                  </DepartmentCard>
                );
              })
            )
          : null}
      </CardsContainer>
    </CardsWrapper>
  );
};

export default DepartmentCharts;
