import React, { FC, ReactNode } from "react";
import { FormContainer } from "./Form.styles";

const Form: FC<PropsType> = (props) => {
  const { formName, onSubmit, children } = props;

  return (
    <form id={formName} onSubmit={onSubmit}>
      <FormContainer>{children}</FormContainer>
    </form>
  );
};

export default Form;

type PropsType = {
  formName: string;
  onSubmit: () => void;
  children: ReactNode;
};
