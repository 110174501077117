import React, { FC, Suspense, useState } from "react";
import { DICTIONARY } from "../../../api/routes";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { ErrorText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../../components/Header";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import HeaderSearchInput from "../../../components/Header/HeaderSearchInput";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/Pagination";
import { Main, PageContainer } from "../../Page.styles";
import CountryAddForm from "./Forms/CountryAddForm";
import CountriesTable from "./Table/CountriesTable";
import { useCountryDictionary } from "./useCountryDictionary";

const CountryDictionaryPage: FC = () => {
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);

  const countryDictionaryMethods = useCountryDictionary();
  const { titlePage, handleDeleteRows } = countryDictionaryMethods;
  const { canViewDictionaries, canEditDictionaries, needPagination } = countryDictionaryMethods;

  const handleCloseAddForm = () => setAddFormIsOpen(false);

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needAddButton={
        canEditDictionaries ? { text: "Добавить", onClick: setAddFormIsOpen } : undefined
      }
      needDeleteButton={
        canEditDictionaries ? { text: "Удалить", onClick: handleDeleteRows } : undefined
      }
      needSearchButton={{ visibleSearch, setVisibleSearch }}
      section={{ name: "Справочники", rout: DICTIONARY }}
    />
  );

  const searchInput = visibleSearch ? <HeaderSearchInput /> : null;

  return canViewDictionaries ? (
    <PageContainer>
      <Header navigation={navigation} searchInput={searchInput} />

      <Main needPagination>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <CountriesTable />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {needPagination && <Pagination needPagination={needPagination} />}

      {addFormIsOpen && <CountryAddForm onClose={handleCloseAddForm} />}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default CountryDictionaryPage;
