import styled from "@emotion/styled";
import { Autocomplete, AutocompleteProps, ChipTypeMap, Popper } from "@mui/material";
import { colors, overflowOneLine, shadow } from "../../styles/styles";

export const Container = styled.div`
  position: relative;
  margin: 0 0 16px 0;
`;

export const Item = styled(Autocomplete)<{ haslabel: number; second: number }>`
  width: 100%;
  height: 56px;
  background: ${({ second }) => (second ? colors.transparent : colors.grayscaleInput)};
  border: ${({ second }) => (second ? `1px solid ${colors.grayscaleInput}` : "none")};
  border-radius: 16px;
  cursor: pointer;
  padding: ${({ haslabel }) => (haslabel ? "8px 16px" : "16px")};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-root {
    margin: ${({ haslabel }) => (haslabel ? "16px 0 0 0" : "0")};
    padding: 0;
    padding-bottom: 8px;
  }

  .MuiButtonBase-root {
    width: 40px;
    height: 40px;
    position: absolute;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
    right: -8px;

    :hover {
      background: ${colors.grayscaleHoverBackground};

      & > svg > path {
        fill: ${colors.grayscaleLable};
      }
    }
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-root {
    display: none;
  }

  input {
    padding: 0 !important;
    z-index: 1;
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: ${colors.grayscaleLable};
    transform: none;
    margin: 0;

    &.Mui-focused {
      color: ${colors.grayscaleLable};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & {
    haslabel: number;
    second: number;
  }
) => JSX.Element;

export const MenuItemWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  font-size: 15px;
  white-space: normal;
  line-height: 20px;
  font-weight: 400;
`;

export const MenuCodeWrapper = styled.div`
  ${overflowOneLine};

  width: 54px;
`;

export const PaperProps = (size: string) => {
  return {
    sx: {
      width: size === "small" ? 372 : 472,
      maxHeight: 264,
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "hidden",
      ml: -2,
      padding: 1,
      "& .MuiAutocomplete-option": {
        minHeight: "56px !important",
      },

      ul: {
        width: "100%",
        maxHeight: 264,
        overflowY: "auto",
        padding: 0,
        paddingBottom: 2,

        li: {
          height: "fit-content",
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },

        "::-webkit-scrollbar": {
          width: 8,
        },

        "::-webkit-scrollbar-thumb": {
          background: colors.grayscaleLine,
          borderRadius: "99em",
        },
      },
    },
  } as const;
};

export const PopperComponent = styled(Popper)`
  padding-top: 16px;
`;
