export const getUserInitials = (name: string) => `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;

export const getFullName = (props: PropsType) => {
  const { nameParts, withoutLastName = false, withoutMiddleName = false } = props;

  if (!nameParts) return "";

  const lastName = withoutLastName ? "" : nameParts.lastName ? nameParts.lastName : "";
  const firstName = nameParts.firstName ? ` ${nameParts.firstName}` : "";
  const middleName = withoutMiddleName
    ? ""
    : nameParts.middleName
    ? ` ${nameParts.middleName}`
    : "";

  return `${lastName}${firstName}${middleName}`;
};

type PropsType = {
  nameParts: { firstName: string; middleName: string; lastName: string } | null;
  withoutLastName?: boolean;
  withoutMiddleName?: boolean;
};
