import { DatePicker, DateRange, DateRangePicker } from "@mui/lab";
import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatDate,
  getPeriodString,
  minusOneDay,
  plusOneDay,
  today,
} from "../../utils/workingWithDates";
import { LeftAndRightArrowsButtons } from "../Buttons";
import { TypeIntervalType } from "./DateSwitcher";
import { DateSwitcher, DateSwitcherText, PaperProps, PopperProps } from "./DateSwitcher.styles";

const DaySwitcher: FC<PropsType> = (props) => {
  const { date, setDate, router, minDate, maxDate } = props;
  const { dateInterval, setDateInterval, typeInterval } = props;

  const navigate = useNavigate();
  const { date: dateParams } = useParams();

  const [open, setOpen] = useState(false);

  const selectedOneDay =
    typeInterval === "twoDate" && formatDate(dateInterval![0]!) === formatDate(dateInterval![1]!);

  const disableBack = date && minDate && date < minDate;
  const disableNext = date && maxDate && date > maxDate;

  const navigateDate = (dateSart: Date | null, endDate?: Date | null) => {
    if (dateParams && dateSart && router) {
      endDate
        ? formatDate(dateSart) === formatDate(endDate)
          ? navigate(`${router(`${formatDate(dateSart)}`)}`)
          : navigate(`${router(`${formatDate(dateSart)}-${formatDate(endDate)}`)}`)
        : navigate(`${router(`${formatDate(dateSart)}`)}`);
    }
  };

  const clickDate = (direction: "left" | "right") => {
    if (date && setDate) {
      if (direction === "left") {
        setDate(minusOneDay(date));
        navigateDate(minusOneDay(date), minusOneDay(date));
      } else {
        setDate(plusOneDay(date));
        navigateDate(plusOneDay(date), plusOneDay(date));
      }
    } else if (dateInterval && dateInterval[0] && setDateInterval) {
      if (direction === "left") {
        setDateInterval([minusOneDay(dateInterval[0]), minusOneDay(dateInterval[0])]);
        navigateDate(minusOneDay(dateInterval[0]), minusOneDay(dateInterval[0]));
      } else {
        setDateInterval([plusOneDay(dateInterval[0]), plusOneDay(dateInterval[0])]);
        navigateDate(plusOneDay(dateInterval[0]), plusOneDay(dateInterval[0]));
      }
    }
  };

  return (
    <>
      {(typeInterval === "twoDate" && selectedOneDay) || (date && setDate) ? (
        <LeftAndRightArrowsButtons
          onClick={() => (disableBack ? undefined : clickDate("left"))}
          disableBack={disableBack}
          orientation="left"
        />
      ) : null}

      {typeInterval === "twoDate" ? (
        dateInterval && setDateInterval ? (
          <DateRangePicker
            calendars={1}
            open={open}
            minDate={minDate}
            maxDate={maxDate}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={dateInterval}
            mask={"__.__.____"}
            inputFormat="dd.MM.yyyy"
            onAccept={(newValue) => {
              setDateInterval(newValue);
              navigateDate(newValue[0], newValue[1]);
            }}
            onChange={() => {}}
            renderInput={(startProps, endProps) => {
              const { inputProps: startInputProps } = startProps;
              const { inputProps: endInputProps } = endProps;

              return (
                <DateSwitcher onClick={() => setOpen(true)}>
                  <DateSwitcherText>
                    {startInputProps?.value === endInputProps?.value
                      ? startInputProps?.value
                      : getPeriodString(startInputProps?.value, endInputProps?.value)}
                  </DateSwitcherText>
                </DateSwitcher>
              );
            }}
            PopperProps={PopperProps}
          />
        ) : null
      ) : date && setDate ? (
        <DatePicker
          views={["day"]}
          value={date}
          mask={"__.__.____"}
          inputFormat="dd.MM.yyyy"
          open={open}
          minDate={minDate}
          maxDate={maxDate}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(newDate) => {
            setDate(newDate ? newDate : today);
            navigateDate(newDate ? newDate : today);
          }}
          renderInput={(params) => (
            <DateSwitcher onClick={() => setOpen(true)} ref={params.inputRef}>
              <DateSwitcherText>{params.inputProps?.value}</DateSwitcherText>
            </DateSwitcher>
          )}
          PaperProps={PaperProps}
        />
      ) : null}

      {(typeInterval === "twoDate" && selectedOneDay) || (date && setDate) ? (
        <LeftAndRightArrowsButtons
          onClick={() => (disableNext ? undefined : clickDate("right"))}
          disableNext={disableNext}
          orientation="right"
        />
      ) : null}
    </>
  );
};

export default DaySwitcher;

type PropsType = {
  dateInterval?: DateRange<Date>;
  setDateInterval?: (arg: DateRange<Date>) => void;
  date?: Date;
  maxDate?: Date;
  minDate?: Date;
  setDate?: (arg: Date) => void;
  typeInterval: TypeIntervalType;
  router?: (date: string | undefined) => string;
};
