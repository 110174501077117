import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import {
  departments,
  DepartmentsFiltersType,
  organizations,
  positions,
  PositionsFiltersType,
} from "../api/apiOrganization";

// ------------------------------ ОРГАНИЗАЦИЯ

export const organizationState = atom("");

// ------------------------------ ОСНОВНОЕ ПОДРАЗДЕЛЕНИЕ

export const mainDepartmentState = atom("");

// ------------------------------ ПОДРАЗДЕЛЕНИЕ

export const departmentState = atom("");

// ------------------------------ СПИСОК ОРГАНИЗАЦИЙ

export const organizationsAtom = atomWithQuery(() => organizationsQuery());

export const organizationsState = loadable(organizationsAtom);

export const organizationsQuery = () => ({
  queryKey: "organizationsState",
  queryFn: fetchOrganizations,
});

export const fetchOrganizations = async () => {
  const res = (await organizations.get()).data.items.map((item) => ({ ...item, id: item.code }));

  res.forEach((item) => {
    const organizationName = item.name;
    item.name = organizationName;
  });

  return res;
};

// ------------------------------ СПИСОК ОСНОВНЫХ ПОДРАЗДЕЛЕНИЙ

export const mainDepartmentsAtom = atomWithQuery((get) => {
  const organizationCode = get(organizationState);

  return mainDepartmentsQuery({ organizationCode });
});

export const mainDepartmentsState = loadable(mainDepartmentsAtom);

export const mainDepartmentsQuery = ({ organizationCode }: DepartmentsFiltersType) => ({
  queryKey: [organizationCode, "mainDepartmentsState"],
  queryFn: () => fetchMainDepartments({ organizationCode, isMainDepartment: true }),
  keepPreviousData: true,
  enabled: !!organizationCode,
});

export const fetchMainDepartments = async (props: DepartmentsFiltersType) =>
  (await departments.get(props)).data.items.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ПОДРАЗДЕЛЕНИЙ

export const departmentsAtom = atomWithQuery((get) => {
  const organizationCode = get(organizationState);
  const mainDepartmentCode = get(mainDepartmentState);

  return departmentsQuery({ organizationCode, mainDepartmentCode });
});

export const departmentsState = loadable(departmentsAtom);

export const departmentsQuery = ({
  organizationCode,
  mainDepartmentCode,
}: DepartmentsFiltersType) => {
  const args = { organizationCode, mainDepartmentCode };

  return {
    queryKey: [...Object.entries(args), "departmentsState"],
    queryFn: () => fetchDepartments(args),
    keepPreviousData: true,
    enabled: !!organizationCode,
  };
};

export const fetchDepartments = async (props: DepartmentsFiltersType) =>
  (await departments.get(props)).data.items.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ДОЛЖНОСТЕЙ

export const positionsAtom = atomWithQuery((get) => {
  const organizationCode = get(organizationState);
  const departmentCode = get(departmentState);

  return positionsQuery({ organizationCode, departmentCode });
});

export const positionsState = loadable(positionsAtom);

export const positionsQuery = ({ organizationCode, departmentCode }: PositionsFiltersType) => {
  const args = { organizationCode, departmentCode };

  return {
    queryKey: [...Object.entries(args), "positionsState"],
    queryFn: () => fetchPositions(args),
  };
};

export const fetchPositions = async (props: PositionsFiltersType) =>
  (await positions.get(props)).data.items.map((item) => ({ ...item, id: item.code }));
