import styled from "@emotion/styled";
import { button, buttonOnlyText, buttonSecondaryDefault } from "../../styles/buttonsStyles";
import { colors } from "../../styles/styles";

export const Title = styled.div`
  display: flex;
`;

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 16px;
  margin: 0 0 16px 0;
  padding: 16px 0 16px 16px;
`;

export const TitlePanel = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  color: ${colors.grayscaleAsh};
  margin: 0 0 12px 0;
`;

export const Icon = styled.div`
    display: flex;
    
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const AllOrganizationButtonContainer = styled.div`
  border-right: 2px solid ${colors.grayscaleInput};
  padding: 0 16px 0 0;
`;

export const OrganizationButtonContainer = styled.div<OrganizationButtonContainerPropsType>`
  ${button};
  ${buttonSecondaryDefault}
  ${buttonOnlyText};

  height: 40px;
  border: ${({ selected, borderColor }) => (selected ? `2px solid ${borderColor}` : "none")};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  cursor: default;
`;

export const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

export const MenuButton = styled.button`
  ${button};

  width: 328px;
  height: 56px;
  justify-content: space-between;
  border-radius: 12px;

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.grayscaleBeautifulBlack};
    margin: 0 16px;
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const SwitchContainer = styled.div<{ checked: boolean }>`
  width: 50px;
  height: 24px;
  display: flex;
  justify-content: ${({ checked }) => (checked ? "end" : "start")};
  background: ${({ checked }) => (checked ? colors.primaryDefault : colors.grayscaleLine)};
  border-radius: 99em;
  margin: 0 16px 0 0;
  padding: 2px;

  div {
    width: 20px;
    height: 20px;
    background: ${colors.white};
    border-radius: 99em;
    margin: 0;
  }
`;

export const Main = styled.main<{ needPagination?: boolean }>`
  width: 100%;
  background: ${colors.gray};
  border-top: 1px solid ${colors.grayscaleInput};
  margin: -1px 0 0 0;
  padding: ${({ needPagination }) => (needPagination ? "16px 16px 0 16px" : "16px")};
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: ${colors.white};
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.grayscaleLableOpacity77};
    border-radius: 99em;
  }
`;

type OrganizationButtonContainerPropsType = {
  selected: boolean;
  borderColor: string;
};
