import React, { FC, ReactNode } from "react";
import * as elements from "./HeaderNavigation.styles";

const HeaderNavigationBreadcrumbsNav: FC<PropsType> = (props) => {
  const { title, section } = props;

  const { BreadcrumbsNav, BreadcrumbsLink, SubTitle, Separator } = elements;

  return (
    <BreadcrumbsNav aria-label="breadcrumb">
      <BreadcrumbsLink to="/">
        Оповещения о заболеваемости<Separator>&#160;/&#160;</Separator>
      </BreadcrumbsLink>
      {section ? (
        <>
          {section.name}
          <Separator>&#160;/&#160;</Separator>
        </>
      ) : null}
      <SubTitle>{typeof title === "string" ? title : title.text}</SubTitle>
    </BreadcrumbsNav>
  );
};

export default HeaderNavigationBreadcrumbsNav;

type PropsType = {
  title: TitleType;
  section?: SectionType;
};

type TitleType = string | { text: string; element: ReactNode };

type SectionType = { name: string; rout: string };
