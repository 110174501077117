import React from "react";

const Info = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12ZM10.1631 7.64142C10.077 7.03899 10.5445 6.5 11.153 6.5H12.847C13.4556 6.5 13.923 7.03899 13.837 7.64142L13.1227 12.6414C13.0523 13.1341 12.6304 13.5 12.1327 13.5H11.8673C11.3697 13.5 10.9477 13.1341 10.8774 12.6414L10.1631 7.64142ZM13.5 15.9C13.5 16.7284 12.8284 17.4 12 17.4C11.1716 17.4 10.5 16.7284 10.5 15.9C10.5 15.0716 11.1716 14.4 12 14.4C12.8284 14.4 13.5 15.0716 13.5 15.9Z"
        fill="#A4B3C6"
      />
    </svg>
  );
};

export default Info;
