import React, { FC } from "react";
import { ListPlus } from "../../Icons";
import { Container } from "./OpenFormButton.styles";

const OpenFormButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <ListPlus />
    </Container>
  );
};

export default OpenFormButton;

type PropsType = {
  onClick: () => void;
};
