import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors } from "../../../styles/styles";

export const TitleAndButton = styled.div`
  display: flex;
`;

export const TitleLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${colors.grayscaleBeautifulBlack};
  text-decoration: none;

  div {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99em;
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};
    border-radius: 12px;
    text-decoration: none;
    color: ${colors.grayscaleBeautifulBlack};
    padding: 0 8px 0 0;

    div {
      background: ${colors.grayscaleHoverBackground};
    }
  }

  p {
    margin: 0 0 0 4px;
  }

  svg > path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
`;
