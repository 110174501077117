import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { DiseaseType, EmployeeType } from "../../../api/apiEmployees";
import { selectedRowsState } from "../../../atoms/atoms";
import { Table, TableBodyRow, TableHeadRow } from "../../../components/Table";
import { useUserSettings } from "../../../utils/useUserSettings";
import { getPeriodString } from "../../../utils/workingWithDates";
import DiseaseEditForm from "../Forms/DiseaseEditForm";
import { usePageTable } from "../hooks/useTable";

const DiseasesTable: FC<PropsType> = (props) => {
  const { employee, canEditEmployeeCard } = props;

  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const [modalData, setModalData] = useState<DiseaseType>();

  const pageTableMethods = usePageTable();
  const { diseases, diseaseIds, handleDeleteRows } = pageTableMethods;
  const { titles, titlesInit, grids, dataForTable, dataWrapper } = pageTableMethods;
  const { getUserSettings, setUserSettings } = useUserSettings();

  useEffect(() => {
    getUserSettings("employeeCard", titlesInit);

    return () => setSelectedRows([]);
  }, []);

  const handleCloseEditForm = () => setModalData(undefined);

  return (
    <>
      <Table titles={titlesInit}>
        <TableHeadRow
          titles={titles}
          titlesInit={titlesInit}
          grid={grids}
          ids={diseaseIds ?? []}
          needCheck={canEditEmployeeCard}
          needMenu={canEditEmployeeCard}
          setUserSettings={(selectedColumnNumbers) =>
            setUserSettings("employeeCard", titlesInit, selectedColumnNumbers)
          }
        />

        {dataWrapper(
          diseases &&
            diseases.map((disease) => {
              const { id, openSickListDate, closeSickListDate } = disease;

              return (
                <TableBodyRow
                  key={id}
                  id={id}
                  data={dataForTable(disease)}
                  grid={grids}
                  onOpenEditForm={(evt) => {
                    evt.stopPropagation();
                    setModalData(disease);
                  }}
                  textConfirmModal={getPeriodString(openSickListDate, closeSickListDate)}
                  onDeleteRows={handleDeleteRows}
                  needMenu={canEditEmployeeCard}
                  needCheck={canEditEmployeeCard}
                />
              );
            })
        )}

        {}
      </Table>

      {canEditEmployeeCard && modalData && employee ? (
        <DiseaseEditForm data={modalData} employee={employee} onClose={handleCloseEditForm} />
      ) : null}
    </>
  );
};

export default DiseasesTable;

type PropsType = {
  employee: EmployeeType | null;
  canEditEmployeeCard: boolean;
};
