import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Sieve: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7H20V9H4V7ZM6 11H18V13H6V11ZM16 15H8V17H16V15Z"
        fill={colors.grayscaleBeautifulBlack}
      />
    </svg>
  );
};

export default Sieve;
