import React, { FC, useEffect } from "react";
import { Info, Upload } from "../../../components/Icons";
import PopoverHover from "../../../components/PopoverHover";
import * as elements from "../ChartsForOrganizationsPage.styles";
import { usePage } from "../hooks/usePage";
import { usePanel } from "../hooks/usePanel";
import OrganizationButton from "./OrganizationButton";
import { UniversalTextButton } from "../../../components/Buttons";

const OrganizationPanel: FC = () => {
  const { userOrganizationCode, oot, osr, canViewUploadEmployeesPage } = usePage();
  const panelMethods = usePanel();
  const { organizations, oneOrganizationSelection, dataWrapper, onClickUploadButton } =
    panelMethods;
  const { anchorPopoverHoverElement, openPopoverHover, closePopoverHover } = panelMethods;

  const { AllOrganizationButtonContainer, Buttons, Container, TitlePanel, Icon } = elements;

  const userOrganization = organizations?.find(
    (organization) => organization.code === userOrganizationCode
  );

  useEffect(() => {
    if (!organizations || !organizations[0]) return;

    oneOrganizationSelection(
      !oot && !osr ? organizations[0].id : userOrganization?.id ?? organizations[0].id
    );
  }, [organizations]);

  return (
    <>
      {dataWrapper(
        <Container>
          <TitlePanel>
            Индекс заболеваемости
            <Icon
              aria-owns={!!anchorPopoverHoverElement ? "popover-hover" : undefined}
              aria-haspopup="true"
              onMouseEnter={({ currentTarget }) => openPopoverHover(currentTarget)}
              onMouseLeave={closePopoverHover}
            >
              <Info />
            </Icon>
          </TitlePanel>

          <Buttons>
            {canViewUploadEmployeesPage && (
              <UniversalTextButton
                text={""}
                iconStart={<Upload />}
                type={"secondary"}
                onClick={onClickUploadButton}
              />
            )}
            {!oot && !osr ? (
              <AllOrganizationButtonContainer>
                <OrganizationButton id="" index={null} text="Показать все" allOrganizations />
              </AllOrganizationButtonContainer>
            ) : null}

            {(!oot && !osr
              ? organizations
              : userOrganization
              ? [userOrganization]
              : undefined
            )?.map(({ id, name }, i) => (
              <OrganizationButton key={i} id={id} index={i} text={name.replaceAll('"', "")} />
            ))}
          </Buttons>

          <PopoverHover
            id={!!anchorPopoverHoverElement ? "popover-hover" : undefined}
            isOpen={!!anchorPopoverHoverElement}
            element={anchorPopoverHoverElement}
            onClose={closePopoverHover}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: 0, horizontal: -20 }}
          >
            Отношение болеющих к общему количеству персонала
          </PopoverHover>
        </Container>
      )}
    </>
  );
};

export default OrganizationPanel;
