import styled from "@emotion/styled";
import { button, buttonOnlyText, buttonOutlinedDefault } from "../../../styles/buttonsStyles";

export const Container = styled.button`
  ${button};
  ${buttonOutlinedDefault};
  ${buttonOnlyText};

  height: 44px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
`;
