import React, { FC, MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TriangleDown, TriangleUp } from "../../Icons";
import SubSectionsButtons from "../SubSectionsButtons/SubSectionsButtons";
import { dictionaries } from "../SubSectionsButtons/subSections";
import { Container, ContainerWrapper, TextAndArrow } from "./SectionsButtons.styles";
import { SectionType } from "./sections";

const SectionButtonWithSubMenu: FC<PropsType> = (props) => {
  const { roleModel, section, menuIsExpand, setMenuIsExpand } = props;
  const { subMenuIsOpen, setSubMenuIsOpen } = props;
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = props;

  const navigate = useNavigate();
  const location = useLocation();

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const canViewDictionaries = roleModel.canViewDictionaries;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  const openSubMenu = (type: string) =>
    subMenuIsOpen === type ? setSubMenuIsOpen("") : setSubMenuIsOpen(type);

  const subSections = section.name === "dictionaries" ? dictionaries(canViewDictionaries) : [];

  return (
    <ContainerWrapper
      active={
        subMenuIsOpen === ""
          ? location.pathname.includes(section.name)
          : subMenuIsOpen === section.name
      }
    >
      <Container
        isExpand={menuIsExpand}
        active={
          subMenuIsOpen === ""
            ? location.pathname.includes(section.name)
            : subMenuIsOpen === section.name
        }
        onClick={() => {
          if (!location.pathname.includes(section.name)) {
            navigate(section.rout);
          }

          !menuIsExpand ? setMenuIsExpand(true) : undefined;
          openSubMenu(section.name);
        }}
        aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(evt) =>
          openPopoverHover(
            evt,
            location.pathname.includes(section.name)
              ? subSections.find((subSection) => location.pathname.includes(subSection.rout))
                  ?.text ?? ""
              : section.text
          )
        }
        onMouseLeave={closePopoverHover}
      >
        <div className="icon">{section.icon}</div>{" "}
        {menuIsExpand ? (
          <TextAndArrow
            active={
              subMenuIsOpen === ""
                ? location.pathname.includes(section.name)
                : subMenuIsOpen === section.name
            }
          >
            <p onClick={() => openSubMenu(section.name)}>{section.text}</p>

            {subMenuIsOpen === section.name ? <TriangleUp /> : <TriangleDown />}
          </TextAndArrow>
        ) : null}
      </Container>

      <SubSectionsButtons
        roleModel={roleModel}
        section={section.name}
        menuIsExpand={menuIsExpand}
        subMenuIsOpen={subMenuIsOpen === section.name}
        setSubMenuIsOpen={setSubMenuIsOpen}
      />
    </ContainerWrapper>
  );
};

export default SectionButtonWithSubMenu;

type PropsType = {
  roleModel: Record<string, boolean>;
  section: SectionType;
  menuIsExpand: boolean;
  setMenuIsExpand: (evt: boolean) => void;
  subMenuIsOpen: string;
  setSubMenuIsOpen: (arg: string) => void;
  popoverHoverIsOpen: boolean;
  openPopoverHover: (evt: MouseEvent<HTMLElement>, text: string) => void;
  closePopoverHover: () => void;
};
