import { DateRange } from "@mui/lab";
import {
  addQuarters,
  endOfQuarter,
  getQuarter,
  getYear,
  startOfQuarter,
  subQuarters,
} from "date-fns";
import React, { FC } from "react";
import { minusOneQuarter, plusOneQuarter, today } from "../../utils/workingWithDates";
import { LeftAndRightArrowsButtons } from "../Buttons";
import { DateSwitcher, DateSwitcherText, QuartersPicker } from "./DateSwitcher.styles";

const QuarterSwitcher: FC<PropsType> = (props) => {
  const { dateInterval, setDateInterval, minDate, maxDate } = props;

  const date = dateInterval[0];

  const disableBack = !!date && subQuarters(date!, 1) < minDate;
  const disableNext = !!date && addQuarters(date!, 1) > maxDate;

  return (
    <>
      <LeftAndRightArrowsButtons
        onClick={() =>
          disableBack
            ? undefined
            : setDateInterval([
                startOfQuarter(minusOneQuarter(date!)),
                endOfQuarter(minusOneQuarter(date!)),
              ])
        }
        disableBack={disableBack}
        orientation="left"
      />

      <QuartersPicker>
        <DateSwitcher>
          {getYear(date!) !== getYear(today) ? (
            <>
              <DateSwitcherText>{`${getQuarter(date!)} кв.`}</DateSwitcherText>
              <DateSwitcherText>{`${getYear(date!)} года`}</DateSwitcherText>
            </>
          ) : (
            <DateSwitcherText>{`${getQuarter(date!)} квартал`}</DateSwitcherText>
          )}
        </DateSwitcher>
      </QuartersPicker>

      <LeftAndRightArrowsButtons
        onClick={() =>
          disableNext
            ? undefined
            : setDateInterval([
                startOfQuarter(plusOneQuarter(date!)),
                endOfQuarter(plusOneQuarter(date!)),
              ])
        }
        disableNext={disableNext}
        orientation="right"
      />
    </>
  );
};

export default QuarterSwitcher;

type PropsType = {
  dateInterval: DateRange<Date>;
  setDateInterval: (arg: DateRange<Date>) => void;
  minDate: Date;
  maxDate: Date;
};
