import Keycloak from "keycloak-js";
import { backendURI } from "./utils/backendURI";

const keycloakInstance = Keycloak({
  url: process.env.REACT_APP_MR_URL
    ? "http://medtech-keycloak:9000/auth/"
    : `https://auth.${backendURI()}/auth/`,
  realm: process.env.REACT_APP_MR_URL ? "medtech-realm" : "medtech-keycloak",
  clientId: "web-client",
});

export default keycloakInstance;
