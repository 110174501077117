import { statistics } from "../../../api/apiStatistics";
import { FormatDateType } from "../../../api/types";
import { parseDate } from "../../../utils/workingWithDates";

export const fetchStatsForOrganizations = async (props: QueryType) => {
  const { organizationCodes, ...propsWithoutOrganizationCodes } = props;

  const resPromises = organizationCodes.map(async (organizationCode) => {
    const args = { ...propsWithoutOrganizationCodes, organizationCode };

    const res = (await statistics.getOrganizationStat(args)).data;

    const data = Object.entries(res)
      .map(([date, morbidity]) => ({
        date: parseDate(date),
        morbidity,
      }))
      .sort((a, b) => a.date.getTime() - b.date.getTime());

    return {
      organization: organizationCode,
      data: data,
    };
  });

  return Promise.all(resPromises);
};

type QueryType = {
  organizationCodes: string[];
  startDate: FormatDateType;
  endDate: FormatDateType;
  disabilityId?: string;
  diseaseClassId?: string;
};
