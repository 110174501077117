import { useAtom } from "jotai";
import React, { FC, ReactNode, SetStateAction } from "react";
import { selectedRowsState } from "../../../atoms/atoms";
import { UniversalTextButton } from "../../Buttons";
import { LeftButtons } from "./HeaderNavigation.styles";

const HeaderNavigationLeftButtons: FC<PropsType> = (props) => {
  const { buttonsStart, buttonsCenter, buttonsEnd } = props;
  const { needAddButton, needDeleteButton, openDeleteConfirmModal } = props;

  const [selectedRows] = useAtom(selectedRowsState);

  // ------------------------------ < ВЫЗОВ ФОРМЫ ДОБАВЛЕНИЯ ДАННЫХ

  const openAddForm = () => {
    if (!needAddButton || !needAddButton.onClick) return;

    needAddButton.onClick(true);
  };

  // ------------------------------ ВЫЗОВ ФОРМЫ ДОБАВЛЕНИЯ ДАННЫХ >

  return (
    <LeftButtons>
      {buttonsStart}

      {needAddButton ? (
        <UniversalTextButton
          text={needAddButton.text}
          type={needAddButton.type}
          enabled={!needAddButton.disabled}
          onClick={openAddForm}
        />
      ) : null}

      {buttonsCenter}

      {needDeleteButton ? (
        <UniversalTextButton
          text={needDeleteButton.text}
          type={needDeleteButton.type}
          enabled={selectedRows && selectedRows.length !== 0 ? true : false}
          onClick={openDeleteConfirmModal}
        />
      ) : null}

      {buttonsEnd}
    </LeftButtons>
  );
};

export default HeaderNavigationLeftButtons;

type PropsType = {
  buttonsStart?: ReactNode;
  buttonsCenter?: ReactNode;
  buttonsEnd?: ReactNode;
  needAddButton?: ButtonType & { onClick?: (arg: SetStateAction<boolean>) => void };
  needDeleteButton?: ButtonType & { onClick?: () => void };
  openDeleteConfirmModal: () => void;
};

type ButtonType = {
  text: string;
  type?: "primary" | "secondary" | undefined;
  disabled?: boolean;
};
