import styled from "@emotion/styled";
import { button, buttonOnlyIcon, buttonTextDefault } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button`
  ${button};
  ${buttonOnlyIcon};
  ${buttonTextDefault};

  padding: 0;

  & > svg > path {
    fill: ${colors.grayscaleIcons};
  }
`;
