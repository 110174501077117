import styled from "@emotion/styled";
import { colors, overflowOneLine } from "../../styles/styles";

export const TableWrapper = styled.div<TableWrapperType>`
  width: 100%;
  height: 100%;
  max-height: ${({ needPagination, needScroll, panelHeight }) =>
    needPagination || needScroll ? "100%" : `calc(100% - ${panelHeight})`};
  position: relative;
  overflow-x: auto;
  overflow-y: overlay;
  border-radius: 16px 16px 0 0;

  &::-webkit-scrollbar {
    background: ${({ needPagination }) =>
      needPagination ? colors.grayscaleLableOpacityA0 : colors.white};
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ needPagination }) =>
      needPagination ? colors.whiteOpacity : colors.grayscaleLableOpacity77};
    border-radius: 99em;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;

export const Container = styled.div<{ needPagination: boolean; needScroll: boolean }>`
  min-width: calc(1024px - 73px - 16px * 2);
  // (73px) - ширина левой панели
  // (16px * 2) - 2 паддинга компоненты Main
  width: 100%;
  min-height: ${({ needPagination, needScroll }) => (needPagination || needScroll ? "100%" : "")};
  background: ${colors.white};
  border-radius: 16px 16px 0 0;
  padding: ${({ needPagination, needScroll }) =>
    needPagination ? "0 0 106px 0" : needScroll ? "0 0 140px 0" : "0"};
`;

export const TitlesList = styled.div<{ isDraggingOver?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: 8px;
  margin: 0 8px 16px 8px;

  div {
    display: flex;
    align-items: center;
  }

  p {
    ${overflowOneLine};

    min-width: 200px;
    max-width: 300px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: ${colors.grayscaleAsh};
    margin: 0 0 0 12px;
  }
`;

type TableWrapperType = {
  needPagination: boolean;
  needScroll: boolean;
  panelHeight?: string;
};
