import styled from "@emotion/styled";
import { TextField, TextFieldProps } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};
  margin: 0 0 16px 0;

  * {
    cursor: inherit !important;
  }
`;

export const Input = styled(TextField)<InputProps>`
  width: 100%;
  height: ${({ multiline }) => (multiline ? "150px" : "56px")};
  background: ${({ haserror }) => (haserror ? colors.redBackground : colors.grayscaleInput)};
  border: none;
  border-radius: 16px;
  outline: none;

  .MuiInputLabel-shrink,
  .MuiInputLabel-root {
    max-width: 100%;
    left: 16px;
    top: 6px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: ${colors.grayscaleLable};
    transform: none;
    margin: 0;

    &.Mui-focused {
      color: ${colors.grayscaleLable};
    }
  }

  .MuiOutlinedInput {
    &-notchedOutline {
      border-radius: 16px;
      background: ${colors.white};
      border-color: ${colors.grayscaleInput} !important;
    }
    &-input.Mui-disabled {
      -webkit-text-fill-color: ${colors.grayscaleBeautifulBlack};
      z-index: 1;
    }
  }

  .MuiFilledInput-root {
    background: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${colors.grayscaleAsh};

    &.Mui-focused {
      background: none;
    }

    &.MuiFilledInput-root {
      ${({ multiline }) => (multiline ? "overflow-y: scroll; margin: 16px; padding: 0;" : "")};

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.grayscaleLine};
        border-radius: 99em;
      }

      &.Mui-disabled {
        background: ${colors.transparent};
        &:before {
          border-bottom-style: none;
        }
      }

      input {
        margin: ${({ haslabel }) => (haslabel ? "22px 16px 8px 16px" : "16px")};
        padding: 0;

        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    textarea::placeholder,
    input::placeholder {
      opacity: 1;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: ${colors.grayscalePlaceholder};
      margin: 0;
    }

    &:hover {
      background: none;
    }

    &:hover:not(.Mui-disabled):before,
    &:after,
    &:before {
      border: none;
    }
  }

  label[data-shrink="false"]
    + .MuiInputBase-formControl
    .css-10botns-MuiInputBase-input-MuiFilledInput-input::-webkit-input-placeholder {
    opacity: 1 !important;
  }
` as (props: TextFieldProps & { haslabel: number; haserror: number }) => JSX.Element;

export const Error = styled.p<{ multiline: boolean }>`
  position: absolute;
  top: ${({ multiline }) => (multiline ? "50px" : "56px")};
  left: 16px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${colors.redDefault};
`;

type InputProps = {
  multiline: boolean;
  haslabel: number;
  haserror: number;
};
