import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { backendURI } from "./backendURI";

export const useCachingKeycloak = () => {
  const { keycloak } = useKeycloak();
  const [hasToken, setHasToken] = useState(false);
  const [initialized, setInitialized] = useState<false | "init" | "refresh">(false);
  const code = new URLSearchParams(location.search).get("code");

  const cacheToken = getToken("token");
  const cacheKcToken = getToken("kc_token");

  const now = () => new Date().getTime() / 1000;

  // ------------------------------ ПЕРВИЧНАЯ ИНИЦИАЛИЗАЦИЯ КЕША
  useEffect(() => {
    if (cacheToken && cacheKcToken) {
      const cacheCode = localStorage.getItem("code");
      if (code && cacheCode !== code) {
        removeKeys();
        localStorage.setItem("code", code);
        setInitialized("init");
      } else if (cacheToken.expiresIn > now() && cacheKcToken.expiresIn > now()) {
        setHasToken(true);
      } else if (
        (cacheToken.expiresIn < now() && cacheKcToken.refreshExpiresIn > now()) ||
        (cacheKcToken.expiresIn < now() && cacheKcToken.refreshExpiresIn > now())
      ) {
        setInitialized("refresh");
      } else {
        removeKeys();
        setInitialized("init");
      }
    } else {
      setInitialized("init");
    }
  }, []);

  // ------------------------------ ОБРАБОТКА KEYCLOAK ТОКЕНА
  useEffect(() => {
    if (keycloak.authenticated && keycloak.token)
      if (initialized) {
        const keycloakToken: CachingTokenType = {
          token: keycloak.token ?? "",
          refresh: keycloak.refreshToken ?? "",
          expiresIn: keycloak.idTokenParsed?.exp ?? 0,
          refreshExpiresIn: keycloak.refreshTokenParsed?.exp ?? 0,
        };
        localStorage.setItem("kc_token", JSON.stringify(keycloakToken));
        loadToken();
      } else if (cacheToken && cacheKcToken) {
        setTimeout(() => {
          //todoDa: в идеале тут нужно сделать что-то на подобии
          // keycloak.idTokenParsed?.exp = cacheKcToken.expiresIn
          // но, ни одна из установок времени таким способом не работает, нужно искать другие пути
          if (keycloak.onTokenExpired) keycloak.onTokenExpired();
        }, (cacheKcToken.expiresIn - now()) * 1000);
      }
  }, [keycloak.authenticated, keycloak.token, initialized]);

  // ------------------------------ ОБНОВЛЕНИЕ ТОКЕНА
  keycloak.onTokenExpired = () => {
    const cacheKc = getToken("kc_token");

    if (cacheKc && cacheKc.refreshExpiresIn > now()) {
      keycloak.refreshToken = cacheKc.refresh;
      keycloak
        .updateToken(-1)
        .then(() => {
          setInitialized("refresh");
        })
        .catch(() => {
          removeKeys();
        });
    } else {
      removeKeys();
    }
  };

  // ------------------------------ ОБРАБОТКА LIFERAY ТОКЕНА
  const loadToken = () => {
    if (keycloak.authenticated) {
      const clientId = "id-a3512ba2-a04a-a31a-e09b-9be0b79b433";
      const clientSecret = "secret-2c91f1ec-7796-bc4c-6c7e-f5c5616ed31b";
      const redirectURI = `${window.location.origin}/index.html`;
      const cacheToken = getToken("token");

      if (initialized === "refresh") {
        if (!cacheToken || (cacheToken && cacheToken?.expiresIn < now())) {
          setInitialized(false);
          removeKeys();
          return;
        }
      } else if (initialized === "init" && code) {
        localStorage.setItem("code", code);
      }

      const body =
        initialized === "init"
          ? `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectURI}`
          : `client_id=${clientId}&client_secret=${clientSecret}&grant_type=refresh_token&refresh_token=${
              cacheToken?.refresh ?? ""
            }`;

      axios(
        process.env.REACT_APP_MR_URL
          ? "http://localhost:8080/o/oauth2/token"
          : `https://${backendURI()}/o/oauth2/token`,
        {
          method: "POST",
          data: body,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((result) => {
          const { access_token, expires_in, refresh_token } = result.data;
          const liferayToken: CachingTokenType = {
            token: access_token,
            refresh: refresh_token,
            expiresIn: new Date().getTime() / 1000 + expires_in,
            refreshExpiresIn: 0,
          };
          localStorage.setItem("token", JSON.stringify(liferayToken));

          setHasToken(true);
        })
        .catch((e) => console.error(e));
      setInitialized(false);
    }
  };

  return { authenticated: keycloak.authenticated && hasToken };
};

const removeKeys = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("kc_token");
};

export const getToken = (name: string) => {
  const token = localStorage.getItem(name);
  if (token && !token.includes("expiresIn")) {
    removeKeys();
  } else if (token) return JSON.parse(token) as CachingTokenType;
  return null;
};

export type CachingTokenType = {
  token: string;
  refresh: string;
  expiresIn: number;
  refreshExpiresIn: number;
};
