import React, { FC } from "react";
import { sexOptions } from "../../../constants/options";
import { getFullName } from "../../../utils/workingWithNames";
import * as elements from "../EmployeeCardPage.styles";
import { usePanel } from "../hooks/usePanel";
import EmployeeCardItem from "./EmployeeCardItem";

const EmployeeCardPanel: FC = () => {
  const panelMethods = usePanel();
  const { employee, employeeDataForPanel, dataWrapper, diseased, dead } = panelMethods;

  const { Container, Data, Name } = elements;
  const { NameAndSex, NameAndSexAndStatus, Sex, Status } = elements;

  return (
    <>
      {dataWrapper(
        <Container>
          <NameAndSexAndStatus>
            <NameAndSex>
              <Name>{getFullName({ nameParts: employee })}</Name>
              <Sex>{sexOptions.find(({ id }) => id === employee?.sex)?.name[0] || null}</Sex>
            </NameAndSex>

            {employee?.employeeStatus ? (
              <Status diseased={diseased}>
                {diseased ? "Болеет" : dead ? "Умер" : "Не болеет"}
              </Status>
            ) : null}
          </NameAndSexAndStatus>

          <Data hasMenuButton={false}>
            {employeeDataForPanel().map((datum, i) => (
              <EmployeeCardItem key={i} i={i} datum={datum} />
            ))}
          </Data>
        </Container>
      )}
    </>
  );
};

export default EmployeeCardPanel;
