import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors } from "../../styles/styles";

export const LinkToCard = styled(Link)`
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.grayscaleBody};
  text-decoration: none;

  :hover {
    opacity: 0.6;
    color: ${colors.grayscalePlaceholder};
    text-decoration: none;
  }
`;
