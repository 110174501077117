import { useAtom } from "jotai";
import React, { ReactNode } from "react";
import * as Yup from "yup";
import { EntitieType, LittleOtherType, countriesApi } from "../../../api/apiDictionaries";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { searchQueryState, selectedRowsState } from "../../../atoms/atoms";
import { ErrorText, InfoText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Skeleton from "../../../components/Skeleton";
import { useTable } from "../../../components/Table/useTable";
import { long, notJustSpaces, required } from "../../../constants/forValidationSchems";
import { unknownErrorText } from "../../../utils/getErrorMessage";
import { useQuery } from "../../../utils/useQuery";
import {
  countriesState,
  pageState,
  quantityInOnePageState,
  totalCountCountriesState,
} from "./utils/atoms";

export const useCountryDictionary = () => {
  const [countriesData] = useAtom(countriesState);
  const [totalCount] = useAtom(totalCountCountriesState);

  const [currentUser] = useAtom(currentUserState);
  const [selectedRows] = useAtom(selectedRowsState);
  const [page, setPage] = useAtom(pageState);
  const [quantity, setQuantity] = useAtom(quantityInOnePageState);
  const [query] = useAtom(searchQueryState);

  // ------------------------------ КЛЮЧИ ЗАПРОСА

  const queryKey = [page * quantity, quantity, query || undefined, "countriesState"];

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Страны";

  const addFormName = "countryAddForm";
  const addFormTitle = "Страна";

  const editFormName = "countryEditForm";
  const editFormTitle = "Изменение страны";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const canViewDictionaries = hasUser && currentUser.data.roleModel.canViewDictionaries;
  const canEditDictionaries = hasUser && currentUser.data.roleModel.canEditDictionaries;

  // ------------------------------ ДАННЫЕ

  const countries = countriesData.state === "hasData" ? countriesData.data : [];

  const dataWrapper = (component: ReactNode) =>
    countriesData.state === "loading" ? (
      <Skeleton forElement="table" grids={grids} canEdit={canEditDictionaries} />
    ) : countriesData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : countries.length === 0 ? (
      <InfoText>Нет данных</InfoText>
    ) : (
      component
    );

  // ------------------------------ ДАННЫЕ - ПАГИНАЦИЯ

  const needPagination =
    totalCount.state === "hasData" && totalCount.data > 1
      ? {
          totalCount: totalCount.data,
          setPage: setPage,
          quantity: quantity,
          setQuantity: setQuantity,
        }
      : undefined;

  // ------------------------------ ДАННЫЕ - IDS

  const countryIds = countries.map((datum) => datum.id);

  // ------------------------------ ДАННЫЕ - УДАЛЕНИЕ

  const deletion = useQuery().delete({ queryKey, api: countriesApi });

  const handleDeleteRows = async () => await deletion.mutateAsync(selectedRows);

  // ------------------------------ ТАБЛИЦА

  const { title, grid, formatData, columnsNames } = useTable();

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "Наименование", name: "name" },
    { id: 2, title: "Комментарий", name: "comment" },
  ];

  const titles = title(titlesInit);

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridInit = [
    ...(canEditDictionaries ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "1fr" },
    { titleId: 2, size: "1fr" },
    ...(canEditDictionaries ? [{ titleId: null, size: "56px" }] : []),
  ];

  const grids = grid(gridInit);

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (datum: EntitieType<LittleOtherType>) => ({
    name: datum.name,
    comment: datum.comment ? (datum.comment === "" ? "—" : datum.comment) : "—",
  });

  const dataForTable = (datum: EntitieType<LittleOtherType>) =>
    formatData(object(datum) ?? {}, columnsNames(titlesInit));

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    name: "",
    comment: "",
  };

  const initialValuesEditForm = (datum: EntitieType<LittleOtherType>) => ({
    name: datum.name,
    comment: datum.comment ?? "",
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/\S/, notJustSpaces).max(255, long).required(required),
    comment: Yup.string().matches(/\S/, notJustSpaces),
  });

  return {
    titlePage,
    addFormName,
    addFormTitle,
    editFormName,
    editFormTitle,

    queryKey,

    canViewDictionaries,
    canEditDictionaries,

    needPagination,
    countries,
    dataWrapper,
    countryIds,
    handleDeleteRows,

    titles,
    titlesInit,
    grids,
    dataForTable,

    initialValuesAddForm,
    initialValuesEditForm,
    validationSchema,
  };
};
