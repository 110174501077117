import styled from "@emotion/styled";
import { Pagination, Select } from "@mui/material";
import { colors, overflowOneLine, shadow } from "../../styles/styles";

export const Overlay = styled.div<{
  isWidthLessThanMinimum: boolean;
  menuIsExpand: boolean;
}>`
  width: calc(100% - ${({menuIsExpand}) => menuIsExpand ? "332px" : "104px"});
  position: absolute;
  right: 16px;
  bottom: ${({ isWidthLessThanMinimum }) =>
    isWidthLessThanMinimum ? "16px" : "0"};
`;

export const Blur = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    ${colors.transparent},
    ${colors.grayscaleLableOpacityA0}
  );
  -webkit-mask: linear-gradient(${colors.transparent}, ${colors.black});
  backdrop-filter: blur(2px);
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 24px 0;
`;

export const PaginationItem = styled(Pagination)`
  background: ${colors.white};
  border: 1px solid ${colors.grayscaleInput};
  border-radius: 16px;
  color: ${colors.grayscaleBeautifulBlack};
  padding: 8px 16px;
  z-index: 1;

  .MuiButtonBase-root:hover {
    background-color: ${colors.grayscaleInput};
  }

  .MuiButtonBase-root.Mui-selected {
    background: ${colors.grayscaleLine};
  }

  .MuiPaginationItem-previousNext {
    & > svg > path {
      fill: ${colors.grayscaleLable};
    }

    &.Mui-disabled {
      & > svg > path {
        fill: ${colors.grayscaleIcons};
      }
    }

    :hover {
      & > svg > path {
        fill: ${colors.grayscaleBeautifulBlack};
      }
    }
  }
` as typeof Pagination;

export const QuantitySelection = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.grayscaleInput};
  border-radius: 16px;
  color: ${colors.grayscaleBeautifulBlack};
  padding: 8px 16px;
  z-index: 1;
`;

export const Input = styled(Select)`
  width: 100px;
  height: 40px;
  background: ${colors.transparent};
  border-radius: 16px;
  cursor: pointer;
  padding: 8px 0 8px 16px;

  .MuiSelect-select {
    margin: 0;
    padding: 0 !important;
    z-index: 1;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    padding: 0;
  }
`;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscaleAsh};
`;

export const MenuProps = {
  sx: {
    height: "100%",

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: "8px",

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": {
        width: 8,
      },

      "&::-webkit-scrollbar-thumb": {
        background: colors.grayscaleLine,
        borderRadius: "99em",
      },
    },
    ".MuiPopover-paper": { width: 130 },
  },
  anchorOrigin: {
    vertical: 0,
    horizontal: -30,
  },
  transformOrigin: {
    vertical: 300,
    horizontal: 0,
  },
};
