import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<{
  menuModalIsOpen: boolean;
  fromCard: boolean;
}>`
  ${button};

  width: 40px;
  height: 40px;
  ${({ menuModalIsOpen }) =>
    menuModalIsOpen ? `border: 1px solid ${colors.grayscaleIcons}` : ""};
  margin: ${({ fromCard }) => (fromCard ? "0 auto" : "4px auto 0 auto")};

  & > svg > path {
    fill: ${colors.grayscaleIcons};
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};

    & > svg > path {
      fill: ${colors.grayscaleLable};
    }
  }
`;
