import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import React, { FC } from "react";
import { EmployeeType, diseasesApi, employeesApi } from "../../../api/apiEmployees";
import { file } from "../../../api/apiFile";
import RightModal from "../../../components/RightModal";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { useQuery } from "../../../utils/useQuery";
import { FiledsType, useForms } from "../hooks/useForms";
import { usePage } from "../hooks/usePage";
import { usePageTable } from "../hooks/useTable";
import DiseaseFields from "./DiseaseFields";

const DiseaseAddForm: FC<PropsType> = (props) => {
  const { employee, onClose } = props;

  const { addFormName, addFormTitle } = usePage();
  const formsMethods = useForms(employee);
  const { diseaseValuesObject, employeeValuesObject } = formsMethods;
  const { initialValuesAddForm, validationSchema } = formsMethods;
  const pageTableMethods = usePageTable();
  const { queryKey } = pageTableMethods;

  const createDisease = useQuery().create({
    queryKey,
    api: diseasesApi(),
  });

  const createEmployee = useQuery().create({
    queryKey,
    api: employeesApi(),
  });

  const error = getErrorMessage(createDisease.error);
  const isLoading = createDisease.isLoading || createEmployee.isLoading;

  return (
    <RightModal title={addFormTitle} isOpen onClose={onClose} needBlur>
      <Formik
        initialValues={initialValuesAddForm}
        validate={(values) => {
          try {
            validateYupSchema(values, validationSchema(values), true);
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={async (values: FiledsType) => {
          try {
            const employeeObject = employeeValuesObject(values);

            const res = await createEmployee.mutateAsync({ data: employeeObject });

            const mrId = (await res).data.mrId;

            let fileRef = "";

            if (values.document) {
              const formData = new FormData();
              formData.append("file", values.document!);
              fileRef = (await file.upload(formData)).data.fileRef;
            }

            const diseaseValues = diseaseValuesObject(
              { ...values, document: fileRef },
              undefined,
              mrId
            );

            if (!diseaseValues) return;

            await createDisease.mutateAsync({ data: diseaseValues });

            onClose();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ handleSubmit }) => (
          <DiseaseFields
            formName={addFormName}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            error={error}
            onClose={onClose}
          />
        )}
      </Formik>
    </RightModal>
  );
};

export default DiseaseAddForm;

type PropsType = {
  employee: EmployeeType;
  onClose: () => void;
};
