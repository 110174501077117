import { useAtom } from "jotai";
import React, { useState } from "react";
import { STATISTICS, STATISTICS_CHARTS_FOR_EMPLOYEES } from "../../../api/routes";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { NavigateButton } from "../../../components/Buttons/";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import HeaderSearchInput from "../../../components/Header/HeaderSearchInput";
import { pageState } from "../../ChartsForEmployeesPage/utils/atoms";
import { employeeState } from "../utils/atoms";

export const usePage = () => {
  // ------------------------------ АТОМЫ

  const [employeeData] = useAtom(employeeState);

  const [currentUser] = useAtom(currentUserState);

  const [pageForBack] = useAtom(pageState);

  // ------------------------------ СТЕЙТЫ

  const [visibleSearch, setVisibleSearch] = useState(false);
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Карточка работника";

  const addFormName = "diseaseAddForm";
  const addFormTitle = "Данные о заболевании";

  const editFormName = "diseaseEditForm";
  const editFormTitle = "Изменение данных о заболевании";

  const employeeEditFormName = "employeeEditForm";
  const employeeEditFormTitle = "Изменение данных работника";

  // ------------------------------ ДАННЫЕ - РАБОТНИК

  const employee = employeeData.state === "hasData" ? employeeData.data : null;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const canViewEmployeeCard = hasUser && currentUser.data.roleModel.canViewEmployeeCard;
  const canEditEmployeeCard =
    hasUser && currentUser.data.roleModel.canEditEmployeeCard && !employee?.deathDate;

  // ------------------------------ ШАПКА СТРАНИЦЫ - НАВИГАЦИЯ

  const handleCloseAddForm = () => setAddFormIsOpen(false);

  const navigation = (handleDeleteRows: () => Promise<void> | Promise<void[]>) => (
    <HeaderNavigation
      title={{
        text: titlePage,
        element: (
          <NavigateButton
            title={titlePage}
            link={STATISTICS_CHARTS_FOR_EMPLOYEES(
              employee?.position?.department.mainDepartment,
              pageForBack === 0 ? undefined : pageForBack + 1
            )}
          />
        ),
      }}
      needAddButton={
        canEditEmployeeCard ? { text: "Добавить", onClick: setAddFormIsOpen } : undefined
      }
      needDeleteButton={
        canEditEmployeeCard ? { text: "Удалить", onClick: handleDeleteRows } : undefined
      }
      needSearchButton={{ visibleSearch, setVisibleSearch }}
      section={{ name: "Статистика заболеваемости", rout: STATISTICS }}
    />
  );

  // ------------------------------ ШАПКА СТРАНИЦЫ - ПОИСК

  const searchInput = visibleSearch ? <HeaderSearchInput /> : null;

  return {
    employee,
    employeeData,

    titlePage,
    addFormName,
    addFormTitle,
    editFormName,
    editFormTitle,
    employeeEditFormName,
    employeeEditFormTitle,

    canViewEmployeeCard,
    canEditEmployeeCard,

    navigation,
    searchInput,
    visibleSearch,
    setVisibleSearch,
    handleCloseAddForm,
    addFormIsOpen,
    setAddFormIsOpen,
  };
};
