import React, { FC, MouseEvent, ReactNode, useState } from "react";
import { TableMenuButton, TableMenuItemButton } from "../../Buttons";
import Check from "../../Check";
import ConfirmModal from "../../ConfirmModal";
import { Pencil, TrashBin } from "../../Icons";
import PopoverModal from "../../PopoverModal";
import { useTable } from "../useTable";
import { Container, MenuButtons, TableBodyItem } from "./TableBodyRow.styles";
import TableBodyRowItem from "./TableBodyRowItem";

const TableBodyRow: FC<PropsType> = (props) => {
  const { id, data, grid, onOpenEditForm, onDeleteRows } = props;
  const { titleConfirmModal, textConfirmModal, needBackground = false } = props;
  const { needCheck = false, needMenu = false, hideMenu = false, isExclude = false } = props;

  const { selectingRows, isRowSelected, setSelectedRows } = useTable();

  // ------------------------------ < ВЫЗОВ КОНТЕКСТНОГО МЕНЮ

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const openMenuModal = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorElement(evt.currentTarget);
  };

  const closeMenuModal = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorElement(null);
  };

  const menuModalIsOpen = !!anchorElement;
  const idMenuModal = menuModalIsOpen ? "menu-modal" : "";

  // ------------------------------ ВЫЗОВ КОНТЕКСТНОГО МЕНЮ >

  // ------------------------------ < ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const openDeleteConfirmModal = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    closeMenuModal(evt);
    setOpenConfirmModal(true);
    setSelectedRows([id]);
  };

  const closeDeleteConfirmModal = () => setOpenConfirmModal(false);

  // ------------------------------ ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ >

  return (
    <>
      <Container grid={grid} needBackground={needBackground}>
        {needCheck ? (
          <TableBodyItem
            className="check"
            onClick={needCheck && onDeleteRows ? () => selectingRows(id) : undefined}
          >
            {onDeleteRows ? <Check checked={isRowSelected(id)} /> : null}
          </TableBodyItem>
        ) : null}

        {Object.values(data).map((value, i) => (
          <TableBodyRowItem data={value} key={i} />
        ))}

        {needMenu ? (
          hideMenu ? (
            <TableBodyItem />
          ) : (
            <TableMenuButton menuModalIsOpen={menuModalIsOpen} onClick={openMenuModal} />
          )
        ) : null}
      </Container>

      <PopoverModal
        id={idMenuModal}
        isOpen={menuModalIsOpen}
        element={anchorElement}
        onClose={closeMenuModal}
      >
        <MenuButtons>
          {onOpenEditForm ? (
            <TableMenuItemButton
              text={isExclude ? "Исключить" : "Изменить"}
              icon={<Pencil />}
              onClick={(evt) => {
                setAnchorElement(null);
                onOpenEditForm(evt);
              }}
            />
          ) : null}

          {onDeleteRows ? (
            <TableMenuItemButton
              text="Удалить"
              icon={<TrashBin />}
              onClick={openDeleteConfirmModal}
            />
          ) : null}
        </MenuButtons>
      </PopoverModal>

      <ConfirmModal
        title={titleConfirmModal}
        text={textConfirmModal || ""}
        isOpen={openConfirmModal}
        onClose={closeDeleteConfirmModal}
        onDelete={onDeleteRows ?? (() => console.info("Невозможно удалить"))}
      />
    </>
  );
};

export default TableBodyRow;

type PropsType = {
  id: string;
  data: Record<string, ReactNode>;
  grid: string;
  onOpenEditForm?: (arg: MouseEvent<HTMLButtonElement>) => void;
  titleConfirmModal?: string;
  textConfirmModal?: string | number;
  onDeleteRows?: () => void;
  needMenu?: boolean;
  needCheck?: boolean;
  needBackground?: boolean;
  hideMenu?: boolean;
  isExclude?: boolean;
};
