import React, { FC, ReactNode } from "react";
import { Container } from "./Header.styles";

const Header: FC<PropsType> = (props) => {
  const { navigation, searchInput, pagesSwitcher } = props;

  return (
    <Container>
      {navigation}
      {searchInput ?? null}
      {pagesSwitcher ?? null}
    </Container>
  );
};

export default Header;

type PropsType = {
  navigation: ReactNode;
  searchInput?: ReactNode;
  pagesSwitcher?: ReactNode;
};
