import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors, overflowOneLine } from "../../../styles/styles";

export const ContainerWrapper = styled.div<{ active?: boolean }>`
  position: relative;

  &::after {
    content: "";
    width: 4px;
    height: 40px;
    position: absolute;
    top: 0;
    display: ${({ active }) => (active ? "block" : "none")};
    left: -16px;
    background: ${colors.grayscaleAsh};
    border-radius: 0 99em 99em 0;
    z-index: 5;
  }
`;

export const Container = styled.button<{ isExpand: boolean; active?: boolean }>`
  ${button};
  ${({ isExpand }) => (isExpand ? "justify-content: flex-start" : undefined)};

  height: 40px;
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  margin: 0 0 8px 0;

  div.icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: ${({ active }) =>
      active ? colors.grayscaleAsh : colors.grayscalePlaceholder};
    ${overflowOneLine};
    margin: 0 0 0 12px;
  }

  svg > path {
    fill: ${({ active }) =>
      active ? colors.grayscaleAsh : colors.grayscalePlaceholder};
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};

    p {
      color: ${colors.grayscaleAsh};
    }

    svg > path {
      fill: ${colors.grayscaleAsh};
    }
  }

  &::after {
    content: "";
    width: 4px;
    height: 40px;
    position: absolute;
    top: 0;
    display: ${({ active }) => (active ? "block" : "none")};
    left: -16px;
    background: ${colors.grayscaleAsh};
    border-radius: 0 99em 99em 0;
    z-index: 5;
  }
`;

export const TextAndArrow = styled.div<{ active?: boolean }>`
  width: 216px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px 0 0;

  svg > path {
    fill: ${({ active }) =>
      active ? colors.grayscaleAsh : colors.grayscalePlaceholder};
  }
`;
