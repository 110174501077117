import React, { FC } from "react";
import { EmployeeType } from "../../../api/apiEmployees";
import { monthNumbers } from "../../../constants/dates";
import * as elements from "../ChartsForEmployeesPage.styles";
import { useCharts } from "../hooks/useCharts";
import EmployeeMorbidityDiagram from "./EmployeeMorbidityDiagram";

const EmployeeMorbidityCard: FC<PropsType> = (props) => {
  const { employee } = props;

  const chartsMethods = useCharts(employee);
  const { morbiditiesInfo, getSickDaysOfYear, getSickDaysOfMonth } = chartsMethods;
  const { popoverId, anchorElement, openPopoverHover, closePopoverHover } = chartsMethods;

  const { MorbidityCard, History, Title, DateText, SubText, Diagram } = elements;

  return (
    <MorbidityCard>
      <History>
        <Title>История</Title>
        <DateText>{getSickDaysOfYear()}</DateText>
        <SubText>Дней болезни</SubText>
      </History>

      <Diagram>
        {monthNumbers.map((monthNumber) => (
          <EmployeeMorbidityDiagram
            key={monthNumber}
            monthNumber={monthNumber}
            morbiditiesInfo={morbiditiesInfo}
            popoverId={popoverId}
            anchorElement={anchorElement}
            openPopoverHover={openPopoverHover}
            closePopoverHover={closePopoverHover}
            getSickDaysOfMonth={getSickDaysOfMonth}
          />
        ))}
      </Diagram>
    </MorbidityCard>
  );
};

export default EmployeeMorbidityCard;

type PropsType = {
  employee: EmployeeType | undefined;
};
