import React, { FC, MouseEvent } from "react";
import { Pencil } from "../../Icons";
import { Container } from "./EditButton.styles";

const EditButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <Pencil />
    </Container>
  );
};

export default EditButton;

type PropsType = {
  onClick: (arg: MouseEvent<HTMLButtonElement>) => void;
};
