import React, { FC, Suspense, useState } from "react";
import { DICTIONARY } from "../../../api/routes";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { ErrorText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../../components/Header";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import HeaderSearchInput from "../../../components/Header/HeaderSearchInput";
import Loader from "../../../components/Loader";
import { Main, PageContainer } from "../../Page.styles";
import HospitalAddForm from "./Forms/HospitalAddForm";
import HospitalsTable from "./Table/HospitalsTable";
import { useHospitalDictionary } from "./useHospitalDictionary";

const HospitalDictionaryPage: FC = () => {
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);

  const hospitalDictionaryMethods = useHospitalDictionary();
  const { titlePage, handleDeleteRows } = hospitalDictionaryMethods;
  const { canViewDictionaries, canEditDictionaries } = hospitalDictionaryMethods;

  const handleCloseAddForm = () => setAddFormIsOpen(false);

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needAddButton={
        canEditDictionaries ? { text: "Добавить", onClick: setAddFormIsOpen } : undefined
      }
      needDeleteButton={
        canEditDictionaries ? { text: "Удалить", onClick: handleDeleteRows } : undefined
      }
      needSearchButton={{ visibleSearch, setVisibleSearch }}
      section={{ name: "Справочники", rout: DICTIONARY }}
    />
  );

  const searchInput = visibleSearch ? <HeaderSearchInput /> : null;

  return canViewDictionaries ? (
    <PageContainer>
      <Header navigation={navigation} searchInput={searchInput} />

      <Main>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <HospitalsTable />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {addFormIsOpen && <HospitalAddForm onClose={handleCloseAddForm} />}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default HospitalDictionaryPage;
