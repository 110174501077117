import { useAtom } from "jotai";
import { RefObject, useState } from "react";
import { statusFileInput } from "../../atoms/atoms";
import { falseAccept, falseSize } from "../../constants/forValidationSchems";

export const useFilesInput = ({ accept, maxSize }: UseFileInputOptions) => {
  const [_, setStatus] = useAtom(statusFileInput);

  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [timerSuccess, setTimerSuccess] = useState<ReturnType<typeof setTimeout>>();
  const [timerIdLoaded, setTimerIdLoaded] = useState<ReturnType<typeof setTimeout>>();
  const [timerProgress, setTimerProgress] = useState<ReturnType<typeof setInterval>>();

  const validFileType = (file: File) => {
    if (!accept || !file) {
      setError("");
      return true;
    }

    if (!accept.some((item) => item === file?.name.slice(file?.name.lastIndexOf(".")))) {
      setError(falseAccept);
      return false;
    }

    setError("");
    return true;
  };

  const validFileSize = (file: File) => {
    if (!maxSize || !file || file.size <= maxSize) {
      setError("");
      return true;
    }

    setError(falseSize);
    return false;
  };

  const addFile = (
    name: string,
    file: File | undefined,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    if (!file) return;

    setFieldValue(name, file);

    if (!validFileType(file) || !validFileSize(file)) {
      setStatus("error");
      return;
    }

    setStatus("progress");

    const intervalTimer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
    }, 300);

    setTimerProgress(intervalTimer);

    setTimerSuccess(setTimeout(() => setStatus("success"), 1500));
    setTimerIdLoaded(setTimeout(() => setStatus("loaded"), 2500));

    setTimeout(() => {
      setProgress(0);
      clearInterval(intervalTimer as ReturnType<typeof setInterval>);
    }, 1500);
  };

  const handleRemove = (
    name: string,
    ref: RefObject<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    setProgress(0);

    clearTimeout(timerSuccess as ReturnType<typeof setTimeout>);
    clearTimeout(timerIdLoaded as ReturnType<typeof setTimeout>);
    clearInterval(timerProgress as ReturnType<typeof setInterval>);

    const input = ref.current!;
    input.value = "";
    setFieldValue(name, undefined);
    setStatus("empty");
  };

  return {
    setStatus,
    error,
    progress,
    timerSuccess,
    timerIdLoaded,
    timerProgress,
    validFileType,
    validFileSize,
    addFile,
    handleRemove,
  };
};

type UseFileInputOptions = {
  accept?: string[];
  maxSize?: number;
};
