import styled from "@emotion/styled";
import { button, buttonOnlyIcon } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<{ isExpand?: boolean }>`
  ${button};
  ${buttonOnlyIcon};

  border-radius: 12px;
  margin: 8px 0 6px 0;

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;
