import { useAtom } from "jotai";
import React, { FC, ReactNode } from "react";
import { selectedRowsState } from "../../atoms/atoms";
import { ConfirmModalBackButton, ConfirmModalOkButton } from "../Buttons";
import * as elements from "./ConfirmModal.styles";

const ConfirmModal: FC<PropsType> = (props) => {
  const { title = "Удалить?", text, onClose, isOpen, onDelete, additionalButton } = props;

  const [_, setSelectedRows] = useAtom(selectedRowsState);

  const { Container, Title, Text, Buttons, AdditionalButton } = elements;

  const handleCancel = () => {
    setSelectedRows([]);
    onClose();
  };

  const handleOk = () => {
    onDelete();
    setSelectedRows([]);
    onClose();
  };

  return (
    <Container open={isOpen}>
      <Title>{title}</Title>
      <Text>
        {typeof text === "string"
          ? `${text}`
          : (text >= 5 && text <= 20) || (text % 10 >= 5 && text % 10 <= 9) || text % 10 === 0
          ? `Выбрано ${text} строк`
          : text % 10 > 1 && text < 5
          ? `Выбрано ${text} строки`
          : `Выбрана ${text} строка`}
      </Text>
      <Buttons>
        <ConfirmModalBackButton onClick={handleCancel} />
        <ConfirmModalOkButton onClick={handleOk} />
      </Buttons>
      {additionalButton && <AdditionalButton>{additionalButton}</AdditionalButton>}
    </Container>
  );
};

export default ConfirmModal;

type PropsType = {
  title?: string;
  text: string | number;
  isOpen: boolean;
  onClose: (value?: string) => void;
  onDelete: () => void;

  additionalButton?: ReactNode;
};
