export { default as ChartsForDepatrmentsPage } from "./ChartsForDepatrmentsPage";
export { default as ChartsForEmployeesPage } from "./ChartsForEmployeesPage";
export { default as ChartsForOrganizationsPage } from "./ChartsForOrganizationsPage";
export { default as CityDictionaryPage } from "./DictionaryPages/CityDictionaryPage";
export { default as CountryDictionaryPage } from "./DictionaryPages/CountryDictionaryPage";
export { default as DisabilityCodeBlockTwoDictionaryPage } from "./DictionaryPages/DisabilityCodeBlockTwoDictionaryPage";
export { default as DisabilityCodeDictionaryPage } from "./DictionaryPages/DisabilityCodeDictionaryPage";
export { default as DiseaseSeverityDictionaryPage } from "./DictionaryPages/DiseaseSeverityDictionaryPage";
export { default as DiseasesClassDictionaryPage } from "./DictionaryPages/DiseasesClassDictionaryPage";
export { default as HospitalDictionaryPage } from "./DictionaryPages/HospitalDictionaryPage";
export { default as InfectiousDiseaseDictionaryPage } from "./DictionaryPages/InfectiousDiseaseDictionaryPage";
export { default as NationalityDictionaryPage } from "./DictionaryPages/NationalityDictionaryPage";
export { default as PersonnelCategoryDictionaryPage } from "./DictionaryPages/PersonnelCategoryDictionaryPage";
export { default as TreatmentPlaceDictionaryPage } from "./DictionaryPages/TreatmentPlaceDictionaryPage";
export { default as EmployeeCardPage } from "./EmployeeCardPage";
export { default as WarningPage } from "./WarningPage";
