import { useAtom } from "jotai";
import * as Yup from "yup";
import { EntitieType, LittleOtherType, diseaseSeverityApi } from "../../../api/apiDictionaries";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { searchQueryState, selectedRowsState } from "../../../atoms/atoms";
import { useTable } from "../../../components/Table/useTable";
import { long, notJustSpaces, required } from "../../../constants/forValidationSchems";
import { useQuery } from "../../../utils/useQuery";
import { diseaseSeverityState } from "./utils/atoms";

export const useDiseaseSeverityDictionary = () => {
  let [diseaseSeverity] = useAtom(diseaseSeverityState);

  const [currentUser] = useAtom(currentUserState);
  const [selectedRows] = useAtom(selectedRowsState);
  const [query] = useAtom(searchQueryState);

  // ------------------------------ КЛЮЧИ ЗАПРОСА

  const queryKey = ["diseaseSeverityState"];

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Степени тяжести заболевания";

  const addFormName = "diseaseSeverityAddForm";
  const addFormTitle = "Степень тяжести заболевания";

  const editFormName = "diseaseSeverityEditForm";
  const editFormTitle = "Изменение степени тяжести заболевания";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const canViewDictionaries = hasUser && currentUser.data.roleModel.canViewDictionaries;
  const canEditDictionaries = hasUser && currentUser.data.roleModel.canEditDictionaries;

  // ------------------------------ ДАННЫЕ - IDS

  const diseaseSeverityIds = diseaseSeverity.map((datum) => datum.id);

  // ------------------------------ ДАННЫЕ - ФИЛЬТРАЦИЯ

  if (query) {
    diseaseSeverity = diseaseSeverity.filter((datum) => {
      const datumForSearch = {
        name: datum.name,
        comment: datum.comment,
      };

      return Object.values(datumForSearch).join().toLowerCase().includes(query.toLowerCase());
    });
  }

  // ------------------------------ ДАННЫЕ - УДАЛЕНИЕ

  const deletion = useQuery().delete({ queryKey, api: diseaseSeverityApi });

  const handleDeleteRows = async () => await deletion.mutateAsync(selectedRows);

  // ------------------------------ ТАБЛИЦА

  const { title, grid, formatData, columnsNames } = useTable();

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "Наименование", name: "name" },
    { id: 2, title: "Комментарий", name: "comment" },
  ];

  const titles = title(titlesInit);

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridInit = [
    ...(canEditDictionaries ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "1fr" },
    { titleId: 2, size: "1fr" },
    ...(canEditDictionaries ? [{ titleId: null, size: "56px" }] : []),
  ];

  const grids = grid(gridInit);

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (datum: EntitieType<LittleOtherType>) => ({
    name: datum.name,
    comment: datum.comment ? (datum.comment === "" ? "—" : datum.comment) : "—",
  });

  const dataForTable = (datum: EntitieType<LittleOtherType>) =>
    formatData(object(datum) ?? {}, columnsNames(titlesInit));

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    name: "",
    comment: "",
  };

  const initialValuesEditForm = (datum: EntitieType<LittleOtherType>) => ({
    name: datum.name ?? "",
    comment: datum.comment ?? "",
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/\S/, notJustSpaces).max(255, long).required(required),
    comment: Yup.string().matches(/\S/, notJustSpaces),
  });

  return {
    titlePage,
    addFormName,
    addFormTitle,
    editFormName,
    editFormTitle,

    queryKey,

    canViewDictionaries,
    canEditDictionaries,

    diseaseSeverity,
    diseaseSeverityIds,
    handleDeleteRows,

    titles,
    titlesInit,
    grids,
    dataForTable,

    initialValuesAddForm,
    initialValuesEditForm,
    validationSchema,
  };
};
