import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "./SubSectionsButtons.styles";
import { dictionaries } from "./subSections";

const SubSectionsButtons: FC<PropsType> = (props) => {
  const { roleModel, section, menuIsExpand, subMenuIsOpen } = props;

  const navigate = useNavigate();
  const location = useLocation();

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const canViewDictionaries = roleModel.canViewDictionaries;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  return (
    <Container isOpen={subMenuIsOpen}>
      {(section === "dictionaries" ? dictionaries(canViewDictionaries) : []).map(
        (subSection, i) => (
          <Button
            key={i}
            isExpand={menuIsExpand}
            active={location.pathname === subSection.rout}
            onClick={() => navigate(subSection.rout)}
          >
            <div className="withoutIcon"></div>{" "}
            {menuIsExpand ? (
              <p onClick={() => navigate(subSection.rout)}>{subSection.text}</p>
            ) : null}
          </Button>
        )
      )}
    </Container>
  );
};

export default SubSectionsButtons;

type PropsType = {
  roleModel: Record<string, boolean>;
  section: string;
  menuIsExpand: boolean;
  subMenuIsOpen: boolean;
  setSubMenuIsOpen: (arg: string) => void;
};
