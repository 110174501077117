import styled from "@emotion/styled";
import { colors } from "../../styles/styles";

export const Container = styled.div<{ isExpand: boolean }>`
  min-width: ${({ isExpand }) => (isExpand ? "300px" : "72px")};
  width: ${({ isExpand }) => (isExpand ? "300px" : "72px")};
  height: 100vh;
  display: grid;
  grid-template-rows: 72px 1fr 124px;
  border-right: 1px solid ${colors.grayscaleInput};
  transition: ${({ isExpand }) =>
    isExpand ? "min-width 0.3s ease-in-out, width 0.3s ease-in-out" : "none"};
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
`;

export const Header = styled(Block)`
  justify-content: center;
  margin: 0 16px;
  z-index: 2;
`;

export const MainWrapper = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Main = styled(Block)<{ isExpand: boolean }>`
  ${({ isExpand }) => (isExpand ? "width: 268px" : "width: 40px")};
  min-height: 100%;
  margin: 0 16px;
  z-index: 1;
`;

export const Footer = styled(Block)`
  border-top: 1px solid ${colors.grayscaleInput};
  margin: 0 16px;
  z-index: 2;
  overflow: hidden;
`;
