import { useAtom } from "jotai";
import React, { FC, ReactNode, useEffect } from "react";
import { statusCreateOrUpdate, statusDeletion } from "../../atoms/atoms";
import Loader from "../Loader";
import { Container, TableWrapper } from "./Table.styles";
import { useTable } from "./useTable";

const Table: FC<PropsType> = (props) => {
  const { titles = [], children, needPagination = false } = props;
  const { needScroll = false, panelHeight = "0" } = props;

  const [statusDel] = useAtom(statusDeletion);
  const [statusCreate] = useAtom(statusCreateOrUpdate);

  const { setSelectedColumns } = useTable();

  useEffect(() => setSelectedColumns(titles.map((title) => title.id)), []);

  return (
    <>
      <TableWrapper
        needPagination={needPagination}
        needScroll={needScroll}
        panelHeight={panelHeight}
      >
        <Container needPagination={needPagination} needScroll={needScroll}>
          {statusDel || statusCreate ? <Loader over /> : null}
          {children}
        </Container>
      </TableWrapper>
    </>
  );
};

export default Table;

type PropsType = {
  children: ReactNode;
  titles?: { id: number; title: string }[];
  needPagination?: boolean;
  needScroll?: boolean;
  panelHeight?: string;
};
