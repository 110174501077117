import { AxiosResponse } from "axios";
import { PaginationType } from "../api/types";
import { mrApi } from "./api";
import { IdType } from "./types";

// ------------------------------ СПРАВОЧНИКИ

const entities = <T>(entity: string) => ({
  getAll: async (props?: EntitieRequestType): Promise<AxiosResponse<EntitiesResponseType<T>>> => {
    return props ? mrApi.get(`/${entity}`, { params: props }) : mrApi.get(`/${entity}`);
  },
  create: async (props: EntitieCreateType<T>): Promise<AxiosResponse<IdType>> => {
    const { data } = props;
    return mrApi.post(`/${entity}`, data);
  },
  update: async (props: EntitieUpdateType<T>): Promise<AxiosResponse<any>> => {
    const { id, data } = props;
    return mrApi.put(`/${entity}/${id}`, data);
  },
  delete: async (id: string) => {
    mrApi.delete(`/${entity}/${id}`);
  },
});

export const disabilityCodesApi = entities<BigOtherType>("disabilities");
export const disabilityCodesBlockTwoApi = entities<BigOtherType>("additional-disabilities");
export const diseasesClassesApi = entities<BigOtherType>("disease-classes");
export const infectiousDiseasesApi = entities<LittleOtherType>("infectious-diseases");
export const nationalitiesApi = entities<LittleOtherType>("citizenships");
export const personnelCategoriesApi = entities<LittleOtherType>("personnel-categories");
export const countriesApi = entities<LittleOtherType>("countries");
export const citiesApi = entities<CityType>("cities");
export const hospitalsApi = entities<HospitalType>("hospitals");
export const diseaseSeverityApi = entities<LittleOtherType>("patient-statuses");
export const treatmentPlacesApi = entities<LittleOtherType>("medical-treatment-places");

// ------------------------------ ТИПЫ - СПРАВОЧНИКИ

export type LittleOtherType = {
  name: string;
  comment?: string;
};

export type BigOtherType = {
  code: string;
  name: string;
  comment?: string;
};

export type CityType = {
  name: string;
  country: { id: string; name?: string };
  comment?: string;
};

export type HospitalType = {
  name: string;
  fmba: boolean;
  comment?: string;
};

// передаваемые значения

export type EntitieRequestType = PaginationType & {
  query?: string;
};

export type EntitieCreateType<T> = {
  data: T;
};

export type EntitieUpdateType<T> = {
  id: string;
  data: T;
};

// получаемые значения

export type EntitiesResponseType<T> = { items: EntitiesType<T>; totalCount: number };

export type EntitiesType<T> = EntitieType<T>[];

export type EntitieType<T> = T & { id: string };
