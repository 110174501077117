import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Gear: FC = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3767 2.08555C11.1758 0.526454 8.8243 0.526454 7.62338 2.08555L6.7944 3.16178C6.31121 3.78907 5.59963 4.1999 4.81479 4.3047L3.46825 4.48451C1.51757 4.74499 0.34181 6.78147 1.09157 8.60105L1.60912 9.85708C1.91078 10.5892 1.91078 11.4108 1.60912 12.1429L1.09157 13.399C0.34181 15.2185 1.51757 17.255 3.46825 17.5155L4.81479 17.6953C5.59963 17.8001 6.31121 18.2109 6.7944 18.8382L7.62338 19.9145C8.8243 21.4736 11.1758 21.4736 12.3767 19.9145L13.2057 18.8382C13.6889 18.2109 14.4005 17.8001 15.1853 17.6953L16.5319 17.5155C18.4826 17.255 19.6583 15.2185 18.9086 13.399L18.391 12.1429C18.0893 11.4108 18.0893 10.5892 18.391 9.85708L18.9086 8.60104C19.6583 6.78147 18.4826 4.74499 16.5319 4.48451L15.1853 4.3047C14.4005 4.1999 13.6889 3.78907 13.2057 3.16178L12.3767 2.08555ZM10.0001 15C12.2092 15 14.0001 13.2091 14.0001 11C14.0001 8.79087 12.2092 7.00001 10.0001 7.00001C7.79092 7.00001 6.00006 8.79087 6.00006 11C6.00006 13.2091 7.79092 15 10.0001 15Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default Gear;
