import { getMainDepartmentOptions, getOrganizationOptions } from "./getOptions";

export const getNames = () => {
  const organizationOptions = getOrganizationOptions();
  const mainDepartmentOptions = getMainDepartmentOptions();

  const organizationName = (organizationId: string) =>
    organizationOptions.find((item) => item.id === organizationId)?.name ?? "";

  const departmentName = (departmentId?: string) =>
    mainDepartmentOptions.find((option) => option.id === departmentId)?.name;

  return {
    organizationName,
    departmentName,
  };
};
