import React, { FC } from "react";
import { colors } from "../../styles/styles";

const Download: FC = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0H10V6H13.0343C13.2552 6 13.4343 6.17909 13.4343 6.4C13.4343 6.50609 13.3922 6.60783 13.3172 6.68284L7.28284 12.7172C7.12663 12.8734 6.87337 12.8734 6.71716 12.7172L0.682843 6.68284C0.526633 6.52663 0.526633 6.27337 0.682843 6.11716C0.757857 6.04214 0.859599 6 0.965685 6H4V0ZM1 15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H1C0.447715 17 0 16.5523 0 16C0 15.4477 0.447715 15 1 15Z"
        fill={colors.grayscaleIcons}
      />
    </svg>
  );
};

export default Download;
