import { AxiosResponse } from "axios";
import { mrApi } from "./api";
import {
  BigOtherType,
  CityType,
  EntitieType,
  HospitalType,
  LittleOtherType,
} from "./apiDictionaries";
import { ContactTypeType, EmployeeStatusType, IdType, SexType } from "./types";

// ------------------------------ КАРТОЧКА РАБОТНИКА

export const employeesApi = <D>() => ({
  getAll: async (props: EmployeesRequestType): Promise<AxiosResponse<EmployeesResponseType>> => {
    return mrApi.get(`/employees`, { params: props });
  },
  get: async (id: string): Promise<AxiosResponse<EmployeeType>> => {
    return mrApi.get(`/employees/${id}`);
  },

  create: async (props: EmployeeCreateType<D>): Promise<AxiosResponse> => {
    const { data } = props;
    return mrApi.post(`/employees`, data);
  },
  update: async (props: EmployeeUpdateType<D>): Promise<AxiosResponse> => {
    const { id, data } = props;
    return mrApi.put(`/employees/${id}`, data);
  },
  upload: async (props: FormData): Promise<AxiosResponse<EmployeesUploadListResponse>> => {
    return mrApi.post("/upload-employees", props, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data; boundary=PART",
      },
    });
  },
});

// ------------------------------ ЗАБОЛЕВАНИЯ

export const diseasesApi = <T>() => ({
  create: async (props: DiseaseCreateType<T>): Promise<AxiosResponse<IdType>> => {
    const { data } = props;
    return mrApi.post("/diseases", data);
  },
  update: async (props: DiseaseUpdateType<T>): Promise<AxiosResponse<DiseaseResponseType>> => {
    const { id, data } = props;
    return mrApi.put(`/diseases/${id}`, data);
  },

  deleteArr: async (ids: string[]) => {
    mrApi.delete(`/diseases`, { data: ids });
  },
  delete: async (id: string) => {
    mrApi.delete(`/diseases/${id}`);
  },
});

// ------------------------------ ТИПЫ

// ------------------------------ ТИПЫ - КАРТОЧКА РАБОТНИКА

// export type EmployeeDataType = {
//   personnelNumber: string;
//   email?: string;
//   phoneNumber?: string;
//   organizationCode: string;
//   mainDepartmentCode: string;
//   departmentCode: string;
//   positionCode: string;
//   deathDate?: string;
//   citizenship?: IdType;
//   personnelCategory?: IdType;
//   since?: string;
//   until?: string;
//   status: StatusType;
// };

// // получаемые значения

// export type EmployeeType = EmployeeDataType & {
//   id: string;
//   mrId?: string;
//   orgId: string;
//   firstName: string;
//   middleName: string;
//   lastName: string;
//   insuranceCode?: string;
//   sex?: SexType;
//   address: string;
//   birthDate: string;
//   employmentDate: string;
//   position: PositionType;
//   contacts: ContactType[];
//   diseases?: DiseaseType[];
//   diseased: boolean;
//   lastModifiedDate?: string;
// };

export type EmployeeType = {
  id: string;
  orgId?: string;
  mrId?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  startPositionDate?: string;
  deathDate?: string;
  sex?: SexType;
  personnelNumber: string;
  position: PositionType;
  positionCode: string;
  departmentCode: string;
  mainDepartmentCode: string;
  organizationCode: string;
  insuranceCode?: string;
  contacts: ContactType[];
  address?: string;
  phoneNumber?: string;
  email?: string;
  citizenship: IdType;
  personnelCategory: IdType;
  status: string;
  employeeStatus: EmployeeStatusType;
  diseases?: DiseaseType[];
  since?: string;
  until?: string;
  lastModifiedDate?: string;
};

// передаваемые значения

export type EmployeesRequestType = {
  startIndex: number;
  size: number;
  query?: string;
  year?: number;
  mainDepartmentCodes?: string;
  status?: string;
  export?: string;
  disabilityId?: string;
  diseaseClassId?: string;
  startDateOfDiseasePeriod?: string;
  endDateOfDiseasePeriod?: string;
};

export type EmployeeCreateType<E> = {
  data: E;
};

export type EmployeeUpdateType<E> = {
  id: string;
  data: E;
};

// получаемые значения

export type EmployeesResponseType = { items: EmployeesType; totalCount: number };

export type EmployeesType = EmployeeType[];

export type EmployeeResponseType = EmployeeType & { id: string };

export type EmployeesUploadListResponse = {
  createdEmployees: EmployeesType;
  failedRows: FailedRowType[];
  updatedDiseases: DiseaseType[];
  updatedEmployees: EmployeesType;
};

export type FailedRowType = {
  fcs: string;
  id: string;
  number: number;
  personnelNumber: string;
};

// ------------------------------ ТИПЫ - ЗАБОЛЕВАНИЯ

export type DiseaseType = {
  id: string;
  employee?: IdType;

  withoutSickLeave?: boolean;
  locationCity?: EntitieType<CityType>;
  disability: EntitieType<BigOtherType>;
  additionalDisability?: EntitieType<BigOtherType>;
  diseaseClass: EntitieType<BigOtherType>;
  otherEmployeeInfection?: string | number;
  contactedNumber: number;
  abroad: boolean;
  infectiousDisease?: EntitieType<LittleOtherType>;
  treatmentPlace: EntitieType<BigOtherType>;
  hospital?: EntitieType<HospitalType>;
  patientStatus: EntitieType<LittleOtherType>;
  briefAnamnesis: string;
  document?: string;
  openSickListDate: string;
  closeSickListDate: string;
};

// export type DiseaseDataType = {
//   employee?: IdType;
//   locationCity?: IdType;
//   withoutSickLeave?: boolean;
//   disability?: IdType;
//   additionalDisability?: IdType;
//   diseaseClass?: IdType;
//   contactedNumber?: number;
//   otherEmployeeInfection?: string | number;
//   abroad?: boolean;
//   treatmentPlace?: IdType;
//   hospital?: IdType;
//   patientStatus?: IdType;
//   briefAnamnesis?: string;
//   document?: string | File;
//   openSickListDate: string;
//   closeSickListDate: string | null;
// };

// export type DiseaseType = {
//   id: string;
//   abroad: boolean;
//   withoutSickLeave: boolean;
//   additionalDisability: EntitieType;
//   disability: EntitieType;
//   diseaseClass: EntitieType;
//   locationCity: EntitieType;
//   patientStatus: EntitieType;
//   treatmentPlace: EntitieType;
//   infectiousDisease: EntitieType;
//   otherEmployeeInfection: string;
//   contactedNumber: number;
//   openSickListDate: string;
//   closeSickListDate: string;
//   briefAnamnesis: string;
//   hospital?: EntitieType;
//   document?: string;
// };

// передаваемые значения

export type DiseaseCreateType<D> = {
  data: D;
};

export type DiseaseUpdateType<D> = {
  id: string;
  data: D;
};

// получаемые значения

export type DiseaseResponseType = DiseaseType & { id: string };

// ------------------------------ ТИПЫ - ВСПОМОГАТЕЛЬНЫЕ

type PositionType = {
  code: string;
  name: string;
  department: DepartmentType;
  organization: { name: string };
};

type DepartmentType = {
  code: string;
  mainDepartment: string;
  mainDepartmentName: string;
  name: string;
  shortName: string;
  type: string;
  organization: OrganizationType;
  parent?: string;
  location?: LocationType;
};

type OrganizationType = {
  code: string;
  name: string;
};

type LocationType = {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  flat: string;
  name: string;
};

type ContactType = {
  isMain: boolean;
  type: ContactTypeType;
  value: string;
};
