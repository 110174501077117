import { useAtom } from "jotai";
import React, { FC, Suspense, useEffect } from "react";
import { dateSwitcherTypeState } from "../../atoms/atoms";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ErrorText } from "../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { PageContainer } from "../Page.styles";
import OrganizationCharts from "./Charts/OrganizationCharts";
import OrganizationChartsExport from "./Charts/OrganizationChartsExport";
import OrganizationsFilters from "./Filters/OrganizationsFilters";
import OrganizationPanel from "./Panel/OrganizationPanel";
import { usePage } from "./hooks/usePage";
import { Main } from "./ChartsForOrganizationsPage.styles";

const ChartsForOrganizationsPage: FC = () => {
  const [_, setDateSwitcherType] = useAtom(dateSwitcherTypeState);

  const pageMethods = usePage();
  const { canViewStatsForOrganizations } = pageMethods;
  const { filtersIsOpen, handleCloseFilters, navigation } = pageMethods;

  useEffect(() => setDateSwitcherType("year"), []);

  return canViewStatsForOrganizations ? (
    <PageContainer>
      <Header navigation={navigation} />

      <Main className="main">
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <OrganizationPanel />

            <OrganizationCharts />

            <OrganizationChartsExport />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {filtersIsOpen && <OrganizationsFilters onClose={handleCloseFilters} />}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default ChartsForOrganizationsPage;
