import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";

export const Container = styled.button`
  ${button};

  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
`;
