import styled from "@emotion/styled";
import { button, buttonOnlyIcon, buttonTextDefault } from "../../../styles/buttonsStyles";
import { colors, overflowOneLine } from "../../../styles/styles";

export const Container = styled.div<ContainerProps>`
  min-width: 0;
  height: 64px;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: ${({ grid }) => grid};
  background: ${colors.grayscaleLable};
  border-bottom: 1px solid ${colors.grayscaleInput};
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  z-index: 2;
`;

export const TableHeadItem = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  border-right: 1px solid ${colors.grayscaleInput};
  min-width: 0;
  overflow: hidden;
  padding: 19px 2px 19px 20px;

  &.check,
  &.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &:last-of-type {
    border: none;
  }

  p {
    ${overflowOneLine};

    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    color: ${colors.grayscaleOffWhite};
  }

  svg > path {
    fill: ${colors.white};
  }
`;

export const DragHandleButton = styled.div`
  ${button}
  ${buttonOnlyIcon}
  ${buttonTextDefault}

  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: ${colors.grayscaleIcons};
  cursor: grab;
  padding: 4px;
`;

type ContainerProps = {
  needCheck: boolean;
  needMenu: boolean;
  grid: string;
};
