import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { CurrentUserType, user } from "../api/apiOrganization";
import { emptyObject, incorrectRes } from "../constants/errorsMessages";

// ------------------------------ ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

export const currentUserAtom = atomWithQuery(() => currentUserQuery());

export const currentUserQuery = () => ({
  queryKey: "currentUser",
  queryFn: fetchCurrentUser,
});

export const currentUserState = loadable(currentUserAtom);

export const fetchCurrentUser = async (): Promise<UserType> => {
  const res = (await user.get()).data;

  if (!res) throw new Error(incorrectRes);
  if (Object.keys(res).length === 0) throw new Error(emptyObject);

  const containsRoles = (roles: string[]) => res?.roles.some((role) => roles?.includes(role));

  // ------------------------------ СУПЕРПОЛЬЗОВАТЕЛЬ (разработчик)

  const superUser = containsRoles(["system-full-access"]);

  // ------------------------------ ПОЛЬЗОВАТЕЛИ

  const director = containsRoles(["mr-director-role"]);
  const oot = containsRoles(["mr-oot-role"]);
  const osr = containsRoles(["mr-osr-role"]);
  const department = containsRoles(["mr-department-role"]);
  const module = containsRoles(["mr-module-role"]);

  // ------------------------------ КНОПКА СТАТИСТИКИ В МЕНЮ

  const canViewSectionsButtonStats = containsRoles([
    "mr-director-role",
    "mr-oot-role",
    "mr-osr-role",
    "mr-department-role",
    "system-full-access",
  ]);

  // ------------------------------ СТАТИСТИКА ЗАБОЛЕВАЕМОСТИ ПО ОРГАНИЗАЦИЯМ

  const canViewStatsForOrganizations = containsRoles([
    "mr-director-role",
    "mr-oot-role",
    "mr-osr-role",
    "system-full-access",
  ]);

  // ------------------------------ СТАТИСТИКА ЗАБОЛЕВАЕМОСТИ ПО ПОДРАЗДЕЛЕНИЯМ

  const canViewStatsForDepartments = containsRoles([
    "mr-director-role",
    "mr-oot-role",
    "mr-osr-role",
    "system-full-access",
  ]);

  // ------------------------------ СТАТИСТИКА ЗАБОЛЕВАЕМОСТИ ПО РАБОТНИКАМ

  const canViewStatsForEmployees = containsRoles([
    "mr-oot-role",
    "mr-osr-role",
    "mr-department-role",
    "system-full-access",
  ]);

  // ------------------------------ ЗАГРУЗКА ЛИСТОВ НЕТРУДОСПОСОБНОСТИ

  const canViewUploadEmployees = containsRoles(["mr-osr-role", "system-full-access"]);

  // ------------------------------ КАРТОЧКА РАБОТНИКА

  const canViewEmployeeCard = containsRoles([
    "mr-oot-role",
    "mr-osr-role",
    "mr-department-role",
    "system-full-access",
  ]);
  const canEditEmployeeCard = containsRoles([
    "mr-osr-role",
    "mr-department-role",
    "system-full-access",
  ]);

  // ------------------------------ СПРАВОЧНИКИ

  const canViewDictionaries = containsRoles([
    "mr-oot-role",
    "mr-osr-role",
    "mr-department-role",
    "mr-module-role",
    "system-full-access",
  ]);
  const canEditDictionaries = containsRoles(["mr-module-role", "system-full-access"]);

  return {
    res,
    settings: res.settings ? JSON.parse(res.settings) : "",
    roleModel: {
      // ------------------------------ СУПЕРПОЛЬЗОВАТЕЛЬ (разработчик)
      superUser,

      // ------------------------------ ПОЛЬЗОВАТЕЛИ

      director,
      oot,
      osr,
      department,
      module,

      // ------------------------------ КНОПКА СТАТИСТИКИ В МЕНЮ

      canViewSectionsButtonStats,

      // ------------------------------ СТАТИСТИКА ЗАБОЛЕВАЕМОСТИ

      canViewStatsForOrganizations,
      canViewStatsForDepartments,
      canViewStatsForEmployees,

      // ------------------------------ ЗАГРУЗКА ЛИСТОВ НЕТРУДОСПОСОБНОСТИ

      canViewUploadEmployees,

      // ------------------------------ КАРТОЧКА РАБОТНИКА

      canViewEmployeeCard,
      canEditEmployeeCard,

      // ------------------------------ СПРАВОЧНИКИ

      canViewDictionaries,
      canEditDictionaries,
    },
  };
};

export type UserType = {
  res: CurrentUserType;
  settings: SettingsType;
  roleModel: RoleModelType;
};

export type SettingsType = {
  chartsForOrganizations?: { tableSettings?: { name: string; visible: boolean }[] };
  chartsForDepartments?: { tableSettings?: { name: string; visible: boolean }[] };
  chartsForEmployees?: { tableSettings?: { name: string; visible: boolean }[] };
  disabilityCode?: { tableSettings?: { name: string; visible: boolean }[] };
  disabilityCodeBlockTwo?: { tableSettings?: { name: string; visible: boolean }[] };
  diseasesClass?: { tableSettings?: { name: string; visible: boolean }[] };
  infectiousDisease?: { tableSettings?: { name: string; visible: boolean }[] };
  nationality?: { tableSettings?: { name: string; visible: boolean }[] };
  personnelCategory?: { tableSettings?: { name: string; visible: boolean }[] };
  country?: { tableSettings?: { name: string; visible: boolean }[] };
  city?: { tableSettings?: { name: string; visible: boolean }[] };
  hospital?: { tableSettings?: { name: string; visible: boolean }[] };
  employeeStatus?: { tableSettings?: { name: string; visible: boolean }[] };
  diseaseSeverity?: { tableSettings?: { name: string; visible: boolean }[] };
  treatmentPlace?: { tableSettings?: { name: string; visible: boolean }[] };
  employeeCard?: { tableSettings?: { name: string; visible: boolean }[] };
};

export type RoleModelType = {
  superUser: boolean;

  director: boolean;
  oot: boolean;
  osr: boolean;
  department: boolean;
  module: boolean;

  canViewSectionsButtonStats: boolean;

  canViewStatsForOrganizations: boolean;
  canViewStatsForDepartments: boolean;
  canViewStatsForEmployees: boolean;

  canViewUploadEmployees: boolean;

  canViewEmployeeCard: boolean;
  canEditEmployeeCard: boolean;

  canViewDictionaries: boolean;
  canEditDictionaries: boolean;
};

export type TypeSettingsType = keyof SettingsType;
