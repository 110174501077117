import React, { ReactNode } from "react";
import { STATISTICS_EMPLOYEE_CARD } from "../../../api/routes";
import { ErrorText, InfoText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import Skeleton from "../../../components/Skeleton";
import { useTable } from "../../../components/Table/useTable";
import { unknownErrorText } from "../../../utils/getErrorMessage";
import { getNames } from "../../../utils/getNames";
import { formatParseDate } from "../../../utils/workingWithDates";
import { getFullName } from "../../../utils/workingWithNames";
import { Status } from "../../EmployeeCardPage/EmployeeCardPage.styles";
import EmployeeMorbidityCard from "../Card/EmployeeMorbidityCard";
import * as elements from "../ChartsForEmployeesPage.styles";
import { usePage } from "./usePage";

export const usePageTable = () => {
  // ------------------------------ ХУКИ

  const { employees, employeesData } = usePage();

  const { departmentName } = getNames();

  // ------------------------------ ДАННЫЕ - ДЛЯ ТАБЛИЦЫ

  const { CenterAlignContainer, EmployeeDescription, LeftAlignContainer, LinkToCard } = elements;

  const employeesForTable = () => {
    let subTitleTable = "";

    return employees?.map((employee, i) => {
      const { id: employeeId, lastModifiedDate, employeeStatus } = employee;
      const diseased = !!employeeStatus && employeeStatus === "SICK";
      const dead = !!employeeStatus && employeeStatus === "DEAD";

      const currentEmployee = employees
        .filter(({ diseases }) => !!diseases)
        .find(({ id }) => id === employeeId);

      const resultEmployee = {
        id: `${i}`,
        employee: employeeId,
        fullName: (
          <LeftAlignContainer>
            <LinkToCard to={STATISTICS_EMPLOYEE_CARD(employeeId)}>
              {getFullName({ nameParts: employee })}
            </LinkToCard>
            <EmployeeDescription>{employee?.position?.name ?? ""}</EmployeeDescription>
            <EmployeeDescription>ТН: {employee.personnelNumber}</EmployeeDescription>
          </LeftAlignContainer>
        ),
        status: (
          <CenterAlignContainer>
            <Status diseased={diseased}>{diseased ? "Болеет" : dead ? "Умер" : "Не болеет"}</Status>
          </CenterAlignContainer>
        ),
        updateDate: (
          <CenterAlignContainer>
            <p>{lastModifiedDate ? formatParseDate(lastModifiedDate) : "—"}</p>
          </CenterAlignContainer>
        ),
        morbidityStats: (
          <CenterAlignContainer>
            <EmployeeMorbidityCard employee={currentEmployee} />
          </CenterAlignContainer>
        ),
      };

      let resultSubTitle = "";
      const departmentCode = employee?.position.department.mainDepartment;

      if (departmentCode !== subTitleTable) {
        subTitleTable = departmentCode;
        resultSubTitle = departmentName(departmentCode) ?? "";
      }

      return { employee: resultEmployee, subTitle: resultSubTitle };
    });
  };

  const dataWrapper = (component: ReactNode) =>
    employeesData.state === "loading" ? (
      <Skeleton forElement="employeesTable" grids={grids} />
    ) : employeesData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : !employeesForTable() ? (
      <></>
    ) : !employeesForTable()?.length ? (
      <InfoText>Нет данных</InfoText>
    ) : (
      component
    );

  // ------------------------------ ТАБЛИЦА

  const { title, grid, formatData, columnsNames } = useTable();

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "Работник", name: "employee" },
    { id: 2, title: "Статус", name: "status" },
    { id: 3, title: "Дата обновления", name: "updateDate" },
    { id: 4, title: "Статистика заболеваемости", name: "morbidityStats" },
  ];

  const titles = title(titlesInit);

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridInit = [
    { titleId: 1, size: "382fr" },
    { titleId: 2, size: "133fr" },
    { titleId: 3, size: "133fr" },
    { titleId: 4, size: "628px" },
  ];

  const grids = grid(gridInit);

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (datum: EmployeeRowType) => ({
    employee: datum.fullName,
    status: datum.status,
    updateDate: datum.updateDate,
    morbidityStats: datum.morbidityStats,
  });

  const dataForTable = (datum: EmployeeRowType) =>
    formatData(object(datum) ?? {}, columnsNames(titlesInit));

  return {
    employeesForTable,
    dataWrapper,

    titles,
    titlesInit,
    grids,
    dataForTable,
  };
};

type EmployeeRowType = {
  id: string;
  employee: string;
  fullName: JSX.Element;
  status: JSX.Element;
  updateDate: JSX.Element;
  morbidityStats: JSX.Element;
};
