import React, { FC, MouseEvent, useState } from "react";
import CheckForCheckbox from "../../../components/Icons/CheckForCheckbox";
import PopoverModal from "../../../components/PopoverModal";
import { colors } from "../../../styles/styles";
import * as elements from "../ChartsForOrganizationsPage.styles";
import { usePanel } from "../hooks/usePanel";
import useColor from "../hooks/useColor";

const OrganizationButton: FC<PropsType> = (props) => {
  const { id, index, text, allOrganizations = false } = props;

  // ------------------------------ < ВЫЗОВ КОНТЕКСТНОГО МЕНЮ

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleMenuModalOpen = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorElement(evt.currentTarget);
  };

  const handleMenuModalClose = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorElement(null);
  };

  const menuModalIsOpen = !!anchorElement;
  const idMenuModal = menuModalIsOpen ? "menu-modal" : "";

  // ------------------------------ ВЫЗОВ КОНТЕКСТНОГО МЕНЮ >

  const panelMethods = usePanel();
  const { getColorsForChart } = useColor();
  const { allOrganizationSelection, selected, showOrHideText } = panelMethods;
  const { onClickDepartmentsButton, onClickViewButton, onClickExcludeButton } = panelMethods;

  const { MenuButton, MenuButtons, OrganizationButtonContainer, SwitchContainer } = elements;

  const borderColorButton =
    typeof index === "number" && !allOrganizations ? getColorsForChart(index).lines : colors.black;

  return allOrganizations ? (
    <OrganizationButtonContainer
      onClick={allOrganizationSelection}
      selected={selected(id, allOrganizations)}
      borderColor={borderColorButton}
    >
      {text}
    </OrganizationButtonContainer>
  ) : (
    <OrganizationButtonContainer
      aria-owns={menuModalIsOpen ? "menu-modal" : undefined}
      aria-haspopup="true"
      onMouseEnter={handleMenuModalOpen}
      onMouseLeave={handleMenuModalClose}
      selected={selected(id, allOrganizations)}
      borderColor={borderColorButton}
    >
      {text}

      <PopoverModal
        id={idMenuModal}
        isOpen={menuModalIsOpen}
        element={anchorElement}
        onClose={handleMenuModalClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuButtons>
          <MenuButton
            onClick={(evt) => {
              onClickDepartmentsButton(id);
              handleMenuModalClose(evt);
            }}
          >
            <p>Открыть</p>
          </MenuButton>

          <MenuButton
            onClick={(evt) => {
              onClickViewButton(id);
              handleMenuModalClose(evt);
            }}
          >
            <p>{showOrHideText(id, allOrganizations)}</p>
            <SwitchContainer checked={selected(id, allOrganizations)}>
              <div>{selected(id, allOrganizations) ? <CheckForCheckbox /> : null}</div>
            </SwitchContainer>
          </MenuButton>

          <MenuButton
            onClick={(evt) => {
              onClickExcludeButton(id);
              handleMenuModalClose(evt);
            }}
          >
            <p>Исключить остальные</p>
          </MenuButton>
        </MenuButtons>
      </PopoverModal>
    </OrganizationButtonContainer>
  );
};

export default OrganizationButton;

type PropsType = {
  id: string;
  index: number | null;
  text: string;
  allOrganizations?: boolean;
};
