import { useAtom } from "jotai";
import React, { FC, Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import { searchQueryState } from "../../atoms/atoms";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ErrorText } from "../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { Main, PageContainer } from "../Page.styles";
import DiseaseAddForm from "./Forms/DiseaseAddForm";
import EmployeeCardPanel from "./Panel/EmployeeCardPanel";
import DiseasesTable from "./Table/DiseasesTable";
import { usePage } from "./hooks/usePage";
import { usePageTable } from "./hooks/useTable";
import { employeeId } from "./utils/atoms";

const EmployeeCardPage: FC = () => {
  const [_, setEmployeeId] = useAtom(employeeId);
  const [__, setSearchQuery] = useAtom(searchQueryState);

  const pageMethods = usePage();
  const { employee, canViewEmployeeCard, canEditEmployeeCard } = pageMethods;
  const { searchInput, handleCloseAddForm, addFormIsOpen, navigation } = pageMethods;
  const { handleDeleteRows } = usePageTable();

  const { id } = useParams();

  useEffect(() => setSearchQuery(""), []);

  useEffect(() => setEmployeeId(id ?? ""), [id]);

  return canViewEmployeeCard ? (
    <PageContainer>
      <Header navigation={navigation(handleDeleteRows)} searchInput={searchInput} />

      <Main>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <EmployeeCardPanel />

            <DiseasesTable employee={employee} canEditEmployeeCard={canEditEmployeeCard} />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {addFormIsOpen && employee && (
        <DiseaseAddForm employee={employee} onClose={handleCloseAddForm} />
      )}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным</ErrorText>
  );
};

export default EmployeeCardPage;
