import React, { FC } from "react";
import { Container } from "./TableTitleRow.styles";

const TableTitleRow: FC<PropsType> = (props) => {
  const { text } = props;

  return (
    <Container>
      <h3>{text}</h3>
    </Container>
  );
};

export default TableTitleRow;

type PropsType = { text: string };
