import React, { FC, Fragment } from "react";
import { Square, ThreeDots } from "../Icons";
import * as elements from "./Skeleton.styles";

const Skeleton: FC<PropsType> = (props) => {
  const { forElement } = props;

  const { Item, Row, SkeletonForEmployee, SkeletonForEmployeesCard } = elements;
  const { SkeletonForCharts, SkeletonForPanel, SkeletonForTable, TableSimulation } = elements;
  const { SkeletonForEmployeesDate, SkeletonForEmployeesStatus, SkeletonForCard } = elements;

  return forElement === "table" ? (
    <TableSimulation>
      {Array.from({ length: 5 }, (_, i) => {
        const columnsCount = props.grids.split(" ").length;

        return (
          <Row key={i} grids={props.grids}>
            {props.canEdit ? (
              <Item>
                <Square />
              </Item>
            ) : null}

            {Array.from({ length: props.canEdit ? columnsCount - 2 : columnsCount }, (_, i) => (
              <Fragment key={i}>
                <Item>
                  <SkeletonForTable animation="wave" />
                </Item>
              </Fragment>
            ))}

            {props.canEdit ? (
              <Item>
                <ThreeDots />
              </Item>
            ) : null}
          </Row>
        );
      })}
    </TableSimulation>
  ) : forElement === "employeesTable" ? (
    <TableSimulation forEmployeesTable>
      {Array.from({ length: 5 }, (_, i) => (
        <Row key={i} grids={props.grids}>
          <Item>
            <SkeletonForEmployee animation="wave" />
            <SkeletonForEmployee animation="wave" />
            <SkeletonForEmployee animation="wave" />
          </Item>
          <Item>
            <SkeletonForEmployeesStatus animation="wave" />
          </Item>
          <Item>
            <SkeletonForEmployeesDate animation="wave" />
          </Item>
          <Item>
            <SkeletonForEmployeesCard animation="wave" />
          </Item>
        </Row>
      ))}
    </TableSimulation>
  ) : forElement === "card" ? (
    <>
      <SkeletonForCard animation="wave" />
      <SkeletonForCard animation="wave" />
      <SkeletonForCard animation="wave" />
    </>
  ) : forElement === "panel" ? (
    <SkeletonForPanel animation="wave" />
  ) : (
    <SkeletonForCharts animation="wave" />
  );
};

export default Skeleton;

type PropsType =
  | {
      forElement: "table";
      grids: string;
      canEdit: boolean;
    }
  | {
      forElement: "employeesTable";
      grids: string;
    }
  | {
      forElement: "panel" | "charts" | "card";
    };
