import React, { FC } from "react";
import { Container } from "./ConfirmModalBackButton.styles";

const ConfirmModalBackButton: FC<PropsType> = (props) => {
  const { onClick, text = "Назад" } = props;

  return (
    <Container autoFocus onClick={onClick}>
      <p>{text}</p>
    </Container>
  );
};

export default ConfirmModalBackButton;

type PropsType = {
  onClick: () => void;
  text?: string;
};
