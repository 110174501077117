import { utils, writeFile } from "sheetjs-style";
import * as Yup from "yup";
import { employeesApi } from "../../../api/apiEmployees";
import { notDate, notJustSpaces, required } from "../../../constants/forValidationSchems";
import * as xlsxStyles from "../../../styles/xlsxStyles";
import * as formatDateMethods from "../../../utils/workingWithDates";
import { getFullName } from "../../../utils/workingWithNames";
import { ExportFiltersType } from "../utils/atoms";
import { useCalculateSickLeaves } from "../../../utils/useCalculateSickLeaves";

export const useExport = () => {
  const { formatDateForBackend, getPeriodString } = formatDateMethods;
  const { getSickDaysInYear } = useCalculateSickLeaves();

  // ------------------------------ ДАННЫЕ

  const exportEmployees = async (values: ExportFiltersType) => {
    const startIndex = 0;

    const disabilityId =
      values.disabilityCode && values.disabilityCode !== "ALL" ? values.disabilityCode : undefined;
    const diseaseClassId = values.diseasesClass || undefined;
    const mainDepartmentCodes = values.mainDepartmentCode || undefined;
    const startDateOfDiseasePeriod = values.startDate
      ? formatDateForBackend(values.startDate)
      : undefined;
    const endDateOfDiseasePeriod = values.endDate
      ? formatDateForBackend(values.endDate)
      : undefined;

    const args = {
      startIndex,
      disabilityId,
      diseaseClassId,
      mainDepartmentCodes,
      startDateOfDiseasePeriod,
      endDateOfDiseasePeriod,
      export: "true",
    };

    const totalCount = await (await employeesApi().getAll({ size: 1, ...args })).data.totalCount;

    return (await employeesApi().getAll({ size: totalCount, ...args })).data.items;
  };

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesExportFilters = {
    disabilityCode: "ALL",
    diseasesClass: "",
    mainDepartmentCode: "",
    startDate: null,
    endDate: null,
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const exportValidationSchema = Yup.object().shape({
    disabilityCode: Yup.string().matches(/\S/, notJustSpaces).required(required),
    diseasesClass: Yup.string().matches(/\S/, notJustSpaces),
    mainDepartmentCode: Yup.string().matches(/\S/, notJustSpaces),
    startDate: Yup.date().typeError(notDate).required(required),
    endDate: Yup.date().typeError(notDate).required(required),
  });

  // ------------------------------ ЭКСПОРТ - СТИЛИ

  const {
    charA,
    dataCenterStyle,
    dataStyle,
    headerStyle,
    subHeaderStyle,
    subTitleRowStyle,
    titleRowStyle,
  } = xlsxStyles;

  // ------------------------------ ЭКСПОРТ

  const downloadStatForEmployeesFile = async (values: ExportFiltersType) => {
    // ------------------------------ индексы заголовков

    const headerIndexes = [
      "number",
      "employee",
      "position",
      "disabilityPeriod",
      "treatmentPlace",
      "comment",
      "disabilityCode",
      "diseasesClass",
      "daysDisabilityNumber",
      "diseasesFrequency",
      "withoutSickLeave",
    ];

    // ------------------------------ заголовки

    const headers = {
      number: "№",
      employee: "Работник",
      position: "Должность",
      disabilityPeriod: "Период нетрудоспособности,\nкол-во дней",
      treatmentPlace: "Место прохождения лечения",
      comment: "Комментарий",
      disabilityCode: "Код нетрудоспособности блок 1",
      diseasesClass: "Класс болезни по МКБ-10",
      daysDisabilityNumber: "Общее количество дней нетрудоспособности",
      diseasesFrequency: "Частота заболеваний",
      withoutSickLeave: "Без БЛ",
    };

    // ------------------------------ данные для строк

    const employees = (await exportEmployees(values)).filter(
      (employee) => employee.diseases?.length
    );

    const mainDepartments = Array.from(
      new Set(employees.map(({ position }) => position?.department?.mainDepartment))
    );

    const blocks = mainDepartments.map((mainDepartment) => {
      const data = employees.filter(
        ({ position }) => position?.department?.mainDepartment === mainDepartment
      );

      return {
        mainDepartment: data[0]?.position?.department?.mainDepartmentName,
        employees: data,
      };
    });

    // ------------------------------ строки с данными

    let rows = [] as RowsType;
    let orderNumber = 0;

    blocks.forEach((block) => {
      rows.push({
        number: block?.mainDepartment,
        employee: "",
        position: "",
        disabilityPeriod: "",
        treatmentPlace: "",
        comment: "",
        disabilityCode: "",
        diseasesClass: "",
        daysDisabilityNumber: 0,
        diseasesFrequency: 0,
        withoutSickLeave: "",
      });

      block.employees!.forEach((employee) => {
        orderNumber += 1;
        if (!!employee.diseases && employee.diseases.length) {
          const daysDisabilityNumber = getSickDaysInYear(employee.diseases);

          employee.diseases.forEach((disease) => {
            const { disability, diseaseClass, treatmentPlace, withoutSickLeave } = disease;
            const { openSickListDate, closeSickListDate, briefAnamnesis } = disease;

            const disabilityPeriod = getPeriodString(openSickListDate, closeSickListDate, true);

            rows.push({
              number: orderNumber,
              employee: getFullName({ nameParts: employee }),
              position: employee?.position?.name,
              disabilityPeriod: disabilityPeriod,

              treatmentPlace: treatmentPlace?.name,
              comment: briefAnamnesis ?? "",
              disabilityCode: disability?.name ?? "",
              diseasesClass: diseaseClass?.name ?? "",
              daysDisabilityNumber: daysDisabilityNumber,

              diseasesFrequency: employee.diseases!.length,
              withoutSickLeave: withoutSickLeave ? "Да" : "",
            });
          });
        }
      });
    });

    // ------------------------------ количество столбцов и строк

    // ------------------------------ формирование книги

    const wb = utils.book_new();

    // ------------------------------ формирование листов

    const { startDate, endDate } = values;

    const ws = utils.json_to_sheet(
      [
        {},
        {
          number: `Статистика заболеваемости: ${
            employees[0]?.position?.department.organization?.name ?? ""
          }`,
        },
        {
          number: `Период: ${getPeriodString(startDate, endDate)}`,
        },
        {},
        headers,
        ...rows,
      ],
      {
        header: [...headerIndexes],
        skipHeader: true,
      }
    );

    utils.book_append_sheet(wb, ws, "Статистика заболеваний");

    // ------------------------------ ширины столбцов

    const widths = [4, 21, 15, 25, 12, 12, 15, 15, 20, 9, 9];
    ws["!cols"] = widths.map((width) => ({ wch: width }));

    // ------------------------------ высоты строк

    ws["!rows"] = [{}, { hpt: 15 * 3 }];

    // ------------------------------ объединения
    // ------------------------------ горизонтально объединённые ячейки с заголовками, подзаголовками и наименованиями подразделений

    const horizontalMergersIndexes = [0, 1, 2, 3] as number[];

    let horizontalMergersStartIndex = 5;

    for (const { employees } of blocks) {
      let dataLengthSum = 0;
      for (const { diseases } of employees) {
        if (!!diseases || (diseases && Object.keys(diseases).length === 0)) {
          const keys = Object.keys(diseases);
          dataLengthSum += keys.length;
        }
      }

      horizontalMergersIndexes.push(horizontalMergersStartIndex);
      horizontalMergersStartIndex += dataLengthSum + 1;
    }

    const horizontalMergers = horizontalMergersIndexes.map((rowIndex) => ({
      s: { c: 0, r: rowIndex },
      e: { c: headerIndexes.length - 1, r: rowIndex },
    })) as MergedCellType;

    // ------------------------------ вертикально объединённые ячейки с данными

    let verticalMergersStartIndex = 6;

    const intervals = [] as { s: number; e: number }[];

    for (const { employees } of blocks) {
      for (const { diseases } of employees) {
        if (!!diseases || (diseases && Object.keys(diseases).length === 0)) {
          const keys = Object.keys(diseases);
          intervals.push({
            s: verticalMergersStartIndex,
            e: verticalMergersStartIndex + keys.length - 1,
          });
          verticalMergersStartIndex += keys.length;
        }
      }
      verticalMergersStartIndex += 1;
    }

    const verticalMergers = [] as MergedCellType;

    for (const { s, e } of intervals) {
      for (const item of [0, 1, 2, 8, 9]) {
        verticalMergers.push({
          s: { c: item, r: s },
          e: { c: item, r: e },
        });
      }
    }

    // ------------------------------ объединение

    ws["!merges"] = [...horizontalMergers, ...verticalMergers];

    // ------------------------------ заголовок

    ws["A2"].s = headerStyle;
    ws["A3"].s = subHeaderStyle;

    // ------------------------------ строки заголовков таблицы

    for (let i = 0; i < headerIndexes.length; i++) {
      const char = String.fromCharCode(charA + i);
      ws[`${char}5`].s = titleRowStyle;
    }

    // ------------------------------ форматирование строк с наименованиями подразделений

    const mergedRowsIndexes = [] as number[];

    let mergedRowsStartIndex = 6;

    for (const { employees } of blocks) {
      let dataLengthSum = 0;
      for (const { diseases } of employees) {
        if (!!diseases || (diseases && Object.keys(diseases).length === 0)) {
          const keys = Object.keys(diseases);
          dataLengthSum += keys.length;
        }
      }

      mergedRowsIndexes.push(mergedRowsStartIndex);
      mergedRowsStartIndex += dataLengthSum + 1;
    }

    mergedRowsIndexes.forEach((i) => {
      for (let j = 0; j < headerIndexes.length; j++) {
        const char = String.fromCharCode(charA + j);
        ws[`${char}${i}`].s = subTitleRowStyle;
      }
    });

    // ------------------------------ ячейки с данными

    const mergedColumnsIndexes = [] as number[];

    let mergedColumnsStartIndex = 7;

    for (const { employees } of blocks) {
      for (const { diseases } of employees) {
        if (!!diseases || (diseases && Object.keys(diseases).length === 0)) {
          const keys = Object.keys(diseases);
          for (let i = 0; i < keys.length; i++) {
            mergedColumnsIndexes.push(mergedColumnsStartIndex++);
          }
        } else {
          mergedColumnsIndexes.push(mergedColumnsStartIndex++);
        }
      }
      mergedColumnsStartIndex += 1;
    }

    // ------------------------------ ячейки с выравниванием по левому краю

    mergedColumnsIndexes.forEach((i) => {
      ["B", "C", "D", "E", "F"].forEach((char) => {
        ws[`${char}${i}`].s = dataStyle;
      });
    });

    // ------------------------------ ячейки с выравниванием по центру

    mergedColumnsIndexes.forEach((i) => {
      ["A", "G", "H", "I", "J", "K"].forEach((char) => {
        ws[`${char}${i}`].s = dataCenterStyle;
      });
    });

    // ------------------------------ скачивание файла

    writeFile(wb, `Статистика заболеваний.xlsx`);
  };

  return {
    initialValuesExportFilters,
    exportValidationSchema,

    downloadStatForEmployeesFile,
  };
};

type RowsType = (
  | {
      number: string;
    }
  | {
      number: number;
      employee: string;
      position: string;
      disabilityPeriod: string;
      treatmentPlace: string;
      comment: string;
      disabilityCode: string;
      diseasesClass: string;
      daysDisabilityNumber: number;
      diseasesFrequency: number;
      withoutSickLeave: string;
    }
)[];

type MergedCellType = {
  s: { c: number; r: number };
  e: { c: number; r: number };
}[];
