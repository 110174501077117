import axios from "axios";
import React, { Component, ErrorInfo, ReactNode } from "react";
import { unknownError } from "../../constants/errorsMessages";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { Error as ErrorBlock, ErrorText } from "./ErrorBoundary.styles";

export default class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: StateType) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    axios.isAxiosError(error)
      ? console.info(unknownError(error.response ? error.response : error), errorInfo)
      : console.info(unknownError(error), errorInfo);
  }

  render() {
    if (this.state.error) {
      const error = this.state.error;

      return (
        <ErrorBlock>
          <ErrorText>{getErrorMessage(error)}</ErrorText>
        </ErrorBlock>
      );
    }

    return this.props.children;
  }
}

type PropsType = {
  children?: ReactNode;
};

type StateType = {
  error: unknown | undefined;
};
