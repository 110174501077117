export const long = "Слишком длинное значение";
export const required = "Поле должно быть заполнено";
export const numbers = "Допустимы только цифры";
export const stringArr = "Все выбранные элементы должны  быть строкой";
export const notJustSpaces = "Поле должно содержать не только пробелы";
export const thisDateIsBusy = "Дата входит в период, для которого уже есть болезнь";
export const thisPeriodIsBusy = "Период пересекается с другой болезнью";

export const falseAccept = "Неподходящий формат файла";
export const falseSize = "Слишком большой файл";

export const tooSoon = "Слишком рано";
export const tooLate = "Слишком поздно";
export const notDate = "Необходимо ввести валидную дату";
