import React, { FC } from "react";
import { ArrowLeft } from "../../Icons";
import { TitleAndButton, TitleLink } from "./NavigateButton.styles";

const NavigateButton: FC<PropsType> = (props) => {
  const { link, title } = props;

  return (
    <TitleAndButton>
      <TitleLink to={link}>
        <div>
          <ArrowLeft />
        </div>
        <p>{title}</p>
      </TitleLink>
    </TitleAndButton>
  );
};

export default NavigateButton;

type PropsType = {
  link: string;
  title: string;
};
