import Chart from "chart.js/auto";
import React, { FC, useEffect, useRef } from "react";
import * as elements from "../../../components/ContainerForExportCharts/HeaderForExportCharts.styles";
import { getNames } from "../../../utils/getNames";
import { useCharts } from "../hooks/useCharts";
import { usePage } from "../hooks/usePage";
import Logo from "./Logo";
import { useAtom } from "jotai";
import { chartYZoom } from "../utils/atoms";
import useColor from "../hooks/useColor";

const OrganizationChartsExport: FC = () => {
  const { setExportElement } = usePage();

  const { organizationName } = getNames();

  const { getColorsForChart } = useColor();

  const chartsMethods = useCharts();
  const { statsForOrganizations, organizationIsSelected } = chartsMethods;
  const { getDatasets, getOptionsForExport, getLabels } = chartsMethods;

  const { ChartWrapper, ElementForExport, Header } = elements;
  const { Organization, OrganizationsContainer, Title } = elements;

  const [chartZoom] = useAtom(chartYZoom);
  const ref = useRef<HTMLCanvasElement>(null);
  const refForExport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExportElement(refForExport.current);

    return () => setExportElement(null);
  }, []);

  useEffect(() => {
    let { labels } = getLabels();

    if (!ref.current) return;

    const datasets = getDatasets();
    const options = getOptionsForExport();

    const myChart = new Chart(ref.current, {
      type: "line",
      options: options,
      data: { labels, datasets },
    });

    if (chartZoom) myChart.zoomScale("y", chartZoom, "zoom");
    else myChart.resetZoom();
    myChart.update();

    return () => myChart.destroy();
  }, [statsForOrganizations, chartZoom]);

  return (
    <ElementForExport ref={refForExport}>
      <Header>
        <Logo />
        <Title>График заболеваемости</Title>
      </Header>

      <OrganizationsContainer>
        {statsForOrganizations.map(
          ({ organization }, i) =>
            organizationIsSelected(organization) && (
              <Organization key={i} borderColor={getColorsForChart(i).lines}>
                <p>{organizationName(organization)}</p>
              </Organization>
            )
        )}
      </OrganizationsContainer>

      <ChartWrapper>
        <canvas ref={ref} />
      </ChartWrapper>
    </ElementForExport>
  );
};

export default OrganizationChartsExport;
