import { useState } from "react";
import * as Yup from "yup";
import { DiseaseType, EmployeeType } from "../../../api/apiEmployees";
import * as textError from "../../../constants/forValidationSchems";
import * as ids from "../../../constants/ids";
import {
  endOfToday,
  formatDateForBackend,
  parseDate,
  today,
} from "../../../utils/workingWithDates";
import { getFullName } from "../../../utils/workingWithNames";

export const useForms = (employee: EmployeeType) => {
  // ------------------------------ СТЕЙТЫ

  const [fileFieldIsTouched, setTileFieldIsTouched] = useState(false);

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    name: getFullName({ nameParts: employee }),
    organization: employee.position?.department.organization.name ?? "",
    department: employee.position?.department.mainDepartmentName ?? "",
    post: employee.position?.name ?? "",

    nationality: employee.citizenship?.id ?? ids.RussianFederationCitizenship,
    personnelCategory: employee.personnelCategory?.id ?? "",

    deathStatus: employee.deathDate ? true : false,
    deathDate: employee.deathDate ? parseDate(employee.deathDate) : null,

    city: "",

    withoutSickLeave: false,

    disability: "",
    additionalDisability: "",

    diseasesClass: "",
    infectionDisease: "",
    contactedNumber: 0,
    otherEmployeeInfection: 0,
    abroad: false,

    treatmentPlace: "",
    hospital: "",

    patientStatus: "",
    comment: "",
    document: "",

    startDate: today,
    endDate: null,
  };

  const initialValuesEditForm = (disease: DiseaseType, file?: File) => {
    const { locationCity, withoutSickLeave } = disease;
    const { disability, additionalDisability, diseaseClass } = disease;
    const { infectiousDisease, otherEmployeeInfection, treatmentPlace } = disease;
    const { contactedNumber, abroad, hospital, patientStatus } = disease;
    const { briefAnamnesis, openSickListDate, closeSickListDate } = disease;

    return {
      name: getFullName({ nameParts: employee }),
      organization: employee.position?.department.organization.name ?? "",
      department: employee.position?.department.mainDepartmentName ?? "",
      post: employee.position?.name ?? "",

      nationality: employee.citizenship.id ?? "",
      personnelCategory: employee.personnelCategory.id ?? "",

      deathStatus: !!employee.deathDate,
      deathDate: employee.deathDate ? parseDate(employee.deathDate) : null,

      city: locationCity?.id ?? "",

      withoutSickLeave: withoutSickLeave,

      disability: disability.id ?? "",
      additionalDisability: additionalDisability?.id ?? "",
      diseasesClass: diseaseClass?.id ?? "",

      infectionDisease: infectiousDisease?.id ?? "",
      contactedNumber: contactedNumber ?? 0,
      otherEmployeeInfection: otherEmployeeInfection ? Number(otherEmployeeInfection) : 0,
      abroad: abroad,

      treatmentPlace: treatmentPlace.id ?? "",
      hospital: hospital?.id ?? "",

      patientStatus: patientStatus.id ?? "",

      comment: briefAnamnesis ?? "",
      document: file,

      startDate: openSickListDate ? parseDate(openSickListDate) : today,
      endDate: closeSickListDate ? parseDate(closeSickListDate) : null,
    };
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const {
    long,
    notDate,
    numbers,
    required,
    tooLate,
    notJustSpaces,
    thisDateIsBusy,
    thisPeriodIsBusy,
  } = textError;

  const dateRanges = employee.diseases
    ? employee.diseases.map(({ id, disability, openSickListDate, closeSickListDate }) => ({
        id,
        disability,
        start: parseDate(openSickListDate),
        end: closeSickListDate ? parseDate(closeSickListDate) : today,
      }))
    : [];

  const validationSchema = (values: FiledsType, diseaseId?: string) => {
    const {
      deathStatus,
      disability,
      diseasesClass,
      hospital,
      withoutSickLeave,
      startDate,
      endDate,
    } = values;

    const validateDates = () =>
      !!startDate && !!endDate
        ? {
            message: thisPeriodIsBusy,
            test: !dateRanges.some(({ id, disability: disabilityRange, start, end }) => {
              return (
                ((startDate >= start && startDate <= end) ||
                  (endDate >= start && endDate <= end) ||
                  (startDate <= start && endDate >= end)) &&
                disability === disabilityRange.id &&
                (id ? diseaseId !== id : true)
              );
            }),
          }
        : !!startDate && !endDate
        ? {
            message: thisDateIsBusy,
            test: !dateRanges.some(
              ({ id, disability: disabilityRange, start, end }) =>
                startDate >= start &&
                startDate <= end &&
                disability === disabilityRange.id &&
                (id ? diseaseId !== id : true)
            ),
          }
        : !startDate && !!endDate
        ? {
            message: thisDateIsBusy,
            test: !dateRanges.some(
              ({ id, disability: disabilityRange, start, end }) =>
                endDate >= start &&
                endDate <= end &&
                disability === disabilityRange.id &&
                (id ? diseaseId !== id : true)
            ),
          }
        : {
            message: "",
            test: true,
          };

    const schema = Yup.object().shape({
      nationality: Yup.string().matches(/\S/, notJustSpaces).min(1, required).required(required),
      personnelCategory: Yup.string()
        .matches(/\S/, notJustSpaces)
        .min(1, required)
        .required(required),
      deathStatus: Yup.boolean(),
      ...(deathStatus
        ? {
            deathDate: Yup.date().max(endOfToday, tooLate).typeError(notDate).required(required),
          }
        : {}),

      city: Yup.string().matches(/\S/, notJustSpaces).min(1, required).required(required),
      withoutSickLeave: Yup.boolean().required(required),

      disability: Yup.string().matches(/\S/, notJustSpaces).min(1, required).required(required),
      additionalDisability: Yup.string().matches(/\S/, notJustSpaces),

      diseasesClass: Yup.string().matches(/\S/, notJustSpaces),
      ...(diseasesClass === ids.diseaseClassIsInfectious
        ? {
            infectionDisease: Yup.string()
              .matches(/\S/, notJustSpaces)
              .min(1, required)
              .required(required),
            contactedNumber: Yup.number()
              .typeError(numbers)
              .min(0)
              .max(999, long)
              .required(required),
            otherEmployeeInfection: Yup.number().typeError(numbers).min(0).max(999, long),
            abroad: Yup.boolean(),
          }
        : {}),

      treatmentPlace: Yup.string().matches(/\S/, notJustSpaces).min(1, required).required(required),
      ...(hospital === ids.treatmentPlaceIsHospital
        ? { hospital: Yup.string().matches(/\S/, notJustSpaces) }
        : {}),

      patientStatus: Yup.string().matches(/\S/, notJustSpaces).min(1, required).required(required),
      comment: Yup.string().matches(/\S/, notJustSpaces),
      ...(!withoutSickLeave ? { document: Yup.mixed() } : {}),

      startDate: Yup.date()
        .max(endOfToday, tooLate)
        .test("validation-start-date", validateDates().message, () => validateDates().test)
        .typeError(notDate)
        .required(required),
      endDate: Yup.date()
        .max(endOfToday, tooLate)
        .test("validation-end-date", validateDates().message, () => validateDates().test)
        .typeError(notDate)
        .nullable(),
    });

    return schema;
  };

  // ------------------------------ ФОРМА - ОТРАБОТКА ЗНАЧЕНИЙ ФОРМЫ

  const employeeValuesObject = (values: EmployeeFiledsType) => {
    const { deathDate, nationality, personnelCategory } = values;

    const { mrId, personnelNumber } = employee;

    return {
      ...(mrId ? { employeeId: mrId } : {}),
      personnelNumber,
      citizenship: { id: nationality },
      personnelCategory: { id: personnelCategory },
      ...(deathDate ? { deathDate: formatDateForBackend(deathDate) } : {}),
    };
  };

  const diseaseValuesObject = (
    values: DiseaseFiledsType,
    disease?: DiseaseType,
    employeeId?: string
  ) => {
    const { city, disability, additionalDisability, withoutSickLeave, diseasesClass } = values;
    const { infectionDisease, contactedNumber, otherEmployeeInfection, abroad } = values;
    const { treatmentPlace, hospital, patientStatus, comment, document } = values;
    const { startDate, endDate } = values;

    return {
      ...(disease?.id ? { diseaseId: disease?.id } : {}),

      employee: { id: employeeId ? employeeId : employee.mrId },

      locationCity: { id: city },
      disability: { id: disability },
      ...(additionalDisability ? { additionalDisability: { id: additionalDisability } } : {}),
      withoutSickLeave: withoutSickLeave!,

      ...(diseasesClass ? { diseaseClass: { id: diseasesClass } } : {}),
      ...(infectionDisease ? { infectiousDisease: { id: infectionDisease } } : {}),
      ...(contactedNumber ? { contactedNumber: contactedNumber } : {}),
      ...(otherEmployeeInfection ? { otherEmployeeInfection: otherEmployeeInfection } : {}),
      ...(abroad !== undefined ? { abroad: abroad } : {}),

      treatmentPlace: { id: treatmentPlace },
      ...(hospital ? { hospital: { id: hospital } } : {}),

      patientStatus: { id: patientStatus },
      ...(comment ? { briefAnamnesis: comment } : {}),

      ...(document ? { document: document } : {}),

      openSickListDate: formatDateForBackend(startDate),
      ...(endDate ? { closeSickListDate: formatDateForBackend(endDate) } : {}),
    };
  };

  return {
    fileFieldIsTouched,
    setTileFieldIsTouched,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    employeeValuesObject,
    diseaseValuesObject,
  };
};

type EmployeeFiledsType = {
  nationality: string;
  personnelCategory: string;
  deathStatus: boolean;
  deathDate?: Date | null;
  startDate: Date;
};

type DiseaseFiledsType = {
  city: string;
  withoutSickLeave?: boolean;

  disability: string;
  additionalDisability?: string;

  diseasesClass?: string;
  infectionDisease?: string;
  contactedNumber?: number;
  otherEmployeeInfection?: number;
  abroad?: boolean;

  treatmentPlace: string;
  hospital?: string;

  patientStatus: string;
  comment?: string;
  document?: File | string;

  startDate: Date;
  endDate: Date | null;
};

export type FiledsType = EmployeeFiledsType & DiseaseFiledsType;
