import styled from "@emotion/styled";
import {
  button,
  buttonPrimaryDefault,
  buttonSecondaryDefault,
  buttonTwoIcons,
} from "../../../styles/buttonsStyles";

export const Container = styled.button<{ typebutton: "primary" | "secondary" }>`
  ${button};
  ${({ typebutton }) => (typebutton === "primary" ? buttonPrimaryDefault : buttonSecondaryDefault)};
  ${buttonTwoIcons};

  width: 100%;
  margin: -2px 0;
`;
