import { DatePicker, DateRange } from "@mui/lab";
import { addYears, endOfYear, getYear, setYear, startOfYear, subYears } from "date-fns";
import React, { FC, useState } from "react";
import { minusOneYear, plusOneYear, today } from "../../utils/workingWithDates";
import { LeftAndRightArrowsButtons } from "../Buttons";
import { DateSwitcher, DateSwitcherText, PaperProps } from "./DateSwitcher.styles";

const YearSwitcher: FC<PropsType> = (props) => {
  const { date, setDate, dateInterval, setDateInterval, minDate, maxDate } = props;

  const currentDate = date ?? (dateInterval ? dateInterval[0] : today);

  const disableBack = !!currentDate && subYears(currentDate, 1) < minDate;
  const disableNext = !!currentDate && addYears(currentDate, 1) > maxDate;

  const [open, setOpen] = useState(false);

  return (
    <>
      <LeftAndRightArrowsButtons
        onClick={() =>
          disableBack
            ? undefined
            : setDate
            ? setDate(minusOneYear(currentDate!))
            : setDateInterval
            ? setDateInterval([
                startOfYear(minusOneYear(currentDate!)),
                endOfYear(minusOneYear(currentDate!)),
              ])
            : undefined
        }
        disableBack={disableBack}
        orientation="left"
      />

      <DatePicker
        views={["year"]}
        value={setYear(today, getYear(currentDate!))}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(newDate) =>
          setDateInterval &&
          setDateInterval([startOfYear(newDate as Date), endOfYear(newDate as Date)])
        }
        renderInput={(params) => (
          <DateSwitcher onClick={() => setOpen(true)} ref={params.inputRef}>
            <DateSwitcherText>{params.inputProps?.value}</DateSwitcherText>
          </DateSwitcher>
        )}
        minDate={minDate}
        maxDate={maxDate}
        PaperProps={PaperProps}
      />

      <LeftAndRightArrowsButtons
        onClick={() =>
          disableNext
            ? undefined
            : setDate
            ? setDate(plusOneYear(currentDate!))
            : setDateInterval
            ? setDateInterval([
                startOfYear(plusOneYear(currentDate!)),
                endOfYear(plusOneYear(currentDate!)),
              ])
            : undefined
        }
        disableNext={disableNext}
        orientation="right"
      />
    </>
  );
};

export default YearSwitcher;

type PropsType = {
  date?: Date;
  setDate?: (arg: Date) => void;
  dateInterval?: DateRange<Date>;
  setDateInterval?: (arg: DateRange<Date>) => void;
  minDate: Date;
  maxDate: Date;
};
