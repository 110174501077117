import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled(Dialog)`
  border-radius: 24px;

  .MuiBackdrop-root {
    background: rgba(224, 242, 255, 0.6);
    backdrop-filter: blur(10px);
    opacity: 0.6;
  }

  .MuiPaper-root {
    width: 360px;
    border-radius: 24px;
    box-shadow: none;
    margin: 0;
    padding: 16px;
  }
` as typeof Dialog;

export const Title = styled.h4`
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  color: ${colors.grayscaleBeautifulBlack};
  margin: 0 0 4px 0;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: ${colors.grayscaleLable};
  margin: 0 0 16px 0;
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const AdditionalButton = styled.div`
  display: flex;
  flex-flow: column;
  margin: 16px 0 0 0;
`;
