import { Formik } from "formik";
import React, { FC, ReactNode } from "react";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { Form, FormButtons } from "../Forms";
import RightModal from "../RightModal";
import RightModalActions from "../RightModal/RightModalActions";
import RightModalBody from "../RightModal/RightModalBody";

const Filters: FC<PropsType> = (props) => {
  const { children, initialValues, absoluteInitialValues, filtersSchema } = props;
  const { setFilters, onClose, downloadReport, formTitle } = props;

  const formName = "filterForm";

  return (
    <RightModal title={formTitle ?? "Фильтры"} isOpen onClose={onClose} size="small">
      <Formik
        initialValues={initialValues}
        validationSchema={filtersSchema}
        validateOnMount
        onSubmit={async (values) => {
          if (!setFilters) return;

          setFilters(values);

          downloadReport?.(values);

          onClose();
        }}
      >
        {({ handleSubmit, setValues }) => (
          <>
            <RightModalBody>
              <Form formName={formName} onSubmit={handleSubmit}>
                {children}
              </Form>
            </RightModalBody>

            <RightModalActions>
              <FormButtons
                formName={formName}
                onClose={onClose}
                saveOrAdd={downloadReport ? "download" : "clear"}
                onClear={() => setValues(absoluteInitialValues)}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

export default Filters;

type PropsType = {
  children: ReactNode;
  formTitle?: string;
  initialValues: ValuesType;
  absoluteInitialValues: ValuesType;
  setFilters: (arg: any) => void;
  onClose: () => void;
  downloadReport?: (arg: any) => void;
  filtersSchema?: Yup.ObjectSchema<ObjectShape>;
};

export type ValuesType = Record<string, string | string[] | null | Date | Record<string, string>>;
