import { useAtom } from "jotai";
import React, { FC, MouseEvent } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";
import { NineDots } from "../../Icons";
import { Container } from "./WidgetsButton.styles";
import { backendURI } from "../../../utils/backendURI";

const WidgetsButton: FC<PropsType> = (props) => {
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = props;

  const [menuIsExpand] = useAtom(menuIsExpandState);

  return (
    <Container
      isExpand={menuIsExpand}
      aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(evt) => openPopoverHover(evt, "Меню")}
      onMouseLeave={closePopoverHover}
      onClick={closePopoverHover}
      href={process.env.REACT_APP_MR_URL ? "/" : `https://${backendURI()}`}
    >
      <div className="icon">
        <NineDots />
      </div>
      {menuIsExpand ? <p>Оповещения о заболеваемости</p> : null}
    </Container>
  );
};

export default WidgetsButton;

type PropsType = {
  popoverHoverIsOpen: boolean;
  openPopoverHover: (evt: MouseEvent<HTMLElement>, text: string) => void;
  closePopoverHover: () => void;
};
