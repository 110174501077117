import styled from "@emotion/styled";
import {
  button,
  buttonOnlyIcon,
  buttonSecondaryDefault,
  buttonTextDefault,
} from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<{ visibleSearch: boolean }>`
  ${button};
  ${({ visibleSearch }) => (visibleSearch ? buttonSecondaryDefault : buttonTextDefault)};
  ${buttonOnlyIcon};

  margin: 0 16px 0 0;

  svg > path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
`;
