import { useAtom } from "jotai";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import { NavigateButton } from "../../../components/Buttons";
import {
  STATISTICS_CHARTS_FOR_ORGANIZATIONS,
  STATISTICS_EMPLOYEE_CARD,
  STATISTICS_UPLOAD_EMPLOYEES,
} from "../../../api/routes";
import React, { ReactNode, useState } from "react";
import UploadButton from "../../../components/Buttons/UploadButton";
import { employeesApi, EmployeeType } from "../../../api/apiEmployees";
import { UploadResponseType, uploadResultState } from "../utils/atoms";
import { useTable } from "../../../components/Table/useTable";
import { EntitieType } from "../../../api/apiDictionaries";
import { getFullName } from "../../../utils/workingWithNames";
import { LinkToCard } from "../UploadEmployees.styles";
import { ErrorText, InfoText } from "../../../components/ErrorBoundary/ErrorBoundary.styles";
import { getErrorMessage, unknownErrorText } from "../../../utils/getErrorMessage";

export const usePage = () => {
  const [currentUser] = useAtom(currentUserState);
  const [uploadResult, setUploadResult] = useAtom(uploadResultState);
  const [uploadErrors, setUploadErrors] = useState("");

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";
  const canViewUploadEmployeesPage =
    hasUser &&
    currentUser.data.roleModel.canViewUploadEmployees &&
    currentUser.data.res.organizationCode === "Y000";

  // ------------------------------ НАВИГАЦИЯ

  const handleUploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    setUploadErrors("");

    await employeesApi()
      .upload(formData)
      .then((responseUpdate) => {
        const uploadData: UploadResponseType[] = [];
        responseUpdate.data.failedRows.forEach((errors) =>
          uploadData.push({
            id: errors.id,
            name: errors.personnelNumber ?? errors.number,
            type: "ОШИБКА",
            operation: errors.fcs,
          })
        );
        responseUpdate.data.updatedEmployees.forEach((employee) =>
          uploadData.push(employeeDatum(employee, "обновление"))
        );
        responseUpdate.data.createdEmployees.forEach((employee) =>
          uploadData.push(employeeDatum(employee, "создание"))
        );
        responseUpdate.data.updatedDiseases.forEach((disease) =>
          uploadData.push({
            id: disease.id,
            name: disease.disability.name,
            type: "заболевание",
            operation: "обновление",
          })
        );
        setUploadResult(uploadData);
      })
      .catch((e) => {
        setUploadErrors(getErrorMessage(e));
      });
  };

  const employeeDatum = (employee: EmployeeType, operation: "обновление" | "создание") => {
    const fullName = getFullName({ nameParts: employee });

    return {
      id: employee.id + operation,
      name: employee.id ? (
        <LinkToCard to={STATISTICS_EMPLOYEE_CARD(employee.id)}>{fullName}</LinkToCard>
      ) : (
        fullName
      ),
      type: "работник",
      operation: operation,
    };
  };

  const navigation = (
    <HeaderNavigation
      title={{
        text: "Листы нетрудоспособности",
        element: (
          <NavigateButton
            title="Загрузка листов нетрудоспобности"
            link={STATISTICS_CHARTS_FOR_ORGANIZATIONS}
          />
        ),
      }}
      buttonsStart={
        <UploadButton
          text="Загрузить листы"
          accept={".xlsx, .xls, .csv"}
          onChange={handleUploadFile}
        />
      }
      section={{ name: "Статистика заболеваемости", rout: STATISTICS_UPLOAD_EMPLOYEES }}
    />
  );

  // ------------------------------ ТАБЛИЦА

  const { title, grid, formatData, columnsNames } = useTable();

  const dataWrapper = (component: ReactNode) =>
    !!uploadErrors ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : uploadResult.length === 0 ? (
      <InfoText>Нет данных</InfoText>
    ) : (
      component
    );

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "Наименование", name: "name" },
    { id: 2, title: "Тип", name: "type" },
    { id: 3, title: "Операция", name: "operation" },
  ];

  const titles = title(titlesInit);

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridInit = [
    { titleId: 1, size: "2fr" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "2fr" },
  ];

  const grids = grid(gridInit);

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (datum: EntitieType<UploadResponseType>) => ({
    name: datum.name,
    type: datum.type,
    operation: datum.operation,
  });

  const dataForTable = (datum: EntitieType<UploadResponseType>) =>
    formatData(object(datum) ?? {}, columnsNames(titlesInit));

  return {
    canViewUploadEmployeesPage,
    navigation,

    dataWrapper,
    grids,
    titlesInit,
    titles,
    dataForTable,
  };
};
