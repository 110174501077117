import React, { FC, useEffect, useRef } from "react";
import * as elements from "../../../components/ContainerForExportCharts/HeaderForExportCharts.styles";
import Logo from "../../../components/ContainerForExportCharts/Logo/Logo";
import DepartmentCard from "../Card/DepartmentCard";
import { CardsContainer } from "../ChartsForDepartmentsPage.styles";
import { useCharts } from "../hooks/useCharts";
import DepartmentDiagram from "./DepartmentDiagram";

const DepartmentChartsExport: FC<PropsType> = (props) => {
  const { titlePage, setExportElement } = props;

  const { statsFilteredForDepartmentsAll, getDatasets, getPlugins, getOptions } = useCharts();

  const { ElementForExport, Header, Title } = elements;

  const refForExport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExportElement(refForExport.current);

    return () => setExportElement(null);
  }, []);

  return (
    <ElementForExport ref={refForExport}>
      <Header>
        <Logo />
        <Title>График заболеваемости для подразделений {titlePage()}</Title>
      </Header>

      <CardsContainer forExport>
        {statsFilteredForDepartmentsAll?.map((datum, i) => {
          const { mainDepartmentCode, healthIndex } = datum;

          return (
            <DepartmentCard key={i} datum={datum} forExport>
              <DepartmentDiagram
                id={mainDepartmentCode}
                healthIndex={healthIndex ?? 0}
                getDatasets={getDatasets}
                getPlugins={getPlugins}
                getOptions={getOptions}
              />
            </DepartmentCard>
          );
        })}
      </CardsContainer>
    </ElementForExport>
  );
};

export default DepartmentChartsExport;

type PropsType = {
  titlePage: () => string | undefined;
  setExportElement: (update: HTMLDivElement | null) => void;
};
