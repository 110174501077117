import React, { FC } from "react";
import { Container } from "./ConfirmModalOkButton.styles";

const ConfirmModalOkButton: FC<PropsType> = (props) => {
  const { onClick, text = "Удалить" } = props;

  return (
    <Container onClick={onClick}>
      <p>{text}</p>
    </Container>
  );
};

export default ConfirmModalOkButton;

type PropsType = {
  onClick: () => void;
  text?: string;
};
