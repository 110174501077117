import { DateRange } from "@mui/lab";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { FiltersButton, SearchButton } from "../../Buttons";
import DateSwitcher from "../../DateSwitcher";
import { TypeIntervalType } from "../../DateSwitcher/DateSwitcher";
import { RightButtons } from "./HeaderNavigation.styles";

const HeaderNavigationRightButtons: FC<PropsType> = (props) => {
  const { needSearchButton, needFilterButton, needDateSwitcher } = props;

  const [_, setSearchParams] = useSearchParams();

  // ------------------------------ < ОТОБРАЖЕНИЕ/СКРЫТИЕ СТРОКИ ПОИСКА

  const openSearch = () => {
    if (!needSearchButton) return;

    needSearchButton.setVisibleSearch(!needSearchButton.visibleSearch);

    setSearchParams({ page: String(1) });
  };

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ СТРОКИ ПОИСКА >

  return (
    <RightButtons>
      {needSearchButton ? (
        <SearchButton onClick={openSearch} visibleSearch={needSearchButton.visibleSearch} />
      ) : null}

      {needFilterButton ? (
        <FiltersButton
          handleOpenFilters={needFilterButton.handleOpenFilters}
          hasActiveFilters={needFilterButton.hasActiveFilters}
        />
      ) : null}

      {needDateSwitcher ? (
        <DateSwitcher
          dateInterval={needDateSwitcher.dateInterval}
          setDateInterval={needDateSwitcher.setDateInterval}
          date={needDateSwitcher.date}
          setDate={needDateSwitcher.setDate}
          typeInterval={needDateSwitcher.typeInterval}
          router={needDateSwitcher.router}
          needPeriodSwitcher={needDateSwitcher.needPeriodSwitcher}
        />
      ) : null}
    </RightButtons>
  );
};

export default HeaderNavigationRightButtons;

type PropsType = {
  needSearchButton?: SearchType;
  needFilterButton?: FilterType;
  needDateSwitcher?: DateSwitcherType;
};

export type SearchType = {
  visibleSearch: boolean;
  setVisibleSearch: (arg: boolean) => void;
};

export type FilterType = {
  handleOpenFilters: (arg: boolean) => void;
  hasActiveFilters?: boolean;
};

export type DateSwitcherType = {
  typeInterval: TypeIntervalType;
  router?: (date: string | undefined) => string;
  dateInterval?: DateRange<Date>;
  setDateInterval?: (arg: DateRange<Date>) => void;
  date?: Date;
  setDate?: (arg: Date) => void;
  needPeriodSwitcher?: boolean;
};
