import React, { FC, ReactNode } from "react";
import { Container } from "./RightModalBody.styles";

const RightModalBody: FC<PropsType> = (props) => {
  const { children } = props;

  return <Container>{children}</Container>;
};

export default RightModalBody;

type PropsType = {
  children: ReactNode;
};
