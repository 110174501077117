import React, { FC, MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./SectionsButtons.styles";
import { SectionType } from "./sections";

const SectionButtonWithoutSubMenu: FC<PropsType> = (props) => {
  const { section, menuIsExpand, setSubMenuIsOpen } = props;
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = props;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container
      isExpand={menuIsExpand}
      active={location.pathname.includes(section.rout)}
      onClick={() => {
        navigate(section.rout);
        setSubMenuIsOpen("");
      }}
      aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(evt) => openPopoverHover(evt, section.text)}
      onMouseLeave={closePopoverHover}
    >
      <div className="icon">{section.icon}</div>{" "}
      {menuIsExpand ? <p onClick={() => navigate(section.rout)}>{section.text}</p> : null}
    </Container>
  );
};

export default SectionButtonWithoutSubMenu;

type PropsType = {
  section: SectionType;
  menuIsExpand: boolean;
  setSubMenuIsOpen: (arg: string) => void;
  popoverHoverIsOpen: boolean;
  openPopoverHover: (evt: MouseEvent<HTMLElement>, text: string) => void;
  closePopoverHover: () => void;
};
