import styled from "@emotion/styled";
import { button, buttonOnlyText, buttonPrimaryAttantion } from "../../../styles/buttonsStyles";

export const Container = styled.button`
  ${button};
  ${buttonPrimaryAttantion};
  ${buttonOnlyText};

  height: 44px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
`;
