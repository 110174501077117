import { useAtom } from "jotai";
import * as Yup from "yup";
import {
  notDate,
  notJustSpaces,
  required,
  stringArr,
} from "../../../constants/forValidationSchems";
import { filtersState } from "../utils/atoms";
import { departmentState } from "../../../atoms/atomsOrg";

export const useForms = () => {
  // ------------------------------ АТОМЫ

  const [filters] = useAtom(filtersState);
  const [department] = useAtom(departmentState);

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters = {
    employeeStatus: filters.employeeStatus,
    mainDepartmentCodes: filters.mainDepartmentCodes.length
      ? filters.mainDepartmentCodes
      : [department],
    startDate: filters.startDate,
    endDate: filters.endDate,
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape(
    {
      employeeStatus: Yup.string().matches(/\S/, notJustSpaces),
      mainDepartmentCodes: Yup.array().of(
        Yup.string().matches(/\S/, notJustSpaces).required(stringArr)
      ),
      startDate: Yup.date().when(["endDate", "employeeStatus"], (endDate, employeeStatus) =>
        employeeStatus && endDate
          ? Yup.date().required(required).typeError(notDate)
          : Yup.date().typeError(notDate).nullable()
      ),
      endDate: Yup.date().when(["startDate", "employeeStatus"], (startDate, employeeStatus) =>
        employeeStatus && startDate
          ? Yup.date().required(required).typeError(notDate)
          : Yup.date().typeError(notDate).nullable()
      ),
    },
    [
      ["startDate", "endDate"],
      ["endDate", "startDate"],
    ]
  );

  return {
    initialValuesFilters,
    validationSchema,
  };
};
