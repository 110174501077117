import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.button<{ disabled: boolean }>`
  ${button};

  width: 46px;
  height: 46px;

  opacity: 1;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? "1" : "0.6")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  svg > path {
    fill: ${({ disabled }) => (disabled ? colors.grayscaleDisabled : colors.grayscaleIcons)};
  }
`;
