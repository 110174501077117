import { endOfYear, startOfYear } from "date-fns";
import React, { FC, Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import { dateSwitcherTypeState } from "../../atoms/atoms";
import { organizationState } from "../../atoms/atomsOrg";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ErrorText } from "../../components/ErrorBoundary/ErrorBoundary.styles";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { today } from "../../utils/workingWithDates";
import { Main, PageContainer } from "../Page.styles";
import DepartmentCharts from "./Charts/DepartmentCharts";
import DepartmentChartsExport from "./Charts/DepartmentChartsExport";
import DepartmentsFilters from "./Filters/DepartmentsFilters";
import { usePage } from "./hooks/usePage";
import { currentDateInterval } from "./utils/atoms";
import { useUpdateAtom } from "jotai/utils";
import Pagination from "../../components/Pagination";

const ChartsForDepartmentsPage: FC = () => {
  const setCurrentDateInterval = useUpdateAtom(currentDateInterval);
  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const setOrganization = useUpdateAtom(organizationState);

  const pageMethods = usePage();
  const { navigation, titlePage } = pageMethods;
  const {
    setExportElement,
    filtersIsOpen,
    handleCloseFilters,
    canViewStatsForDepartments,
    needPagination,
  } = pageMethods;

  const { id } = useParams();

  useEffect(() => {
    setCurrentDateInterval([startOfYear(today), endOfYear(today)]);
    setDateSwitcherType("year");
    setOrganization(id ?? "");
  }, []);

  return canViewStatsForDepartments ? (
    <PageContainer>
      <Header navigation={navigation} />

      <Main>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <DepartmentCharts />

            <DepartmentChartsExport titlePage={titlePage} setExportElement={setExportElement} />
          </Suspense>
        </ErrorBoundary>
      </Main>

      {needPagination && <Pagination needPagination={needPagination} />}

      {filtersIsOpen && <DepartmentsFilters onClose={handleCloseFilters} />}
    </PageContainer>
  ) : (
    <ErrorText>У вас нет доступа к этим данным1</ErrorText>
  );
};

export default ChartsForDepartmentsPage;
