import { useAtom } from "jotai";
import React, { FC, MouseEvent, useState } from "react";
import { UserType } from "../../../atoms/atomCurrentUser";
import { menuIsExpandState } from "../../../atoms/atoms";
import { role as roleOptions } from "../../../constants/options";
import { getFullName, getUserInitials } from "../../../utils/workingWithNames";
import PopoverModal from "../../PopoverModal";
import { Card, Container, Icon, Name, Roles } from "./Profile.styles";
import ProfileMenu from "./ProfileMenu";

const Profile: FC<PropsType> = (props) => {
  const { currentUser } = props;

  const [menuIsExpand] = useAtom(menuIsExpandState);

  // ------------------------------ < ВЫЗОВ КОНТЕСТНОГО МЕНЮ ПРОФИЛЯ ПОЛЬЗОВАТЕЛЯ

  const [profileMenu, setProfileMenuElement] = useState<HTMLElement | null>(null);

  const openProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setProfileMenuElement(evt.currentTarget);
  };

  const closeProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setProfileMenuElement(null);
  };

  const profileMenuIsOpen = !!profileMenu;

  // ------------------------------ ВЫЗОВ КОНТЕСТНОГО МЕНЮ ПРОФИЛЯ ПОЛЬЗОВАТЕЛЯ >

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const user = currentUser.res;

  const { lastName, firstName, roles, imageInBase64 } = user;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  const initials =
    lastName && firstName
      ? getUserInitials(getFullName({ nameParts: user, withoutMiddleName: true }))
      : "";

  const role =
    roles.length !== 0
      ? roles.map((role) => roleOptions.find(({ id }) => id === role)).filter((role) => role)[0]
          ?.name
      : "";

  return (
    <>
      {menuIsExpand ? (
        <Container onClick={openProfileMenu}>
          <Card>
            <Icon isExpand={menuIsExpand} backgroundImg={imageInBase64}>
              <p>{initials}</p>
            </Icon>
            {user ? (
              <div style={{ overflow: "hidden" }}>
                <Name>{getFullName({ nameParts: user, withoutMiddleName: true })}</Name>

                <Roles>{role}</Roles>
              </div>
            ) : null}
          </Card>
        </Container>
      ) : (
        <Container onClick={openProfileMenu} onlyIcon>
          <Icon isExpand={menuIsExpand} backgroundImg={imageInBase64}>
            <p>{initials}</p>
          </Icon>
        </Container>
      )}

      <PopoverModal
        id="profileMenu"
        isOpen={profileMenuIsOpen}
        element={profileMenu}
        onClose={closeProfileMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <ProfileMenu roleModel={currentUser.roleModel} onClose={closeProfileMenu} />
      </PopoverModal>
    </>
  );
};

export default Profile;

type PropsType = {
  currentUser: UserType;
};
