import React, { FC, MouseEvent, ReactNode } from "react";
import { Container } from "./TableMenuItemButton.styles";

const TableMenuItemButton: FC<PropsType> = (props) => {
  const { text, long = false, xLong = false, icon, onClick } = props;

  return (
    <Container onClick={onClick} long={long} xLong={xLong}>
      {icon}
      <p>{text}</p>
    </Container>
  );
};

export default TableMenuItemButton;

type PropsType = {
  text: string;
  long?: boolean;
  xLong?: boolean;
  icon?: ReactNode;
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
};
