import { useAtom } from "jotai";
import React, { FC } from "react";
import { StartAndEndDatesInput } from "../../../components/DateInput";
import Filters from "../../../components/Filters";
import SelectInput from "../../../components/SelectInput/SelectInput";
import {
  getDisabilityCodeOptions,
  getDiseasesClassOptions,
  getMainDepartmentOptions,
} from "../../../utils/getOptions";
import { today } from "../../../utils/workingWithDates";
import { useExport } from "../hooks/useExport";
import { ExportFiltersType, absoluteInitialValuesExport, exportFiltersState } from "../utils/atoms";

const EmployeesFiltersExport: FC<PropsType> = (props) => {
  const { onClose } = props;

  const [_, setExportFilters] = useAtom(exportFiltersState);

  const exportMethods = useExport();
  const { downloadStatForEmployeesFile } = exportMethods;
  const { initialValuesExportFilters, exportValidationSchema } = exportMethods;

  const handleSetFilters = (values: ExportFiltersType) => setExportFilters(values);

  const fields = (
    <>
      <SelectInput
        name="disabilityCode"
        size="small"
        options={[{ id: "ALL", name: "ВСЕ" }, ...getDisabilityCodeOptions()]}
        label="Код нетрудоспособности *"
        placeholder="Выберите из списка"
      />
      <SelectInput
        name="diseasesClass"
        size="small"
        options={[{ id: "", name: "" }, ...getDiseasesClassOptions()]}
        label="Класс болезни по МКБ-10"
        placeholder="Выберите из списка"
      />

      <SelectInput
        name="mainDepartmentCode"
        size="small"
        options={getMainDepartmentOptions()}
        label="Подразделение"
        placeholder="Выберите из списка"
      />

      <StartAndEndDatesInput
        maxDate={today}
        label={{ start: "Начало периода *", stop: "Окончание периода *" }}
      />
    </>
  );

  return (
    <Filters
      formTitle="Выгрузить статистику"
      initialValues={initialValuesExportFilters}
      absoluteInitialValues={absoluteInitialValuesExport}
      filtersSchema={exportValidationSchema}
      downloadReport={downloadStatForEmployeesFile}
      onClose={onClose}
      setFilters={handleSetFilters}
    >
      {fields}
    </Filters>
  );
};

export default EmployeesFiltersExport;

type PropsType = {
  onClose: () => void;
};
