import styled from "@emotion/styled";
import { colors } from "../../../styles/styles";

export const Container = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 4px;

  :hover {
    text-decoration: none;
  }
`;

export const Icon = styled.div`
  padding: 0 8px 0 0;

  svg {
    :hover {
      transition: transform 1.5s cubic-bezier(0, 0.95, 0.83, -0.52);
      transform: rotate(360deg);
      transform-origin: 43% center;
    }
  }
`;

export const Text = styled.div<{ isExpand?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  border-left: ${({ isExpand }) => (isExpand ? `1px solid ${colors.grayscaleAsh}` : "none")};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 0 0 0 8px;
`;

export const TextMedTech = styled.p<{ gradientText?: string }>`
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  color: ${colors.grayscaleAsh};
  ${({ gradientText }) =>
    gradientText
      ? `background: ${gradientText}; -webkit-background-clip: text; -moz-background-clip: text; background-clip: text; color: ${colors.transparent};`
      : undefined}
`;

export const TextRosatom = styled.p`
  font-size: 10px;
  line-height: 13px;
  font-weight: 400;
  color: ${colors.indigo};
`;
