import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { colors, overflowOneLine } from "../../styles/styles";

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 16px;
  margin: 0 0 16px 0;
  padding: 16px 0 16px 16px;
`;

export const NameAndSexAndStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0 12px 12px 0;
`;

export const NameAndSex = styled.div`
  display: flex;
`;

export const Name = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  color: ${colors.grayscaleAsh};
`;

export const Sex = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 800;
  color: ${colors.grayscaleLable};
  text-transform: uppercase;
  margin: 0 0 0 8px;
`;

export const Status = styled.div<{ diseased: boolean }>`
  width: fit-content;
  background: ${({ diseased }) => (diseased ? colors.redBackground : colors.greenBackground)};
  border-radius: 99em;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  color: ${colors.black};
  padding: 8px 12px;
`;

export const ActivityItem = styled(Chip)`
  font-size: 13px;
  margin-left: 10px;
  margin-top: -4px;
`;

export const Data = styled.div<{ hasMenuButton: boolean }>`
  display: grid;
  grid-template-columns: repeat(9, 1fr) ${({ hasMenuButton }) => (hasMenuButton ? "56px" : null)};
`;

export const DataItem = styled.div<{ hasMenuButton: boolean }>`
  min-width: 100%;
  border-right: 2px solid ${colors.grayscaleInput};
  padding: 0 0 0 13px;

  &:first-of-type {
    padding: 0;
  }

  &:last-of-type {
    ${({ hasMenuButton }) => (hasMenuButton ? undefined : "border-right: none")};
  }
`;

export const Title = styled.h5`
  ${overflowOneLine};

  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  color: ${colors.grayscaleLable};
`;

export const Description = styled.p`
  ${overflowOneLine};

  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grayscaleAsh};
`;

export const FormBlock = styled.div`
  margin-bottom: 16px;
`;

export const File = styled.div``;

export const FileName = styled.h4`
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  cursor: pointer;
  color: ${colors.primaryDefault};

  :hover {
    color: ${colors.primaryLight};
  }
`;
