import { DateRange } from "@mui/lab";
import { endOfYear, startOfYear } from "date-fns";
import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { FormatDateType } from "../../../api/types";
import { formatDateForBackend, today } from "../../../utils/workingWithDates";
import { fetchStatsForOrganizations } from "./fetchData";

// ------------------------------ ТЕКУЩАЯ ДАТА СТАТИСТИКИ

export const currentDateInterval = atom<DateRange<Date>>([startOfYear(today), endOfYear(today)]);

// ------------------------------ ЭКСПОРТИРУЕМЫЙ ЭЛЕМЕНТ

export const exportElementAtom = atom<HTMLDivElement | null>(null);

// ------------------------------ ФИЛЬТРЫ

export const absoluteInitialValues = {
  disabilityCode: "",
  diseaseClass: "",
};

export const filtersState = atom(absoluteInitialValues);

export type FiltersType = {
  disabilityCode: string;
  diseaseClass: string;
};

// ------------------------------ МАСШТАБ ГРАФИКА

export const chartYZoom = atom<MinMax | undefined>(undefined);

type MinMax = { min: number; max: number };

// ------------------------------ ВЫБРАННЫЕ ОРГАНИЗАЦИИ

export const selectedOrganizationsAtom = atomWithImmer<string[]>([]);

// ------------------------------ ДАННЫЕ

export const statsForOrganizationAtom = atomWithQuery((get) => {
  const organizationCodes = get(selectedOrganizationsAtom);
  const dateInterval = get(currentDateInterval);
  const filters = get(filtersState);

  return statsForOrganizationQuery({ organizationCodes, dateInterval, filters });
});

export const statsForOrganizationState = loadable(statsForOrganizationAtom);

export const statsForOrganizationQuery = (props: PropsType) => {
  const { organizationCodes, dateInterval, filters } = props;

  const startDate = formatDateForBackend(dateInterval[0]!) as FormatDateType;
  const endDate = (
    dateInterval[1] ? formatDateForBackend(dateInterval[1]) : startDate
  ) as FormatDateType;

  const disabilityId = filters.disabilityCode || undefined;
  const diseaseClassId = filters.diseaseClass || undefined;

  const args = { organizationCodes, disabilityId, diseaseClassId, startDate, endDate };

  return {
    queryKey: [...Object.entries(args), "statsForOrganizationState"],
    queryFn: () => fetchStatsForOrganizations(args),
    keepPreviousData: true,
  };
};

type PropsType = {
  organizationCodes: string[];
  dateInterval: DateRange<Date>;
  filters: FiltersType;
};
