import styled from "@emotion/styled";
import { button } from "../../styles/buttonsStyles";
import { colors, overflowOneLine } from "../../styles/styles";

export const Container = styled.button<{ status: string }>`
  width: 100%;
  height: 72px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.transparent};
  border: ${({ status }) =>
    status === "empty"
      ? `2px dashed ${colors.primaryActiveElement}`
      : `2px solid ${colors.grayscaleInput}`};
  border-radius: 16px;
  outline: none;
  cursor: ${({ status }) => (status === "empty" ? "pointer" : "default")};
  margin: 0 0 16px 0;
  padding: 16px;

  :hover {
    border: ${({ status }) =>
      status === "empty"
        ? `2px dashed ${colors.primaryDefault}`
        : `2px solid ${colors.grayscaleInput}`};
  }
`;

export const LoadBarAndText = styled.div`
  width: calc(100% - 52px);
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const LoadBar = styled.div<{ status: string }>`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${({ status }) =>
    status === "loaded"
      ? "none"
      : `3px solid ${
          status === "error"
            ? colors.redDefault
            : status === "success"
            ? colors.greenDefault
            : colors.grayscaleBackground
        }  `};
  border-radius: 99em;
  font-size: 10px;
  line-height: 13px;
  font-weight: 900;
  color: ${colors.grayscaleIcons};
  margin: 0 12px 0 0;

  & > svg > path {
    ${({ status }) =>
      status === "empty" || status === "loaded" ? `fill: ${colors.grayscaleLable}` : ""};
  }
`;

export const Progress = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 12px 0 0;

  .MuiCircularProgress-root {
    color: ${colors.primaryDefault};
  }
`;

export const Percent = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${colors.grayscaleBackground};
  border-radius: 99em;
  font-size: 10px;
  line-height: 10px;
  font-weight: 900;
  color: ${colors.grayscaleBeautifulBlack};
  z-index: -1;
`;

export const DocumentIconAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Text = styled.div`
  ${overflowOneLine}

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.p<{ status: string; needDownload?: boolean }>`
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ status }) =>
    status === "progress" || status === "error" || status === "success"
      ? colors.grayscaleLable
      : colors.primaryDefault};

  :hover {
    ${({ needDownload }) => needDownload && "cursor: pointer"};
  }
`;

export const Description = styled.p<{ status: string }>`
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  color: ${({ status }) => (status === "error" ? colors.redDefault : colors.grayscaleLable)};
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
`;

export const Button = styled.button`
  ${button}

  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
`;

export const Input = styled.input`
  display: none;
  position: absolute;
  top: 24px;
`;
