import styled from "@emotion/styled";
import { button, buttonOnlyIcon } from "../../../styles/buttonsStyles";
import { colors } from "../../../styles/styles";

export const Container = styled.a<{ isExpand: boolean }>`
  ${button};
  ${({ isExpand }) => (isExpand ? undefined : buttonOnlyIcon)};
  ${({ isExpand }) => (isExpand ? "justify-content: flex-start" : undefined)};

  height: 40px;
  border-radius: 12px;
  cursor: pointer;
  margin: 40px 0;

  div.icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};
    text-decoration: none;
  }

  p {
    font-size: 17px;
    line-height: 21.8px;
    font-weight: 600;
    color: ${colors.grayscaleAsh};
    margin: 0 0 0 12px;
  }
`;
