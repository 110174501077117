import html2canvas from "html2canvas";
import { useAtom } from "jotai";
import jsPDF from "jspdf";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { STATISTICS, STATISTICS_CHARTS_FOR_ORGANIZATIONS } from "../../../api/routes";
import { currentUserState } from "../../../atoms/atomCurrentUser";
import { NavigateButton, UniversalTextButton } from "../../../components/Buttons";
import HeaderNavigation from "../../../components/Header/HeaderNavigation";
import { getOrganizationOptions } from "../../../utils/getOptions";
import {
  absoluteInitialValues,
  currentDateInterval,
  exportElementAtom,
  filtersState,
  mainDepartmentsForChartsState,
  pageState,
  quantityInOnePageState,
  statsForDepartmentState,
} from "../utils/atoms";
import { useAtomValue, useUpdateAtom } from "jotai/utils";

export const usePage = () => {
  // ------------------------------ АТОМЫ

  const statsForDepartmentsData = useAtomValue(statsForDepartmentState);
  const departmentsForCharts = useAtomValue(mainDepartmentsForChartsState);

  const currentUser = useAtomValue(currentUserState);
  const filters = useAtomValue(filtersState);
  const [dateInterval, setDateInterval] = useAtom(currentDateInterval);
  const [exportElement, setExportElement] = useAtom(exportElementAtom);
  const setPage = useUpdateAtom(pageState);
  const [quantity, setQuantity] = useAtom(quantityInOnePageState);

  // ------------------------------ СТЕЙТЫ

  const [filtersIsOpen, setFiltersIsOpen] = useState(false);

  // ------------------------------ ХУКИ

  const { id } = useParams();

  // ------------------------------ КОНСТАНТЫ

  const titlePage = () =>
    getOrganizationOptions()
      .find((option) => option.id === id)
      ?.name.replaceAll('"', "");
  const totalCount =
    departmentsForCharts.state === "hasData" ? departmentsForCharts.data?.totalCount : 0;

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const hasUser = currentUser.state === "hasData";

  const canViewStatsForDepartments =
    hasUser && currentUser.data.roleModel.canViewStatsForDepartments;
  const director = hasUser && currentUser.data.roleModel.director;

  // ------------------------------ ДАННЫЕ - ПАГИНАЦИЯ

  const needPagination =
    totalCount && totalCount > 1
      ? {
          totalCount: totalCount,
          setPage: setPage,
          quantity: quantity,
          setQuantity: setQuantity,
        }
      : undefined;

  // ------------------------------ ЭКСПОРТ

  const screenshot = (options: OptionsScreenshotType) => {
    const cropper = document.createElement("canvas").getContext("2d");
    if (!exportElement || !cropper) return;
    const finalWidth = options.width;
    let finalHeight = options.height;
    if (options.x) {
      options.width = finalWidth + options.x;
    }
    if (options.y) {
      options.height = finalHeight + options.y;
    }
    return html2canvas(exportElement, options).then((canvas) => {
      cropper.canvas.width = finalWidth;
      cropper.canvas.height = finalHeight;
      cropper.drawImage(canvas, -options.x, -options.y, finalWidth, finalHeight + options.y);
      return cropper.canvas;
    });
  };

  const exportChartsToPDF = async () => {
    if (!exportElement) return;

    const height = exportElement.clientHeight / 2;
    const pdf = new jsPDF({ orientation: "landscape" });
    let currentOffset = 0;

    do {
      await screenshot({
        width: 1122,
        height: currentOffset ? 728 : 831,
        x: 0,
        y: currentOffset,
      })?.then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 2.0);
        if (!currentOffset) {
          pdf.addImage(imgData, "PNG", 0, 0, 280, 210);
          currentOffset += 831 / 2;
        } else {
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, 0, 297, 205);
          currentOffset += 745 / 2;
        }
      });
    } while (currentOffset < height);

    pdf.save("График заболеваемости.pdf");
  };

  // ------------------------------ ШАПКА СТРАНИЦЫ - НАВИГАЦИЯ

  const handleOpenFilters = (arg: boolean) => setFiltersIsOpen(arg);
  const handleCloseFilters = () => setFiltersIsOpen(false);

  const hasActiveFilters = JSON.stringify(filters) !== JSON.stringify(absoluteInitialValues);

  const navigation = (
    <HeaderNavigation
      title={{
        text: titlePage() || "",
        element: (
          <NavigateButton link={STATISTICS_CHARTS_FOR_ORGANIZATIONS} title={titlePage() || ""} />
        ),
      }}
      buttonsStart={
        <UniversalTextButton
          text="Скачать график"
          onClick={exportChartsToPDF}
          enabled={
            (statsForDepartmentsData.state === "hasData"
              ? statsForDepartmentsData.data?.items ?? []
              : []
            ).length !== 0
          }
        />
      }
      section={{ name: "Статистика заболеваемости", rout: STATISTICS }}
      needFilterButton={{ handleOpenFilters, hasActiveFilters }}
      needDateSwitcher={{
        typeInterval: "twoDate",
        dateInterval: dateInterval,
        date: dateInterval[0] ?? undefined,
        setDateInterval: setDateInterval,
        needPeriodSwitcher: true,
      }}
    />
  );

  return {
    titlePage,

    director,
    canViewStatsForDepartments,

    setExportElement,
    exportChartsToPDF,

    filtersIsOpen,
    handleCloseFilters,
    navigation,
    needPagination,
  };
};

type OptionsScreenshotType = {
  width: number;
  height: number;
  x: number;
  y: number;
};
