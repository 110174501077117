import styled from "@emotion/styled";
import { button } from "../../../styles/buttonsStyles";
import { colors, overflowOneLine } from "../../../styles/styles";

export const Container = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => (isOpen ? "fit-content" : "0")};
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;

export const Button = styled.button<{ isExpand: boolean; active?: boolean }>`
  ${button};
  ${({ isExpand }) => (isExpand ? "justify-content: flex-start" : undefined)};

  width: 100%;
  height: 40px;
  border-radius: 12px;
  cursor: pointer;
  margin: 0 0 8px 0;

  div.withoutIcon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    width: calc(100% - 12px - 8px - 16px * 2);
    // (12px) - левый паддинг
    // (8px) - правый марджин
    // (16px * 2) - 2 паддинга контейнера панели
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    text-align: start;
    color: ${({ active }) => (active ? colors.grayscaleAsh : colors.grayscalePlaceholder)};
    ${overflowOneLine};
    padding: 0 8px 0 0;
    margin: 0 0 0 12px;
  }

  svg > path {
    fill: ${({ active }) => (active ? colors.grayscaleAsh : colors.grayscalePlaceholder)};
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};

    p {
      color: ${colors.grayscaleAsh};
    }

    svg > path {
      fill: ${colors.grayscaleAsh};
    }
  }
`;
