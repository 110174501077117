import styled from "@emotion/styled";
import { colors } from "../../styles/styles";

export const Error = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const ErrorText = styled.p`
  font-size: 21px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  color: ${colors.redDark};
`;

export const WarningText = styled.p`
  font-size: 21px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  color: ${colors.warningDark};
`;

export const InfoText = styled.p<{ paddingAuto?: boolean }>`
  font-size: 21px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  color: ${colors.grayscaleLable};
  padding: ${({ paddingAuto }) => (paddingAuto ? "25% 0" : "16px 0")};
`;

export const SuccesText = styled.p<{ visible: boolean }>`
  font-size: 21px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  color: ${colors.greenDark};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 0.2s ease-out;
`;
