import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors, shadow } from "../../styles/styles";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: ${colors.white};
`;

export const LeftAlignContainer = styled(Container)`
  flex-direction: column;
  justify-content: center;
`;

export const CenterAlignContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;

export const LinkToCard = styled(Link)`
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.grayscaleBody};
  text-decoration: none;

  :hover {
    opacity: 0.6;
    color: ${colors.grayscalePlaceholder};
    text-decoration: none;
  }
`;

export const EmployeeDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.grayscaleLable};
`;

export const MorbidityCard = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 16px;
  border-radius: 12px;
  box-shadow: ${shadow};
  padding: 12px;
`;

export const History = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  color: ${colors.grayscaleLable};
`;

export const DateText = styled.p`
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  color: ${colors.black};
  margin: 8px 0 -6px 0;
`;

export const SubText = styled.p`
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  color: ${colors.grayscalePlaceholder};
`;

export const Diagram = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 4px;
  padding: 0 4px;
`;

export const MonthAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopoverDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Day = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}%` : 0)};
  border-radius: 8px;
`;

export const HealthDays = styled(Day)`
  background: ${colors.greenDefault};
`;

export const SickDays = styled(Day)`
  background: ${colors.redDefault};
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  color: ${colors.grayscalePlaceholder};
`;

export const Month = styled.div`
  width: 33px;
  height: 69px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  background: ${colors.grayscaleInput};
  border-radius: 8px;
  padding: 2px;
`;
