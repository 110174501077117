import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { fetchEmployee } from "./fetchData";

// ------------------------------ ID РАБОТНИКА

export const employeeId = atom("");

// ------------------------------ ДАННЫЕ РАБОТНИКА

export const employeeAtom = atomWithQuery((get) => {
  const id = get(employeeId);

  return employeeQuery(id);
});

export const employeeState = loadable(employeeAtom);

export const employeeQuery = (id: string) => ({
  queryKey: [id, "employeeState"],
  queryFn: () => fetchEmployee(id),
});
