import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import { colors } from "../../styles/styles";

export const Container = styled(Popover)`` as typeof Popover;

export const Title = styled.h4`
  font-size: 17px;
  line-height: 27px;
  font-weight: 700;
  color: ${colors.grayscaleAsh};
  margin: 16px 24px;
`;
